import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { IResults, MultiplayerStats, SinglePlayerStats } from '../interfaces/results.interface'
import { fontFamily } from '../../../theme/fontFamily'

interface ResultsProps {
  results: IResults
}

export const Results = ({ results }: ResultsProps) => {
  const { completionPercentage, runTime, stats, total } = results

  const isSinglePlayer = (stats: SinglePlayerStats | MultiplayerStats): stats is SinglePlayerStats => {
    return stats.hasOwnProperty('correct')
  }

  return (
    <View style={styles.container}>
      <View style={styles.result}>
        {stats && isSinglePlayer(stats) ? (
          <Text style={styles.completionText}>{completionPercentage}% Completed</Text>
        ) : (
          <Text style={styles.completionText}>You Won!</Text>
        )}
        <View style={styles.separator}></View>
        <Text style={styles.runtimeText}>Runtime</Text>
        <Text style={styles.runTime}>{runTime}</Text>
      </View>

      <View style={styles.totalsContainer}>
        {stats && isSinglePlayer(stats) ? (
          <View>
            <View style={styles.totalItem}>
              <Text style={[styles.totalItemText, styles.boldTotalItemText]}>Total Questions</Text>
              <Text style={[styles.totalItemText, styles.boldTotalItemText]}>{total}</Text>
            </View>

            <View style={styles.totalItem}>
              <Text style={styles.totalItemText}>Answered Correctly</Text>
              <Text style={styles.totalItemText}>{stats.correct}</Text>
            </View>

            <View style={styles.totalItem}>
              <Text style={styles.totalItemText}>Answered Incorrectly</Text>
              <Text style={styles.totalItemText}>{stats.wrong}</Text>
            </View>
          </View>
        ) : (
          stats && (
            <View>
              <View style={styles.totalItem}>
                <Text style={[styles.totalItemText, styles.boldTotalItemText]}>Total Rounds</Text>
                <Text style={[styles.totalItemText, styles.boldTotalItemText]}>{total}</Text>
              </View>
              <View style={styles.totalItem}>
                <Text style={styles.totalItemText}>Rounds Won By You</Text>
                <Text style={styles.totalItemText}>{stats.roundsWon}</Text>
              </View>
              <View style={styles.totalItem}>
                <Text style={styles.totalItemText}>Rounds Won By Opponent</Text>
                <Text style={styles.totalItemText}>{stats.rounds - stats.roundsWon}</Text>
              </View>

              <View style={[styles.separator, { width: '100%' }]}></View>

              <View style={styles.totalItem}>
                <Text style={[styles.totalItemText, styles.boldTotalItemText]}>Total Wars</Text>
                <Text style={[styles.totalItemText, styles.boldTotalItemText]}>{stats.wars}</Text>
              </View>
              <View style={styles.totalItem}>
                <Text style={styles.totalItemText}>Wars Won By You</Text>
                <Text style={styles.totalItemText}>{stats.warsWon}</Text>
              </View>
              <View style={styles.totalItem}>
                <Text style={styles.totalItemText}>Wars Won By Opponent</Text>
                <Text style={styles.totalItemText}>
                  {stats.wars - stats.warsWon > 0 ? stats.wars - stats.warsWon : 0}
                </Text>
              </View>
            </View>
          )
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  result: {
    display: 'flex',
    alignItems: 'center',
  },
  completionText: {
    fontFamily: fontFamily.sfProDisplayBlackItalic,
    fontSize: 24,
    lineHeight: 29,
    color: '#fff',
  },
  separator: {
    borderBottomColor: '#fff',
    borderBottomWidth: 1,
    width: '80%',
    marginVertical: 15,
  },
  runtimeText: {
    fontFamily: fontFamily.sfProDisplayHeavyItalic,
    fontSize: 14,
    lineHeight: 17,
    color: '#6E8DE3',
  },
  runTime: {
    fontFamily: fontFamily.sfProDisplayBlack,
    fontSize: 14,
    lineHeight: 17,
    color: '#fff',
    marginTop: 5,
  },
  totalsContainer: {
    marginTop: 20,
  },
  boldTotalItemText: {
    fontFamily: fontFamily.sfProDisplayBold,
  },
  totalItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  totalItemText: {
    fontFamily: fontFamily.sfProDisplayMedium,
    fontSize: 12,
    lineHeight: 14,
    color: '#fff',
  },
})
