import { Instance, SnapshotOut, types } from 'mobx-state-tree'

// import { DdLogs, DdSdkReactNative } from '@datadog/mobile-react-native'
// import crashlytics from '@react-native-firebase/crashlytics'
import { UserModelType } from '../api-store'
import { EnvironmentStages } from '../environment'
import { withRootStore } from '../extensions'

// import { initializeDataDog } from './datadog'

/**
 * A TelemetryStore model.
 */

export const TelemetryStore = types
  .model('TelemetryStore')
  .props({})
  .extend(withRootStore)
  .actions((self) => ({
    async setUser(user: UserModelType) {
      console.log('telemetry-store setUser', user)
      // try {
      //   const role = (user.userRoles ?? []).join(',')
      //   await Promise.all([
      //     crashlytics().setUserId(user._id),
      //     crashlytics().setAttributes({
      //       role,
      //       email: user.email ?? '',
      //     }),
      //     DdLogs.info('telemetry-store setUser'),
      //     DdSdkReactNative.setUser({
      //       id: user._id,
      //       name: user.name,
      //       email: user.email,
      //       type: role,
      //     }),
      //   ])
      // } catch (error) {
      //   console.error('telemetry-store setUser', error)
      // }
    },
  }))
  .actions((self) => ({
    async setTelemetryStage(stage: EnvironmentStages) {
      console.log('telemetry-store setStage', stage)
      // try {
      //   await initializeDataDog(stage)
      // } catch (error) {
      //   console.error('telemetry-store setStage', error)
      // }
    },
  }))

/**
 * The TelemetryStore instance.
 */
export type TelemetryStoreType = Instance<typeof TelemetryStore>

/**
 * The data of a TelemetryStore.
 */
export type TelemetryStoreSnapshot = SnapshotOut<typeof TelemetryStore>
