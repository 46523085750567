import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { GestureResponderEvent, TouchableOpacity, ViewProps } from 'react-native'
import { Text as CustomText } from '../'
import { TextProps } from '../text/text.props'

interface PrimaryButtonProps extends ViewProps {
  buttonStyles?: Object
  textProps: TextProps
  onPress: (event: GestureResponderEvent) => void
}

export const PrimaryButton = ({ buttonStyles, textProps, onPress }: PrimaryButtonProps) => {
  return (
    <TouchableOpacity
      onPress={(e) => {
        onPress(e)
      }}
      style={buttonStyles}
    >
      <LinearGradient
        colors={['#49CDF2', '#9444FB']}
        start={[0, 1]}
        end={[1, 0]}
        style={{ flex: 1, flexDirection: 'row', borderRadius: 6, justifyContent: 'center', alignItems: 'center' }}
      >
        <CustomText allowFontScaling={false} {...textProps} />
        {/* {loading ? <ActivityIndicator size={'small'} color={'#fff'} /> : null} */}
      </LinearGradient>
    </TouchableOpacity>
  )
}
