/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum TournamentKind {
  multiTable="multiTable",
singleTable="singleTable"
}

/**
* TournamentKind
 *
 * The specific tournament type
*/
export const TournamentKindEnumType = types.enumeration("TournamentKind", [
        "multiTable",
  "singleTable",
      ])
