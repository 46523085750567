/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app or storybook.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigation, so head over there
 * if you're interested in adding screens and navigators.
 */
import './i18n'
import './utils/ignore-warnings'
import 'react-native-gesture-handler'

import React, { useEffect, useRef } from 'react'
import { Text, TextInput, Platform } from 'react-native'
import OneSignal from 'react-native-onesignal'
import { initialWindowMetrics, initialWindowSafeAreaInsets, SafeAreaProvider } from 'react-native-safe-area-context'
// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator
import { enableFreeze, enableScreens } from 'react-native-screens'

import { NavigationContainerRef } from '@react-navigation/native'
//  import { AnalyticsProvider } from '@segment/analytics-react-native'

import { StoreProvider } from './models'
import { segmentClient } from './models/session/analytics/session-analytics'
import { canExit, RootNavigator, setRootNavigation, useBackButtonHandler, useNavigationPersistence } from './navigation'
import { initFonts } from './theme/fonts'
import * as storage from './utils/storage'

// https://github.com/software-mansion/react-native-screens#disabling-react-native-screens
// enableScreens(false)
enableScreens(true)
// https://github.com/software-mansion/react-native-screens#experimental-support-for-react-freeze
enableFreeze(true)

// NOTE: We disable accessibility font scaling to normalize the text inside the game screens
//   https://stackoverflow.com/questions/41807843/how-to-disable-font-scaling-in-react-native-for-ios-app#:~:text=However%20if%20you%20absolutely%20want,js%20)%20before%20AppRegistry.
// @ts-ignore
Text.defaultProps = Text.defaultProps || {}
// @ts-ignore
Text.defaultProps.allowFontScaling = false
// @ts-ignore
TextInput.defaultProps = TextInput.defaultProps || {}
// @ts-ignore
TextInput.defaultProps.allowFontScaling = false

export const NAVIGATION_PERSISTENCE_KEY = 'NAVIGATION_STATE'

/**
 * This is the root component of our app.
 */
const App = () => {
  //
  // FIX: flashing white screen in dark mode when loading app from splash
  // https://github.com/facebook/react-native/issues/28525
  // https://stackoverflow.com/questions/64729084/expo-app-is-blink-at-ios-when-it-is-appeared-to-front-but-android-is-no-problem
  //
  // const toggleTheme = useCallback((colorScheme) => {
  //   const statusBarTheme: StatusBarStyle = colorScheme === 'light' ? 'light-content' : 'dark-content'
  //   StatusBar.setBarStyle(statusBarTheme)
  // }, [])

  // useEffect(() => {
  //   const handleColorModeChange = (preferences: Appearance.AppearancePreferences) => {
  //     toggleTheme(preferences.colorScheme)
  //   }
  //   // delay 0.5 second to handle change
  //   Appearance.addChangeListener(
  //     throttle(handleColorModeChange, 500, {
  //       leading: false,
  //       trailing: true,
  //     })
  //   )
  //   return () => {
  //     Appearance.removeChangeListener(handleColorModeChange)
  //   }
  // }, [toggleTheme])

  const navigationRef = useRef<NavigationContainerRef>()

  setRootNavigation(navigationRef)
  useBackButtonHandler(navigationRef, canExit) // test

  const { initialNavigationState, onNavigationStateChange } = useNavigationPersistence(
    storage,
    NAVIGATION_PERSISTENCE_KEY
  )

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    const bootstrap = async () => {
      await initFonts()

      console.log('[App.useEffect] setup OneSignal')
      //OneSignal Init Code
      OneSignal.setLogLevel(6, 0)
      // vv Probably needs to be in a .env file
      OneSignal.setAppId('ee982b05-fba9-44b9-9ee3-eb432c40d1f2')

      //Prompt for push on iOS
      // OneSignal.promptForPushNotificationsWithUserResponse((response) => {
      //   console.log('Prompt response:', response)
      // })

      //Method for handling notifications received while app in foreground
      OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent) => {
        console.log('OneSignal: notification will show in foreground:', notificationReceivedEvent)
        const notification = notificationReceivedEvent.getNotification()
        console.log('notification: ', notification)
        const data = notification.additionalData
        console.log('additionalData: ', data)
        // Complete with null means don't show a notification.
        notificationReceivedEvent.complete(notification)
      })

      //Method for handling notifications opened
      OneSignal.setNotificationOpenedHandler((notification) => {
        console.log('OneSignal: notification opened:', notification)
      })
    }
    bootstrap()
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color. You can replace
  // with your own loading component if you wish.
  // if (!rootStore) return null

  // otherwise, we're ready to render the app
  return (
    <StoreProvider>
      <SafeAreaProvider initialMetrics={initialWindowMetrics} initialSafeAreaInsets={initialWindowSafeAreaInsets}>
        <RootNavigator
          ref={navigationRef}
          initialState={initialNavigationState}
          onStateChange={onNavigationStateChange}
        />
      </SafeAreaProvider>
    </StoreProvider>
  )
}

export default App
