import { debounce, get } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { PermissionsAndroid, Platform, ViewProps } from 'react-native'

import { BetKind, BettingValidator, PlayerStates, Pot, PotWinner, TableSnapshot } from '@poker-powher/poker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import PokerChips1 from '../../assets/poker-chips1.svg'
import PokerChips2 from '../../assets/poker-chips2.svg'
import PokerChips3 from '../../assets/poker-chips3.svg'
import { OptionModal } from '../../components'
import { ActionModal } from '../../components/ActionModal/actionModal'
import { BetModal } from '../../components/BetModal/betModal'
import { LeaderBoard } from '../../components/LeaderBoard/leaderBoard'
import { ModalTypes, StatusModal } from '../../components/modal/modal'
import { PotModal } from '../../components/PotModal/potModal'
import { properSuffix } from '../../hooks/proper-suffix/proper-suffix-util'
import { translate } from '../../i18n'
import { useStore } from '../../models'
import { PlayerModelType } from '../../models/api-store'
import { GameEventTypes } from '../../models/session/game/game-events'
import { GameModalKind, GameModalType, LayerType } from '../../models/session/game/game-modals'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import { emitter } from '../../utils/events'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { moderateScale } from '../../styles/sizes'

interface GameModalProps extends ViewProps {
  onClose: () => void
  turnTimer: () => number
  timerValue: number
}

export const GameModal = observer((props: GameModalProps) => {
  const { onClose, timerValue } = props

  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  // const myIndex = store.session.myPlayer?.index
  const rebuyAmount = 10000
  const stack = store.session.myPlayer?.chips ?? 0
  const modalProps: GameModalType | undefined = store.session.getFirstGameModal()
  const pokerChips = [<PokerChips1 />, <PokerChips2 />, <PokerChips3 />]
  const countPokerChips = pokerChips.length
  const actions = store.session.myActions
  const me = store.session.myPlayer

  const [errorMsg, setErrorMsg] = React.useState('')
  // let errorMsg = ''
  const [visible, setVisible] = useState(false)
  console.log('GameModal.render store.session.modalQueue.length', store.session.modalQueue.length)
  console.log('GameModal.render store.session.getFirstGameModal', modalProps)

  const isBlocking = modalProps?.isBlocking ?? false
  const isPrompt = modalProps?.layerType === LayerType.prompts

  const closeModal = (event?: unknown) => {
    console.log('GameModal.render closeModal', event)
    if (
      store.session.modalQueue.length > 0 &&
      (store.session.modalQueue[0]?.kind === 'winningHand' || store.session.modalQueue[0]?.kind === 'playerOutOfChips')
    ) {
      if (store.session.isTournamentModeOn && !store.session.isGameOver) {
        store.session.startBlindTimer()
      }
    }
    setVisible(false)
    onClose()
  }

  const doClose = debounce(async () => {
    console.log('GameModal.doClose')
    if (isBlocking) {
      return
    }
    if (!isPrompt) {
      store.session.events.clearTimeout()
    }
    closeModal()
  }, 250)

  const onModalHide = () => {
    console.log('GameModal.onModalHide modalQueue.length', store.session.modalQueue.length)
    requestAnimationFrame(() => {
      console.log(
        'GameModal.onModalHide kind and game',
        modalProps?.kind,
        store.session.channel?.channelName,
        store.session.game
      )
      store.session.shiftGameModals()
      store.session.events.checkGameEvents()
    })
  }

  useFocusEffect(
    useCallback(() => {
      let timer1: string | number | NodeJS.Timeout | undefined
      console.log('GameModal.useEffect')
      emitter.removeListener(GameEventTypes.processed, closeModal)
      if (store.session.modalQueue.length > 0) {
        const timeToKeepOpen = modalProps?.timeToKeepOpen ?? 0
        console.log('GameModal.useEffect modalProps.timeToKeepOpen', timeToKeepOpen)
        console.log('GameModal.useEffect modalProps.isPrompt', isPrompt)

        if (timeToKeepOpen > 0 && !isPrompt) {
          store.session.setisWinningModalAutoClose(true)
          store.session.setIsLeaderBoardModalAutoClose(true)
          // const timeInMilliSeconds = timeToKeepOpen * 1000
          timer1 = setTimeout(() => {
            if (store.session.isWinningModalAutoClose || store.session.isLeaderBoardModalAutoClose) {
              console.log('GameModal.useEffect# ==>>>>> Do Close')
              doClose()
            }
          }, 5000)
        }
        setVisible(true)
      }
      return () => {
        // emitter.removeAllListeners()
        clearTimeout(timer1)
        console.log('GameModal.useEffect# => return statement')
      }
    }, [store.session.modalQueue.length])
  )

  // If we catch an error after closing betModal, reopen && disply err.
  React.useEffect(() => {
    if (errorMsg !== '' && !visible) {
      setTimeout(() => {
        store.session.createBetPrompt()
      }, 500)
    }
  }, [errorMsg])

  // emitter.emit(DismissModalEvent)

  if (!modalProps) {
    return null
  }

  const defaultTable: TableSnapshot = get(store.session.game, 'table[0]', null)
  const mainPot = defaultTable?.mainPot ?? 0
  const sidePots: Pot[] = defaultTable?.sidePots ?? []

  console.log('GameModal.render sidePots', sidePots)
  const potList = sidePots
    ? sidePots.map((pot, index) => ({
        ...pot,
        icon: pokerChips[index % countPokerChips],
        playerNames: (pot.players || []).map((player) => player.name),
      }))
    : []
  // DEBUG: WinningHand
  // const winners = [{chipsWon: 123, winningCards:[{value:'2',suit:'d'}]}]
  // const won = 'AJ WINS WITH TWO PAIR!'
  // const winners = store.session.winners.slice() ?? []
  const winners: PotWinner[] = get(modalProps, 'game.table[0].winners', [])
  const myPlayerId = store.session.myPlayer?.id ?? -1
  const winningTexts = winners.map((winner) => {
    const winText = myPlayerId === winner.playerId ? 'you won' : `${winner.playerName} wins`
    if (winner.winningCards.length) {
      return `${winText} with a ${winner.rankedHandName}!`.toUpperCase()
    }
    return `${winText} everyone folded!`.toUpperCase()
  }, '')

  console.log('GameModal.render winners', winners)
  // const won = store.session.winningTexts.length > 0 ? store.session.winningTexts[0].slice() : ''
  const won = winningTexts.length > 0 ? winningTexts[0].slice() : ''
  // NOTE: mismatched type for winningCards
  const winningHand =
    winners.length > 0 ? winners[0].winningCards.map((winningCard) => `${winningCard.value}${winningCard.suit}`) : []
  // const chipsWon = winners.length > 0 ? winners[0].chipsWon : mainPot
  // DEBUG: WinningHand
  // const statusModalType = ModalTypes.winningHand
  // const statusModalVisible = true
  console.log('GameModal winningHand', winningHand)
  const statusModalType = modalProps.kind === GameModalKind.winningHand ? ModalTypes.winningHand : ModalTypes.allIn
  const status = statusModalType === ModalTypes.allIn ? store.session.allIn : won

  const doBuy = debounce(async (value: number) => {
    console.log('doBuy')
    try {
      await store.session.doBuyForPlayer(value)
      requestAnimationFrame(async () => {
        store.session.setHasUserDeclinedToBuy(false)
        await doClose()
        //NOTE: If game state is 'playing' then calling 'startGame()' won't trigger new hand
        if (store.session?.isGameReset || store.session?.isGamePaused) {
          await store.session.resumeTheGame()
        }
      })
    } catch (error) {
      const err = error.response.errors[0].message
      setResetErrorMessage(err)
      setErrorMsg(err)
    } finally {
      if (timerValue === 0) {
        await store.session.startGame()
      }
    }
  }, 250)

  const setResetErrorMessage = (snackMessage: string) => {
    //BetModal is hiding snackbar
    setErrorMsg(snackMessage)
    setTimeout(() => {
      setErrorMsg('')
    }, 5000)
  }

  const onLeaveGame = async () => {
    try {
      console.log('GameModal onLeaveGame requesting api...')
      store.session.setGameId('')
      store.session.setPlayKind('')
      doClose()
      requestAnimationFrame(async () => {
        console.log('GameModal onLeaveGame api requested')
        store.session
          .leaveCurrentGame()
          .then(() => console.log('GameModal store.session.leaveCurrentGame api completed'))
        // NOTE: Without set time out leave modal remains opened and user can't rejoin game without killing the app
        store.session.clearGameModalQueue()
        const isTournamentMode = store.session.isTournamentModeOn
        setTimeout(() => {
          isTournamentMode ? navigation.navigate(DrawerRoutes.activeGames) : navigation.navigate(DrawerRoutes.lobby)
        }, 500)
      })
    } catch (error) {
      console.error('GameModal.onLeaveGame or Game Deleted', error)
    } finally {
      console.log('GameModal.onLeaveGame finally store cleared')
    }
  }

  const onJoinCall = async () => {
    console.log('GameModal onJoinCall')
    try {
      console.log('GameModal onJoinCall requesting api...')
      requestAnimationFrame(async () => {
        await doClose()
        await store.session.resumeVideoStream()
        console.log('GameModal onJoinCall with Video api requested')
      })
    } catch (error) {
      console.error('GameModal.onJoinCall or Agora not available', error)
    } finally {
      console.log('GameModal.onJoinCall finally store cleared')
    }
  }

  const actionOnDeny = async () => {
    try {
      requestAnimationFrame(async () => {
        await doClose()
        await store.session.setPlayerBuyIn()
      })
    } catch (error) {
      console.error('GameModal.mutateSetPlayerBuyIn', error)
    }
  }

  console.log('GameModal.render modalProps.kind', modalProps.kind)
  switch (modalProps.kind) {
    case GameModalKind.sidepotPlayers:
      return (
        <StatusModal
          communityCards={[]}
          winningHandCards={[]}
          holeCards={[]}
          onClose={debounce(() => {
            doClose()
            store.session.clearAllIn()
          }, 250)}
          onModalHide={onModalHide}
          points={modalProps.gameWinner?.chipsWon || 0}
          sidePots={sidePots}
          status={modalProps.message}
          type={statusModalType}
          visible={visible}
          isFolded={me?.playerState.tag === PlayerStates.Folded.tag}
        />
      )
    case GameModalKind.winningHand:
      const players = store.session.seatedPlayers
      const activePlayers = players.filter((player) => player.chips > 0)
      const showLottieAnimation = store.session.isTournamentModeOn
        ? store.session.isGameOver
        : activePlayers.length === 1
      return (
        <StatusModal
          communityCards={modalProps.gameWinner.winningCards.length > 0 ? store.session.winnerCommunityCards : []}
          winningHandCards={modalProps.gameWinner.winningCards}
          holeCards={modalProps.gameWinner.holeCards}
          onClose={debounce(() => {
            // ========================
            // @TODO: we only need to call this when game mode autostart to trigger the newHand
            // store.session.startGame()
            // ========================
            store.session.unshiftGameWinner()
            store.session.setisWinningModalAutoClose(false)
            // emitter.emit('CloseSidePotModals')
            // emitter.removeListener('CloseSidePotModals', closeSidePotModals)
            console.log('GameModal.useEffect# In WinningHand', store.session.isWinningModalAutoClose)
            doClose()
          }, 250)}
          onModalHide={onModalHide}
          points={modalProps.gameWinner?.chipsWon || 0}
          sidePots={sidePots}
          status={modalProps.gameWinner?.winningText || ''}
          type={statusModalType}
          visible={visible}
          showLottieAnimation={showLottieAnimation}
          // showLottieAnimation={store.session.isGameOver}
          useFourColoredCards={store.session.isFourColoredDeckEnabled}
          isFolded={me?.playerState.tag === PlayerStates.Folded.tag}
        />
      )
    case GameModalKind.buy:
      return (
        <ActionModal
          key={'BuyPromptModal'}
          onAction={() => doBuy(rebuyAmount)}
          actionOnDeny={() => actionOnDeny()}
          onModalHide={onModalHide}
          onClose={() => {
            store.session.setHasUserDeclinedToBuy(true)
            store.session.setisFirstTimeLostMoney(true)
            doClose()
          }}
          message={'Buy in for'}
          stack={rebuyAmount}
          visible={visible}
          shouldHideSecondButton={false}
        />
      )

    case GameModalKind.bet:
      /**
       * use state as props calling for <BetModal />
       */
      const myPlayer = me?.player ?? { bet: 0, chips: 0 }
      const betsPot = mainPot || 50
      const betHints = store.session.defaultTable?.betHints ?? []
      let minBetAmount = 100000
      let maxBetAmount = -1
      const defaultBetValues =
        betHints.length > 0
          ? betHints
              .map((betHint) => ({
                points: betHint.amount,
                label: betHint.label,
              }))
              .reverse()
          : [
              { points: betsPot, label: 'Pot' },
              {
                points: Math.round(betsPot * 0.75),
                label: '3/4 Pot',
              },
              {
                points: Math.round(betsPot * 0.5),
                label: '1/2 Pot',
              },
              {
                points: Math.round(betsPot / 3),
                label: '1/3 Pot',
              },
            ]

      const betValues = [{ points: myPlayer.chips + myPlayer.bet, label: 'ALL IN' }].concat(
        defaultBetValues.filter((bet) => {
          //bets must be greater than my current bet, but less than my total chips and current bet
          return BettingValidator.isValidBetForPlayer(
            store.session.defaultTable?.bigBlindAmount || 50,
            store.session.defaultTable?.biggestBet || 0,
            myPlayer.bet,
            myPlayer.chips,
            bet.points
          )
        })
      )
      betValues.forEach((element) => {
        if (element.points < minBetAmount) {
          minBetAmount = element.points
        }
        if (element.points > maxBetAmount) {
          maxBetAmount = element.points
        }
      })

      const minimumAmount = get(store.session.game, 'table[0].minimumRaise', 0)
      const onBet = debounce(async (value: number) => {
        if (value === maxBetAmount) {
          try {
            doClose()
            await store.session.doAllInForPlayer()
          } catch (error) {
            const err = error.response.errors[0].message
            setVisible(true)
            setResetErrorMessage(err || 'Invalid Bet')
            setErrorMsg(err || 'Invalid Bet')
          }
        } else {
          try {
            console.log('onBet', value)
            if (
              BettingValidator.isValidBetForPlayer(
                store.session.defaultTable?.bigBlindAmount || 50,
                store.session.defaultTable?.biggestBet || 0,
                myPlayer.bet,
                myPlayer.chips,
                value
              )
            ) {
              const betKind =
                actions.includes(BetKind.RAISE.toUpperCase()) && value !== maxBetAmount ? BetKind.RAISE : BetKind.BET
              setErrorMsg('')
              // NOTE: do not await promise and block ui
              requestAnimationFrame(async () => {
                try {
                  doClose()
                  await store.session.doBetForPlayer(value, betKind)
                } catch (error) {
                  const err = error.response.errors[0].message
                  setResetErrorMessage(err)
                  setErrorMsg(err)
                }
              })
            } else {
              if (
                !BettingValidator.betAmountIsLessThanPlayersTotalChips(
                  store.session.defaultTable?.bigBlindAmount,
                  store.session.defaultTable?.biggestBet,
                  myPlayer.bet,
                  myPlayer.chips,
                  value
                )
              ) {
                const errorMessage = translate('gameScreen.betModal.maxBetAmountError', {
                  maxAmount: numberWithCommas(maxBetAmount),
                })
                setResetErrorMessage(errorMessage)
              } else if (
                BettingValidator.betAmountIsAsBigAsBigBlindBet(
                  store.session.defaultTable?.bigBlindAmount,
                  store.session.defaultTable?.biggestBet,
                  myPlayer.bet,
                  myPlayer.chips,
                  value
                )
              ) {
                const errorMessage = translate('gameScreen.betModal.minRaiseAmountError', {
                  minAmount: numberWithCommas(minimumAmount),
                })
                setResetErrorMessage(errorMessage)
              }
            }
          } catch (error) {
            const err = error.response.errors[0].message
            setResetErrorMessage(err)
            setErrorMsg(err)
          }
        }
      }, 250)

      return (
        <BetModal
          betValues={betValues}
          biggestBet={store.session.defaultTable?.biggestBet}
          onBet={onBet}
          onClose={doClose}
          onModalHide={onModalHide}
          stack={stack}
          visible={visible}
          errorMsg={errorMsg}
          turnTimer={props.turnTimer}
          actionTime={store.session.actionTime}
        />
      )

    case GameModalKind.leaveGame:
      return (
        <ActionModal
          key={'LeaveGameModal'}
          onAction={onLeaveGame}
          onModalHide={onModalHide}
          onClose={doClose}
          message={translate('gameScreen.confirmMessageBeforeLeavingGame') ?? ''}
          visible={visible}
        />
      )

    case GameModalKind.options:
      const requestCameraAndAudioPermission = async () => {
        try {
          const granted = await PermissionsAndroid.requestMultiple([
            PermissionsAndroid.PERMISSIONS.CAMERA,
            PermissionsAndroid.PERMISSIONS.RECORD_AUDIO,
          ])
          if (
            granted['android.permission.RECORD_AUDIO'] === PermissionsAndroid.RESULTS.GRANTED &&
            granted['android.permission.CAMERA'] === PermissionsAndroid.RESULTS.GRANTED
          ) {
            console.log('You can use the cameras & mic')
            startCall()
          } else {
            console.log('Permission denied')
          }
        } catch (err) {
          console.warn(err)
        }
      }

      const userId = store.session.user?._id
      const startCall = debounce(async () => {
        const playerUserId = store.session.user?._id
        const myPlayerData = store.session.gamePlayers.filter((data: PlayerModelType) => data.userId === playerUserId)

        if (myPlayerData.length > 0) {
          const playerId = Number(myPlayerData[0].id)
          if (Platform.OS === 'ios') {
            doClose()
          }
          store.session.createJoinVideoPrompt()
          await store.session.startCall(playerId)
        }
      }, 250)

      const endCall = debounce(async () => {
        doClose()
        await store.session.endCall()
      }, 250)

      const muteLocalUser = debounce(async () => {
        doClose()
        await store.session.muteVideoCall()
      }, 250)

      const unMuteLocalUser = debounce(async () => {
        doClose()
        await store.session.unMuteVideoCall()
      }, 250)

      const hideVideoLocalUser = debounce(async () => {
        doClose()
        await store.session.pauseVideoStream()
      }, 250)

      const showVideoLocalUser = debounce(async () => {
        doClose()
        await store.session.resumeVideoStream()
      }, 250)

      const removePlayer = async () => {
        try {
          await store.session.removePlayerFromGame()
          await doClose()
        } catch (error) {
          console.log('Error in removing player from game')
        }
      }

      const seatBackPlayer = async () => {
        try {
          await store.session.seatBackPlayerInGame()
          await doClose()
        } catch (error) {
          console.log('Error in sitting back player in game')
        }
      }

      return (
        <OptionModal
          key={'OptionModal'}
          visible={visible}
          onModalHide={onModalHide}
          joinVideoCall={() => {
            if (Platform.OS === 'android' && visible) {
              doClose()
              setTimeout(() => {
                requestCameraAndAudioPermission()
              }, 1000)
            } else {
              startCall()
            }
          }}
          leaveVideoCall={endCall}
          muteCall={muteLocalUser}
          unMuteCall={unMuteLocalUser}
          hideVideo={hideVideoLocalUser}
          showVideo={showVideoLocalUser}
          isLocalUserMuted={store.session.isLocalUserMuted}
          isLocalUserVideoHidden={store.session.isLocalUserVideoHidden}
          isLocalUserJoined={store.session.isLocalUserJoined}
          localUserPlayerState={store.session.myPlayer?.playerState.tag}
          onClose={doClose}
          onMuteEveryone={() => {
            store.session.muteEveryone()
            doClose()
          }}
          onUnmuteEveryone={() => {
            store.session.unmuteEveryone()
            doClose()
          }}
          isEveryoneMute={store.session.isEveryoneMute}
          unseatPlayer={() => {
            store.session.unseatPlayer()
            doClose()
          }}
          isTeacher={store.session.isTeacher}
          playerUserIdForTeacher={store.session.playerUserIdForTeacher}
          myUserId={userId}
          onRemovingPlayer={removePlayer}
          players={store.session.seatedPlayers}
          onSeatBackPlayer={seatBackPlayer}
        />
      )

    case GameModalKind.joinVideo:
      return (
        <ActionModal
          key={'JoinVideoModal'}
          onAction={onJoinCall}
          onModalHide={onModalHide}
          onClose={doClose}
          message={translate('gameScreen.joinVideoCall') ?? ''}
          visible={visible}
        />
      )

    case GameModalKind.pot:
      return (
        <PotModal key={'PotModal'} potList={potList} onModalHide={onModalHide} visible={visible} onClose={doClose} />
      )
    case GameModalKind.gameDeleted:
      const title = translate('gameScreen.gameDeletedTitle')
      const message = translate('gameScreen.gameDeletedMessage')

      return (
        <ActionModal
          key={'gameDeleted'}
          onAction={async () => {
            // NOTE: Without set time out leave modal remains opened and user can't rejoin game without killing the app
            store.session.unsubscribeOnGameDelete()
            await doClose()
            // NOTE: Manually closing because 'isBlocking' is true
            setVisible(false)
            setTimeout(() => {
              navigation.navigate(DrawerRoutes.lobby)
            }, 1000)
          }}
          onModalHide={onModalHide}
          onClose={doClose}
          message={message}
          visible={visible}
          buttonTitle1={translate('common.ok')}
          shouldHideSecondButton={true}
        />
      )

    case GameModalKind.terminateGame:
      const msg = translate('gameScreen.terminateGameMessage')
      return (
        <ActionModal
          key={'TerminateGame'}
          onAction={async () => {
            try {
              const gameId = store.session.gameId
              if (gameId) {
                await store.session.terminateGame(gameId)
              }
            } catch (error) {
              console.log('Error In terminating game: ', error)
            }
          }}
          onModalHide={onModalHide}
          onClose={doClose}
          message={msg}
          visible={visible}
        />
      )

    case GameModalKind.leaderBoard:
      const playerUserId = store.session.user?._id
      const observerArray = store.session.observerPlayer
      const myPlayerData = store.session.gamePlayers.filter((data: PlayerModelType) => data.userId === playerUserId)
      const playerId = myPlayerData[0] && Number(myPlayerData[0].id)

      if (store.session.observerPlayer) {
        const observerPlayer = store.session.gamePlayers.filter((data: PlayerModelType) => {
          for (let observer of observerArray) {
            return data.userId === observer
          }
        })
        if (observerPlayer.length > 0) {
          for (var i = 0; i < observerPlayer.length; i++) {
            store.session.addObserverPlayersNumber(Number(observerPlayer[i].id))
          }
        }
      }

      return (
        <LeaderBoard
          key={'LeaderBoard'}
          onAction={async () => {}}
          onModalHide={() => {
            store.session.setIsLeaderBoardModalAutoClose(false)
            onModalHide()
            if (store.session.seatedPlayers.length === 1) {
              onLeaveGame()
            }
          }}
          onClose={() => {
            store.session.setIsLeaderBoardModalAutoClose(false)
            doClose()
            if (store.session.seatedPlayers.length === 1) {
              onLeaveGame()
            }
          }}
          visible={visible}
          message={'Shiv Won!'}
          boardData={store.session.leaderboardData}
          observers={store.session.observerPlayerNumber}
          myPlayerId={playerId}
          isAccessedFromDrawer={store.session.isLeaderboardAccessedFromDrawer}
          isGameOver={store.session.isGameOver}
        />
      )

    case GameModalKind.playerOutOfChips:
      const suffixRank = properSuffix(store.session.myRank)
      const outOfChipsMessage = translate('gameScreen.PlayerOutOfChipsMessage', {
        rank: store.session.myRank,
        suffix: suffixRank,
      })
      return (
        <StatusModal
          communityCards={[]}
          winningHandCards={[]}
          holeCards={[]}
          onClose={debounce(() => {
            store.session.clearAllIn()
            doClose()
          }, 250)}
          onModalHide={onModalHide}
          points={modalProps.gameWinner?.chipsWon || 0}
          sidePots={sidePots}
          status={outOfChipsMessage}
          type={statusModalType}
          visible={visible}
          outOfChipsTextStyle={{
            fontSize: moderateScale(25),
          }}
          isFolded={me?.playerState.tag === PlayerStates.Folded.tag}
        />
      )
    default:
      return null
  }
})
