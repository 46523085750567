import * as React from 'react'
import Svg, { G, Defs, Rect, ClipPath, Use, Path } from 'react-native-svg'

export const AvatarSvgWeb = (props: { avatarColor: string }) => {
  const avatarComponent = () => {
    switch (props.avatarColor) {
      case 'purple':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 799 798.5"
            enableBackground="new 0 0 799 798.5"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.5} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#5C2DA8" d="M799.5,0h-800v800h800V0z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M484.849,492.057c44.646-28.09,74.313-77.802,74.313-134.441 c0-87.663-71.064-158.729-158.729-158.729c-87.662,0-158.727,71.065-158.727,158.729c0,56.641,29.668,106.353,74.312,134.441 c-89.826,34.068-153.676,120.926-153.676,222.693c0,2.92,0.053,5.827,0.157,8.721V1031h476V718.231 c0.017-1.158,0.025-2.319,0.025-3.481C638.525,612.981,574.675,526.125,484.849,492.057z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'pink':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 799 798.5"
            enableBackground="new 0 0 799 798.5"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.5} y={-0.75} width={'auto'} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#AB037C" d="M799.5-0.75h-800v800h800V-0.75z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M484.849,491.421c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.595 c0.017-1.159,0.025-2.317,0.025-3.479C638.525,612.345,574.675,525.489,484.849,491.421z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'green':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 798.5 799"
            enableBackground="new 0 0 798.5 799"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.75} y={-0.5} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#128942" d="M799.25-0.5h-800v800h800V-0.5z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M484.599,491.671c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.845 c0.017-1.159,0.025-2.317,0.025-3.479C638.275,612.595,574.425,525.739,484.599,491.671z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'blue':
        return (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 798.5 798.5"
            enable-background="new 0 0 798.5 798.5"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <defs>
                  <rect id="SVGID_1_" x="-0.75" y="-0.75" width="800" height="800" />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlinkHref="#SVGID_1_" overflow="visible" />
                </clipPath>
                <g clip-path="url(#SVGID_2_)">
                  <path fill="#0271B7" d="M799.25-0.75h-800v800h800V-0.75z" />
                  <path
                    fill="#FFFFFF"
                    fill-opacity="0.3"
                    d="M484.599,491.421c44.646-28.09,74.313-77.802,74.313-134.444
				c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444
				c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.595
				c0.017-1.159,0.025-2.317,0.025-3.479C638.275,612.345,574.425,525.489,484.599,491.421z"
                  />
                </g>
              </g>
            </g>
          </svg>
        )

      case 'orange':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 800 798"
            enableBackground="new 0 0 800 798"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" y={-1} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#D96800" d="M800-1H0v800h800V-1z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M485.349,491.171c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.345 c0.017-1.159,0.025-2.317,0.025-3.479C639.025,612.095,575.175,525.239,485.349,491.171z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'red':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 799.5 798.5"
            enableBackground="new 0 0 799.5 798.5"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.25} y={-0.75} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#C41313" d="M799.75-0.75h-800v800h800V-0.75z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M485.099,491.421c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.595 c0.017-1.159,0.025-2.317,0.025-3.479C638.775,612.345,574.925,525.489,485.099,491.421z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'crimson':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 798.5 799"
            enableBackground="new 0 0 798.5 799"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.75} y={-0.5} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#FF7F50" d="M799.25-0.5h-800v800h800V-0.5z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M484.599,491.671c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.845 c0.017-1.159,0.025-2.317,0.025-3.479C638.275,612.595,574.425,525.739,484.599,491.671z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'teal':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 798 799"
            enableBackground="new 0 0 798 799"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-1} y={-0.5} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#5B6196" d="M799-0.5H-1v800h800V-0.5z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M484.349,491.671c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.845 c0.017-1.159,0.025-2.317,0.025-3.479C638.025,612.595,574.175,525.739,484.349,491.671z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )

      case 'lightblue':
        return (
          <Svg
            width="100%"
            height="100%"
            viewBox="0 0 800 800"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <G clipPath="url(#clip0)">
              <Path d="M800 0H0V800H800V0Z" fill="#02A0C2" />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M485.349 492.171C529.993 464.081 559.661 414.369 559.661 357.727C559.661 270.065 488.597 199 400.934 199C313.272 199 242.207 270.065 242.207 357.727C242.207 414.369 271.875 464.08 316.519 492.171C226.693 526.239 162.843 613.095 162.843 714.864C162.843 717.783 162.896 720.691 163 723.585V1031.11H639V718.345C639.017 717.186 639.025 716.026 639.025 714.864C639.025 613.095 575.175 526.239 485.349 492.171Z"
                fill="white"
                fillOpacity={0.3}
              />
            </G>
            <Defs>
              <ClipPath id="clip0">
                <Rect width={800} height={800} fill="white" />
              </ClipPath>
            </Defs>
          </Svg>
        )

      case 'gray':
        return (
          <Svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 799.5 799"
            enableBackground="new 0 0 799.5 799"
            xmlSpace="preserve"
            {...props}
          >
            <G>
              <G>
                <Defs>
                  <Rect id="SVGID_1_" x={-0.25} y={-0.5} width={800} height={800} />
                </Defs>
                <ClipPath id="SVGID_2_">
                  <Use xlinkHref="#SVGID_1_" overflow="visible" />
                </ClipPath>
                <G clipPath="url(#SVGID_2_)">
                  <Path fill="#343434" d="M799.75-0.5h-800v800h800V-0.5z" />
                  <Path
                    fill="#FFFFFF"
                    fillOpacity={0.3}
                    d="M485.099,491.671c44.646-28.09,74.313-77.802,74.313-134.444 c0-87.662-71.064-158.727-158.729-158.727c-87.66,0-158.727,71.065-158.727,158.727c0,56.642,29.668,106.353,74.312,134.444 c-89.826,34.068-153.676,120.924-153.676,222.693c0,2.919,0.053,5.827,0.157,8.721v307.525h476V717.845 c0.017-1.159,0.025-2.317,0.025-3.479C638.775,612.595,574.925,525.739,485.099,491.671z"
                  />
                </G>
              </G>
            </G>
          </Svg>
        )
    }
  }
  return <React.Fragment>{avatarComponent()}</React.Fragment>
}
