import 'react-native-get-random-values'
import React from 'react'
import { ActivityIndicator, Image, ImageSourcePropType, View, ViewProps } from 'react-native'
import { LinearText, Text } from '../../components'
import startStyles from './styles'

export enum Options {
  joinGame = 'joinGame',
  createGame = 'createGame',
  communityGame = 'communityGame',
}

interface CardProps extends ViewProps {
  description?: string
  heading1: string
  heading2: string
  icon: ImageSourcePropType
  loading: boolean
  showBanner: boolean
}

export const Card = (props: CardProps) => {
  return (
    <View style={startStyles.cardContainer}>
      <View style={startStyles.cardIconContainer}>
        <Image
          source={props.icon}
          style={[startStyles.shareIcon, props.showBanner ? { transform: [{ translateY: -8 }] } : {}]}
        />
      </View>
      {props.description !== '' ? (
        <View style={startStyles.cardTitleContainer}>
          <LinearText txt={props.heading1} />
          {props.heading2 !== '' ? <LinearText txt={props.heading2} /> : null}
          <Text allowFontScaling={false} tx={props.description} style={startStyles.createGameButtonMeassage} />
        </View>
      ) : (
        <View
          style={[
            startStyles.cardTitleContainer,
            {
              display: 'flex',
              justifyContent: 'center',
            },
          ]}
        >
          <LinearText txt={props.heading1} />
          {props.heading2 !== '' ? <LinearText txt={props.heading2} /> : null}
        </View>
      )}
      {props.loading ? (
        <View style={startStyles.loader}>
          <ActivityIndicator size="large" />
        </View>
      ) : null}
      {props.showBanner ? (
        <View style={startStyles.bannerContainer}>
          <View style={startStyles.banner}>
            <Text style={startStyles.bannerText} text="COMING SOON" />
          </View>
        </View>
      ) : null}
    </View>
  )
}
