/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * GameArchiveEntityBase
 * auto generated base class for the model GameArchiveEntityModel.
 */
export const GameArchiveEntityModelBase = ModelBase
  .named('GameArchiveEntity')
  .props({
    __typename: types.optional(types.literal("GameArchiveEntity"), "GameArchiveEntity"),
    _id: types.identifier,
    channelName: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    forPlayers: types.union(types.undefined, types.frozen()),
    gameId: types.union(types.undefined, types.string),
    hostId: types.union(types.undefined, types.frozen()),
    snapshot: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    users: types.union(types.undefined, types.array(types.string)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GameArchiveEntityModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get channelName() { return this.__attr(`channelName`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get forPlayers() { return this.__attr(`forPlayers`) }
  get gameId() { return this.__attr(`gameId`) }
  get hostId() { return this.__attr(`hostId`) }
  get snapshot() { return this.__attr(`snapshot`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get users() { return this.__attr(`users`) }
}
export function selectFromGameArchiveEntity() {
  return new GameArchiveEntityModelSelector()
}

export const gameArchiveEntityModelPrimitives = selectFromGameArchiveEntity()._id.channelName.createdAt.forPlayers.gameId.hostId.snapshot.updatedAt.users
