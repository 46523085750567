/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
// import { AxiosResponse } from 'axios'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { UserModel, UserModelType, UserRoles } from '../api-store'
import { withRootStore } from '../extensions'

/**
 * A SessionUserStore model.
 */
export const SessionUserStoreModel = types
  .model('SessionUserStoreModel')
  .props({
    user: types.maybe(UserModel),
  })
  .extend(withRootStore)
  // .views((self) => ({
  //   get name() {
  //     if (self.firstName && self.lastName) {
  //       return `${self.firstName} ${self.lastName}`
  //     }
  //     return ''
  //   },
  // }))
  .actions((self) => ({
    clearUser() {
      self.user = undefined
    },
    setUser(data: UserModelType) {
      self.user = data
    },
  }))
  .actions((self) => ({
    async fetchMe() {
      try {
        const user = await self.rootStore.api.queryMe(
          {},
          '_id  createdAt name avatar  email loginFrom phoneNumber userRoles socialId updatedAt',
          { fetchPolicy: 'no-cache' }
        )
        console.log('session-user fetchMe', user)
        if ('me' in user && user.me) {
          if (UserModel.is(user.me)) {
            self.setUser(UserModel.create(user.me))
            console.log('session-user fetchMe', self.user)
            if (self.user?._id) {
              self.rootStore.telemetry.setUser(self.user)
            }
          }
        }
      } catch (error) {
        console.error('session-user fetchMe', error)
        throw error
      }
    },
    async deleteAccountData(userId: string) {
      if (userId) {
        try {
          await self.rootStore.api.mutateDeleteUser({id: userId})
          await self.rootStore.api.mutateDeletePlayerStats({id: userId})
        } catch (error) {
          console.error('session-user deleteAccountData', error)
          throw error
        } finally {
          console.log(`Successfully deleted user with userId: ${userId} and all of their data.`)
          await self.rootStore.session.logout();
          await self.rootStore.session.leaveCurrentGame();
        }
      }
    },
  }))
  .views((self) => ({
    get firstName() {
      const [firstName, lastName] = (self.user?.name ?? ' ').split(' ')
      return firstName ?? ''
    },
    get lastName() {
      const [firstName, lastName] = (self.user?.name ?? ' ').split(' ')
      return lastName ?? ''
    },
    get isTeacher() {
      const roles = self.user?.userRoles ?? ([UserRoles.user] as UserRoles[])
      console.log('isTeacher', roles)
      return roles.includes(UserRoles.teacher)
    },
    get isAdmin() {
      const roles = self.user?.userRoles ?? ([UserRoles.admin, UserRoles.superadmin] as UserRoles[])
      console.log('isAdmin', roles)
      return roles.includes(UserRoles.admin)
    },
    get isSuperAdmin() {
      const roles = self.user?.userRoles ?? ([UserRoles.superadmin] as UserRoles[])
      console.log('isSuperAdmin', roles)
      return roles.includes(UserRoles.superadmin)
    },
    get isHost(): boolean {
      const channelName = self.rootStore.session.channel ? self.rootStore.session.channel.hostId : ''
      const userId = self.rootStore.session.user?._id
      return channelName === userId
    },
    getRolesByEmail(email: string): UserRoles[] {
      if (email.match(/pokerpowh?er.com/i)?.length ?? 0 > 0) {
        return [UserRoles.user, UserRoles.teacher]
      } else if (email.match(/apexfintechsolutions.com/i)?.length ?? 0 > 0) {
        return [UserRoles.user, UserRoles.teacher]
      } else if (email.match(/peak6.com/i)?.length ?? 0 > 0) {
        return [UserRoles.user, UserRoles.teacher]
      }
      return [UserRoles.user]
    },
    async updateName(username: string) {
      try {
        const userId = self.rootStore.session.user?._id.toString()
        if (userId) {
          const payload = {
            id: userId,
            user: {
              name: username,
            },
          }
          const response = await self.rootStore.api.mutateUpdateUser(payload)
          console.log('Update name response: ', response)
        }
      } catch (error) {
        console.log('Session User Update Name Error: ', error)
        throw error
      }
    },
  }))
  .actions((self) => {
    const afterAttach = async () => {
      console.log('session-user afterAttach')
      if (self.rootStore.session.isLoggedIn && !self.user) {
        await self.fetchMe()
      }
      console.log('session-user afterAttach me', self.user)
    }
    return { afterAttach }
  })

// .actions((self) => {
//   const root = self.rootStore
//   const getUser = async () => {
//     try {
//       const response: IUserResponse = await root.services.api.session.get('/users')
//       const { data } = response
//       self.setUser(data)
//     } catch (error) {
//       console.error('unable to getUser', { error })
//     }
//     return self
//   }

//   return { getUser }
// })

/**
 * The SessionUserStore instance.
 */
export type SessionUserStore = Instance<typeof SessionUserStoreModel>

/**
 * The data of a SessionUserStore.
 */
export type SessionUserStoreSnapshot = SnapshotOut<typeof SessionUserStoreModel>
