import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import styles from '../../styles/globalStyle'
import { Card } from '../card/card'
import { CardTilt } from '../card/card.base'

export interface PlayerHoleCardsProps {
  hand: string[]
  customCardContainerStyle?: StyleProp<ViewStyle>
  customLeftCardStyle?: StyleProp<ViewStyle>
  customRightCardStyle?: StyleProp<ViewStyle>
  useFourColoredCardStyle: boolean
  rankedHandName?: string
  disabledCards?: string[]
  isFolded: boolean
}

const startStyles = StyleSheet.create({
  rankedHandContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
    width: '100%'
  },
  rankHandName: {
    color: '#fff',
    fontWeight: '400',
    textAlign: 'center',
  },
  foldedStyle: {
    position: 'absolute',
    zIndex: 1,
    top: -5,
    left: -8,
    backgroundColor: 'rgba(5, 16, 45, 0.6)',
    height: '110%',
    width: '110%',
  },
})

export const PlayerHoleCards = observer<PlayerHoleCardsProps>(function PlayerHoleCards(props: PlayerHoleCardsProps) {
  const {
    hand,
    customLeftCardStyle,
    customRightCardStyle,
    customCardContainerStyle,
    useFourColoredCardStyle,
    rankedHandName,
    disabledCards,
    isFolded,
  } = props

  const isCardDisabled = (cardName: string) => {
    if (disabledCards && disabledCards.length > 0) {
      const index = disabledCards.indexOf(cardName)
      return index === -1 ? false : true
    } else {
      return false
    }
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={[styles.cardActionRow, customCardContainerStyle]}>
        {isFolded && <View style={startStyles.foldedStyle} />}
        <View
          shouldRasterizeIOS={true}
          style={[styles.cardLeftActionLayout, { opacity: isCardDisabled(hand[0]) ? 0.4 : 1 }, customLeftCardStyle]}
        >
          <Card name={hand[0]} tilt={CardTilt.left} fourColor={useFourColoredCardStyle} />
        </View>
        <View
          shouldRasterizeIOS={true}
          style={[styles.cardRightActionLayout, { opacity: isCardDisabled(hand[1]) ? 0.4 : 1 }, customRightCardStyle]}
        >
          <Card name={hand[1]} tilt={CardTilt.right} fourColor={useFourColoredCardStyle} />
        </View>
      </View>
      {rankedHandName ? (
        <View style={startStyles.rankedHandContainer}>
          <Text style={startStyles.rankHandName}>{rankedHandName}</Text>
        </View>
      ) : null}
    </View>
  )
})
