'use strict'
import { StyleSheet,  PlatformIOSStatic, Platform  } from 'react-native'
import { moderateScale, scale, verticalScale } from '../styles/sizes'
import { color } from '../theme'

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  viewMain: {
    flex: 1,
    backgroundColor: color.palette.white,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: verticalScale(20),
  },
  viewMainSmall: {
    backgroundColor: color.palette.white,
    marginHorizontal: scale(50),
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMainLarge: {
    backgroundColor: color.palette.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMainLargeStart: {
    backgroundColor: color.palette.white,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  viewMainLargeEnd: {
    backgroundColor: color.palette.white,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewMainRow: { flexDirection: 'row' },
  viewTopSpace: { marginTop: 20 },
  textLarge: {
    fontSize: 28,
    lineHeight: 33.89,
    color: color.palette.HighSlateBlue,
    fontWeight: '700',
    paddingVertical: 5,
    textAlign: 'center',
  },
  textLargeStart: {
    fontSize: 24,
    lineHeight: 29,
    color: color.palette.HighSlateBlue,
    fontWeight: 'bold',
    paddingVertical: 5,
    // left: 20,
  },
  textMedium: {
    fontSize: 18,
    color: color.palette.HighSlateBlue,
    fontWeight: 'bold',
  },
  textMediumBold: {
    fontSize: 16,
    color: color.palette.HighSlateBlue,
    fontWeight: 'bold',
  },
  textSmall: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19.36,
    color: color.palette.HighSlateBlue,
    textAlign: 'center',
    opacity: 0.6,
  },
  textSmallWithPadding: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19.36,
    color: color.palette.HighSlateBlue,
    textAlign: 'center',
    paddingVertical: 10,
    marginHorizontal: scale(50),
    opacity: 0.6,
  },
  textOuter: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19.36,
    color: '#666f8a',
    textAlign: 'center',
    paddingVertical: 10,
    marginHorizontal: scale(46),
  },
  dotContainer: {
    backgroundColor: color.palette.white,
    flexDirection: 'row',
    marginVertical: 52,
  },
  dotButtonActive: {
    marginHorizontal: scale(10),
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: color.palette.HighSlateBlue,
  },
  dotButtonInActive: {
    marginHorizontal: 10,
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: color.palette.gray,
  },
  buttonDark: {
    backgroundColor: color.palette.HighSlateBlue,
    width: scale(335),
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginVertical: 15,
  },
  buttonDarkText: {
    fontSize: 16,
    color: color.palette.white,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 19.36,
  },
  textInput: {
    width: scale(335),
    height: 50,
    borderRadius: 10,
    backgroundColor: color.palette.lighterGrey,
    color: color.palette.HighSlateBlue,
    borderBottomWidth: 0,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
    marginTop: 30,
    marginBottom: 27,
    paddingHorizontal: 20,
  },
  textInputBox: {
    width: scale(40),
    height: 50,
    borderRadius: 10,
    backgroundColor: color.palette.lighterGrey,
    borderBottomWidth: 0,
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 36,
    marginVertical: 30,
    paddingHorizontal: 10,
    marginHorizontal: scale(7),
  },
  linkText: {
    textDecorationLine: 'underline',
    fontSize: 14,
    lineHeight: 17,
    color: color.palette.HighSlateBlue,
    textDecorationColor: color.palette.HighSlateBlue,
  },
  errorText: { color: '#dd3333', fontSize: 10 },
  bottomTextContainer: { backgroundColor: color.palette.white, justifyContent: 'flex-end', alignItems: 'center' },
  bottomViewContainer: { flex: 1, justifyContent: 'flex-end', alignItems: 'center' },
  headerContainerMain: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainerCenterText: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 19,
    color: color.palette.LowSlateBlue,
    textAlign: 'center',
  },
  headerContainerCenterImage: { height: 40, width: 80 },
  headerContainerLeftImage: { height: 20, width: 20 },
  headerContainerRightImage: { height: 20, width: 22.5 },
  headerContainerBackImage: { height: 12, width: 6 },
  headerContainerBackText: { paddingHorizontal: 10, fontSize: 16, fontWeight: '500', lineHeight: 19 },
  videoMainView: {
    width: scale(376),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  homeBottomView: {
    alignItems: 'center',
    width: scale(375),
    height: 115,
    backgroundColor: color.palette.white,
    justifyContent: 'flex-end',
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  addButtonView: { position: 'absolute', bottom: 45 },
  addButtonIcon: {
    width: 74,
    height: 74,
    resizeMode: 'contain',
  },
  buttonContainerMain: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainerImage: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
    marginVertical: 12,
  },
  buttonContainerText: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 15,
    color: color.palette.white,
  },
  cardPointsView: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 18,
    width: '100%',
    marginVertical: isIPad ? 0 : 2,
  },
  cardRow: { flexDirection: 'row', marginLeft: 0 },
  cardLayout: { width: moderateScale(48), height: moderateScale(72), backgroundColor: '#686D96', marginLeft: 2 },
  disabedCardLayout: {
    opacity: 0.4,
    borderRadius: 2,
    width: moderateScale(48),
    height: moderateScale(72),
    backgroundColor: '#fff',
    marginLeft: 2,
  },
  cardImage: { width: '100%', height: '100%', resizeMode: 'cover' },
  cardActionContainer: {
    flexDirection:  isIPad ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    width: '100%',
    // marginTop: 10,
    // minHeight: moderateScale(120),
    // marginBottom: 16,
    marginVertical: 4,
  },
  cardActionRow: { flexDirection: 'row', marginRight: 0 },
  cardRightActionLayout: {
    transform: [{ rotate: '7deg' }],
    width: moderateScale(80.5 * .70),
    height: moderateScale(120.24 * .70),
  },
  cardLeftActionLayout: {
    transform: [{ rotate: '-4deg' }],
    left: -4,
    width: moderateScale(80.5 * .70),
    height: moderateScale(120.24 * .70),
  },
  highPairContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginVertical: 3 },
  pairSignal: { backgroundColor: '#02B90A', width: 10, height: 10, borderRadius: 5, marginRight: 6 },
  highPairText: { textAlign: 'center', color: '#FFF', fontSize: 12, fontWeight: '500' },
})
