import { StyleSheet, Platform, PlatformIOSStatic } from 'react-native'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  cardContainer: {
    flexDirection: 'row',
    marginTop: 18,
    marginHorizontal: isIPad ? 135 : 30,
    borderRadius: 12,
    backgroundColor: 'white',
    paddingTop: 15,
    paddingBottom: 18,
  },
  cardIconContainer: {
    flex: 1.4,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  cardTitleContainer: {
    flex: 2.6,
    paddingRight: 30,
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  loader: {
    position: 'absolute',
    // backgroundColor: '#00000030',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    // position: isIPad ? 'absolute' : undefined,
    // bottom: isIPad ? 0 : undefined,
    // height: '100%',
    // width: '100%',
  },
  lottieImage: {
    // position: 'absolute',
    // top: '8%',
    height: 300,
    width: '100%',
    marginVertical: 10,
    alignSelf: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
  inviteByCodeText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    paddingRight: 4,
  },
  inviteByCodeContainer: {
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareIcon: {
    width: 58,
    height: 58,
    resizeMode: 'contain',
    // marginRight: 23,
  },
  createGameButtonMeassage: {
    fontWeight: '400',
    fontSize: moderateScale(12),
    color: '#646464',
    fontFamily: fontFamily.sfProTextRegular,
  },
  createGameAndInviteButton: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: moderateScale(89),
    maxHeight: isIPad ? 140 : undefined,
    width: moderateScale(320),
    // maxWidth: isIPad ? 400 : undefined,
    borderRadius: 10,
    borderColor: '#EAEAEA',
    borderWidth: 1,
    backgroundColor: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.01)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    paddingVertical: isIPad ? 0 : 20,
    paddingHorizontal: 23,
    shadowRadius: 4,
    elevation: 5,
  },
  bannerContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 28,
  },
  banner: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,190,0,1)',
    zIndex: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  bannerText: {
    color: 'white',
    zIndex: 100,
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
  },
})

export default styles
