import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import { observer } from 'mobx-react-lite'
import { GameScreen } from '../screens'
import { ChatOverlay } from '../components/ChatOverlay/ChatOverlay'
import { BackButton } from '../components/BackButton/BackButton'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { useStore } from '../models'
import React from 'react'
import { View, Text, Image, Platform, StyleSheet, PlatformIOSStatic } from 'react-native'
import { DrawerHamburgerButton } from './drawer-navigator'
import { translate } from '../i18n'
import { ViewObserverButton, ObserverModal } from '../components/Observer/observer'
const chatImg = require('../../assets/icons/chat-double-white.png')
const observerImg = require('../../app/assets/ObserverIcon.png')

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export enum GameRoutes {
  gameScreen = 'gameScreen',
  chatOverlay = 'chatOverlay',
  observerOverlay = 'observerOverlay'
}

export type ModalParamList = Record<keyof typeof GameRoutes, undefined>

const Stack = createStackNavigator<ModalParamList>()
export const GameNavigator = observer(() => {
  const navigation = useNavigation()
  const store = useStore()
  const observerPlayers = store.session?.observerPlayer
  const [gameState, setGameState] = React.useState('')

  const navToGameScreen = () => {
    navigation.navigate(GameRoutes.gameScreen)
  }

  const navToLobby = () => {
    store.session.createLeaveGamePrompt()
  }

  React.useEffect(() => {
    if (store.session.game && store.session.gameStartTime > 0) {
      if (store.session?.isGamePaused) {
        setGameState(translate('common.gamePaused'))
      } else {
        if (gameState === translate('common.gamePaused')) {
          setGameState(translate('common.gameResumed'))
          setTimeout(() => {
            setGameState('')
          }, 5000)
        }
      }
    } else {
      setGameState('')
    }
  }, [store.session?.isGamePaused, store.session?.gameStartTime])

  return (
    <Stack.Navigator
      initialRouteName={GameRoutes.gameScreen}
      screenOptions={{
        headerShown: true,
        gestureEnabled: false,
        headerStyle: { 
          borderBottomWidth: 0, 
          backgroundColor: 'rgb(5, 16,45)', 
          height: 86, 
        },
        cardOverlayEnabled: true,
        cardStyle: {backgroundColor: 'transparent'},
        presentation: 'transparentModal',
        ...Platform.select({
          android:
            Platform.Version >= 28
              ? TransitionPresets.RevealFromBottomAndroid
              : TransitionPresets.FadeFromBottomAndroid,
          default: TransitionPresets.ModalSlideFromBottomIOS,
        }),
      }}
    >
      <Stack.Screen 
        name={GameRoutes.gameScreen} 
        component={GameScreen} 
        options={{
          headerLeft: () => (
            <View style={styles.backContainer}>
              <BackButton navigate={navToLobby}/>
            </View>
          ),
          headerTitle: () => {
              return (             
                <View style={[{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}]}>
                  <Text style={[{color: 'white', fontSize: 18, fontWeight: '500',paddingHorizontal: 5}]}>
                    {gameState}
                  </Text>
                </View>
              )
          },
          headerRight: () => (
            <>
              <ViewObserverButton disabled={observerPlayers.length> 0 ? false : true}/>
              <DrawerHamburgerButton />
            </>
          ),
          headerLeftContainerStyle: {
            flex: 1.5
          },
          headerTitleContainerStyle: {
            flex: 2
          },
          headerRightContainerStyle: {
            flex: 1.5,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }
        }}
      />
      <Stack.Screen 
        name={GameRoutes.chatOverlay} 
        component={ChatOverlay} 
        options={{
          headerLeft: () => (
            <View style={styles.backContainer}>
              <BackButton navigate={navToGameScreen}/>
            </View>
          ),
          headerTitle: () => (
            <View style={[{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}]}>
              <Image source={chatImg} style={{ width: 24, height: 24, resizeMode: 'contain', paddingHorizontal: 5 }} />
              <Text style={[{color: 'white', fontSize: 18, fontWeight: '500',paddingHorizontal: 5}]}>Chat</Text>
            </View>
          ),
          headerRight: () => (
            <DrawerHamburgerButton />
          ),
          headerLeftContainerStyle: {
            flex: 1.5
          },
          headerTitleContainerStyle: {
            flex: 2
          },
          headerRightContainerStyle: {
            flex: 1.5
          },
        }}
      />
      <Stack.Screen
        name={GameRoutes.observerOverlay}
        component={ObserverModal}
        options={{
          headerLeft: () => (
            <View style={styles.backContainer}>
              <BackButton navigate={navToGameScreen}/>
            </View>
          ),
          headerTitle: () => (
            <View style={[{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}]}>
              <Image source={observerImg} style={{ width: 24, height: 24, resizeMode: 'contain', paddingHorizontal: 5 }} />
              <Text style={[{color: 'white', fontSize: 18, fontWeight: '500',paddingHorizontal: 5}]}>Observer</Text>
            </View>
          ),
          headerRight: () => (
            <DrawerHamburgerButton />
          ),
          headerLeftContainerStyle: {
            flex: 1.5
          },
          headerTitleContainerStyle: {
            flex: 2
          },
          headerRightContainerStyle: {
            flex: 1.5
          },
        }}
      />
    </Stack.Navigator>
  )
})


const styles = StyleSheet.create({
  backContainer:{
    width: isIPad ? '58%': '100%', height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'
  }
})
