import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { GestureResponderEvent, TouchableOpacity, ViewProps, View, StyleSheet } from 'react-native'
import { Text as CustomText } from '../'
import { TextProps } from '../text/text.props'
import { fontFamily } from '../../theme/fontFamily'

interface SecondaryButtonProps {
  textProps: TextProps
  onPress: (event: GestureResponderEvent) => void
  outline?: boolean
  height?: number
  width?: string
  borderRadius?: number
  rounded?: boolean
  blue?: boolean
  disabled?: boolean
  loading?: boolean
  style?: ViewProps['style']
}

export const SecondaryButton = ({
  textProps,
  onPress,
  outline = false,
  height = 40,
  rounded = false,
  blue = false,
  disabled = false,
  style,
}: SecondaryButtonProps) => {
  const borderStyles = rounded ? styles.roundedBorderStyles : styles.defaultBorderStyles
  return (
    <TouchableOpacity disabled={disabled} onPress={onPress} style={[{ height }, style]}>
      <LinearGradient
        colors={blue ? ['#49CDF2', '#9444FB'] : ['#FF3F25', '#DC2BFF']}
        start={[0, 1]}
        end={[1, 0]}
        style={{
          flex: 1,
          flexDirection: 'row',
          borderRadius: borderStyles.borderRadius,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <View
          style={{
            margin: 1,
            height: '100%',
            width: '100%',
            backgroundColor: outline ? '#06102B' : 'transparent',
            borderRadius: borderStyles.borderRadius,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomText allowFontScaling={false} style={styles.defaultButtonStyles} {...textProps} />
        </View>
      </LinearGradient>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  defaultButtonStyles: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontFamily: fontFamily.sfProDisplayBlackItalic,
    fontSize: 16,
    lineHeight: 19,
    borderRadius: 30,
  },
  defaultBorderStyles: {
    borderRadius: 5,
  },
  roundedBorderStyles: {
    borderRadius: 20,
  },
})
