import { LinearGradient } from 'expo-linear-gradient'
import { debounce } from 'lodash'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { ModalProps } from 'react-native-modal'
import { useStore } from '../../models'

import { numberWithCommas } from '../../utils/numberWithCommas'
import { CommonModal } from '../CommonModal/commonModal'
import { Text } from '../text/text'
import { styles } from './styles'

export interface ActionModalProps extends ModalProps {
  stack?: number
  visible: boolean
  message: string
  buttonTitle1?: string
  buttonTitle2?: string
  onAction: () => void
  onClose: () => void
  shouldHideSecondButton?: boolean
  type?: string
  onSelect?: (key: string) => void
  actionOnDeny?: () => void
}

export const ActionModal = (props: ActionModalProps) => {
  const store = useStore()
  const {
    visible,
    onAction,
    onClose,
    message,
    stack,
    buttonTitle1,
    buttonTitle2,
    shouldHideSecondButton,
    type,
    onSelect,
    actionOnDeny,
    ...rest
  } = props

  const onPress = debounce(() => {
    onAction()
    onClose()
  }, 250)

  const modalContent = () => {
    const stackValue = !!stack ? `${numberWithCommas(stack)}?` : ''
    return (
      <View style={{ paddingHorizontal: 20, paddingBottom: 31, backgroundColor: 'white', borderRadius: 6 }}>
        <Text
          allowFontScaling={false}
          tx="gameScreen.actionModal.title"
          txOptions={{ message: message, stackValue: stackValue }}
          style={[styles.modalTitle, { color: '#000', paddingHorizontal: 5 }]}
        />
        <TouchableOpacity onPress={onPress} style={styles.button1}>
          <LinearGradient
            colors={['#2d50d9', '#5025a9']}
            start={[0, 1]}
            end={[1, 0]}
            style={{ flex: 1, borderRadius: 6 }}
          >
            <Text
              allowFontScaling={false}
              tx={!buttonTitle1 ? 'common.yes' : 'common.label'}
              txOptions={!buttonTitle1 ? {} : { label: buttonTitle1 }}
              style={styles.buttonText}
            />
          </LinearGradient>
        </TouchableOpacity>
        {shouldHideSecondButton !== true && (
          <TouchableOpacity
            onPress={(e) => {
              e.preventDefault()
              onClose()
              actionOnDeny && actionOnDeny()
            }}
            style={styles.button2}
          >
            <Text
              allowFontScaling={false}
              tx={!buttonTitle2 ? 'common.no' : 'common.label'}
              txOptions={!buttonTitle2 ? {} : { label: buttonTitle2 }}
              style={styles.buttonText}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const modalContentUserRole = () => {
    const stackValue = !!stack ? `${numberWithCommas(stack)}?` : ''
    return (
      <View style={{ paddingHorizontal: 20, paddingBottom: 31, backgroundColor: 'white', borderRadius: 6 }}>
        <Text
          allowFontScaling={false}
          tx="gameScreen.actionModal.title"
          txOptions={{ message: message, stackValue: stackValue }}
          style={[styles.modalTitle, { color: '#000', paddingHorizontal: 5 }]}
        />
        <TouchableOpacity onPress={() => onSelect && onSelect('observer')} style={styles.button1}>
          <LinearGradient
            colors={['#2d50d9', '#5025a9']}
            start={[0, 1]}
            end={[1, 0]}
            style={{ flex: 1, borderRadius: 6 }}
          >
            <Text
              allowFontScaling={false}
              tx={!buttonTitle1 ? 'common.yes' : 'common.label'}
              txOptions={!buttonTitle1 ? {} : { label: buttonTitle1 }}
              style={styles.buttonText}
            />
          </LinearGradient>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onSelect && onSelect('player')} style={styles.button1}>
          <LinearGradient
            colors={['#2d50d9', '#5025a9']}
            start={[0, 1]}
            end={[1, 0]}
            style={{ flex: 1, borderRadius: 6 }}
          >
            <Text
              allowFontScaling={false}
              tx={!buttonTitle2 ? 'common.yes' : 'common.label'}
              txOptions={!buttonTitle2 ? {} : { label: buttonTitle2 }}
              style={styles.buttonText}
            />
          </LinearGradient>
        </TouchableOpacity>
      </View>
    )
  }


  return (
    <CommonModal
      {...rest}
      visible={visible}
      onClose={onClose}
      showCloseButton={false}
      containerStyle={{ borderRadius: 6 }}
    >
      {type !== 'userRole' ? modalContent() : modalContentUserRole()}
    </CommonModal>
  )
}
