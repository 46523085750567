import React from "react";
import { TouchableOpacity, Image } from "react-native";

const backButtonImg = require('../../../assets/icons/chevron-90-white.png')

interface IBackButtonProps {
    navigate: () => void
}

export const BackButton = (props: IBackButtonProps) => {
    const { navigate } = props
    return (
    <TouchableOpacity
        onPress={navigate}
        style={[{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundColor: 'rgba(255,255,255,0.2)', 
            width: 75,
            height: 30,
            borderRadius: 33,
        }]}
    >
        <Image 
            source={backButtonImg} 
            style={{ 
                width: 20, 
                height: 20, 
                resizeMode: 'contain',
                transform: [{rotateY: '180deg'}, { translateX: 2 }],
            }} 
        />
    </TouchableOpacity>
    )
}