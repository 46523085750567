import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { Linking } from 'react-native'
import { URL } from 'react-native-url-polyfill'

import { withRootStore } from '../extensions'

/**
 * A SessionLinkingStore model.
 */

export const SessionLinkingModel = types
  .model('SessionLinkingModel')
  .props({
    linkingUrl: types.maybe(types.string),
  })
  .extend(withRootStore)
  .actions((self) => ({
    setLinkingUrl(url: string) {
      self.linkingUrl = url
      console.log('session-linking setLinkingUrl', url)
    },
  }))
  .actions((self) => ({
    clearLinkingUrl() {
      self.linkingUrl = undefined
    },
  }))
  .actions((self) => {
    const onUrl = (options: { url: string }) => {
      console.log('session-linking onUrl', options)
      self.setLinkingUrl(options.url)
    }

    const afterCreate = async () => {
      Linking.addEventListener('url', onUrl)
      const url = await Linking.getInitialURL()
      if (url) {
        self.setLinkingUrl(url)
      }
      console.log('session-linking afterCreate', url)
    }

    const beforeDestroy = () => {
      Linking.removeEventListener('url', onUrl)
    }

    return { afterCreate, beforeDestroy }
  })
  .views((self) => ({
    get joinCode(): string | null {
      // return 'test123'
      // parse code from url
      //    https://games.powkerpowher.com/join/[code]
      if (self.linkingUrl) {
        if (self.linkingUrl.indexOf('join') > -1) {
          try {
            const url = new URL(self.linkingUrl)
            console.log('session-linking.joinCode url', url)
            const [rootPath, joinPath, code] = url.pathname.split('/')
            console.log('session-linking.joinCode parsed', rootPath, joinPath, code)
            return code
          } catch (error) {
            console.error('session-linking.joinCode error parsing join code')
          }
        }
      }
      return null
    },
  }))

/**
 * The SessionLinkingStore instance.
 */
export type SessionLinkingStore = Instance<typeof SessionLinkingModel>

/**
 * The data of a SessionLinkingStore.
 */
export type SessionLinkingStoreSnapshot = SnapshotOut<typeof SessionLinkingModel>
