import { getEnv, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { BoardEventKind, GameEventKind, PlayerEventKind, TableEventKind } from '@poker-powher/poker'

import { isLatestSubscriptionClient } from '../'
import { ChatKind, MessageContractModel, MessageContractModelSelector } from '../api-store'
import { InputChannelSubscription } from '../api-store/RootStore.base'
import { withRootStore } from '../extensions'
import { RootStoreEnv } from '../RootStoreEnv'

export enum APIEvents {
  GameSnapshotForPlayer = 'api:user:game:snapshot:for:player',
  GameDeleted = 'api:user:game:deleted',
  GamePlayerLeft = 'api:user:game:left',
}

/**
 * A SessionMessagesStore model.
 */

export const SessionMessagesModel = types
  .model('SessionMessagesModel')
  .props({
    snack: types.maybe(types.string),
    showSnackbar: types.optional(types.boolean, false),
    messages: types.optional(types.array(MessageContractModel), []),
    isSortingMessageByTimestamp: types.optional(types.boolean, true),
  })
  .extend(withRootStore)
  .volatile((self) => {
    let unsubscribeOnReconnected = () => {
      return
    }
    let unsubscribeOnError = () => {
      return
    }
    return {
      unsubscribeOnError,
      unsubscribeOnReconnected,
    }
  })
  .actions((self) => ({
    setUnsubscribeOnReconnected(unsubscribeOnReconnected: () => void) {
      self.unsubscribeOnReconnected = unsubscribeOnReconnected
    },
    clearMessages() {
      // self.messages.clear()
      self.messages.replace([])
    },
    setSnack(message: string) {
      self.snack = message
    },
    clearSnack() {
      self.snack = undefined
    },
    toggleSnackbar(force?: boolean) {
      self.showSnackbar = force !== undefined ? force : !self.showSnackbar
    },
    setIsSortingMessageByTimestamp(value: boolean) {
      self.isSortingMessageByTimestamp = value
    },
    onMessageData(message: MessageContractModelSelector) {
      // console.log('session.messages onMessageData message correlationId', message.correlationId)
      // console.log('session.messages onMessageData message messageId', message.messageId)
      // console.log('session.messages onMessageData message payload', message.payload)
      const isMessage = MessageContractModel.is(message)
      // console.log('session.messages onMessageData message isMessage', isMessage)

      if (isMessage) {
        const model = MessageContractModel.create(message)
        const currentChannelName = self.rootStore.session.channel?.channelName ?? 'current'
        const incomingChannelName = model.channelName ?? 'incoming'
        const isRegisteredToCurrentChannel = incomingChannelName.toLowerCase() === currentChannelName.toLowerCase()
        if (!isRegisteredToCurrentChannel) {
          return
        }
        // self.messages.push(model)
        self.messages.replace([...self.messages.slice(-20), model])
        // console.log('session.messages onMessageData messages.length', self.messages.length)
        const ProcessableEventTypes = [
          APIEvents.GameSnapshotForPlayer,
          APIEvents.GameDeleted,
          BoardEventKind.DealtFlop,
          BoardEventKind.DealtTurn,
          BoardEventKind.DealtRiver,
          // TableEventKind.CaptureLastAction,
          TableEventKind.HandWinners,
          TableEventKind.PlayerAllIn,
          // TableEventKind.HandAllPlayersAllIn,
          TableEventKind.HandShowdown,
          TableEventKind.PlayerOutOfChips,
          // TableEventKind.PlayerBuyIn,
          TableEventKind.PlayerLeft,
          TableEventKind.Paused,
          TableEventKind.Started,
          TableEventKind.StreetFinished,
          TableEventKind.PlayerJoined,
          TableEventKind.PlayerWaitlisted,
          TableEventKind.PlayerFolded,
          TableEventKind.PlayerCalled,
          TableEventKind.PlayerChecked,
          TableEventKind.PlayerBet,
          // PlayerEventKind.Created,
          GameEventKind.Pause,
          GameEventKind.Start,
          TableEventKind.ShowCardsForTeacherEvent,
          TableEventKind.HideCardsForTeacherEvent,
          // TableEventKind.HandCurrentPlayer,
          TableEventKind.HandNew,
          // TableEventKind.PlayerBuyIn
          TableEventKind.HighestBetPlayer,
          TableEventKind.Reset,
          TableEventKind.PlayerObserver,
          TableEventKind.CalculateNextBlindEvent,
          TableEventKind.PlayerObserver,
          TableEventKind.PlayerRemovedByTeacherEvent,
          APIEvents.GamePlayerLeft,
          TableEventKind.SetPlayerBuyInEvent,
          TableEventKind.DoNoActionForPlayerEvent
        ]
        const ChatEventTypes = [ChatKind.admin, ChatKind.dealer, ChatKind.engine, ChatKind.observer, ChatKind.user]
        const kind = message?.payload?.kind ?? undefined

        // console.log("@@@@@@@@@@@@@@: ",kind, '==', JSON.stringify(message?.payload))
        // DEBUG: show incoming event in snackbar
        // if (kind) {
        //   self.snack = kind
        // }
        if (ProcessableEventTypes.includes(kind)) {
          self.rootStore.session.events.pushGameEvents([{ gamePlayTime: 0, message: { ...model } }])
        } else if (ChatEventTypes.includes(kind)) {
          self.rootStore.session.handleChat({ ...model })
        }
      }
      return message
    },
    async unsubscribeAll() {
      console.log('unsubscribeAll')

      self.unsubscribeOnError()
      self.unsubscribeOnReconnected()
      // Reference:
      //   https://github.com/apollographql/apollo-client/issues/3967
      // const env = getEnv<RootStoreEnv>(self)
      // env.gqlWsClient.client.dispose()
      // env.gqlWsClient.unsubscribeAll()
      // console.log('unsubscribeAll wsReadyState', self.rootStore.session.wsReadyState)
      // if (env.gqlWsClient.status === 3) {
      //   console.log('unsubscribeAll already closed')
      // } else {
      //   console.warn('unsubscribeAll force close and reconnect??')
      //   // env.gqlWsClient.close(false, false)
      // }
    },
  }))
  .actions((self) => ({
    async createChannelSubscription(shouldSyncGameState: boolean): Promise<boolean> {
      console.log('createChannelSubscription', self.rootStore.network.wsReadyState)
      const env = getEnv<RootStoreEnv>(self)
      const manager = env.gqlWsClient
      // if (isLatestSubscriptionClient(manager)) {
      //   const shouldResubscribe = manager.reconnectIfClosed()
      //   console.log('createChannelSubscription manager.reconnectIfClosed', shouldResubscribe)
      //   if (!shouldResubscribe) {
      //     return false
      //   }
      // }
      const channelName = self.rootStore.session.channel?.channelName
      if (!channelName) {
        console.info('createChannelSubscription no channel, no-op', channelName)
        return false
      }
      self.unsubscribeAll()
      const createSubscription = () => {
        const userId = self.rootStore.session.user?._id.toString()
        console.info('session-messages createSubscription', channelName, userId)
        if (channelName && userId) {
          const channelSubscription: InputChannelSubscription = {
            channelName,
            userId,
          }
          console.info('session.messages subscribeMessages', channelSubscription)
          const unsubscribeOnReconnected = self.rootStore.api.subscribeMessages(
            { channelSubscription },
            undefined,
            self.onMessageData,
            (error: Error) => {
              console.error('session.messages subscribeMessages error', typeof error, error)
            }
          )
          self.setUnsubscribeOnReconnected(unsubscribeOnReconnected)
        }
      }

      try {
        if (isLatestSubscriptionClient(manager)) {
          self.unsubscribeOnError = manager.client.on('error', (error) => {
            console.error('error', error)
            // NOTE:
            //  https://github.com/apollographql/subscriptions-transport-ws/issues/171

            // // Close socket connection which will also unregister subscriptions on the server-side.
            // wsClient.close();

            // // Reconnect to the server.
            // wsClient.connect();

            // // Reregister all subscriptions.
            // Object.keys(wsClient.operations).forEach((id) => {
            //   wsClient.sendMessage(id, MessageTypes.GQL_START, wsClient.operations[id].options);
            // });
            // self.setTimeout(setTimeout(() => createSubscription(), 500))
          })
        }

        // const waitForConnection = manager.client.on('connected', () => {
        //   console.log('session-messages onConnected')
        // })

        // const promise = new Promise((resolve, reject) => {
        //   waitForConnection()
        //   resolve({ isSubscribed: true })
        // })
        // if (['open'].includes(self.rootStore.network.wsReadyState)) {
        //   console.log('already connected, resync game...')
        //   await self.rootStore.session.joinGame()
        //   console.log('game rejoined')
        //   return
        // }
        await self.rootStore.session.joinChannel(channelName)
        createSubscription()
        await self.rootStore.session.joinGame(shouldSyncGameState)
        return true
      } catch (error) {
        // waitForConnection()
        console.error('session-messages error', error)
        // throw error
      }
      return false
    },
    async forceWSConnect(dumpEvents: boolean) {
      await self.unsubscribeAll()
      await self.rootStore.session.createChannelSubscription(true)
      if (dumpEvents) {
        self.clearMessages()
      }
      await self.rootStore.session.joinGame()
    },
    async forceClearModalQueue() {
      if (self.rootStore.session.modalQueue.length > 0) {
        self.rootStore.session.modalQueue.replace([])
      }
    },
  }))
  .actions((self) => {
    const afterAttach = () => {
      console.log('session.messages afterAttach')
      // self.unsubscribeAll()
    }
    const afterCreate = () => {
      console.log('session.messages afterCreate')
      self.clearMessages()
    }
    return { afterAttach, afterCreate }
  })

/**
 * The SessionMessagesStore instance.
 */
export type SessionMessagesStore = Instance<typeof SessionMessagesModel>

/**
 * The data of a SessionMessagesStore.
 */
export type SessionMessagesStoreSnapshot = SnapshotOut<typeof SessionMessagesModel>
