import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  moduleContainer: {
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  chatPreviewContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width:'100%', 
    height: 30, 
    backgroundColor: '#06102B', 
  },
  chatPreviewField: {
    flex: 8,
    // borderColor: 'red',
    // borderStyle: 'solid',
    // borderWidth: 2
  },
  chatPreviewIcon: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // borderColor: 'red',
    // borderStyle: 'solid',
    // borderWidth: 2    
  },
  baseTextStyle: {
    color: 'white',
    fontSize: 16,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    opacity: 1,
  },
})
