/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * MessageContractBase
 * auto generated base class for the model MessageContractModel.
 */
export const MessageContractModelBase = ModelBase
  .named('MessageContract')
  .props({
    __typename: types.optional(types.literal("MessageContract"), "MessageContract"),
    channelName: types.union(types.undefined, types.string),
    correlationId: types.union(types.undefined, types.string),
    messageId: types.union(types.undefined, types.string),
    payload: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MessageContractModelSelector extends QueryBuilder {
  get channelName() { return this.__attr(`channelName`) }
  get correlationId() { return this.__attr(`correlationId`) }
  get messageId() { return this.__attr(`messageId`) }
  get payload() { return this.__attr(`payload`) }
}
export function selectFromMessageContract() {
  return new MessageContractModelSelector()
}

export const messageContractModelPrimitives = selectFromMessageContract().channelName.correlationId.messageId.payload
