import { PlayerStates } from '@poker-powher/poker'
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { Text } from '../text/text'

export interface FoldOpacityOverlayProps {
  points: string | number
  containerStyle?: StyleProp<ViewStyle>
  isTableVisible: boolean
  isLeft: boolean
  mappedIndex: number
  isFolded: boolean
  playerStatus: string
}

export const FoldOpacityOverlay = React.memo((props: FoldOpacityOverlayProps) => {
  const { points, containerStyle, isTableVisible, isLeft, mappedIndex, isFolded, playerStatus } = props

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={[
          styles.innerContainer,
          {
            flexDirection: mappedIndex % 2 === 0 ? 'row-reverse' : 'row'
          },
          isTableVisible
            ? {
                marginLeft: isLeft ? 0 : -50,
                marginRight: isLeft ? -50 : 0,
                alignItems: isLeft ? 'flex-end' : 'flex-start',
                flexDirection: 'column',
              }
            : styles.noTableInnerContainer,
        ]}
      >
        <Text
          allowFontScaling={false}
          tx={
            playerStatus === PlayerStates.ForeverOut.tag
              ? 'gameScreen.watching'
              : isFolded
              ? 'playerActionHints.FOLD'
              : playerStatus === PlayerStates.Out.tag
              ? 'gameScreen.out'
              : 'gameScreen.sittingOut'
          }
          style={[styles.leftText, !isTableVisible ? { paddingHorizontal: 5} : {}]}
        />
        <Text
          allowFontScaling={false}
          tx="gameScreen.formattedNumber"
          txOptions={{ value: numberWithCommas(points) }}
          style={[
            styles.rightText,
            !isTableVisible ? { paddingHorizontal: 5 } : {},
            isTableVisible ? { textAlign: !isLeft ? 'left' : 'right' } : {},
            isLeft && isTableVisible ? { paddingRight: 5, textAlign: 'right' } : {},
            !isLeft && isTableVisible ? { paddingLeft: 5 } : {},
          ]}
        ></Text>
      </View>
    </View>
  )
})
//width: '145%', left: isLeft ? 0 : -50
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: 'auto',
    height: '102%',
    backgroundColor: '#232335',
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.6,
  },
  innerContainer: {
    flex: 1,
    // flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 3,
    paddingVertical: 1,
  },
  noTableInnerContainer: {
    height: moderateScale(23),
    bottom: 0,
    position: 'absolute',
    width: 'auto',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
  leftText: {
    fontSize: moderateScale(16),
    fontWeight: '900',
    color: '#fff',
    opacity: 0.4,
    // fontFamily: 'SFProDisplay-Bold',
    // lineHeight: 16,
    paddingHorizontal: 3,
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  rightText: {
    fontSize: moderateScale(13),
    fontWeight: '700',
    color: '#fff',
    opacity: 0.6,
    // fontFamily: 'SFProDisplay-Bold',
    paddingHorizontal: 0,
    fontFamily: fontFamily.sfProDisplayBold,
  },
})
