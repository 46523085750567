const basicHandRanking = require('../../../assets/basic-hand-ranking.png')
const advancedHandRanking = require('../../../assets/advanced-hand-ranking.png')
const lingo = require('../../../assets/lingo.png')
const raiseOrFold1 = require('../../../assets/raise-or-fold1.png')
const raiseOrFold2 = require('../../../assets/raise-or-fold2.png')
const war = require('../../../assets/war.png')

export const miniGames = [
  {
    id: 1,
    title: 'Basic Hand Ranking',
    img: basicHandRanking,
    params: {
      gameName: 'basic-hand-ranking',
    },
  },
  {
    id: 2,
    title: 'Advanced Hand Ranking',
    img: advancedHandRanking,
    params: {
      gameName: 'advanced-hand-ranking',
    },
  },
  {
    id: 3,
    title: 'Lingo',
    img: lingo,
    params: {
      gameName: 'lingo',
    },
  },
  {
    id: 4,
    title: 'War',
    img: war,
    params: {
      gameName: 'war',
    },
  },
  {
    id: 5,
    title: 'Raise Or Fold 1',
    img: raiseOrFold1,
    params: {
      gameName: 'raise-or-fold-1',
    },
  },
  {
    id: 6,
    title: 'Raise Or Fold 2',
    img: raiseOrFold2,
    params: {
      gameName: 'raise-or-fold-2',
    },
  },
]
