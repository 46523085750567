import { StyleSheet } from 'react-native'
import { moderateScale } from '../../styles/sizes'

export const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
	},
	navBar: {
		paddingHorizontal: 15,
		flexDirection: 'row',
		paddingVertical: 15,
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 50,
		marginHorizontal: 12,
		borderRadius: 8,
		backgroundColor: '#131E46',
	},
	backText: {
		color: '#000',
		fontWeight: '900',
		textTransform: 'uppercase',
	},
	backButton: {
		alignSelf: 'flex-start',
		marginLeft: 10,
		marginTop: 10,
	},
	tabBarView: {
		marginTop: 38,
		marginBottom: 10,
		flexDirection: 'row',
		justifyContent: 'space-around',
		paddingHorizontal: 5,
	},
	userAvatar: {
		width: 110,
		height: 110,
		borderRadius: 55,
		marginTop: 41,
	},
	userName: {
		paddingVertical: 15,
		fontSize: moderateScale(14),
		color: '#414969',
		fontWeight: '600',
	},
	label: {
		color: '#414969',
		fontSize: moderateScale(16),
		paddingTop: 20,
		paddingBottom: 10,
		textAlign: 'center',
	},
	overviewLabel: {
		color: '#414969',
		fontSize: moderateScale(12),
		paddingTop: 20,
		paddingBottom: 10,
		paddingLeft: 20,
		// textAlign: 'center',
	},
	values: {
		fontSize: moderateScale(21),
		color: '#414969',
		fontWeight: '700',
		textAlign: 'center',
		paddingVertical: 15,
		backgroundColor: '#CED0D550',
	},
	overviewValues: {
		fontSize: moderateScale(16),
		color: '#414969',
		fontWeight: '300',
		paddingBottom: 20,
		paddingLeft: 20,
	},
	image: {
		flex: 1,
		resizeMode: 'cover',
		justifyContent: 'center',
	},
})