/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum LoginFromProvider {
  apple="apple",
google="google"
}

/**
* LoginFromProvider
 *
 * The supported backing login from providers.
*/
export const LoginFromProviderEnumType = types.enumeration("LoginFromProvider", [
        "apple",
  "google",
      ])
