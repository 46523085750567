import 'react-native-get-random-values'

import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
    FlatList, Image, ImageBackground, SafeAreaView, StyleSheet, TouchableOpacity, View
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { List, Menu, Modal, Searchbar, Switch } from 'react-native-paper'
import { SwipeListView } from 'react-native-swipe-list-view'

import { useIsFocused, useNavigation } from '@react-navigation/native'

import { Text } from '../../components'
import { GridPreset } from '../../components/Grid/Grid'
import { translate } from '../../i18n'
import { useStore } from '../../models'
import { GameEntityModelType } from '../../models/api-store'
import styles from '../../styles/globalStyle'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
  noResultFound: {
    fontSize: 15,
    textAlign: 'center',
    marginVertical: 15,
  },
  backTextWhite: {
    color: '#FFF',
  },
  rowFront: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottomColor: 'black',
    borderBottomWidth: 0.3,
    justifyContent: 'center',
    height: 50,
  },
  rowBack: {
    alignItems: 'center',
    backgroundColor: '#fff',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: 'blue',
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: 'red',
    right: 0,
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

export const AdminGamesScreen = observer(() => {
  const store = useStore()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const [visible, setVisible] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [gameList, setGameList] = React.useState<GameEntityModelType[]>([])
  const [searchQuery, setSearchQuery] = React.useState('')
  const [filteredGames, setFilteredGame] = React.useState<GameEntityModelType[]>([])

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const containerStyle = { backgroundColor: '#fff', padding: 20 }

  const onChangeSearch = (query: string) => setSearchQuery(query)

  const searchInGames = () => {
    setFilteredGame(filteredGames.splice(0, filteredGames.length))
    const gameArray = gameList
    console.log('Search query', searchQuery)
    const filteredArray = gameArray.filter((item) => item.shares._id.includes(searchQuery))
    console.log(filteredArray)
    setFilteredGame([...filteredArray])
  }

  const getAllGames = async () => {
    try {
      setGameList(gameList.splice(0, gameList.length))
      const response = await store.session.getAllGames(1)
      setGameList([...response])
    } catch (error) {
      console.log('Error in fetching all games', error)
    }
  }

  useEffect(() => {
    if (isFocused) {
      getAllGames()
    }
  }, [isFocused])

  useEffect(() => {
    if (searchQuery.length > 0) {
      searchInGames()
    }
  }, [searchQuery])

  const [isGridPresetSwitchOn, setIsGridPresetSwitchOn] = useState(store.session.gridPreset === GridPreset.table)
  const [isLottieAnimationEnabled, setIsLottieAnimationEnabled] = useState(store.session.showWinningHandLottie)
  const [isOptionModalEnabled, setIsOptionModalEnabled] = useState(store.session.isOptionModalEnabled)
  const [isFourColoredDeckEnabled, setIsFourColoredDeckEnabled] = useState(store.session.isFourColoredDeckEnabled)
  const [isLobbyScreenEnabled, setIsLobbyScreenEnabled] = useState(store.session.isLobbyScreenEnabled)
  const [isGameAutostartEnabled, setIsGameAutostartEnabled] = useState(store.session.isGameAutostartEnabled)

  const onToggleSwitch = async (switchKey: string) => {
    switch (switchKey) {
      case 'gameAutostart':
        try {
          const gameAutostartStatus = isGameAutostartEnabled
          await store.session.updateGame(!gameAutostartStatus)
          setIsGameAutostartEnabled(!gameAutostartStatus)
        } catch (error) {
          console.log('Error in game autostart toggle', error)
        }
        break
      case 'gamePreset':
        const toggle = !isGridPresetSwitchOn
        if (toggle) {
          store.session.setGridPreset(GridPreset.table)
        } else {
          store.session.setGridPreset(GridPreset.noTable)
        }
        setIsGridPresetSwitchOn(toggle)
        break

      case 'animation':
        const isAnimationEnabled = isLottieAnimationEnabled
        store.session.setShowWinningHandLottie(!isAnimationEnabled)
        setIsLottieAnimationEnabled(!isAnimationEnabled)
        break

      case 'fourColoredDeck':
        const isFourColoredDeck = isFourColoredDeckEnabled
        store.session.setIsFourColoredDeckEnabled(!isFourColoredDeck)
        setIsFourColoredDeckEnabled(!isFourColoredDeck)
        break

      case 'enableLobby':
        const isLobbyScreen = isLobbyScreenEnabled
        store.session.setIsLobbyScreenEnabled(!isLobbyScreen)
        setIsLobbyScreenEnabled(!isLobbyScreen)
        break

      case 'optionModal':
        const isOptionModalEnabledByAdmin = isOptionModalEnabled
        store.session.setIsOptionModalEnabled(!isOptionModalEnabledByAdmin)
        setIsOptionModalEnabled(!isOptionModalEnabledByAdmin)
        break
    }
  }

  const Icon = (props: { type: string }) => {
    const joinIcon = require('../../../assets/menu/join.png')
    const deleteIcon = require('../../../assets/menu/delete.png')
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Image source={props.type === 'join' ? joinIcon : deleteIcon} style={{ width: 30, height: 30 }} />
      </View>
    )
  }

  const setResetSnackbar = (snackMessage: string) => {
    store.session.setSnack(snackMessage)
    store.session.toggleSnackbar()
    setTimeout(() => {
      store.session.clearSnack()
      store.session.toggleSnackbar()
    }, 5000)
  }

  const joinAnotherGame = async (channelName: string) => {
    try {
      await store.session.joinChannel(channelName)
      const message = translate('adminGameScreen.gameJoined')
      message && setResetSnackbar(message)
      navigation.navigate('game')
    } catch (error) {
      console.log('Error while joining ', channelName)
    }
  }

  const deleteGame = async (gameId: string) => {
    try {
      await store.session.deleteGame(gameId)
      const message = translate('adminGameScreen.gameDeleted')
      message && setResetSnackbar(message)
      getAllGames()
    } catch (error) {
      console.log('Error while deleting ', gameId)
    }
  }

  const renderItem = (data: any) => (
    <List.Item
      key={data.item.shares._id}
      onPress={debounce(() => {
        // store.session.joinGame()
        setMessage(JSON.stringify(data.item, null, 2))
        showModal()
      }, 250)}
      style={[startStyles.rowFront, {}]}
      title={translate('adminGameScreen.gameTitle', {
        playerName: data.item.shares.firstName ? data.item.shares.firstName : 'Player',
        hashCode: data.item.shares.hash,
      })}
      description={translate('adminGameScreen.gameDescription', {
        gameId: data.item.shares._id,
        currentGameStatus: store.session.game?.gameId === data.item.snapshot.gameId ? '- Current Game' : '',
      })}
      right={(props) => (
        <List.Icon {...props} icon="apple-keyboard-control" style={{ transform: [{ rotate: '90deg' }] }} />
      )}
    />
  )

  const renderHiddenItem = (data: any, rowMap: any) => (
    <View style={startStyles.rowBack}>
      <TouchableOpacity
        style={[startStyles.backRightBtn, startStyles.backRightBtnLeft]}
        onPress={() => joinAnotherGame(data.item.snapshot.gameName)}
      >
        <Icon type="join" />
      </TouchableOpacity>
      <TouchableOpacity
        style={[startStyles.backRightBtn, startStyles.backRightBtnRight]}
        onPress={() => deleteGame(data.item._id)}
      >
        <Icon type="delete" />
      </TouchableOpacity>
    </View>
  )
  return (
    <ImageBackground source={image} style={{ ...startStyles.image }}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View
            key={'gameState'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="play-speed" title={`Game State: ${store.session.game?.gameState?.tag}`} />
          </View>
          <View
            key={'gameAutostart'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="play-speed" title={translate('adminGameScreen.gameAutostart')} />
            <Switch value={isGameAutostartEnabled} onValueChange={() => onToggleSwitch('gameAutostart')} />
          </View>
          <View
            key={'gridPreset'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="microsoft" title={translate('adminGameScreen.gridPresetTitle')} />
            <Switch value={isGridPresetSwitchOn} onValueChange={() => onToggleSwitch('gamePreset')} />
          </View>
          <View
            key={'lottieAnimation'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="microsoft" title={translate('adminGameScreen.lottieAnimationTitle')} />
            <Switch value={isLottieAnimationEnabled} onValueChange={() => onToggleSwitch('animation')} />
          </View>
          <View
            key={'agoraVideo'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="dots-vertical-circle" title={translate('adminGameScreen.agoraVideoTitle')} />
            <Switch value={isOptionModalEnabled} onValueChange={() => onToggleSwitch('optionModal')} />
          </View>
          <View
            key={'fourColoredDeck'}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Menu.Item icon="dots-vertical-circle" title={translate('adminGameScreen.fourColoredDeckTitle')} />
            <Switch value={isFourColoredDeckEnabled} onValueChange={() => onToggleSwitch('fourColoredDeck')} />
          </View>
          <View key={'lobby'} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Menu.Item icon="dots-vertical-circle" title={translate('adminGameScreen.enableLobby')} />
            <Switch value={isLobbyScreenEnabled} onValueChange={() => onToggleSwitch('enableLobby')} />
          </View>
          <Searchbar
            placeholder="Search"
            autoCapitalize={'none'}
            onChangeText={onChangeSearch}
            value={searchQuery}
            textAlign={'left'}
          />
          <View style={{ flex: 1 }}>
            {gameList.length > 0 && searchQuery.length < 1 ? (
              <SwipeListView
                key="AdminGameList"
                data={gameList}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                leftOpenValue={75}
                rightOpenValue={-150}
                previewRowKey={'0'}
                previewOpenValue={-40}
                previewOpenDelay={3000}
                // onRowDidOpen={onRowDidOpen}
              />
            ) : searchQuery.length > 0 && filteredGames.length > 0 ? (
              <SwipeListView
                key="AdminFilteredGameList"
                data={filteredGames}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                leftOpenValue={75}
                rightOpenValue={-150}
                previewRowKey={'0'}
                previewOpenValue={-40}
                previewOpenDelay={3000}
                // onRowDidOpen={onRowDidOpen}
              />
            ) : (
              <Text allowFontScaling={false} tx="adminGameScreen.noGameFound" style={startStyles.noResultFound} />
            )}
          </View>
          <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
            <Text
              allowFontScaling={false}
              tx="adminGameScreen.howToDismissMessage"
              style={{ color: 'black', textAlign: 'center' }}
            />
            <Text allowFontScaling={false}>{'\n'}</Text>
            <ScrollView>
              <Text allowFontScaling={false} style={{ color: 'black' }}>
                {message}
              </Text>
            </ScrollView>
          </Modal>
        </SafeAreaView>
      </View>
    </ImageBackground>
  )
})
