import { observer } from 'mobx-react-lite'
import React from 'react'

import { NavigationProp } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { IResults } from '../screens/mini-games/interfaces/results.interface'
import {
  MiniGamesLobbyScreen,
  IntroScreen,
  SurveyGameScreen,
  FinalScreen,
  AllRankingsScreen,
  VersusGameScreen,
} from '../screens'
import { BackButton } from '../components/BackButton/BackButton'
import { Text, StyleSheet } from 'react-native'

export enum MiniGamesRoutes {
  miniGamesLobby = 'miniGamesLobby',
  introGameScreen = 'introGameScreen',
  surveyGameScreen = 'surveyGameScreen',
  miniGameFinalScreen = 'miniGameFinalScreen',
  allRankings = 'allRankings',
  versusGameScreen = 'versusGameScreen',
}

export type MiniGamesParamList = Record<keyof typeof MiniGamesRoutes, IResults | undefined>
export type MiniGamesNavigation = NavigationProp<MiniGamesParamList>

const Stack = createStackNavigator<MiniGamesParamList>()

export const MiniGamesNavigator = observer((props: any) => {
  const initialRouteName = MiniGamesRoutes.miniGamesLobby

  const goBackNavigation = (navigation: MiniGamesNavigation) => {
    navigation.goBack()
  }

  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={({ navigation }) => ({
        gestureEnabled: false,
        headerStyle: styles.header,
        headerLeft: () => <BackButton navigate={() => goBackNavigation(navigation)} />,
        headerTitle: () => (
          <React.Fragment>
            <Text> </Text>
          </React.Fragment>
        ),
      })}
    >
      <Stack.Screen name={MiniGamesRoutes.miniGamesLobby} component={MiniGamesLobbyScreen} />
      <Stack.Screen name={MiniGamesRoutes.introGameScreen} component={IntroScreen} />
      <Stack.Screen name={MiniGamesRoutes.surveyGameScreen} component={SurveyGameScreen} />
      <Stack.Screen name={MiniGamesRoutes.versusGameScreen} component={VersusGameScreen} />
      <Stack.Screen name={MiniGamesRoutes.miniGameFinalScreen} component={FinalScreen} />
      <Stack.Screen name={MiniGamesRoutes.allRankings} component={AllRankingsScreen} />
    </Stack.Navigator>
  )
})

const styles = StyleSheet.create({
  header: { borderBottomWidth: 0, backgroundColor: 'rgb(5, 16,45)', height: 86 },
})
