import { StyleSheet } from 'react-native'

import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'

export const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
    justifyContent: 'center',
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 6,
    padding: 2,
  },
  modalInnerContainer: {
    backgroundColor: '#fff',
    borderRadius: 6,
  },
  modalTitle: {
    textAlign: 'center',
    marginTop: 45,
    fontSize: moderateScale(22),
    // fontFamily: 'SFProDisplay-Black',
    // lineHeight: 28,
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 20,
    color: '#fff',
    fontWeight: '800',
    marginVertical: 11,
    fontFamily: fontFamily.sfProDisplayBold,
    textTransform: "uppercase"
  },
  button1: {
    marginTop: 22,
    height: 50,
  },
  button2: {
    backgroundColor: '#B6B6B6',
    marginTop: 10,
    borderRadius: 6,
    height: 50,
  },
})
