import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Pressable, View } from 'react-native'
import { Text } from '../text/text'
import { styles } from './styles'
import { PlayerRole, PlayerRoleBadge } from './PlayerRoleBadge'
import { Cell } from './Cell'
import { PlayerState } from '@poker-powher/poker'

export const PlayerCellWithTable = (props: {
  currentPlayerIndex: number
  index: number
  totalPlayers: number
  isLeft?: boolean
  onPress: () => void
  isTableVisible: boolean
  lastAction: string
  lastAmount: string
  playerState: PlayerState
  chipsText: string
  playerRole: PlayerRole
  isPlayerMutingVideoCall: boolean
  cardViewHeight: number
}) => {
  const colors = props.currentPlayerIndex === props.index ? ['#0CE0FF', '#DD2AFF'] : ['#05102d', '#05102d']
  return (
    <View
      key={'player-cell-' + props.index}
      style={[
        props.totalPlayers === 1 ? { height: '100%', flex: 1 } : null,
        styles.cardMain,
        { flexDirection: props.isLeft ? 'row' : 'row-reverse' },
        props.totalPlayers === 2
          ? { height: '30%', width: '100%' }
          : {
              height:
                100 / ((props.totalPlayers % 2 === 0 ? props.totalPlayers : props.totalPlayers + 1) / 2) - 1.2 + '%',
              width: '100%',
              maxHeight: '25%',
            },
      ]}
    >
      <Pressable onPress={props.onPress} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        {props.currentPlayerIndex === props.index ? (
          <View style={{ flex: 1, flexDirection: props.isLeft ? 'row' : 'row-reverse', alignItems: 'center' }}>
            <LinearGradient
              colors={colors}
              start={{ x: 0.0, y: 1.0 }}
              end={{ x: 1.0, y: 1.0 }}
              style={{ width: '66%', height: '100%', padding: 3, borderRadius: 2 }}
            >
              <Cell isPlayerMutingVideoCall={props.isPlayerMutingVideoCall} cardViewHeight={props.cardViewHeight} />
            </LinearGradient>

            <View style={[styles.playerCellSidebarContainer, props.isLeft ? { marginLeft: 3 } : { marginRight: 3 }]}>
              <>
                {props.isLeft ? (
                  <View style={{ justifyContent: 'space-between', flex: 1 }}>
                    <View>
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.formattedNumber"
                        txOptions={{ value: props.chipsText }}
                        style={styles.coinAndRoleOnTableLeftPanel}
                      />
                      <PlayerRoleBadge
                        playerRole={props.playerRole}
                        mappedIndex={props.index}
                        isTableVisible={props.isTableVisible}
                        textStyle={styles.coinAndRoleOnTableLeftPanel}
                        playerTag={props.playerState.tag}
                      />
                    </View>
                    <View>
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.formattedNumber"
                        txOptions={{ value: props.lastAmount }}
                        style={styles.betValueLeftPanel}
                      />
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.playerAction"
                        txOptions={{ action: props.lastAction }}
                        style={styles.callLeftPanelText}
                      />
                    </View>
                  </View>
                ) : (
                  <View style={{ justifyContent: 'space-between', flex: 1 }}>
                    <View>
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.formattedNumber"
                        txOptions={{ value: props.chipsText }}
                        style={styles.coinAndRoleOnTableRightPanel}
                      />
                      <PlayerRoleBadge
                        playerRole={props.playerRole}
                        mappedIndex={props.index}
                        isTableVisible={props.isTableVisible}
                        textStyle={{ textAlign: 'right' }}
                        playerTag={props.playerState.tag}
                      />
                    </View>
                    <View>
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.formattedNumber"
                        txOptions={{ value: props.lastAmount }}
                        style={styles.betValueRightPanel}
                      />
                      <Text
                        allowFontScaling={false}
                        tx="gameScreen.playerAction"
                        txOptions={{ action: props.lastAction }}
                        style={styles.callRightPanelText}
                      />
                    </View>
                  </View>
                )}
              </>
            </View>
          </View>
        ) : (
          <View style={{ flex: 1, flexDirection: props.isLeft ? 'row' : 'row-reverse', alignItems: 'center' }}>
            <View style={{ width: '66%', height: '100%', borderRadius: 2 }}>
              <Cell isPlayerMutingVideoCall={props.isPlayerMutingVideoCall} cardViewHeight={props.cardViewHeight} />
            </View>
            {props.playerState.tag !== 'folded' ? (
              <View style={[styles.playerCellSidebarContainer, props.isLeft ? { marginLeft: 3 } : { marginRight: 3 }]}>
                <>
                  {props.isLeft ? (
                    <View style={{ justifyContent: 'space-between', flex: 1 }}>
                      <View>
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.formattedNumber"
                          txOptions={{ value: props.chipsText }}
                          style={styles.coinAndRoleOnTableLeftPanel}
                        />

                        <PlayerRoleBadge
                          playerRole={props.playerRole}
                          mappedIndex={props.index}
                          isTableVisible={props.isTableVisible}
                          textStyle={styles.coinAndRoleOnTableLeftPanel}
                          playerTag={props.playerState.tag}
                        />
                      </View>
                      <View>
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.formattedNumber"
                          txOptions={{ value: props.lastAmount }}
                          style={styles.betValueLeftPanel}
                        />
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.playerAction"
                          txOptions={{ action: props.lastAction }}
                          style={styles.callLeftPanelText}
                        />
                      </View>
                    </View>
                  ) : (
                    <View style={{ justifyContent: 'space-between', flex: 1 }}>
                      <View>
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.formattedNumber"
                          txOptions={{ value: props.chipsText }}
                          style={styles.coinAndRoleOnTableRightPanel}
                        />

                        <PlayerRoleBadge
                          playerRole={props.playerRole}
                          mappedIndex={props.index}
                          isTableVisible={props.isTableVisible}
                          textStyle={styles.coinAndRoleOnTableRightPanel}
                          containerStyle={{ justifyContent: 'flex-end' }}
                          playerTag={props.playerState.tag}
                        />
                      </View>
                      <View>
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.formattedNumber"
                          txOptions={{ value: props.lastAmount }}
                          style={styles.betValueRightPanel}
                        />
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.playerAction"
                          txOptions={{ action: props.lastAction }}
                          style={styles.callRightPanelText}
                        />
                      </View>
                    </View>
                  )}
                </>
              </View>
            ) : null}
          </View>
        )}
      </Pressable>
    </View>
  )
}
