import { observer } from 'mobx-react-lite'
/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import { AdminNavigator } from './admin-navigator'
import { DrawerNavigator } from './drawer-navigator'

export enum MainRoutes {
  admin = 'admin',
  drawer = 'drawer',
}

export type MainParamList = Record<keyof typeof MainRoutes, undefined>

const Stack = createStackNavigator<MainParamList>()
export const MainNavigator = observer(() => {
  return (
    <Stack.Navigator
      initialRouteName={MainRoutes.drawer}
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        headerStyle: { borderBottomWidth: 0, backgroundColor: 'rgb(5, 16,45)' },
      }}
    >
      <Stack.Screen name={MainRoutes.drawer} component={DrawerNavigator} />
      <Stack.Screen name={MainRoutes.admin} component={AdminNavigator} />
    </Stack.Navigator>
  )
})

const exitRoutes = ['welcome']
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
