import React from 'react'
import { TouchableOpacity, Image, View, Text, StyleSheet } from 'react-native'
import { moderateScale } from '../../styles/sizes'
import { useNavigation } from '@react-navigation/native'
import { GameRoutes } from '../../navigation/game-navigator'
import { fontFamily } from '../../theme/fontFamily'
import { useStore } from '../../models'
import { observer } from 'mobx-react-lite'
import { ScrollView } from 'react-native-gesture-handler'

export const ViewObserverButton = (prop: any) => {
  const navigation = useNavigation()
  return (
    <TouchableOpacity
      disabled={prop.disabled}
      hitSlop={{ top: 10, bottom: 20, left: 10, right: 20 }}
      onPress={() => {
        navigation.navigate(GameRoutes.observerOverlay)
      }}
      style={{ paddingTop: 0, paddingRight: 10, paddingBottom: 5 }}
    >
      <Image
        style={{ height: moderateScale(16), width: 30, marginLeft: 7, tintColor: prop.disabled ? 'gray' : 'white' }}
        source={require('../../assets/ObserverIcon.png')}
      />
    </TouchableOpacity>
  )
}

export const ObserverModal = observer(() => {
  const store = useStore()
  const observerPlayer = store.session.observerPlayerName

  // const [people, setPeople] = useState([
  //   { name: 'bowser', key: '1'},
  //   { name: 'yoshi', key: '2'},
  //   { name: 'mario', key: '3'},
  //   { name: 'luigi', key: '4'},
  //   { name: 'peach', key: '5'}
  // ])
  console.log('###===>', observerPlayer)
  observerPlayer.map((item) => console.log('###===>', item))
  return (
    <View style={[styles.background]}>
      <Text style={[styles.leaderBoardText]}>Observer List</Text>
      <ScrollView>
        {observerPlayer.map((item) => (
          <View style={styles.itemView} key={item.id}>
            <Text style={styles.item}>{item}</Text>
          </View>
        ))}
      </ScrollView>
    </View>
  )
})

const styles = StyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0D2063',
  },
  leaderBoardText: {
    fontSize: 20,
    color: '#FFBB0E',
    fontFamily: fontFamily.sfProDisplayBoldItalic,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
  },
  item: {
    fontSize: 26,
    fontFamily: fontFamily.sfProDisplayBold,
    color: 'white',
  },
  itemView: {
    paddingTop: 3,
  },
})
