import React from 'react'
import { ModalProps, View } from 'react-native'

import { Pot } from '@poker-powher/poker'

import { numberWithCommas } from '../../utils/numberWithCommas'
import { CommonModal } from '../CommonModal/commonModal'
import { Text } from '../text/text'
import { styles } from './styles'

interface PotListItem extends Pot {
  icon: JSX.Element
  playerNames: string[]
}

export interface PotModalProps extends ModalProps {
  onClose: () => void
  potList: PotListItem[]
  visible: boolean
}

export const PotModal = (props: PotModalProps) => {
  const { visible, onClose, potList, ...rest } = props

  const modalContent = () => {
    return (
      <View style={[styles.modalInnerContainer, { paddingHorizontal: 20, paddingVertical: 15 }]}>
        {potList.map((item, index) => {
          console.log('PotModal potList', item, index)
          const players = item.playerNames ?? []
          const length = players.length
          const lastPlayerName = players[length - 1]
          const playerNames = players
            .slice(0, length - 1)
            .toString()
            .replace(/,/g, ', ')
          return (
            <View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={styles.chipsStackContainer}>
                <View style={styles.chipsIcon}>{item.icon}</View>
                <View style={styles.chipsValue}>
                  <Text
                    allowFontScaling={false}
                    tx="gameScreen.formattedNumber"
                    txOptions={{ value: numberWithCommas(item.amount) }}
                    style={styles.chipsText}
                  />
                </View>
              </View>
              <View style={styles.playerListContainer}>
                <Text
                  allowFontScaling={false}
                  tx="gameScreen.playerNameList"
                  txOptions={{ playerNames: playerNames, lastPlayerName: lastPlayerName }}
                  style={styles.playerList}
                />
              </View>
            </View>
          )
        })}
      </View>
    )
  }

  return (
    <CommonModal
      {...rest}
      onClose={onClose}
      visible={visible}
    >
      {modalContent()}
    </CommonModal>
  )
}
