export const events = {
  signIn: {
    userSignIn: 'User Signin',
  },
  game: {
    gameCreated: 'Game Created',
    joinGame: 'Game Joined',
    start: 'Game Start',
    leave: 'Game Leave',
  },
  logout: {
    userLogout: 'User Logout',
  },
}
