import { Client, ClientOptions, createClient, Event, SubscribePayload } from 'graphql-ws'
import { ExecutionResult } from 'graphql/execution/execute'
import { Observable } from 'rxjs'
import {
    OperationOptions, Operations, SubscriptionClient as SubscriptionClientInterface
} from 'subscriptions-transport-ws'

// import crashlytics from '@react-native-firebase/crashlytics'

export enum WebsocketReadyState {
  'connecting',
  'open',
  'closing',
  'closed',
  'unknown',
}

export enum WebsocketConnectionStates {
  connecting = 'connecting',
  connected = 'connected',
  message = 'message',
  closed = 'closed',
  error = 'error',
  unknown = 'unknown',
}

export const websocketConnectionEvents: Event[] = [
  WebsocketConnectionStates.connected,
  WebsocketConnectionStates.connecting,
  WebsocketConnectionStates.message,
  WebsocketConnectionStates.closed,
  WebsocketConnectionStates.error,
]

export interface SubscriptionClientOptions extends ClientOptions {
  on?: any
}

function isWebSocket(value: unknown): value is WebSocket {
  // console.log('isWebsocket', typeof value)
  return typeof value === 'object' && value !== null && value.hasOwnProperty('readyState')
}

const target = 'SubscriptionClient'

const reconnectCode = 4400 // DEPRECATED: 4205

export class SubscriptionClient implements Partial<SubscriptionClientInterface> {
  // @ts-ignore
  private _client: Client
  private _activeSocket?: WebSocket
  private _restartRequested = false

  public operations: Operations = {}

  constructor(options: SubscriptionClientOptions) {
    const setup = () => {
      this._activeSocket && this._activeSocket.close(reconnectCode, 'Client Restart')
      this._activeSocket = undefined

      let onclose = (event: CloseEvent) => {
        return
      }
      const onSocketClose = (event: CloseEvent) => {
        console.log('onSocketClose', event)
        onclose(event)
        setup()
      }
      // let onerror = (event: Event) => {
      //   return
      // }
      // const onSocketError = (event: Event) => {
      //   console.log('onSocketClose', event)
      //   onerror(event)
      // }

      this._client && this.client.dispose()
      this._client = createClient({
        ...options,
        retryAttempts: Number.MAX_SAFE_INTEGER,
        shouldRetry: () => true,
        onNonLazyError: (errorOrCloseEvent: unknown) => {
          //   const onNonLazyError = `SubscriptionClient onNonLazyError ${errorOrCloseEvent}`
          console.warn('onNonLazyError', errorOrCloseEvent)
          //   crashlytics().log(onNonLazyError)
          this.restart()
        },
        on: {
          ...options.on,
          opened: (socket: WebSocket) => {
            console.log(target, 'client opened')
            this._activeSocket = socket
            if (this._activeSocket.onclose) {
              onclose = this._activeSocket.onclose
            }
            // if (this._activeSocket.onerror) {
            //   onerror = this._activeSocket.onerror
            // }
            this._activeSocket.onclose = onSocketClose
            // this._activeSocket.onerror = onSocketError
            options.on?.opened?.(socket)

            // just in case you were eager to restart
            if (this._restartRequested) {
              this._restartRequested = false
              this.restart()
            }
          },
          connected: (event: unknown) => {
            console.log(target, 'client connected', event)
            options.on?.connected?.(event)
            console.log(target, 'client connected isWebSocket', isWebSocket(event))
            // if (isWebSocket(event)) {
            //   const socket = event as WebSocket
            //   this._activeSocket = socket
            // }
          },
        },
        // webSocketImpl: WebSocket,
        // webSocketImpl: ReconnectingWebSocket,
      })
    }
    setup()
  }

  public close(isForced?: boolean, closedByUser?: boolean): void {
    if (this._activeSocket) {
      // List of valid status codes:
      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
      this._activeSocket.close(1000, 'Game Ended')
    }
  }

  public restart() {
    if (this._activeSocket) {
      if (this._activeSocket.readyState === WebSocket.OPEN) {
        // if the socket is still open for the restart, do the restart
        this._activeSocket.close(reconnectCode, 'Client Restart')
      } else {
        // otherwise the socket might've closed, indicate that you want
        // a restart on the next connected event
        this._restartRequested = true
      }
    }
  }

  public isSocketConnected() {
    const isSocketConnected = this._activeSocket && this._activeSocket.readyState === WebsocketReadyState.open
    // console.log('SubscriptionClient', isSocketConnected)
    return isSocketConnected
  }

  public reconnectIfClosed() {
    const isSocketConnected = this.isSocketConnected()
    console.log('reconnectIfClosed isSocketConnected', isSocketConnected)
    if (this._activeSocket && !isSocketConnected) {
      console.log('reconnectIfClosed close and restart!')
      this._activeSocket.close(reconnectCode, 'Client Restart')
      this._restartRequested = false
      this.restart()
      return true
    }
    return false
  }

  get client() {
    return this._client
  }

  get readyState(): WebsocketReadyState {
    return this._activeSocket?.readyState ?? WebsocketReadyState.unknown
  }

  get status(): WebsocketReadyState {
    return this.readyState
  }

  public request(request: OperationOptions): Observable<ExecutionResult> {
    const operation = request as SubscribePayload
    // this.operations.push(operation)
    // console.debug(target, 'request operation', operation)
    return new Observable((observer) =>
      this._client.subscribe(operation, {
        next: (data: ExecutionResult) => {
          // console.debug('onNext', data)
          observer.next(data)
        },
        error: (err) => observer.error(err),
        complete: () => observer.complete(),
      })
    )
  }

  public subscribe<T>(payload: SubscribePayload): AsyncIterableIterator<T> {
    let deferred: {
      resolve: (done: boolean) => void
      reject: (err: unknown) => void
    } | null = null
    const pending: T[] = []
    let throwMe: unknown = null,
      done = false
    const dispose = this._client.subscribe<T>(payload, {
      next: (data) => {
        pending.push(data)
        deferred?.resolve(false)
      },
      error: (err) => {
        throwMe = err
        deferred?.reject(throwMe)
      },
      complete: () => {
        done = true
        deferred?.resolve(true)
      },
    })
    return {
      [Symbol.asyncIterator]() {
        return this
      },
      async next() {
        if (done) return { done: true, value: undefined }
        if (throwMe) throw throwMe
        if (pending.length) return { value: pending.shift()! }
        return (await new Promise<boolean>((resolve, reject) => (deferred = { resolve, reject })))
          ? { done: true, value: undefined }
          : { value: pending.shift()! }
      },
      async return() {
        dispose()
        return { done: true, value: undefined }
      },
    }
  }
}
