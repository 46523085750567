import * as React from 'react'
import Svg, { Defs, LinearGradient, Stop, Text, TSpan } from 'react-native-svg'

import { translate } from '../../i18n'

type linearTextProps = {
  txt: string
}

export const LinearText = ({ txt }: linearTextProps) => {
  return (
    <Svg viewBox="0 0 200 31" height="31" width="200">
      <Defs>
        <LinearGradient id="rainbow" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse">
          <Stop stopColor="#5D2CB0" offset="0%" />
          <Stop stopColor="#9444FB" offset="100%" />
        </LinearGradient>
      </Defs>
      <Text fill="url(#rainbow)">
        <TSpan fontWeight="900" fontSize="27" x="0" y="24">
          {translate(txt)}
        </TSpan>
      </Text>
    </Svg>
  )
}
