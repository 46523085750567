import { NativeModules, Platform } from 'react-native'

export enum DeviceType {
  phone = 'phone',
  pad = 'pad',
}

export const loggedInDeviceDeviceType = () => {
  if (Platform.OS === 'web') {
    return 'web'
  } else {
    const { PlatformConstants } = NativeModules
    const deviceType = PlatformConstants.interfaceIdiom
    return deviceType
  }
}
