/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum GameKind {
  default="default",
tournament="tournament"
}

/**
* GameKind
 *
 * The specific kind of different game modes
*/
export const GameKindEnumType = types.enumeration("GameKind", [
        "default",
  "tournament",
      ])
