import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { PlayerStates } from '@poker-powher/poker'
import { translate } from '../../i18n'
import { moderateScale } from '../../styles/sizes'
import { spacing } from '../../theme'
import { fontFamily } from '../../theme/fontFamily'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { Text } from '../text/text'
import { PlayerRole } from '../PlayerCell/PlayerRoleBadge'

export interface PlayerCellOverlayProps {
  player: any
  index: number
  mappedIndex: number
  isTableVisible: boolean
  status: boolean
  chips: number
  lastAction: string
  playerState: string
  localUserId?: string
  isMyTurn: boolean
  playerRole: PlayerRole
  currentPlayerIndex: number
  lastBetKind: string
}

export const PlayerCellOverlay = React.memo((props: PlayerCellOverlayProps) => {
  const { mappedIndex, index, isTableVisible, status, player, chips, lastAction, playerState } = props

  const chipsText = numberWithCommas(chips)
  const playerTag = playerState === PlayerStates.Waiting.tag.toUpperCase() ? 'SITTING OUT' : playerState

  const contentWithoutTable = () => {
    console.log('PlayerCellOverlay player', player, mappedIndex, index)
    // const lastAmount = lastBet.amount.toString()
    let lastAmount = player.bet === 0 || status ? '' : numberWithCommas(player.bet).toString()
    const isEven = mappedIndex % 2 === 0
    return (
      <View key={`cell-overlay-${index}`} style={styles.blackOverlay}>
        <View style={[styles.overlayTextContainer, { alignItems: 'center' }]}>
          {isEven ? (
            <>
              <Text
                allowFontScaling={false}
                tx="gameScreen.formattedNumber"
                txOptions={{ value: playerTag === PlayerStates.Out.tag.toUpperCase() ? ' ' : chipsText }}
                style={styles.leftPoints}
              />
              {status ? (
                <Text
                  allowFontScaling={false}
                  tx="gameScreen.playerAction"
                  txOptions={{ action: playerTag }}
                  style={styles.leftActionText}
                />
              ) : (
                <Text allowFontScaling={false} style={styles.leftActionText}>
                  <Text
                    allowFontScaling={false}
                    tx="gameScreen.formattedNumber"
                    txOptions={{
                      value: lastAmount,
                    }}
                    style={styles.actionDigit}
                  />
                  {'  '}
                  {translate('gameScreen.playerAction', { action: lastAction })}
                </Text>
              )}
            </>
          ) : (
            <>
              {status ? (
                <Text
                  allowFontScaling={false}
                  tx="gameScreen.playerAction"
                  txOptions={{ action: playerTag }}
                  style={styles.rightActionText}
                />
              ) : (
                <Text allowFontScaling={false} style={styles.rightActionText}>
                  <Text
                    allowFontScaling={false}
                    tx="gameScreen.formattedNumber"
                    txOptions={{
                      value: lastAmount,
                    }}
                    style={styles.actionDigit}
                  />
                  {'  '}
                  {translate('gameScreen.playerAction', { action: lastAction })}
                </Text>
              )}
              <Text
                allowFontScaling={false}
                tx="gameScreen.formattedNumber"
                txOptions={{ value: playerTag === PlayerStates.Out.tag.toUpperCase() ? ' ' : chipsText }}
                style={styles.rightPoints}
              />
            </>
          )}
        </View>
      </View>
    )
  }

  const contentWithTable = () => {
    return <View key={`cell-overlay-${index}`} />
  }

  return <>{isTableVisible ? contentWithTable() : contentWithoutTable()}</>
})

const styles = StyleSheet.create({
  blackOverlay: {
    width: 'auto',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    height: moderateScale(23),
    backgroundColor: 'rgba(0, 0, 0, .6)',
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
  },
  overlayTextContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    height: moderateScale(23),
    justifyContent: 'space-between',
    paddingHorizontal: spacing[1],
    position: 'absolute',
    width: 'auto',
    bottom: 0,
    left: 0,
    right: 0,
  },
  leftPoints: {
    marginTop: 4,
    textAlign: 'left',
    color: '#fff',
    fontSize: moderateScale(13),
    fontWeight: '700',
    fontFamily: fontFamily.sfProDisplayBold,
  },
  rightPoints: {
    marginTop: 4,
    textAlign: 'right',
    color: '#fff',
    fontSize: moderateScale(13),
    fontWeight: '700',
    fontFamily: fontFamily.sfProDisplayBold,
  },
  leftActionText: {
    marginTop: 1,
    textAlign: 'left',
    color: '#fff',
    fontSize: moderateScale(16),
    fontWeight: '900',
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  rightActionText: {
    marginTop: 1,
    textAlign: 'left',
    color: '#fff',
    fontSize: moderateScale(16),
    fontWeight: '900',
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  actionDigit: {
    marginRight: 5,
    color: '#FFBB0E',
    fontSize: moderateScale(18),
    fontWeight: '900',
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
})
