'use strict'
import { StyleSheet } from 'react-native'
import { scale } from '../../styles/sizes'
import { color } from '../../theme'

export default StyleSheet.create({
  homeTitleContainer: { backgroundColor: color.palette.white, width: '100%', height: 100, marginTop: 15 },
  homeTextSmall: {
    fontSize: 14,
    lineHeight: 17,
    color: color.palette.lightGrey,
    textAlign: 'center',
    fontWeight: '500',
  },
  homeTextLarge: {
    fontSize: 44,
    lineHeight: 53,
    color: color.palette.HighSlateBlue,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: 5,
  },
  homeTextMini: {
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 12,
    color: 'rgba(0, 15, 60, 0.6)',
    alignSelf: 'center',
  },
  homeTextMedium: {
    left: 19,
    fontSize: 10,
    fontWeight: '600',
    lineHeight: 16,
    color: color.palette.darkBlack,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
  homeMiniUnderline: {
    width: scale(50),
    alignSelf: 'center',
    borderBottomColor: color.palette.orange,
    borderBottomWidth: 1,
  },
  homeMiddleView: { backgroundColor: color.palette.white, width: '100%', height: 100, marginTop: 15 },
  homeMiddleViewSecond: { backgroundColor: color.palette.white, width: '100%', height: 100, marginTop: 30 },
  listViewContainer: {
    justifyContent: 'center',
    flex: 1,
  },
  gameScreenContainer:{
    flex: 1,
    backgroundColor: 'rgb(5, 16, 45)',
    paddingHorizontal: 5,
  },
  holeCardContainerIpad:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    left: 20,
    top: 3,
  },
  fullTableText: {
    textAlign: 'center',
    color: '#E34234',
    marginTop: 0,
    fontSize: 30,
  },
  holeCardContainer:{
    display: 'flex',
    flexDirection: 'row',
    alignItems:'flex-end',
    paddingHorizontal: 18,
    marginVertical: 2,
    width: '100%',
  },
  holeCardView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalStyle:{
    backgroundColor: 'transparent',
    flex: 1,
    minHeight: '100%',
    marginTop: 0,
  }
})
