import * as React from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'
import ColorPicker from 'react-native-wheel-color-picker'

export interface StoryControlsProps {
  name: string
  editable?: boolean
  colorCode?: string
  AllPlayerText?: string
  handText?: string
  setSeatCount?: (arg0: string) => void
  onChangeText?: (arg0: string) => void
  setBoard?: (arg0: string) => void
  setPoints?: (arg0: string) => void
  setPot?: (arg0: string) => void
  setPlayerHand?: (arg0: string) => void
  onChangeName?: (arg0: string, arg1: string) => void
  setColor?: (arg0: string) => void
  setPlayerAction?: (arg0: string, arg1: string) => void
  onAction?: (arg0: string) => void
  setPlayerName?: (arg0: string) => void
}

export const StoryControls = (props: StoryControlsProps) => {
  const { name } = props
  return (
    <>
      {name === 'Grid' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setSeatCount && props.setSeatCount(text)}
            placeholder="Enter Seat"
            value={props.AllPlayerText}
          />
        </>
      ) : null}
      {name === 'Card' ? (
        <TextInput
          allowFontScaling={false}
          style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
          onChangeText={(text) => props.onChangeText && props.onChangeText(text)}
          placeholder="Enter Name"
          autoCapitalize="sentences"
          maxLength={3}
        />
      ) : null}
      {name === 'Board' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setBoard && props.setBoard(text)}
            placeholder="Enter Name With Comma Seperated without space"
          />
        </>
      ) : null}
      {name === 'Pot' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPoints && props.setPoints(text)}
            placeholder="Enter Points"
            keyboardType="numeric"
          />
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPot && props.setPot(text)}
            maxLength={1}
            placeholder="Enter Pot"
            keyboardType="numeric"
          />
        </>
      ) : null}
      {name === 'PlayerHand' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPlayerHand && props.setPlayerHand(text)}
            placeholder="Enter player hand"
            value={props.handText}
          />
        </>
      ) : null}
      {name === 'PlayerActions' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.onChangeName && props.onChangeName('call', text.toUpperCase())}
            placeholder="Enter button action name 1"
          />
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.onChangeName && props.onChangeName('raise', text.toUpperCase())}
            placeholder="Enter button action name 2"
          />
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.onChangeName && props.onChangeName('fold', text.toUpperCase())}
            placeholder="Enter button action name 3"
          />
          <View>
            <Text allowFontScaling={false}>Color Picker for Actions:</Text>
            <ColorPicker
              color={props.colorCode}
              onColorChangeComplete={(color: string) => {
                props.setColor && props.setColor(color)
              }}
              thumbSize={20}
              sliderSize={20}
              noSnap={false}
              row={true}
            />
          </View>
        </>
      ) : null}
      {name === 'Game' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPlayerAction && props.setPlayerAction('type', text)}
            placeholder="Enter type win/buy/leave/options/joinvideo/pot/gamedeleted/terminategame/playeroutofchips"
          />
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPlayerAction && props.setPlayerAction('description', text)}
            placeholder="Enter description..."
          />
          <TouchableOpacity
            style={{
              width: 100,
              paddingVertical: 10,
              borderRadius: 5,
              borderColor: 'lightgray',
              borderWidth: 1,
              shadowColor: '#000',
              elevation: 5,
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.7,
            }}
            onPress={() => props.onAction && props.onAction('')}
          >
            <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
              Action
            </Text>
          </TouchableOpacity>
        </>
      ) : null}
      {name === 'GameAction' ? (
        <>
          <TextInput
            allowFontScaling={false}
            style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
            onChangeText={(text) => props.setPlayerName && props.setPlayerName(text)}
            placeholder="Enter player name"
          />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <TouchableOpacity
              style={{
                width: 100,
                paddingVertical: 10,
                borderRadius: 5,
                borderColor: 'lightgray',
                borderWidth: 1,
                shadowColor: '#000',
                elevation: 5,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.7,
              }}
              onPress={() => props.onAction && props.onAction('CALL')}
            >
              <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
                CALL
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                width: 100,
                paddingVertical: 10,
                borderRadius: 5,
                borderColor: 'lightgray',
                borderWidth: 1,
                shadowColor: '#000',
                elevation: 5,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.7,
              }}
              onPress={() => props.onAction && props.onAction('RAISE')}
            >
              <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
                RAISE
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                width: 100,
                paddingVertical: 10,
                borderRadius: 5,
                borderColor: 'lightgray',
                borderWidth: 1,
                shadowColor: '#000',
                elevation: 5,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 0.7,
              }}
              onPress={() => props.onAction && props.onAction('FOLD')}
            >
              <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
                FOLD
              </Text>
            </TouchableOpacity>
          </View>
        </>
      ) : null}
    </>
  )
}
