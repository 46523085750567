export const palette = {
  black: '#1d1d1d',
  gray: '#ccc',
  white: '#FFFFFF',
  offWhite: '#e6e6e6',
  orange: '#F1592A',
  orangeDarker: '#EB9918',
  lightGrey: '#989898',
  lighterGrey: '#F1F5F9',
  angry: '#dd3333',
  HighSlateBlue: '#000F3C',
  MediumSlateBlue: '#A694CB',
  LowSlateBlue: '#22215B',
  darkBlack: '#000000',
  lightGreen: '#4DCEAA',
  grainBoro: '#D9D9D9',
}
