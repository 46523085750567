import React, { useState, useEffect } from 'react'
import { Text, View, SafeAreaView, Button, StyleSheet, GestureResponderEvent } from 'react-native'
import { CustomProgressBar, SecondaryButton } from '../../components'
import { PaddedLayout } from '../layouts'

interface ProgressLayoutInterface {
  children: React.ReactNode
  progress: number
  maxProgress: number
  onPressLeftButton: (e: GestureResponderEvent) => void
  onPressRightButton: (e: GestureResponderEvent) => void
  leftButtonText: string
  rightButtonText: string
  showButtons?: boolean
}

export const ProgressLayout = ({
  children,
  progress,
  maxProgress,
  onPressLeftButton,
  onPressRightButton,
  leftButtonText,
  rightButtonText,
  showButtons = true,
}: ProgressLayoutInterface) => {
  return (
    <View style={styles.layout}>
      <PaddedLayout>
        <CustomProgressBar progress={progress} max={maxProgress} />
        <View style={{ flexGrow: 1 }}>{children}</View>
        {showButtons && (
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <SecondaryButton
              outline
              textProps={{
                text: leftButtonText,
              }}
              onPress={onPressLeftButton}
              style={styles.playButton}
            />
            <SecondaryButton
              textProps={{
                text: rightButtonText,
              }}
              style={{ ...styles.playButton }}
              onPress={onPressRightButton}
            />
          </View>
        )}
      </PaddedLayout>
    </View>
  )
}
const styles = StyleSheet.create({
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  playButton: { height: 45, flex: 1, marginHorizontal: 4 },
})
