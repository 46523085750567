import { debounce } from 'lodash'
import React from 'react'
import { Animated, Easing, ViewProps } from 'react-native'
import { BetKind, GameState, PlayerState, PlayerStates } from '@poker-powher/poker'
import { avatars } from '../Avatars/avatars'
import { translate } from '../../i18n'
import { PlayerType } from '../../models/session/session-game'
import { emitter, PlayerActionTimerExpired } from '../../utils/events'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { PlayerRole } from './PlayerRoleBadge'
import { PlayerCellWithTable } from './PlayerCellWithTable'
import { PlayerCellWithoutTable } from './PlayerCellWithoutTable'

export interface PlayerCellProps extends ViewProps {
  id: string
  index: number
  mappedIndex: number
  name: string
  totalPlayers: number
  currentPlayerIndex: number
  bigBlindIndex: number
  smallBlindIndex: number
  dealerIndex: number
  playerState: PlayerState
  isTableVisible: boolean
  isLeft?: boolean
  player: PlayerType
  hasLocalUserJoinedVideo: boolean
  hasLocalUserHiddenVideo: boolean
  gameState: GameState
  agoraUserList: { uid: number; videoMuteState: boolean }[]
  channelName?: string
  localUserId?: string
  hasLocalUserMutedVideo: boolean
  isPlayerMutingVideoCall: boolean
  showOptionModal: () => void
  cards: string[]
  isShowdown: boolean
  onTimeoutAction: () => void
  useFourColoredCards: boolean
  showCardsForAllin: boolean
  isGamePaused: boolean
  isGameBegin: boolean
  isMyTurn: boolean
  setUserForTeacher: (userId: string) => void
  isTeacher: boolean
  isCardVisible: boolean
  secondsPassed: number
  role: string
  shouldHighlightCurrentPlayer: boolean
  actionTime: number
  startingImageHeight: number
  actionDurationLeft: number
  hasUserTappedOnAction: boolean
}

export const PlayerCell = React.memo((props: PlayerCellProps) => {
  const {
    id,
    isTableVisible,
    mappedIndex,
    index,
    totalPlayers,
    currentPlayerIndex,
    bigBlindIndex,
    smallBlindIndex,
    dealerIndex,
    player,
    playerState,
    isLeft,
    name,
    hasLocalUserJoinedVideo,
    agoraUserList,
    channelName,
    localUserId,
    hasLocalUserMutedVideo,
    hasLocalUserHiddenVideo,
    gameState,
    showOptionModal,
    cards,
    isShowdown,
    onTimeoutAction,
    useFourColoredCards,
    showCardsForAllin,
    isGamePaused,
    isGameBegin,
    isMyTurn,
    setUserForTeacher,
    isPlayerMutingVideoCall,
    isTeacher,
    isCardVisible,
    secondsPassed,
    role,
    shouldHighlightCurrentPlayer,
    actionTime,
    startingImageHeight,
    actionDurationLeft,
    hasUserTappedOnAction,
    ...rest
  } = props

  const isVideoScreenVisible = agoraUserList.map((user) => user.uid).indexOf(Number(player.id)) !== -1 ? true : false
  const playerVideoMuteState = agoraUserList.find((user) => user.uid === Number(player.id))?.videoMuteState || false
  const [cellSize, setCellSize] = React.useState([0, 0])
  const imageHeight = React.useState(new Animated.Value(0))[0]
  const userId = localUserId ? localUserId : ''
  const isWaitlisted =
    playerState && (playerState.tag === PlayerStates.Waiting.tag || playerState.tag === PlayerStates.Out.tag)
  const isAllIn = (playerState && playerState.tag) === PlayerStates.AllIn.tag
  const bets = player ? player.bets : []
  const lastBet = (bets && bets[bets.length - 1]) || { kind: '', amount: ' ' }
  const isFolded = (playerState && playerState.tag) === PlayerStates.Folded.tag
  const isForeverOut = (playerState && playerState.tag) === PlayerStates.ForeverOut.tag
  const chips = player ? player.chips : 0
  // const lastAction = ((localUserId === player.userId) && isMyTurn) ? '' : isAllIn
  // all in and chips > 0  -> need to show last bet
  const lastAction = isForeverOut
    ? translate('gameScreen.watching')
    : isFolded
    ? translate('playerActionHints.FOLD')
    : isAllIn
    ? chips > 0
      ? lastBet.kind.toUpperCase()
      : translate('playerActionHints.ALLIN')
    : isWaitlisted
    ? playerState.tag === PlayerStates.Waiting.tag
      ? translate('gameScreen.sittingOut')
      : playerState.tag.toUpperCase()
    : lastBet.kind
        .toUpperCase()
        .replace(BetKind.BIGBLIND.toUpperCase(), '') // CHECK
        .replace(BetKind.SMALLBLIND.toUpperCase(), '') // CALL
  // lastAction = lastAction.replace(BetKind.BIGBLIND.toUpperCase(), '').replace(BetKind.SMALLBLIND.toUpperCase(), '')
  console.log('PlayerCell lastAction', player.name, lastAction, playerState.tag)
  const bet = player ? player.bet : 0
  const lastAmount = bet === 0 || isWaitlisted ? '' : numberWithCommas(bet).toString()
  const chipsText = isWaitlisted || chips === 0 ? '' : numberWithCommas(player.chips)
  const playerRole =
    player.role === PlayerRole.bigBlind
      ? PlayerRole.bigBlind
      : player.role === PlayerRole.smallBlind
      ? PlayerRole.smallBlind
      : dealerIndex === index
      ? PlayerRole.dealer
      : PlayerRole.player

  const onPress = debounce(() => {
    setUserForTeacher(player.userId)
    showOptionModal()
  }, 250)

  const composition =
    currentPlayerIndex === index
      ? Animated.timing(imageHeight, {
          duration: actionDurationLeft,
          // toValue: cellSize[1],
          toValue: 0,
          useNativeDriver: false,
          easing: Easing.linear,
        })
      : null

  const animateHeight = () => {
    if (currentPlayerIndex === index) {
      console.info('[PlayerCell] animateHeight...', currentPlayerIndex, index)
      imageHeight.setValue(startingImageHeight)
      // imageHeight.setValue(1)
      if ((isGameBegin && !isGamePaused) || isMyTurn) {
        console.info('[PlayerCell] action timer started...', isGameBegin, isGamePaused, isMyTurn)
        if (composition && !isShowdown) {
          composition.start(({ finished }) => {
            if (finished) {
              console.log('[PlayerCell] action timer expired!', finished)
              try {
                onTimeoutAction()
                emitter.emit(PlayerActionTimerExpired)
              } catch (error) {
                console.error('PlayerCell animateHeight', error)
              }
            }
          })
        }
      } else if (isGameBegin && isWaitlisted && composition && !isShowdown) {
        // Waiting users can see the animation for current users
        composition.start()
      }
    }
    return composition
  }

  React.useEffect(() => {
    imageHeight.stopAnimation()
    const composition = animateHeight()
    return () => {
      if (composition) {
        composition.reset()
      }
    }
  }, [currentPlayerIndex, cellSize[1], isGameBegin, isGamePaused])

  return (
    <>
      {isTableVisible ? (
        <PlayerCellWithTable
          playerState={playerState}
          isTableVisible={isTableVisible}
          onPress={onPress}
          chipsText={chipsText}
          lastAction={lastAction}
          lastAmount={lastAmount}
          isLeft={isLeft}
          totalPlayers={totalPlayers}
          isPlayerMutingVideoCall={playerVideoMuteState}
          playerRole={playerRole}
          index={index}
          currentPlayerIndex={currentPlayerIndex}
          cardViewHeight={cellSize[1] / 2.8}
        />
      ) : (
        <PlayerCellWithoutTable
          playerId={player.id}
          cellSize={cellSize}
          imageHeight={imageHeight}
          avatar={player.color !== '' ? avatars[player.color] : avatars.gray}
          totalPlayers={totalPlayers}
          channelName={channelName}
          hasLocalUserJoinedVideo={hasLocalUserJoinedVideo}
          hasLocalUserHiddenVideo={hasLocalUserHiddenVideo}
          gameState={gameState}
          index={index}
          localUserId={localUserId}
          player={player}
          agoraUserList={agoraUserList}
          currentPlayerIndex={currentPlayerIndex}
          userId={userId}
          name={name}
          mappedIndex={mappedIndex}
          cards={cards}
          isShowdown={isShowdown}
          useFourColoredCards={useFourColoredCards}
          showCardsForAllin={showCardsForAllin}
          playerState={playerState}
          isCardVisible={isCardVisible}
          playerRole={playerRole}
          isTableVisible={isTableVisible}
          chips={chips}
          isLeft={isLeft}
          isWaitlisted={isWaitlisted}
          isFolded={isFolded}
          lastAction={lastAction}
          isMyTurn={isMyTurn}
          isVideoScreenVisible={isVideoScreenVisible}
          isPlayerOnVideoCall={
            props.agoraUserList.map((user) => user.uid).indexOf(Number(player.id)) === -1 ? false : true
          }
          isGameBegin={isGameBegin}
          isPlayerMutingVideoCall={playerVideoMuteState}
          isGamePaused={isGamePaused}
          hasUserTappedOnAction={hasUserTappedOnAction}
          startingImageHeight={startingImageHeight}
          setCellSize={setCellSize}
          onPress={onPress}
          bigBlindIndex={bigBlindIndex}
          smallBlindIndex={smallBlindIndex}
          dealerIndex={dealerIndex}
          showOptionModal={showOptionModal}
          onTimeoutAction={onTimeoutAction}
          hasLocalUserMutedVideo={hasLocalUserMutedVideo}
          shouldHighlightCurrentPlayer={shouldHighlightCurrentPlayer}
          setUserForTeacher={setUserForTeacher}
          isTeacher={isTeacher}
          secondsPassed={secondsPassed}
          role={role}
          actionTime={actionTime}
          actionDurationLeft={actionDurationLeft}
          playerVideoMuteState={playerVideoMuteState}
          cardViewHeight={cellSize[1] / 2.8}
        />
      )}
    </>
  )
})
