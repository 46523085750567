import React from 'react'
import { Image, StatusBar, View } from 'react-native'
import { GameState } from '@poker-powher/poker'
import { PlayerCell } from '../PlayerCell/PlayerCell'
import { PlayerType } from '../../models/session/session-game'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { Text } from '../text/text'
import { styles } from './style'

export enum GridPreset {
  table = 'table',
  noTable = 'noTable',
}
export interface PlayerProps {
  mainPot: number
  players: PlayerType[]
  preset: GridPreset
  hasLocalUserJoinedVideo: boolean
  hasLocalUserMutedVideo: boolean
  hasLocalUserHiddenVideo: boolean
  gameState: GameState
  agoraUserList: { uid: number; videoMuteState: boolean }[]
  channelName?: string
  localUserId?: string
  showOptionModal: () => void
  isShowdown: boolean
  onTimeoutAction: () => void
  useFourColoredCards: boolean
  showCardsForAllin: boolean
  isGamePaused: boolean
  isGameBegin: boolean
  isMyTurn: boolean
  isTeacher: boolean
  isCardVisible: boolean
  setUserForTeacher: (userId: string) => void
  secondsPassed: number
  shouldHighlightCurrentPlayer: boolean
  actionTime: number
  startingImageHeight: number
  actionDurationLeft: number
  hasUserTappedOnAction: boolean
}

const getTableHeight = (numberOfPlayers: number) => {
  switch (numberOfPlayers) {
    case 1:
    case 2:
      return '30%'
    case 3:
    case 4:
      return '50%'
    case 5:
    case 6:
      return '75%'
    case 7:
    case 8:
      return '95%'
    case 9:
      return '100%'
  }
}

export const Grid = React.memo((props: PlayerProps) => {
  const {
    players,
    preset,
    mainPot,
    hasLocalUserJoinedVideo,
    agoraUserList,
    channelName,
    localUserId,
    hasLocalUserMutedVideo,
    hasLocalUserHiddenVideo,
    gameState,
    showOptionModal,
    isShowdown,
    onTimeoutAction,
    useFourColoredCards,
    showCardsForAllin,
    isGamePaused,
    isGameBegin,
    isMyTurn,
    isTeacher,
    isCardVisible,
    setUserForTeacher,
    secondsPassed,
    shouldHighlightCurrentPlayer,
    actionTime,
    startingImageHeight,
    actionDurationLeft,
    hasUserTappedOnAction,
  } = props
  const countPlayers = players.length

  let leftPlayers: any[] = []
  let rightPlayers: any[] = []
  if (countPlayers === 1) {
    //playerIndex - for preserving the actual player index after splitting the players into left and right panel(side) of the table
    leftPlayers.push({ ...players[0], playerIndex: 0 })
  } else if (countPlayers === 2) {
    leftPlayers.push({ ...players[0], playerIndex: players[0].index })
    rightPlayers.push({ ...players[1], playerIndex: players[1].index })
  } else {
    players.forEach((element, index) => {
      if (index % 2 === 0) {
        leftPlayers.push({ ...element, playerIndex: element.index })
      } else {
        rightPlayers.push({ ...element, playerIndex: element.index })
      }
    })
  }

  const displayPlayersWithoutTable = () => {
    const pad = 10
    return (
      <View
        style={[
          {
            flex: 1,
            flexDirection: players.length === 2 ? 'column' : 'row',
            flexWrap: players.length === 2 ? 'nowrap' : 'wrap',
            justifyContent: 'center',
            paddingHorizontal: players.length === 2 ? pad : 0,
            paddingTop: pad,
            marginBottom: players.length === 2 ? pad : 0,
          },
        ]}
      >
        <StatusBar barStyle="light-content" />
        {players.map((item, index) => {
          const { bigBlindPlayer, smallBlindPlayer, currentPlayer, dealer, userId, cards } = item
          return (
            <PlayerCell
              key={`player-cell-${index}`}
              isTableVisible={false}
              currentPlayerIndex={currentPlayer}
              bigBlindIndex={bigBlindPlayer}
              smallBlindIndex={smallBlindPlayer}
              dealerIndex={dealer}
              totalPlayers={countPlayers}
              hasLocalUserJoinedVideo={hasLocalUserJoinedVideo}
              agoraUserList={agoraUserList}
              channelName={channelName}
              localUserId={localUserId}
              hasLocalUserMutedVideo={hasLocalUserMutedVideo}
              hasLocalUserHiddenVideo={hasLocalUserHiddenVideo}
              gameState={gameState}
              showOptionModal={showOptionModal}
              onTimeoutAction={onTimeoutAction}
              cards={cards}
              isShowdown={isShowdown}
              useFourColoredCards={useFourColoredCards}
              showCardsForAllin={showCardsForAllin}
              isGamePaused={isGamePaused}
              isGameBegin={isGameBegin}
              isMyTurn={isMyTurn}
              isTeacher={isTeacher}
              isCardVisible={isCardVisible}
              setUserForTeacher={setUserForTeacher}
              secondsPassed={secondsPassed}
              shouldHighlightCurrentPlayer={shouldHighlightCurrentPlayer}
              actionTime={actionTime}
              startingImageHeight={startingImageHeight}
              actionDurationLeft={actionDurationLeft}
              hasUserTappedOnAction={hasUserTappedOnAction}
              {...item}
            />
          )
        })}
      </View>
    )
  }

  const displayPlayersWithTable = () => {
    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={styles.leftPanelContainer}>
          {leftPlayers.length > 0
            ? leftPlayers.map((item, index) => {
                const { bigBlindPlayer, smallBlindPlayer, currentPlayer, dealer, cards } = item
                return (
                  <PlayerCell
                    isTableVisible={true}
                    currentPlayerIndex={currentPlayer}
                    bigBlindIndex={bigBlindPlayer}
                    smallBlindIndex={smallBlindPlayer}
                    dealerIndex={dealer}
                    totalPlayers={countPlayers}
                    customStyle={{ flexDirection: 'row-reverse' }}
                    isLeft={true}
                    hasLocalUserJoinedVideo={hasLocalUserJoinedVideo}
                    agoraUserList={agoraUserList}
                    channelName={channelName}
                    localUserId={localUserId}
                    hasLocalUserMutedVideo={hasLocalUserMutedVideo}
                    showOptionModal={showOptionModal}
                    onTimeoutAction={onTimeoutAction}
                    cards={cards}
                    isShowdown={isShowdown}
                    useFourColoredCards={useFourColoredCards}
                    showCardsForAllin={showCardsForAllin}
                    isGamePaused={isGamePaused}
                    isGameBegin={isGameBegin}
                    isMyTurn={isMyTurn}
                    isTeacher={isTeacher}
                    isCardVisible={isCardVisible}
                    secondsPassed={secondsPassed}
                    shouldHighlightCurrentPlayer={shouldHighlightCurrentPlayer}
                    actionTime={actionTime}
                    startingImageHeight={startingImageHeight}
                    actionDurationLeft={actionDurationLeft}
                    hasUserTappedOnAction={hasUserTappedOnAction}
                    {...item}
                  />
                )
              })
            : null}
        </View>
        <View style={styles.pokerTableContainer}>
          <View style={[styles.pokerTable, { height: getTableHeight(countPlayers) }]}>
            <Image
              source={require('../../../assets/icons/poker-coin1.png')}
              resizeMode="contain"
              style={styles.pokerCoinImage}
            />
            <Text
              allowFontScaling={false}
              tx="gameScreen.formattedNumber"
              txOptions={{ value: numberWithCommas(mainPot) }}
              style={styles.totalChipsText}
            />
          </View>
        </View>
        <View style={styles.rightPanelContainer}>
          {rightPlayers.length > 0
            ? rightPlayers.map((item, index) => {
                const { bigBlindPlayer, smallBlindPlayer, currentPlayer, dealer, cards } = item
                return (
                  <PlayerCell
                    isTableVisible={true}
                    currentPlayerIndex={currentPlayer}
                    bigBlindIndex={bigBlindPlayer}
                    smallBlindIndex={smallBlindPlayer}
                    dealerIndex={dealer}
                    totalPlayers={countPlayers}
                    isLeft={false}
                    hasLocalUserJoinedVideo={hasLocalUserJoinedVideo}
                    agoraUserList={agoraUserList}
                    channelName={channelName}
                    localUserId={localUserId}
                    hasLocalUserMutedVideo={hasLocalUserMutedVideo}
                    showOptionModal={showOptionModal}
                    onTimeoutAction={onTimeoutAction}
                    cards={cards}
                    isShowdown={isShowdown}
                    useFourColoredCards={useFourColoredCards}
                    showCardsForAllin={showCardsForAllin}
                    isGamePaused={isGamePaused}
                    isGameBegin={isGameBegin}
                    isMyTurn={isMyTurn}
                    isTeacher={isTeacher}
                    isCardVisible={isCardVisible}
                    secondsPassed={secondsPassed}
                    shouldHighlightCurrentPlayer={shouldHighlightCurrentPlayer}
                    actionTime={actionTime}
                    startingImageHeight={startingImageHeight}
                    actionDurationLeft={actionDurationLeft}
                    hasUserTappedOnAction={hasUserTappedOnAction}
                    {...item}
                  />
                )
              })
            : null}
        </View>
      </View>
    )
  }
  return <>{preset === GridPreset.noTable ? displayPlayersWithoutTable() : displayPlayersWithTable()}</>
})
