import 'react-native-get-random-values'

import { LinearGradient } from 'expo-linear-gradient'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  SafeAreaView,
  StyleSheet,
  TextInput,
  View,
  Linking,
} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { useNavigation } from '@react-navigation/native'

import { Text } from '../../components'
import { useStore } from '../../models'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { translate } from '../../i18n'

export const TournamentPasswordScreen = observer(() => {
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  const [tournamentPassword, setTournamentPassword] = React.useState('')
  const [loading, setLoading] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = React.useState(true)

  useEffect(() => {
    setTournamentPassword('')
    setIsPasswordValid(true)
  }, [])

  return (
    <ImageBackground
      source={require('../../../assets/icons/Blank-Home_Purple.png')}
      style={[{ width: `100%`, height: `100%`, flex: 1 }]}
    >
      <SafeAreaView style={[styles.container, {}]}>
        <View style={styles.contentContainer}>
          <Image
            source={require('../../../assets/icons/Logo-Purple.png')}
            style={{
              width: '80%',
              height: '30%',
              resizeMode: 'contain',
            }}
          />

          <View
            style={{
              marginHorizontal: 41,
              width: '80%',
            }}
          >
            <Text tx="communityGamesPassword.heading" style={styles.heading}></Text>
            <View style={{ height: 20 }}>
              {!isPasswordValid && <Text tx="communityGamesPassword.invalidPassword" style={styles.invalidPassword} />}
            </View>
            <TextInput
              value={tournamentPassword}
              secureTextEntry
              onChangeText={(value) => setTournamentPassword(value)}
              style={styles.inputField}
              onSubmitEditing={() => {
                if (tournamentPassword === 'Poker') {
                  navigation.navigate(DrawerRoutes.activeGames)
                  store.session.clearChannelChats()
                } else {
                  setIsPasswordValid(false)
                }
              }}
            />
            <TouchableOpacity
              onPress={() => {
                if (tournamentPassword === 'Poker') {
                  navigation.navigate(DrawerRoutes.activeGames)
                  store.session.clearChannelChats()
                } else {
                  setIsPasswordValid(false)
                }
              }}
              style={{ height: 55, marginTop: 4 }}
            >
              <LinearGradient
                colors={['#49CDF2', '#9444FB']}
                start={[0, 1]}
                end={[1, 0]}
                style={[styles.gradientButton, { flexDirection: 'row', alignItems: 'center' }]}
              >
                <Text tx="communityGamesPassword.proceed" allowFontScaling={false} style={styles.buttonText} />
                {loading && <ActivityIndicator size={'small'} color="white" />}
              </LinearGradient>
            </TouchableOpacity>
            <View style={{ marginVertical: 5 }}>
              <Text>
                {translate('communityGamesPassword.communityDescription')}
                <Text
                  style={{ color: '#87CEEB', textDecorationLine: 'underline' }}
                  onPress={() => {
                    Linking.openURL('https://pokerpower.com/')
                  }}
                >
                  {translate('communityGamesPassword.pokerWebsite')}
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </ImageBackground>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 20,
  },
  buttonText: {
    textAlign: 'center',
    fontWeight: '800',
    fontSize: moderateScale(22),
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  gradientButton: {
    flex: 1,
    borderRadius: 8,
    justifyContent: 'center',
    height: 55,
  },
  inputField: {
    height: 58,
    backgroundColor: 'white',
    borderRadius: 8,
    fontSize: moderateScale(24),
    paddingStart: 10,
    marginTop: 6,
  },
  backCopyText: {
    fontSize: moderateScale(14),
    fontFamily: fontFamily.sfProDisplayHeavy,
    textTransform: 'uppercase',
    color: '#49CDF2',
  },
  heading: {
    fontSize: moderateScale(24),
    fontFamily: fontFamily.sfProDisplayHeavy,
    marginTop: 36,
    textAlign: 'center',
  },
  nameHeading: {
    fontSize: moderateScale(14),
    fontFamily: fontFamily.sfProDisplayRegular,
    marginTop: 16,
  },
  invalidPassword: {
    fontSize: moderateScale(14),
    marginTop: 6,
    color: 'red',
  },
})
