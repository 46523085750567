import 'react-native-get-random-values'

import { debounce, orderBy, uniqBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native'
import { FlatList, ScrollView, Switch } from 'react-native-gesture-handler'
import { List, Menu, Modal } from 'react-native-paper'

import Clipboard from '@react-native-clipboard/clipboard'

import { Text } from '../../components'
import { translate } from '../../i18n'
import { useStore } from '../../models'
import styles from '../../styles/globalStyle'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

export const AdminMessagesScreen = observer(() => {
  const store = useStore()
  const unsortedMessages = store.session.messages.slice()
  const messages = orderBy(
    uniqBy(unsortedMessages, (message) => message.payload.id),
    [(message) => message.payload.timestamp, (message) => message.payload.version],
    ['asc', 'asc']
  )

  const [visible, setVisible] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const isMessageSortingEnabled = store.session.isSortingMessageByTimestamp
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const containerStyle = { backgroundColor: 'white', padding: 20 }
  const showSnackbar = store.session.showSnackbar

  const onToggleSwitch = debounce(() => {
    const toggleSorting = isMessageSortingEnabled
    store.session.setIsSortingMessageByTimestamp(!toggleSorting)
  }, 250)

  return (
    <ImageBackground source={image} style={{ ...startStyles.image }}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View style={{ flex: 1 }}>
            <List.Item
              title={translate('adminMessageScreen.toast')}
              description={translate('adminMessageScreen.showSnackbar')}
              right={(props) => (
                <Switch value={showSnackbar} onValueChange={debounce(() => store.session.toggleSnackbar(), 250)} />
              )}
            />
            <List.Item
              title={translate('adminMessageScreen.sortMessage')}
              right={(props) => <Switch value={isMessageSortingEnabled} onValueChange={onToggleSwitch} />}
            />
            <FlatList
              data={isMessageSortingEnabled ? messages : unsortedMessages}
              keyExtractor={(item, index) => `message-item-${index}`}
              renderItem={({ item, index, separators }) => (
                <Menu.Item
                  key={`admin-menu-messages-${index}`}
                  // icon="content-paste"
                  onPress={debounce(() => {
                    const pretty = JSON.stringify(item, null, 2)
                    setMessage(pretty)
                    Clipboard.setString(pretty)
                    showModal()
                  }, 250)}
                  title={item.payload.kind ?? ''}
                />
              )}
            />
          </View>
          <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
            <Text
              allowFontScaling={false}
              tx="adminMessageScreen.howToDismissMessage"
              style={{ color: 'black', textAlign: 'center' }}
            />
            <Text allowFontScaling={false}>{'\n'}</Text>
            <ScrollView>
              <Text allowFontScaling={false} style={{ color: 'black' }}>
                {message}
              </Text>
            </ScrollView>
          </Modal>
        </SafeAreaView>
      </View>
    </ImageBackground>
  )
})
