import { LinearGradient } from 'expo-linear-gradient'
import React, { useRef } from 'react'
import { Dimensions, StyleSheet, View, ViewProps } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { DrawerActions, useNavigation } from '@react-navigation/native'

import { moderateScale } from '../../styles/sizes'
import { DismissModalEvent, emitter } from '../../utils/events'
import { Text } from '../text/text'

export interface TimerOverlayProps extends ViewProps {
  onClose: () => void
  onPress: () => void
  gameStartForEvent: () => void
  visible: boolean
  time: number
  type: string
  isTeacher: boolean
  isHost: boolean
}

export enum TimerTypes {
  gameStart = 'gameStart',
  gamePause = 'gamePause',
}

const StartButton = (props: TimerOverlayProps) => {
  const { onPress, gameStartForEvent } = props
  return (
    <LinearGradient
      colors={['#FF3F25', '#DC2BFF']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      style={{
        width: Dimensions.get('screen').width / 1.3,
        alignItems: 'center',
        padding: 1,
        height: 50,
        borderRadius: 4,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          gameStartForEvent(), props.onPress()
        }}
        style={{
          backgroundColor: '#232323',
          padding: 5,
          width: Dimensions.get('screen').width / 1.3 - 2,
          height: 48,
          borderRadius: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          allowFontScaling={false}
          tx="common.startNow"
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            fontStyle: 'italic',
            fontWeight: '900',
            fontSize: 18,
            width: '100%',
          }}
        />
      </TouchableOpacity>
    </LinearGradient>
  )
}

export const TimerOverlay = (props: TimerOverlayProps) => {
  const navigation = useNavigation()
  const { visible, onClose, onPress, time, type, isTeacher, isHost, gameStartForEvent, ...rest } = props
  const [seconds, setSeconds] = React.useState(time)
  const [minutes, setMinutes] = React.useState(Math.floor(time/60))
  const interval = useRef<NodeJS.Timer>()

  React.useEffect(() => {
    interval.current = setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)
    if (seconds <= 0) {
      clearTimeout(interval.current)
      onPress()
    } else {
      const reCalcMinutes = Math.floor(seconds/60)
      if (minutes !== reCalcMinutes) {
        setMinutes(reCalcMinutes)
      }
    }

    return () => {
      interval.current && clearTimeout(interval.current)
      // NOTE: do not flash start time at the end...
      // setSeconds(time)
    }
  }, [seconds])


  React.useEffect(() => {
    const dismissListener = (event: unknown) => {
      console.log(DismissModalEvent, event)
      onClose()
    }
    if (visible) {
      navigation.dispatch(DrawerActions.closeDrawer)
      emitter.removeListener(DismissModalEvent, dismissListener)
      emitter.addListener(DismissModalEvent, dismissListener)
    }

    return () => {
      emitter.removeListener(DismissModalEvent, dismissListener)
    }
  }, [visible])

  const TimerContent = (): JSX.Element | null => {
    console.log('TimerContent', type)
    switch (type) {
      case TimerTypes.gameStart:
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              tx={'common.gameStartsIn'}
              style={{ 
                color: '#fff', 
                fontSize: moderateScale(15), 
                textAlign: 'center', 
                padding: 0, 
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
            />
            <Text
              text={': '}
              style={{ 
                color: '#fff', 
                fontSize: moderateScale(15), 
                fontWeight: 'bold',
              }}
            />
            <Text
              tx={'common.label'}
              txOptions={{ 
                label: `0${minutes}:${
                  seconds - (minutes * 60) <= 0 
                    ? `00` 
                    : seconds - (minutes * 60) < 10
                      ? `0${seconds - (minutes * 60)}`
                      : seconds - (minutes * 60)
                }` 
              }}
              style={{ 
                color: '#49CDF2', 
                fontSize: moderateScale(16), 
                textAlign: 'center', 
                padding: 0,
                fontWeight: 'bold',
              }}
            />
          </View>
        )

      case TimerTypes.gamePause:
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                tx={'common.buyingChips'}
                txOptions={{ playerName: 'Player 1' }}
                style={{ color: '#fff', fontSize: moderateScale(12), textAlign: 'center' }}
              />
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                tx={'common.gameResumesIn'}
                style={{ 
                  color: '#fff', 
                  fontSize: moderateScale(15), 
                  textAlign: 'center', 
                  padding: 0, 
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              />
              <Text
                text={': '}
                style={{ 
                  color: '#fff', 
                  fontSize: moderateScale(15), 
                  fontWeight: 'bold',
                }}
              />
              <Text
                tx={'common.label'}
                txOptions={{ 
                  label: `0${minutes}:${
                    seconds - (minutes * 60) <= 0 
                      ? `00` 
                      : seconds - (minutes * 60) < 10
                        ? `0${seconds - (minutes * 60)}`
                        : seconds - (minutes * 60)
                  }` 
                }}
                style={{ 
                  color: '#49CDF2', 
                  fontSize: moderateScale(16), 
                  textAlign: 'center', 
                  padding: 0,
                  fontWeight: 'bold',
                }}
              />
            </View>
          </View>
        )
      default:
        return null
    }
  }

  return (
    <View style={[styles.modal, { height: '100%', minHeight: '100%' }]} shouldRasterizeIOS>
      <View
        style={[
          styles.modelContentContainer,
          {
            minHeight: 200,
            elevation: 5,
            shadowColor: '#ffffff',
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.5,
            shadowRadius: 5,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 16
          },
        ]}
      >
        <LinearGradient
          colors={['#49CDF2', '#49CDF2', '#9444FB']}
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 1.0, y: 1.0 }}
          style={[styles.modalContainer, { marginHorizontal: 0, minWidth: 210 }]}
        >
          <LinearGradient
            colors={['#0D2063', '#040933']}
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={{ paddingVertical: 4, paddingHorizontal: 6, borderRadius: 4 }}
          >
            <TimerContent />
          </LinearGradient>
        </LinearGradient>
      </View>
      {/**
       * Instead of returning null render a view to hold timer in center
       */}
      {isTeacher || isHost ? (
        <View style={[styles.startButtonContainer, {}]}>
          <StartButton {...props} onPress={() => props.onPress()} gameStartForEvent={gameStartForEvent} />
        </View>
      ) : (
        <View style={{ flex: 1, justifyContent: 'center' }} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    // backgroundColor: '#232335',
  },
  startButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 100,
  },
  modelContentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    // marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 6,
    padding: 2,
  },
})
