/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum UserRoles {
  admin="admin",
superadmin="superadmin",
teacher="teacher",
user="user"
}

/**
* UserRoles
 *
 * The specific roles of different user
*/
export const UserRolesEnumType = types.enumeration("UserRoles", [
        "admin",
  "superadmin",
  "teacher",
  "user",
      ])
