/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ChatKind {
  admin="admin",
dealer="dealer",
engine="engine",
observer="observer",
user="user"
}

/**
* ChatKind
 *
 * The specific roles of different chat
*/
export const ChatKindEnumType = types.enumeration("ChatKind", [
        "admin",
  "dealer",
  "engine",
  "observer",
  "user",
      ])
