
import {StyleSheet} from 'react-native'
import { fontFamily } from '../../theme/fontFamily'

const styles = StyleSheet.create({
    winningHeading: {
      textAlign: 'center',
      paddingTop: 20,
      fontSize: 24,
      color: 'white',
      fontFamily: fontFamily.sfProDisplayHeavyItalic,
    },
    separator: {
      height: 1,
      width: '70%',
      backgroundColor: '#C4C4C4',
      marginVertical: 12,
      alignSelf: 'center',
    },
    leaderBoardText: {
      fontSize: 20,
      color: '#FFBB0E',
      fontFamily: fontFamily.sfProDisplayBoldItalic,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    myDataContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    myDataLabels: {
      fontFamily: fontFamily.sfProDisplayMediumItalic,
      fontSize: 12,
      color: '#6E8DE3',
    },
    myDataValues: {
      fontFamily: fontFamily.sfProDisplayBoldItalic,
      fontSize: 12,
      color: '#fff',
      paddingTop: 5,
    },
    tableHeader: {
      height: 30,
      borderBottomWidth: 1,
      borderColor: '#ffffff20',
      borderTopWidth: 1,
      width: '100%',
      flexDirection: 'row',
      marginTop: 16,
      justifyContent: 'center',
      alignItems: 'center',
    },
    raValueContainer: {
      alignItems: 'flex-end',
      paddingHorizontal: 5,
      width: '20%',
    },
    raValue: {
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayBold,
    },
    nameValue: {
      textAlign: 'left',
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayBold,
    },
    stackValue: {
      textAlign: 'center',
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayBold,
    },
    raHeaderContainer: {
      width: '20%',
      alignItems: 'flex-end',
      paddingHorizontal: 5,
    },
    raHeading: {
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayMediumItalic,
      textAlign: 'right',
    },
    nameHeaderContainer: {
      width: '50%',
      // width: '40%',
      paddingHorizontal: 15,
      alignItems: 'flex-start',
    },
    nameHeading: {
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayMediumItalic,
      textAlign: 'right',
    },
    stackHeading: {
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayMediumItalic,
      textAlign: 'center',
    },
    stackHeaderContainer: {
      width: '25%',
      justifyContent: 'center',
      // alignItems: 'flex-end',
      paddingHorizontal: 5,
    },
    rankHeading: {
      fontSize: 12,
      fontFamily: fontFamily.sfProDisplayMediumItalic,
      textAlign: 'center',
    },
    rankHeaderContainer: {
      width: '25%',
      justifyContent: 'center',
      // alignItems: 'flex-end',
      paddingHorizontal: 5,
    },
  })

  export default styles;