import { RouteProp, useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PaddedLayout } from '../../components/layouts/PaddedLayout'
import { ScreenTitle } from '../../components/ScreenTitle/ScreenTitle'
import { SecondaryButton } from '../../components/SecondaryButton/SecondaryButton'
import { MiniGamesRoutes, MiniGamesNavigation } from '../../navigation/minigames-navigator'
import { Results } from './components/Results'
import { IResults } from './interfaces/results.interface'
import { fontFamily } from '../../theme/fontFamily'

interface FinalScreenProps {
  route: RouteProp<{
    params: IResults
  }>
}

export const FinalScreen = ({ route }: FinalScreenProps) => {
  const navigation = useNavigation<MiniGamesNavigation>()
  const { gameName } = route.params

  return (
    <PaddedLayout>
      <View style={styles.container}>
        <ScreenTitle tx="miniGamesScreen.finalScreen.title" />

        <Results results={route.params}></Results>

        <SecondaryButton
          textProps={{
            tx: 'miniGamesScreen.finalScreen.playAgainButton',
            style: styles.button,
          }}
          onPress={() => navigation.navigate(MiniGamesRoutes.introGameScreen, { gameName })}
        ></SecondaryButton>
      </View>
    </PaddedLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    fontFamily: fontFamily.sfProDisplayBlackItalic,
    fontSize: 18,
    lineHeight: 21,
  },
})
