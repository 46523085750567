export const fontFamily = {
  heroNewRegular: 'HeroNew-Regular',
  heroNewRegularItalic: 'HeroNew-RegularItalic.otf',
  heroNewBold: 'HeroNew-Bold',
  heroNewExtraBold: 'HeroNew-ExtraBold',
  heroNewBoldItalic: 'HeroNew-BoldItalic',
  heroNewExtraBoldItalic: 'HeroNew-ExtraBoldItalic',
  sfProDisplayBlack: 'SFProDisplay-Black',
  sfProDisplayBold: 'SFProDisplay-Bold',
  sfProDisplayBlackItalic: 'SFProDisplay-BlackItalic',
  sfProDisplayBoldItalic: 'SFProDisplay-BoldItalic',
  sfProDisplayHeavy: 'SFProDisplay-Heavy',
  sfProDisplayHeavyItalic: 'SFProDisplay-HeavyItalic',
  sfProDisplayLight: 'SFProDisplay-Light',
  sfProDisplayLightItalic: 'SFProDisplay-LightItalic',
  sfProDisplayMedium: 'SFProDisplay-Medium',
  sfProDisplayMediumItalic: 'SFProDisplay-MediumItalic',
  sfProDisplayRegular: 'SFProDisplay-Regular',
  sfProDisplayRegularItalic: 'SFProDisplay-RegularItalic',
  sfProDisplaySemibold: 'SFProDisplay-Semibold',
  sfProDisplaySemiboldItalic: 'SFProDisplay-SemiboldItalic',
  sfProDisplayThin: 'SFProDisplay-Thin',
  sfProDisplayThinItalic: 'SFProDisplay-ThinItalic',
  sfProDisplayUltralight: 'SFProDisplay-Ultralight',
  sfProDisplayUltralightItalic: 'SFProDisplay-UltralightItalic',
  sfProTextBold: 'SFProText-Bold',
  sfProTextBoldItalic: 'SFProText-BoldItalic',
  sfProTextHeavy: 'SFProText-Heavy',
  sfProTextHeavyItalic: 'SFProText-HeavyItalic',
  sfProTextLight: 'SFProText-Light',
  sfProTextLightItalic: 'SFProText-LightItalic',
  sfProTextMedium: 'SFProText-Medium',
  sfProTextMediumItalic: 'SFProText-MediumItalic',
  sfProTextRegular: 'SFProText-Regular',
  sfProTextRegularItalic: 'SFProText-RegularItalic',
  sfProTextSemibold: 'SFProText-Semibold',
  sfProTextSemiboldItalic: 'SFProText-SemiboldItalic',
}
