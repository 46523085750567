import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import OneSignal from 'react-native-onesignal'

/**
 * A PermissionsStore model.
 */

export const PermissionsStore = types
  .model('PermissionsStore')
  .props({ isPushDisabled: types.optional(types.boolean, false) })
  .actions((self) => ({
    setIsPushDisabled(value: boolean) {
      self.isPushDisabled = value
    },
  }))
  .actions((self) => ({
    async refresh() {
      const deviceState = await OneSignal.getDeviceState()
      if (deviceState) {
        console.log('[permissions.store.refresh]', deviceState)
        if (deviceState.hasNotificationPermission === false) {
          OneSignal.disablePush(true)
          self.setIsPushDisabled(true)
        } else {
          self.setIsPushDisabled(deviceState.isPushDisabled)
        }
        console.log('[permissions.store.refresh]', deviceState)
      }
    },
    async afterAttach() {
      await this.refresh()
    },
  }))

/**
 * The PermissionsStore instance.
 */
export type PermissionsStoreType = Instance<typeof PermissionsStore>

/**
 * The data of a PermissionsStore.
 */
export type PermissionsStoreSnapshot = SnapshotOut<typeof PermissionsStore>
