import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  chatOverlayContainer: {
    backgroundColor: 'rgba(0,0,50,0.2)',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatOverlayHeaderSpacer : {
    width: '100%',
    height: 40,
  },
  chatBlurUnderlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  chatOverlayKeyboardAvoid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatOverlayHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    width: '100%',
    height: 50,
    backgroundColor: "rgba(255,255,255,0.1)",
  },
  chatOverlayHeaderItem: {
    height: '100%',
    width: '25%',
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  chatOverlayMessageContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 10,
    // borderStyle: 'solid',
    // borderWidth: 2,
    // borderColor: 'red',
  },
  chatOverlayInputContainer: {
    borderTopColor: 'rgba(255,255,255,0.2)',
    borderTopWidth: 1,
    borderStyle: 'solid',
    width: '100%',
    height: 80,
    paddingHorizontal: 10,
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  chatOverlayInputFieldBorder: {
    height: '50%', 
    fontSize: 16,
    color: 'white',
    borderRadius: 20,
    width: '75%',
    padding: 2,
  },
  chatOverlayInputField: {
    fontSize: 16,
    paddingHorizontal: 20, 
    color: 'white',
    borderRadius: 30,
    width: '100%',
    height: '100%', 
    backgroundColor: '#06102B',
  },
  chatOverlayInputButton: {
    height: '50%', 
    width: '22%',
    backgroundColor: 'gray',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  chatOverlayInputBackground: {
    height: '100%', 
    width: '100%',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
})
