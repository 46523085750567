import React from 'react'
import { View } from 'react-native'
import { PlayerStates } from '@poker-powher/poker'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { DeviceType, loggedInDeviceDeviceType } from '../../utils/detectDeviceType'
import { PlayerHoleCards } from '../PlayerHoleCards/PlayerHoleCards'
import { Text } from '../text/text'
import { styles } from './styles'

export const PlayerName = (props: {
  name: string
  index: number
  mappedIndex: number
  cards: string[]
  isShowdown: boolean
  useFourColoredCards: boolean
  showCardsForAllin: boolean
  chipsRemaining: number
  hasPlayerFolded: boolean
  isMe?: boolean
  isCardVisible: boolean
  showPlayerName: boolean
  isPlayerSittingOut: boolean
  playerState: string
  cardViewHeight: number
  isPlayerOnVideoCall: boolean
  currentPlayerIndex: number
}) => {
  const {
    name,
    index,
    mappedIndex,
    cards,
    isShowdown,
    useFourColoredCards,
    hasPlayerFolded,
    isCardVisible,
    showPlayerName,
    isPlayerSittingOut,
    playerState,
    cardViewHeight,
    isPlayerOnVideoCall,
    currentPlayerIndex
  } = props
  /* State for handling dynamic height of cards w.r.t height of view */
  return (
    <View style={styles.playerNameContainer}>
      <View
        style={[styles.playerCardInnerContainer, { alignItems: mappedIndex % 2 === 0 ? 'flex-end' : 'flex-start' }]}
      >
        {isCardVisible || (isShowdown && !hasPlayerFolded && !isPlayerSittingOut) ? (
          <PlayerHoleCards
            hand={cards}
            customCardContainerStyle={{ marginRight: 0, opacity: hasPlayerFolded ? 0 : 1 }}
            customLeftCardStyle={{
              width:
                loggedInDeviceDeviceType() === DeviceType.pad
                  ? moderateScale(cardViewHeight / 3.2)
                  : moderateScale(cardViewHeight / 1.6),
              height:
                loggedInDeviceDeviceType() === DeviceType.pad
                  ? moderateScale(cardViewHeight - 10)
                  : moderateScale(cardViewHeight),
            }}
            customRightCardStyle={{
              width:
                loggedInDeviceDeviceType() === DeviceType.pad
                  ? moderateScale(cardViewHeight / 3.2)
                  : moderateScale(cardViewHeight / 1.6),
              height:
                loggedInDeviceDeviceType() === DeviceType.pad
                  ? moderateScale(cardViewHeight - 10)
                  : moderateScale(cardViewHeight),
            }}
            useFourColoredCardStyle={useFourColoredCards}
            isFolded={false}
          />
        ) : null}
      </View>
      <View style={styles.playerNameInnerContainer}>
        {!isPlayerOnVideoCall ? (
          <Text
            allowFontScaling={false}
            ellipsizeMode={'tail'}
            numberOfLines={1}
            tx={showPlayerName ? 'gameScreen.playerRoleOrName' : 'gameScreen.defaultName'}
            txOptions={{ value: name.length > 0 ? name : index ? index.toString() : '' }}
            style={[
              styles.playerNameText,
              {
                color:
                  playerState === PlayerStates.Observer.tag || isPlayerSittingOut || hasPlayerFolded
                    ? '#ffffff40'
                    : '#ffffff',
              },
            ]}
          />
        ) : null}
      </View>
      <View style={{ flex: 0.8, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        {playerState === PlayerStates.Observer.tag ? (
          <View style={{ alignSelf: 'flex-start' }}>
            <Text
              allowFontScaling={false}
              tx={'gameScreen.observer'}
              style={{
                fontSize: moderateScale(16),
                fontWeight: '900',
                fontFamily: fontFamily.sfProDisplayHeavy,
                textAlign: 'left',
                paddingLeft: 5,
                paddingBottom: 0,
                color: '#ffffff40',
                position: 'absolute',
                bottom: isPlayerOnVideoCall ? -26 : -3,
              }}
            />
          </View>
        ) : null}
        {isPlayerOnVideoCall && (
          <View style={[styles.playerNameInBottom, { bottom: currentPlayerIndex === index && !isShowdown ? 17 : 20 }, { backgroundColor: playerState === PlayerStates.Observer.tag || isPlayerSittingOut || hasPlayerFolded ||  playerState === PlayerStates.ForeverOut.tag ? 'transparent' : 'rgba(0, 0, 0, .6)' }
          ]}>
            <Text
              allowFontScaling={false}
              ellipsizeMode={'tail'}
              numberOfLines={1}
              tx={showPlayerName ? 'gameScreen.playerRoleOrName' : 'gameScreen.defaultName'}
              txOptions={{ value: name.length > 0 ? name : index ? index.toString() : '' }}
              style={[
                styles.playerNameText,
                {
                  marginTop: 0,
                  color:
                    playerState === PlayerStates.Observer.tag || isPlayerSittingOut || hasPlayerFolded
                      ? '#ffffff'
                      : '#ffffff',
                },
              ]}
            />
          </View>
        )}
      </View>
    </View>
  )
}
