import React, { useEffect, useState } from 'react'
import { RouteProp } from '@react-navigation/native'
import { View, StyleSheet, ScrollView } from 'react-native'
import { CardList, PaddedLayout, Text } from '../../components'
import { CARD, RankingCards } from '../../screens'
import { rankings } from './data'

interface IntroScreenProps {
  route: RouteProp<{ params: { ranking: string } }>
}

export const AllRankingsScreen = ({ route }: IntroScreenProps) => {
  const [rankingData, setRankingData] = useState<RankingCards>()

  useEffect(() => {
    if (route.params.ranking) {
      const currentRanking = rankings.find((ranking: RankingCards) => ranking.name === route.params.ranking)
      if (!currentRanking) {
        return
      }
      setRankingData(currentRanking)
    }
  }, [])

  return (
    <PaddedLayout>
      <ScrollView>
        <Text text={rankingData?.displayName} style={styles.title} />
        {rankingData?.rankingCards.map((hand, index) => (
          <View key={index} style={styles.handContainer}>
            <View style={styles.handTitleContainer}>
              <Text text={`${index + 1}.`} style={[styles.handIndex, styles.handTitle]} />
              <Text text={hand.name} style={styles.handTitle} />
            </View>
            <CardList contentContainerStyles={{ justifyContent: 'flex-start' }} cards={hand.cards} />
          </View>
        ))}
      </ScrollView>
    </PaddedLayout>
  )
}

const styles = StyleSheet.create({
  title: { fontSize: 20, fontWeight: 'bold', paddingBottom: 20 },
  handContainer: { paddingBottom: 15 },
  handTitleContainer: { flexDirection: 'row', paddingBottom: 5 },
  handTitle: { fontWeight: 'bold', fontSize: 16 },
  handIndex: { paddingRight: 2 },
  description: {
    fontFamily: 'SF Pro Display',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 17,
    textAlign: 'center',
    color: '#FFFFFF',
  },
})
