import { translate } from '../../i18n/translate'
import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { fontFamily } from '../../theme/fontFamily'

interface ScreenTitleProps {
  tx: string
}

export const ScreenTitle = ({ tx }: ScreenTitleProps) => {
  return <Text style={styles.title}>{translate(tx)}</Text>
}

const styles = StyleSheet.create({
  title: {
    color: '#fff',
    fontFamily: fontFamily.sfProDisplayBlack,
    fontSize: 24,
    lineHeight: 29,
  },
})
