import { LinearGradient } from 'expo-linear-gradient'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useEffect, useRef, useState } from 'react'
import {
  BackHandler,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'

// import { BlurView } from 'expo-blur' - no android.
import { useNavigation } from '@react-navigation/native'

import { useStore } from '../../models'
import { ChatKind } from '../../models/api-store/ChatKindEnum'
import { DrawerNavigation } from '../../navigation/drawer-navigator'
import { ChatBubble } from '../ChatBubble/ChatBubble'
import { styles } from './styles'
import { PlayerStates } from '@poker-powher/poker'
import { ChatView } from './ChatView'

const chatSubmitImg = require('../../../assets/icons/chat-submit.png')

export const ChatOverlay = observer(() => {
  const store = useStore()

  const keyboardVerticalOffset = Platform.OS === 'ios' ? 62 : 80

  const scrollViewRef = useRef<ScrollView>(null)

  const [inputFieldContent, setInputFieldContent] = useState<string>('')
  const [inputFocused, setInputFocused] = useState<boolean>(false)
  const [isScrolledNearBottom, setIsScrolledNearBottom] = useState<boolean>(true)

  const localPlayerData = store.session.players.find((u) => u.userId === store.session.user?._id)
  const getChannelChats = store.session.getChannelChats
  const channelChats = getSnapshot(store.session.channelChats)
  const setIsChatting = store.session.setIsChatting
  const isChatting = store.session.isChatting
  const sendChat = store.session.sendChat
  const navigation = useNavigation<DrawerNavigation>()

  interface ITrueAvatarChatColors {
    red: string
    blue: string
    green: string
    orange: string
    pink: string
    crimson: string
    purple: string
    teal: string
    lightblue: string
    gray: string
  }
  const trueAvatarChatColors: ITrueAvatarChatColors = {
    red: '#C41313',
    blue: '#0271B7',
    green: '#128942',
    orange: '#D96800',
    pink: '#AB037C',
    crimson: '#FF7F50',
    purple: '#5C2DA8',
    teal: '#5B6196',
    lightblue: '#02A0C2',
    gray: '#343434',
  }

  const ScrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({ animated: true })
  }

  const handleScroll = (event: any) => {
    const bottomVal = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height - 150
    if (event.nativeEvent.contentOffset.y >= bottomVal) {
      if (!isScrolledNearBottom) {
        setIsScrolledNearBottom(true)
      }
    } else {
      if (isScrolledNearBottom) {
        setIsScrolledNearBottom(false)
      }
    }
  }

  const handleAndroidBackButton = () => {
    setIsChatting(false)
    navigation.goBack()
    return true
  }

  const handleMessageAction = debounce(() => {
    try {
      // const { index, dealer, color } = props.localPlayerData
      const color = localPlayerData && localPlayerData.color ? localPlayerData.color : trueAvatarChatColors.gray
      if (inputFieldContent.length > 0) {
        // if (index === dealer) {
        //   props.sendChat(inputFieldContent, ChatKind.dealer)
        // }
        if (trueAvatarChatColors.hasOwnProperty(color)) {
          sendChat(`${inputFieldContent}`, ChatKind.user, trueAvatarChatColors[color as keyof ITrueAvatarChatColors])
        } else {
          if (localPlayerData?.playerState.tag === PlayerStates.Observer.tag) {
            sendChat(`${inputFieldContent}`, ChatKind.observer, 'white')
          } else {
            // If we see gray for any reason, this indicates an issue parsing or reading localPlayerData.color.
            sendChat(`${inputFieldContent}`, ChatKind.observer, trueAvatarChatColors.gray)
          }
        }
      } else {
        console.warn('SubmitChat -- Refused empty message.')
      }
      setInputFieldContent('')
      Keyboard.dismiss()
    } catch (error) {
      console.warn('localPlayerData error', error)
    }
  }, 250)

  useEffect(() => {
    if (isChatting) {
      setTimeout(ScrollToBottom, 400)
    }
  }, [isChatting])

  useEffect(() => {
    setTimeout(ScrollToBottom, 100)
  }, [inputFocused])

  useEffect(() => {
    if (isChatting) {
      BackHandler.addEventListener('hardwareBackPress', handleAndroidBackButton)
    } else {
      BackHandler.removeEventListener('hardwareBackPress', handleAndroidBackButton)
    }

    // Additional cleanup incase state slips.
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleAndroidBackButton)
    }
  }, [isChatting])

  useEffect(() => {
    if (channelChats.length === 0) {
      getChannelChats()
    }
  })

  return (
    <View style={styles.chatOverlayContainer}>
      {Platform.OS === 'android' ? (
        // <BlurView
        //   style={[styles.chatBlurUnderlay, { backgroundColor: "transparent" }]}
        //   overlayColor="rgba(0,0,0,0.25)"
        //   reducedTransparencyFallbackColor='rgba(0,0,0,0.25)'
        //   blurType="dark"
        //   blurAmount={19}
        // />
        <View style={[styles.chatBlurUnderlay, { backgroundColor: 'rgba(0,0,0,0.75)' }]} />
      ) : (
        <ChatView />
      )}
      <KeyboardAvoidingView
        behavior="padding"
        style={styles.chatOverlayKeyboardAvoid}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        <ScrollView
          style={styles.chatOverlayMessageContainer}
          ref={scrollViewRef}
          onScroll={handleScroll}
          scrollEventThrottle={128}
          onContentSizeChange={() => {
            if (scrollViewRef.current !== null) {
              if (isScrolledNearBottom) {
                ScrollToBottom()
              }
            }
          }}
          bounces
        >
          {channelChats.length > 0
            ? channelChats.map((item: any, index: number) => {
                const { message, chatKind, userName, color } = item
                return (
                  <ChatBubble key={index} message={message} chatKind={chatKind} userName={userName} color={color} />
                )
              })
            : null}
        </ScrollView>

        <View style={styles.chatOverlayInputContainer}>
          <LinearGradient
            colors={['#49CDF2', '#49CDF2', '#9444FB']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={[styles.chatOverlayInputFieldBorder, Platform.OS === 'android' && { height: '62%' }]}
          >
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <TextInput
                numberOfLines={1}
                placeholder={`Type message here`}
                placeholderTextColor={'#808080'}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                value={inputFieldContent}
                onChangeText={(input) => setInputFieldContent(input)}
                style={styles.chatOverlayInputField}
              />
            </TouchableWithoutFeedback>
          </LinearGradient>
          <TouchableOpacity style={styles.chatOverlayInputButton} onPress={handleMessageAction}>
            {inputFocused || inputFieldContent.length > 0 ? (
              <LinearGradient
                colors={['#49CDF2', '#49CDF2', '#9444FB']}
                start={{ x: 0.0, y: 1.0 }}
                end={{ x: 1.0, y: 1.0 }}
                style={styles.chatOverlayInputBackground}
              >
                <Image source={chatSubmitImg} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
              </LinearGradient>
            ) : (
              <View style={styles.chatOverlayInputBackground}>
                <Image source={chatSubmitImg} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
              </View>
            )}
          </TouchableOpacity>
        </View>
      </KeyboardAvoidingView>
    </View>
  )
})
