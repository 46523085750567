import React, { useEffect } from 'react'

import AsyncStorage from '@react-native-community/async-storage'
import { configure, getStorybookUI } from '@storybook/react-native'

import { initFonts } from '../app/theme/fonts'

// declare let module

configure(() => {
  require('./storybook-registry')
}, module)

const StorybookUI = getStorybookUI({
  port: 9001,
  host: 'localhost',
  onDeviceUI: true,
  asyncStorage: AsyncStorage,
})

export function StorybookUIRoot() {
  useEffect(() => {
    ;(async () => {
      await initFonts()
      // if (typeof __TEST__ === "undefined" || !__TEST__) {
      //   const Reactotron = require("../app/services/reactotron")
      //   const reactotron = new Reactotron.Reactotron()
      //   reactotron.setup()
      // }
    })()
  }, [])

  return <StorybookUI />
}
