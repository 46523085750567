/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameEntityModel, GameEntityModelType } from "./GameEntityModel"
import { GameEntityModelSelector } from "./GameEntityModel.base"
import { TournamentKindEnumType } from "./TournamentKindEnum"
import { RootStoreType } from "./index"


/**
 * TournamentBase
 * auto generated base class for the model TournamentModel.
 */
export const TournamentModelBase = ModelBase
  .named('Tournament')
  .props({
    __typename: types.optional(types.literal("Tournament"), "Tournament"),
    _id: types.identifier,
    actionTime: types.union(types.undefined, types.null, types.number),
    blindTimer: types.union(types.undefined, types.null, types.number),
    color: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    endTime: types.union(types.undefined, types.frozen()),
    gameIds: types.union(types.undefined, types.array(types.late((): any => GameEntityModel))),
    isLocked: types.union(types.undefined, types.null, types.boolean),
    name: types.union(types.undefined, types.string),
    startingChips: types.union(types.undefined, types.null, types.number),
    startTime: types.union(types.undefined, types.frozen()),
    tournamentKind: types.union(types.undefined, TournamentKindEnumType),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class TournamentModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get actionTime() { return this.__attr(`actionTime`) }
  get blindTimer() { return this.__attr(`blindTimer`) }
  get color() { return this.__attr(`color`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get endTime() { return this.__attr(`endTime`) }
  get isLocked() { return this.__attr(`isLocked`) }
  get name() { return this.__attr(`name`) }
  get startingChips() { return this.__attr(`startingChips`) }
  get startTime() { return this.__attr(`startTime`) }
  get tournamentKind() { return this.__attr(`tournamentKind`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  gameIds(builder?: string | GameEntityModelSelector | ((selector: GameEntityModelSelector) => GameEntityModelSelector)) { return this.__child(`gameIds`, GameEntityModelSelector, builder) }
}
export function selectFromTournament() {
  return new TournamentModelSelector()
}

export const tournamentModelPrimitives = selectFromTournament()._id.actionTime.blindTimer.color.createdAt.endTime.isLocked.name.startingChips.startTime.tournamentKind.updatedAt
