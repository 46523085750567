import { Instance, types } from 'mobx-state-tree'

import { STAGE } from '@env'
// import { createClient } from '@segment/analytics-react-native'
// import { MixpanelPlugin } from '@segment/analytics-react-native-plugin-mixpanel'

import { EnvironmentStages } from '../../environment/environment.store'
import { withRootStore } from '../../extensions'

/**
 * A SessionEventAnalyticsModel model.
 */

export enum SegmentWriteKeyEnvironment {
  dev = 'BY3obwL1Ruy225C52R3OKmQwTTyVpIsr', // WRITE_KEYS FOR DEV
  prod = 'dbGXHUB2g1tPTfHFUXVoi24ATAHD3P46', // WRITE_KEYS FOR PROD
}

// export let segmentClient = createClient({
//   writeKey: STAGE === EnvironmentStages.prod ? SegmentWriteKeyEnvironment.prod : SegmentWriteKeyEnvironment.dev,
//   autoAddSegmentDestination: true,
//   trackAppLifecycleEvents: true,
//   trackDeepLinks: true,
// })
// segmentClient.add({ plugin: new MixpanelPlugin() })

export const EventAnalyticsStore = types
  .model('EventAnalyticsStore')
  .props({
    event: types.maybe(types.string),
  })
  .extend(withRootStore)
  .actions((self) => ({
    setEvent(event: string, props?: any) {
      console.log('session-analytics event', event, props)
      //   segmentClient.track(event, props)
    },
    setUserIdentify(userid: string, props?: any) {
      //   segmentClient.identify(userid, props)
      console.log('session-analytics identify', userid, props)
    },
  }))
  .actions((self) => ({
    async setAnalyticsStage(stage: EnvironmentStages) {
      //   console.log('session-analytics setAnalyticsStage', stage)
      //   if (stage === EnvironmentStages.prod) {
      //     segmentClient = createClient({
      //       writeKey: EnvironmentStages.prod,
      //       autoAddSegmentDestination: true,
      //       trackAppLifecycleEvents: true,
      //       trackDeepLinks: true,
      //     })
      //   } else {
      //     segmentClient = createClient({
      //       writeKey: EnvironmentStages.dev,
      //       autoAddSegmentDestination: true,
      //       trackAppLifecycleEvents: true,
      //       trackDeepLinks: true,
      //     })
      //   }
      //   segmentClient.add({ plugin: new MixpanelPlugin() })
    },
  }))

export type EventAnalyticsStoreType = Instance<typeof EventAnalyticsStore>
