/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
// import { AxiosResponse } from 'axios'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { ChannelModel, ChannelModelType } from '../api-store'
import { withRootStore } from '../extensions'

/**
 * A SessionChannelStore model.
 */
export const SessionChannelStoreModel = types
  .model('SessionChannelStoreModel')
  .props({
    channel: types.maybe(ChannelModel),
  })
  .extend(withRootStore)
  .actions((self) => ({
    clearChannel() {
      self.channel = undefined
    },
    setChannel(data: ChannelModelType) {
      console.log('setChannel', data)
      self.channel = ChannelModel.create(data)
      console.log('setChannel', self.channel.channelName)
    },
  }))
  .actions((self) => ({
    async createChannel(createChannelName?: string) {
      console.log('createChannel')
      const userId = self.rootStore.session.user?._id
      console.log('createChannel userId', userId)
      if (userId) {
        try {
          const response = await self.rootStore.api.mutateCreateChannel({
            userId,
            channelName: createChannelName || userId,
          })
          if (ChannelModel.is(response.createChannel)) {
            self.setChannel(response.createChannel)
          }
        } catch (error) {
          console.error('createChannel', error)
        }
      }
    },
    async joinChannel(channelName: string) {
      console.log('joinChannel channelName', channelName)
      const userId = self.rootStore.session.user?._id
      console.log('joinChannel userId', userId)
      if (userId) {
        try {
          const args = { channelName, userId }
          console.log('joinChannel args', args)
          const response = await self.rootStore.api.mutateJoinChannel(args)
          console.log('joinChannel response', response.joinChannel)
          if (ChannelModel.is(response.joinChannel)) {
            self.setChannel(response.joinChannel)
          }
        } catch (error) {
          console.error('joinChannel', error)
          throw error
        }
      }
    },
    async deleteGame(gameId: string) {
      try {
        const args = { id: gameId }
        const response = await self.rootStore.api.mutateDeleteGame(args)
        console.log('delete gameId response', response)
      } catch (error) {
        console.error('deleteGameId', error)
        throw error
      }
    },
    async terminateGame(gameId: string) {
      try {
        const args = { id: gameId }
        const response = await self.rootStore.api.mutateTerminateGame(args)
        // NOTE: tearDown() here will clear everything and the Game deleted event won't be received
        // DEPRECATED: self.rootStore.session.teardown()
        console.log('terminate gameId response', response)
      } catch (error) {
        console.error('terminate GameId', error)
        throw error
      }
    },
  }))

/**
 * The SessionChannelStore instance.
 */
export type SessionChannelStore = Instance<typeof SessionChannelStoreModel>

/**
 * The data of a SessionChannelStore.
 */
export type SessionChannelStoreSnapshot = SnapshotOut<typeof SessionChannelStoreModel>
