import React from 'react'
import { Dimensions, Image, PermissionsAndroid, Platform, StyleSheet, View, ViewProps } from 'react-native'
import { RtcLocalView, RtcRemoteView, VideoRenderMode } from 'react-native-agora'
import { AvatarSvgWeb } from './avatarSvgWeb'

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
}

const styles = StyleSheet.create({
  max: {
    flex: 1,
  },
  buttonHolder: {
    height: 100,
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: '#0093E9',
    borderRadius: 25,
  },
  buttonText: {
    // color: '#fff',
    color: '#000',
  },
  fullView: {
    width: dimensions.width,
    height: dimensions.height - 100,
  },
  remoteContainer: {
    width: '100%',
    height: 150,
    position: 'absolute',
    top: 5,
  },
  remote: {
    width: 50,
    height: 50,
    marginHorizontal: 2.5,
  },
  noUserText: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: '#0093E9',
  },
})

interface LiveVideoProps extends ViewProps {
  avatar: JSX.Element
  channelName?: string
  hasLocalUserJoinedVideo: boolean
  hasLocalUserHiddenVideo: boolean
  isLocalUser: boolean
  isPlayerOnVideoCall: boolean
  isPlayerMutingVideoCall: boolean
  playerId: string
  avatarColor: string
}

//React.memo = PureComponent
export const LiveVideo = React.memo((props: LiveVideoProps) => {
  const { playerId } = props
  const id = Number(playerId)

  return (
    <>
      <View key={`player-${id}`} style={[styles.max, { borderRadius: 4, overflow: 'hidden' }]}>
        <AvatarSvgWeb avatarColor={props.avatarColor} />
      </View>
    </>
  )
})
