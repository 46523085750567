import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Appbar } from 'react-native-paper'

import { createStackNavigator } from '@react-navigation/stack'

import { useStore } from '../models'
import { AdminGamesScreen, AdminNetworkScreen } from '../screens'
import { AdminChannelsScreen } from '../screens/admin/admin-channels-screen'
import { AdminEnvironmentScreen } from '../screens/admin/admin-environment-screen'
import { AdminMessagesScreen } from '../screens/admin/admin-messages-screen'
import { AdminScreen } from '../screens/admin/admin-screen'

export enum AdminRoutes {
  admin = 'admin',
  channels = 'channels',
  environment = 'environment',
  games = 'games',
  messages = 'messages',
  network = 'network',
}
export type AdminParamList = Record<keyof typeof AdminRoutes, undefined>

const Stack = createStackNavigator<AdminParamList>()
export const AdminNavigator = observer((props) => {
  const store = useStore()
  const initialRouteName = AdminRoutes.admin
  console.log('AdminNavigator initialRouteName', initialRouteName)
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={({ navigation, route }) => ({
        headerShown: true,
        gestureEnabled: true,
        headerTitleAlign: 'center',
        headerLeft: () => <Appbar.BackAction onPress={debounce(() => navigation.navigate(AdminRoutes.admin), 250)} />,
        headerRight: () => {
          if ([AdminRoutes.messages].includes(AdminRoutes[route.name])) {
            return <Appbar.Action icon="close-circle" onPress={debounce(() => store.session.clearMessages(), 250)} />
          }
          return <></>
        },
      })}
    >
      <Stack.Screen
        name={AdminRoutes.admin}
        component={AdminScreen}
        options={{
          headerLeft: () => <></>,
          title: AdminRoutes.admin.toUpperCase(),
        }}
      />
      <Stack.Screen
        name={AdminRoutes.environment}
        component={AdminEnvironmentScreen}
        options={{
          title: AdminRoutes.environment.toUpperCase(),
        }}
      />
      <Stack.Screen
        name={AdminRoutes.channels}
        component={AdminChannelsScreen}
        options={{
          title: AdminRoutes.channels.toUpperCase(),
        }}
      />
      <Stack.Screen
        name={AdminRoutes.games}
        component={AdminGamesScreen}
        options={{
          title: AdminRoutes.games.toUpperCase(),
        }}
      />
      <Stack.Screen
        name={AdminRoutes.messages}
        component={AdminMessagesScreen}
        options={{
          title: AdminRoutes.messages.toUpperCase(),
        }}
      />
      <Stack.Screen
        name={AdminRoutes.network}
        component={AdminNetworkScreen}
        options={{
          title: AdminRoutes.network.toUpperCase(),
        }}
      />
    </Stack.Navigator>
  )
})
