import { Question, CARD, RankingCards, Game } from './interfaces/mini-games.interface'

export const cardsToSelect = 5 as const

export const rankings: RankingCards[] = [
  {
    name: 'allRankings',
    displayName: 'All Rankings',
    rankingCards: [
      {
        name: 'Royal Flush',
        cards: [CARD.AH, CARD.KH, CARD.QH, CARD.JH, CARD.TH],
      },
      {
        name: 'Straight Flush',
        cards: [CARD.JC, CARD.TC, CARD['9C'], CARD['8C'], CARD['7C']],
      },
      {
        name: 'Four of a Kind',
        cards: [CARD.QD, CARD.QH, CARD.QC, CARD.QS, CARD['4D']],
      },
      {
        name: 'Full House',
        cards: [CARD.AS, CARD.AC, CARD['8H'], CARD['8D'], CARD['8S']],
      },
      {
        name: 'Flush',
        cards: [CARD.KD, CARD['TD'], CARD['4D'], CARD['9D'], CARD['JD']],
      },
      {
        name: 'Straight',
        cards: [CARD.JH, CARD.TD, CARD['9S'], CARD['8C'], CARD['7D']],
      },
      {
        name: 'Three of a Kind',
        cards: [CARD['8D'], CARD['8H'], CARD['8C'], CARD.KS, CARD['4D']],
      },
      {
        name: 'Two Pair',
        cards: [CARD['5H'], CARD['5C'], CARD.JD, CARD.JS, CARD.AD],
      },
      {
        name: 'Pair',
        cards: [CARD.KS, CARD.KD, CARD.AH, CARD['9S'], CARD['7D']],
      },
      {
        name: 'High Card',
        cards: [CARD.AC, CARD.JD, CARD['4D'], CARD['7H'], CARD['2D']],
      },
    ],
  },
  {
    name: 'bestHands',
    displayName: 'Best Hands',
    rankingCards: [
      {
        name: 'Pairs',
        cards: [CARD['9C'], CARD['9H']],
      },
      {
        name: 'Face Cards',
        cards: [CARD.KD, CARD.QH],
      },
      {
        name: 'Suited Connectors',
        cards: [CARD.TC, CARD.JC],
      },
    ],
  },
]

export const CardValues = {
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  T: 10,
  J: 11,
  Q: 12,
  K: 13,
  A: 14,
}

const questions: Question[] = [
  {
    question: 'What do you call a hand with both three of a kind and two of a kind?',
    correctAnswer: 2,
    elementToDisplay: [CARD.AS, CARD.AC, CARD['8H'], CARD['8D'], CARD['8S']],
    incorrectCaption: 'The correct answer is Full House. Better luck next time.',
    options: [
      {
        id: 1,
        text: 'Royal flush',
      },
      {
        id: 2,
        text: 'Full house',
      },
    ],
  },
  {
    question: 'What is it called when the final community card is revealed to the players?',
    correctAnswer: 1,
    elementToDisplay: [CARD.AS, CARD.AC, CARD['8H'], CARD['8D'], CARD.QUESTION],
    options: [
      {
        id: 1,
        text: 'Turn',
      },
      {
        id: 2,
        text: 'River',
      },
    ],
  },
  {
    question: 'What are the two cards dealt to each player, known only to them called?',
    correctAnswer: 2,
    elementToDisplay: [CARD['2C'], CARD['7H']],
    correctCaption: 'Also, the 7 2 offsuit (different suits) is the worst hand in poker.',
    funFact: {
      title: 'Fun fact',
      text:
        "7 2 offsuit is the worst hand because it is the lowest four gap hand combination that exists in poker. It's difficult to make a straight or a flush and the pairs you would make are low as well.",
    },
    options: [
      {
        id: 1,
        text: 'Community cards',
      },
      {
        id: 2,
        text: 'Hole cards',
      },
    ],
  },
  {
    question: 'What is the mandatory bet placed by the person left of the dealer called?',
    correctAnswer: 1,
    elementToDisplay: require('../../assets/poker-table.png'),
    correctCaption: 'The Blinds will ensure there is a pot at stake.',
    options: [
      {
        id: 1,
        text: 'Small blind',
      },
      {
        id: 2,
        text: 'Pre-Flop',
      },
    ],
  },
]

const basicHandRankingQuestions: Question[] = [
  {
    question: 'Select the best 5 card hand by combining the Community Cards with one hand or the other.',
    correctAnswer: 1,
    elementToDisplay: [CARD.TC, CARD.JH, CARD.KD, CARD.QC, CARD.AH],
    highlight: [CARD.TC, CARD.JH, CARD.KD, CARD.QC, CARD['9C']],
    optionsWithCards: true,
    optionsTitle: 'Tap to select the winning hand',
    incorrectCaption: 'Straight beats Two Pair. Better luck next time.',
    options: [
      {
        id: 1,
        text: 'Hand 1',
        cards: [CARD['9C'], CARD['QH']],
      },
      {
        id: 2,
        text: 'Hand 2',
        cards: [CARD.AD, CARD.QS],
      },
    ],
  },
]
const advancedHandRankingQuestions: Question[] = [
  {
    question: 'Select the best 5 card hand by combining the Community Cards with one hand or the other.',
    correctAnswer: [CARD.TC, CARD.JH, CARD.KD, CARD.QC, CARD['9C']],
    elementToDisplay: [CARD.TC, CARD.JH, CARD.KD, CARD.QC, CARD['4H']],
    highlight: [CARD.TC, CARD.JH, CARD.KD, CARD.QC, CARD['9C']],
    optionsWithCards: true,
    optionsTitle: 'Your Hand',
    selectableCards: true,
    options: [
      {
        id: 1,
        text: 'Play hand',
        cards: [CARD['9C'], CARD['QH']],
      },
    ],
  },
]
const raiseOrFold1Questions: Question[] = [
  {
    question: 'Raise or Fold?',
    caption: 'Before Community Cards are dealt (Pre-Flop)',
    correctAnswer: 1,
    elementToDisplay: [CARD['9C'], CARD['9H']],
    optionsTitle: 'Tap to select answer',
    incorrectCaption: 'You should always raise when you have a pair.',
    options: [
      {
        id: 1,
        text: 'Raise',
      },
      {
        id: 2,
        text: 'Fold',
      },
    ],
  },
  {
    question: 'Raise or Fold?',
    caption: 'Before Community Cards are dealt (Pre-Flop)',
    correctAnswer: 1,
    elementToDisplay: [CARD.QC, CARD.KH],
    optionsTitle: 'Tap to select answer',
    correctCaption: 'You should always raise when you have face cards.',
    options: [
      {
        id: 1,
        text: 'Raise',
      },
      {
        id: 2,
        text: 'Fold',
      },
    ],
  },
  {
    question: 'Raise or Fold?',
    caption: 'Before Community Cards are dealt (Pre-Flop)',
    correctAnswer: 1,
    elementToDisplay: [CARD.TC, CARD.JH],
    optionsTitle: 'Tap to select answer',
    correctCaption: 'You should always raise when you have suited connectors.',
    options: [
      {
        id: 1,
        text: 'Raise',
      },
      {
        id: 2,
        text: 'Fold',
      },
    ],
  },
  {
    question: 'Raise or Fold?',
    caption: 'Before Community Cards are dealt (Pre-Flop)',
    correctAnswer: 2,
    elementToDisplay: [CARD['7C'], CARD['3H']],
    optionsTitle: 'Tap to select answer',
    correctCaption: 'You should always fold when you have a low pair.',
    options: [
      {
        id: 1,
        text: 'Raise',
      },
      {
        id: 2,
        text: 'Fold',
      },
    ],
  },
]
const raiseOrFold2Questions: Question[] = [
  {
    question: 'Which hand should you raise with before the community cards are dealt (pre-flop)?',
    correctAnswer: 2,
    optionsTitle: 'Tap to select the winning hand',
    incorrectCaption: 'You should always raise when you have a pair.',
    optionsWithCards: true,
    highlight: [CARD['9C'], CARD['9H']],
    options: [
      {
        id: 1,
        text: 'Hand 1',
        cards: [CARD.AD, CARD['8S']],
      },
      {
        id: 2,
        text: 'Hand 2',
        cards: [CARD['9C'], CARD['9H']],
      },
    ],
  },
  {
    question: 'Which hand should you raise with before the community cards are dealt (pre-flop)?',
    correctAnswer: 1,
    optionsTitle: 'Tap to select the winning hand',
    correctCaption: 'You should always raise when you have face cards.',
    optionsWithCards: true,
    highlight: [CARD.QD, CARD.KS],
    options: [
      {
        id: 1,
        text: 'Hand 1',
        cards: [CARD.QD, CARD.KS],
      },
      {
        id: 2,
        text: 'Hand 2',
        cards: [CARD.TD, CARD.JH],
      },
    ],
  },
  {
    question: 'Which hand should you raise with before the community cards are dealt (pre-flop)?',
    correctAnswer: 1,
    optionsTitle: 'Tap to select the winning hand',
    correctCaption: 'You should always raise when you have suited connectors.',
    optionsWithCards: true,
    highlight: [CARD.TC, CARD.JH],
    options: [
      {
        id: 1,
        text: 'Hand 1',
        cards: [CARD.TC, CARD.JH],
      },
      {
        id: 2,
        text: 'Hand 2',
        cards: [CARD['7C'], CARD['3H']],
      },
    ],
  },
  {
    question: 'Which hand should you fold with before the community cards are dealt (pre-flop)?',
    correctAnswer: 2,
    optionsTitle: 'Tap to select the winning hand',
    correctCaption: 'You should always fold when you have a low pair.',
    optionsWithCards: true,
    highlight: [CARD['7C'], CARD['3H']],
    options: [
      {
        id: 1,
        text: 'Hand 1',
        cards: [CARD['9C'], CARD['9H']],
      },
      {
        id: 2,
        text: 'Hand 2',

        cards: [CARD['7C'], CARD['3H']],
      },
    ],
  },
]

export const gamesData: Game[] = [
  {
    name: 'lingo',
    cards: [CARD['3D'], CARD['2S'], CARD['2H'], CARD['2C'], CARD['2D']],
    img: require('../../assets/lingo_intro_screen.png'),
    gameDescription: 'Select the correct answer to each question. Learn common poker terms.',
    questions,
    prevButton: 'Lingo Lookup',
    nextButton: 'Next Round',
  },
  {
    name: 'basic-hand-ranking',
    cards: [CARD.AS, CARD.AC, CARD['8H'], CARD['8D'], CARD['8S']],
    img: require('../../assets/basic_hr_intro_screen.png'),
    gameDescription: 'Select the winning hand from your cards. Learn poker hand rankings.',
    questions: basicHandRankingQuestions,
    prevButton: 'View Rankings',
    prevButtonRedirect: 'allRankings',
    rankingToDisplay: 'allRankings',
    nextButton: 'Next Round',
  },
  {
    name: 'advanced-hand-ranking',
    cards: [CARD.AS, CARD.AC, CARD['8H'], CARD['8D'], CARD['8S']],
    img: require('../../assets/basic_hr_intro_screen.png'),
    gameDescription: 'Select the winning hand from your cards. Learn poker hand rankings.',
    questions: advancedHandRankingQuestions,
    prevButton: 'View Rankings',
    prevButtonRedirect: 'allRankings',
    rankingToDisplay: 'allRankings',
    nextButton: 'Next Round',
  },
  {
    name: 'raise-or-fold-1',
    cards: [CARD.QD, CARD.AS],
    img: require('../../assets/raise-or-fold-intro-screen.png'),
    gameDescription: 'Select the correct answer for each question. Learn when to raise or fold.',
    questions: raiseOrFold1Questions,
    prevButton: 'See best hands',
    prevButtonRedirect: 'allRankings',
    rankingToDisplay: 'bestHands',
    nextButton: 'Next Round',
  },
  {
    name: 'raise-or-fold-2',
    cards: [CARD.QD, CARD.AS],
    img: require('../../assets/raise-or-fold-2-intro-screen.png'),
    gameDescription: 'Select the correct answer for each question. Learn when to raise or fold.',
    questions: raiseOrFold2Questions,
    prevButton: 'See best hands',
    prevButtonRedirect: 'allRankings',
    rankingToDisplay: 'bestHands',
    nextButton: 'Next Round',
  },
  {
    name: 'war',
    cards: [CARD.BACK, CARD['2S'], CARD['2H'], CARD.BACK],
    img: require('../../assets/war_intro_screen.png'),
    gameDescription: `Capture all of the opponents cards.
    
    Each player receives 26 cards placed face down and presents one card for battle. Highest card wins and captures opponent’s card.
    
    If both players present cards of equal value, there is a war. Both lay down three cards and the player whose fourth card is highest captures all ten cards.
    
    If the fourth cards are equal, the process is repeated until someone wins.`,
  },
]
