import 'react-native-get-random-values'

// import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FlatList, ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { List, Modal } from 'react-native-paper'

import { Text } from '../../components'
import { useStore } from '../../models'
import styles from '../../styles/globalStyle'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

interface ChannelRenderItemProps {
  _id: string
  channelName: string | undefined
}

export const AdminChannelsScreen = observer(() => {
  const store = useStore()
  const channels = [store.session.channel]
  const [visible, setVisible] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const containerStyle = { backgroundColor: '#fff', padding: 20 }

  const renderItem = ({ item }: ChannelRenderItemProps) => (
    <List.Item
      onPress={() => {
        store.session.joinChannel(item?.channelName)
      }}
      style={{ margin: 5 }}
      title={item?.channelName}
      description={store.session.channel?.channelName === item?.channelName ? 'Current Session Channel' : ''}
      right={(props) => (
        <List.Icon {...props} icon="apple-keyboard-control" style={{ transform: [{ rotate: '90deg' }] }} />
      )}
    />
  )

  return (
    <ImageBackground source={image} style={{ ...startStyles.image }}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View style={{ flex: 1 }}>
            <FlatList
              data={channels}
              keyExtractor={(item, index) => `admin-channel-${index}`}
              renderItem={renderItem}
            />
          </View>
          <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
            <Text
              allowFontScaling={false}
              tx="adminMessageScreen.howToDismissMessage"
              style={{ color: 'black', textAlign: 'center' }}
            />
            <Text allowFontScaling={false}>{'\n'}</Text>
            <ScrollView>
              <Text allowFontScaling={false} style={{ color: 'black' }}>
                {message}
              </Text>
            </ScrollView>
          </Modal>
        </SafeAreaView>
      </View>
    </ImageBackground>
  )
})
