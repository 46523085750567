import parsePhoneNumber from 'libphonenumber-js'
import { debounce, get } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import {
    Alert, Image, ImageBackground, NativeModules, Platform, SafeAreaView, StyleSheet, Text, View
} from 'react-native'
import { v4 as uuidv4 } from 'uuid'

// import { GoogleSignin } from '@react-native-community/google-signin'
import auth from '@react-native-firebase/auth'
import { useNavigation } from '@react-navigation/native'

import { Button } from '../../components'
import { translate } from '../../i18n/translate'
import { useStore } from '../../models'
import { UserRoles } from '../../models/api-store'
import { InputUser } from '../../models/api-store/RootStore.base'
import styles from '../../styles/globalStyle'
import { scale } from '../../styles/sizes'

// GoogleSignin.configure({
//   webClientId: '469309110195-rf5ngun6lnkf7lqni9ao65t2imtig1d3.apps.googleusercontent.com',
// })

const isIOS = Platform.OS === 'ios'

const deviceLanguage =
  Platform.OS === 'ios'
    ? NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0] //iOS 13
    : Platform.OS === 'android'
    ? NativeModules.I18nManager.localeIdentifier
    : navigator.languages && navigator.languages.length > 1
    ? navigator.languages[0]
    : 'en-US'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

export const SignUp = observer(function SignUp() {
  const navigation = useNavigation()
  const store = useStore()
  const { session } = useStore()
  const [phoneNumber, setPhoneNumber] = React.useState<string>('')
  const [isUS, setIsUS] = React.useState<boolean>(deviceLanguage.indexOf('en') !== -1)
  // console.log('deviceLanguage', deviceLanguage, isUS)
  const [phoneNumberError, setPhoneNumberError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const validate = (name: string) => {
    if (name === 'phone') {
      setPhoneNumberError(false)
    }
  }

  const onSubmit = debounce(async () => {
    let error = false
    setPhoneNumberError(false)
    const phone = parsePhoneNumber(`+${phoneNumber}`)
    console.log('phone', phone, phone?.isPossible())
    if (phoneNumber === '' || !phone?.isPossible()) {
      error = true
      setPhoneNumberError(true)
    }
    if (!error) {
      setIsLoading(true)
      try {
        const payload = { phoneNumber }
        // console.log('payload==> ', payload)
        const response = await store.api.mutateUserSignup(payload, '_id createdAt phoneNumber updatedAt')
        console.log('response==> ', response)
        if (get(response, 'userSignup.phoneNumber', '') !== '') {
          store.session.setUser(response.userSignup)
          navigation.navigate('verifycode')
        }
      } catch (error) {
        Alert.alert('Something went wrong, please try again')
        // console.log('error======>', error)
      } finally {
        setIsLoading(false)
      }
    }
  }, 250)

  const onGoogleButtonPress = async () => {
    const googleCredential = auth.GoogleAuthProvider.credential(userInfo.idToken)
    // console.log("idToken", idToken)
    console.log('userInfo==>>>', userInfo)
    const avatar = userInfo.user.photo || ''
    const name = userInfo.user.name || ''
    const userObject = {
      avatar,
      email: userInfo.user.email,
      loginFrom: 'google',
      name,
      phoneNumber: '',
      socialId: userInfo.user.id,
      userRoles: store.session.getRolesByEmail(userInfo.user.email),
    }
    console.log('googleCredential', userObject)
    const api = await store.api.mutateSocialLogin(userObject)
    console.log('userObject', api)
    // console.log("googleCredential", googleCredential)
    if ('socialLogin' in api && api.socialLogin && api.socialLogin.accessToken) {
      session.setToken(api.socialLogin.accessToken)
    }

    navigation.navigate('home')

    return auth().signInWithCredential(googleCredential)
  }

  const image = require('../../../assets/icons/Login.png')

  return (
    <ImageBackground source={image} style={startStyles.image}>
      <SafeAreaView style={styles.container}>
        <View style={[styles.container, { alignItems: 'center', paddingBottom: 18 }]}>
          <View style={{ flex: 0.8 }} />
          <Button
            name="Sign in with Google"
            icon="google"
            onPress={() => navigation.navigate('home')}
            // onPress={debounce(onGoogleButtonPress, 250)}
          />
        </View>
      </SafeAreaView>
    </ImageBackground>
  )
})
