import 'react-native-get-random-values'

import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { ImageBackground, Linking, SafeAreaView, StyleSheet, TextStyle, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import { Button } from '../../components'
import styles from '../../styles/globalStyle'
import { moderateScale } from '../../styles/sizes'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})
const textStyle: TextStyle = { color: '#232335', fontSize: moderateScale(18), fontWeight: 'bold', textAlign: 'center' }

export const Join = observer(function Join() {
  const navigation = useNavigation()

  const onJoinPress = () => {
    Linking.openURL('https://games.pokerpowherapp.com/join/aa5093')
    navigation.navigate('home')
  }

  useEffect(() => {}, [])

  const image = require('../../../assets/icons/Login.png')

  return (
    <ImageBackground source={image} style={startStyles.image}>
      <SafeAreaView style={styles.container}>
        <View style={[styles.container, { alignItems: 'center', paddingBottom: 18 }]}>
          <View style={{ flex: 0.8 }} />
          <Button type="join" name="Join The Game" textStyle={textStyle} onPress={debounce(onJoinPress, 250)} />
        </View>
      </SafeAreaView>
    </ImageBackground>
  )
})
