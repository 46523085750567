/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameEntityModel, GameEntityModelType } from "./GameEntityModel"
import { GameEntityModelSelector } from "./GameEntityModel.base"
import { MessageContractModel, MessageContractModelType } from "./MessageContractModel"
import { MessageContractModelSelector } from "./MessageContractModel.base"
import { RootStoreType } from "./index"


/**
 * GameResponseBase
 * auto generated base class for the model GameResponseModel.
 */
export const GameResponseModelBase = ModelBase
  .named('GameResponse')
  .props({
    __typename: types.optional(types.literal("GameResponse"), "GameResponse"),
    game: types.union(types.undefined, types.late((): any => GameEntityModel)),
    messages: types.union(types.undefined, types.array(types.late((): any => MessageContractModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GameResponseModelSelector extends QueryBuilder {
  game(builder?: string | GameEntityModelSelector | ((selector: GameEntityModelSelector) => GameEntityModelSelector)) { return this.__child(`game`, GameEntityModelSelector, builder) }
  messages(builder?: string | MessageContractModelSelector | ((selector: MessageContractModelSelector) => MessageContractModelSelector)) { return this.__child(`messages`, MessageContractModelSelector, builder) }
}
export function selectFromGameResponse() {
  return new GameResponseModelSelector()
}

export const gameResponseModelPrimitives = selectFromGameResponse()
