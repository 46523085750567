import { StyleSheet } from 'react-native'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { color } from '../../theme'

export const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'rgb(5, 16, 45)',
    },
    buttonText: {
      textAlign: 'center',
      fontWeight: '800',
      fontSize: moderateScale(22),
      fontFamily: fontFamily.sfProDisplayHeavy,
    },
    gradientButton: {
      flex: 1,
      borderRadius: 8,
      justifyContent: 'center',
      height: 55,
    },
    inputField: {
      height: 58,
      backgroundColor: 'white',
      borderRadius: 8,
      fontSize: moderateScale(24),
      paddingStart: 10,
      marginTop: 6,
    },
    backCopyText: {
      fontSize: moderateScale(14),
      fontFamily: fontFamily.sfProDisplayHeavy,
      textTransform: 'uppercase',
      color: '#49CDF2',
    },
    heading: {
      fontSize: moderateScale(24),
      fontFamily: fontFamily.sfProDisplayHeavy,
      marginTop: 36,
    },
    nameHeading: {
      fontSize: moderateScale(14),
      fontFamily: fontFamily.sfProDisplayRegular,
      marginTop: 16,
    },
    dropdown: {
      width: '100%',
      height: 58,
      backgroundColor: 'white',
      borderRadius: 8,
      fontSize: moderateScale(24),
      paddingStart: 10,
      marginTop: 6,
    },
    dropdownPickerImage: {
      width: 20,
      height: 20,
      resizeMode: 'contain',
      tintColor: color.primaryBlack,
    },
  })
  