import { Instance, SnapshotOut, types } from 'mobx-state-tree'

import { EventAnalyticsStore } from './analytics/session-analytics'
import { GameModalQueue } from './game/game-modals'
import { SessionAgoraStoreModel } from './session-agora'
import { SessionChannelStoreModel } from './session-channel'
import { SessionGameModel } from './session-game'
import { SessionLinkingModel } from './session-linking'
import { SessionMessagesModel } from './session-messages'
import { SessionShareStoreModel } from './session-share'
import { SessionTokenStoreModel } from './session-token'
import { SessionChatStoreModel } from './session-chat'
import { SessionUserStoreModel } from './session-user'

/**
 * A SessionStore model.
 */

export const SessionStore = types
  .compose(
    SessionAgoraStoreModel,
    SessionChannelStoreModel,
    SessionLinkingModel,
    SessionGameModel,
    SessionMessagesModel,
    SessionShareStoreModel,
    SessionTokenStoreModel,
    SessionUserStoreModel,
    SessionChatStoreModel,
    GameModalQueue,
    EventAnalyticsStore
  )
  .named('SessionStore')

/**
 * The SessionStore instance.
 */
export type SessionStoreType = Instance<typeof SessionStore>

/**
 * The data of a SessionStore.
 */
export type SessionStoreSnapshot = SnapshotOut<typeof SessionStore>
