/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameSnapshotModel, GameSnapshotModelType } from "./GameSnapshotModel"
import { GameSnapshotModelSelector } from "./GameSnapshotModel.base"
import { RootStoreType } from "./index"


/**
 * PlayerBase
 * auto generated base class for the model PlayerModel.
 */
export const PlayerModelBase = ModelBase
  .named('Player')
  .props({
    __typename: types.optional(types.literal("Player"), "Player"),
    _id: types.identifier,
    cards: types.union(types.undefined, types.array(types.string)),
    chips: types.union(types.undefined, types.number),
    createdAt: types.union(types.undefined, types.frozen()),
    gameId: types.union(types.undefined, types.late((): any => GameSnapshotModel)),
    id: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string),
    state: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    userId: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PlayerModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get cards() { return this.__attr(`cards`) }
  get chips() { return this.__attr(`chips`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get state() { return this.__attr(`state`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get userId() { return this.__attr(`userId`) }
  gameId(builder?: string | GameSnapshotModelSelector | ((selector: GameSnapshotModelSelector) => GameSnapshotModelSelector)) { return this.__child(`gameId`, GameSnapshotModelSelector, builder) }
}
export function selectFromPlayer() {
  return new PlayerModelSelector()
}

export const playerModelPrimitives = selectFromPlayer()._id.cards.chips.createdAt.name.state.updatedAt.userId
