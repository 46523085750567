import EventEmitter from 'eventemitter3'

export const emitter = new EventEmitter()

const Emitter = {
  // @ts-ignore
  addEventListener: (event, fn) => emitter.on(event, fn),
  // @ts-ignore
  removeEventListener: (event, fn) => emitter.off(event, fn),
  // @ts-ignore
  on: (event, fn) => emitter.on(event, fn),
  // @ts-ignore
  once: (event, fn) => emitter.once(event, fn),
  // @ts-ignore
  off: (event, fn) => emitter.off(event, fn),
  // @ts-ignore
  emit: (event, payload) => emitter.emit(event, payload),
}

Object.freeze(Emitter)

export default Emitter
