import * as React from 'react'
import { Image, ImageSourcePropType } from 'react-native'

import { CardSvgs } from './card-svgs'
import { CardProps } from './card.base'

export const Card = (props: CardProps) => {
  const { name } = props
  let cardName = name ? name.toUpperCase() : ''
  const svg = CardSvgs[cardName] as ImageSourcePropType
  return svg ? <Image source={svg} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} /> : <></>
}
