import { LinearGradient } from 'expo-linear-gradient'
import React, { useEffect, useState } from 'react'
import { FlatList, Image, Pressable, TouchableOpacity, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { ModalProps } from 'react-native-modal'

import { useFocusEffect } from '@react-navigation/native'

import { DeviceType, loggedInDeviceDeviceType } from '../../utils/detectDeviceType'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { CommonModal } from '../CommonModal/commonModal'
import { Text } from '../text/text'
import { styles } from './styles'

export interface BetValue {
  points: number
  label: string
}

export interface BetModalProps extends ModalProps {
  stack: string | number
  onBet: (value: number) => void
  betValues: BetValue[]
  biggestBet: number
  visible: boolean
  turnTimer: () => number
  onClose: () => void
  errorMsg?: string
  actionTime: number
}

const NumberPad = ['1', '2', '3', '4', '5', '6', '7', '8', '9']

export const BetModal = (props: BetModalProps) => {
  const { errorMsg, onBet, betValues, biggestBet, onClose, visible, stack, ...rest } = props
  const [isNumberEntrySelected, setIsNumberEntrySelected] = useState(false)
  const [enteredBetValue, setEnteredBetValue] = useState('')
  const [confirmedBetValue, setConfirmedBetValue] = useState('')
  const [turnTimer, setTurnTimer] = useState<number>(Math.floor((props.actionTime - props.turnTimer() + 10) / 1000))

  // Purely visual countdown timer in top-left corner.
  useEffect(() => {
    const interval = setInterval(() => {
      if (turnTimer <= 0) {
        setTurnTimer(0)
        setTimeout(() => {
          // Added a little buffer before closing. Less frustrating.
          props.onClose()
        }, 500)
      } else if (turnTimer >= 90) {
        setTurnTimer(90)
      } else {
        setTurnTimer(turnTimer - 1)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [turnTimer])

  useEffect(() => {
    if (errorMsg !== '') {
      setEnteredBetValue('')
      setConfirmedBetValue('')
    }
  }, [errorMsg])

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        setEnteredBetValue('')
        setConfirmedBetValue('')
        setIsNumberEntrySelected(betValues.length === 0)
      }
    }, [])
  )

  const onChangeBetValue = (value: string) => {
    setEnteredBetValue('')
    let newValue = enteredBetValue
    newValue = newValue + value
    setEnteredBetValue(newValue)
    if (confirmedBetValue !== '') {
      setConfirmedBetValue('')
    }
  }

  const onDeleteEnteredValue = () => {
    let newValue = enteredBetValue
    newValue = newValue.substring(0, newValue.length - 1)
    setEnteredBetValue(newValue)
    if (confirmedBetValue !== '') {
      setConfirmedBetValue('')
    }
  }

  const confirmFooter = () => {
    return (
      <View style={styles.modalFooter}>
        {/* TOP */}
        <View style={styles.footerConfirm}>
          <Pressable
            onPress={() => {
              onBet(Number(enteredBetValue))
              setConfirmedBetValue(enteredBetValue)
              setEnteredBetValue('')
            }}
            style={{ flex: 1 }}
          >
            <Text
              allowFontScaling={false}
              tx="common.confirm"
              style={[
                styles.confirmText,
                {
                  color: enteredBetValue === '' ? 'rgba(255,255,255,0.5)' : '#FFBB0E',
                  textDecorationLine: enteredBetValue === '' ? 'none' : 'underline',
                },
              ]}
            />
          </Pressable>
        </View>

        {/* BOT */}
        <View style={styles.footerError}>
          <Text style={styles.errorText}>{errorMsg}</Text>
        </View>
      </View>
    )
  }

  const renderItem = ({ item }: any) => (
    <LinearGradient
      key={item}
      colors={['#49CDF2', '#49CDF2', '#9444FB']}
      start={{ x: 0.0, y: 1.0 }}
      end={{ x: 1.0, y: 1.0 }}
      style={styles.dialNumberContainer}
    >
      <TouchableOpacity
        onPress={() => {
          onChangeBetValue(item)
        }}
        style={{
          flex: 1,
          backgroundColor: '#070D37',
          borderRadius: 39,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          allowFontScaling={false}
          tx="gameScreen.betModal.dialNumber"
          txOptions={{ number: item }}
          style={styles.dialNumbers}
        />
      </TouchableOpacity>
    </LinearGradient>
  )

  const numberEntry = () => {
    return (
      <View>
        <React.Fragment>
          <View style={styles.dialContainer}>
            <FlatList
              ListHeaderComponent={() => {
                return (
                  <TextInputMask
                    allowFontScaling={false}
                    type={'money'}
                    placeholder={'0'}
                    placeholderTextColor={'#fff'}
                    options={{
                      precision: 0,
                      separator: ',',
                      delimiter: ',',
                      unit: '',
                      suffixUnit: '',
                    }}
                    style={styles.numberEntryValue}
                    // value is the enteredBetValue, unless the user has confirmed their selection. Prevents 0 from being displayed.
                    value={confirmedBetValue === '' ? enteredBetValue : confirmedBetValue}
                    editable={false}
                  />
                )
              }}
              data={NumberPad}
              numColumns={3}
              renderItem={renderItem}
              keyExtractor={(item, index) => `bet-item-${index}`}
            />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <TouchableOpacity
              onPress={onDeleteEnteredValue}
              style={styles.cancelImageContainer}
              disabled={enteredBetValue.length < 1}
            >
              <Image source={require('../../../assets/icons/BkSpc.png')} style={styles.deleteButtonImage} />
            </TouchableOpacity>
            <LinearGradient
              colors={['#49CDF2', '#49CDF2', '#9444FB']}
              start={{ x: 0.0, y: 1.0 }}
              end={{ x: 1.0, y: 1.0 }}
              style={styles.dialNumberContainer}
            >
              <TouchableOpacity
                disabled={enteredBetValue.length < 1}
                onPress={() => {
                  onChangeBetValue('0')
                }}
                style={{
                  flex: 1,
                  backgroundColor: '#070D37',
                  borderRadius: 39,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  allowFontScaling={false}
                  tx="gameScreen.betModal.dialNumber"
                  txOptions={{ number: 0 }}
                  style={styles.dialNumbers}
                />
              </TouchableOpacity>
            </LinearGradient>
            <View style={styles.bottomRightDialSpacer} />
          </View>
        </React.Fragment>
      </View>
    )
  }

  return (
    <CommonModal
      {...rest}
      // key="BetModal"
      title={`Chip Stack: ${numberWithCommas(stack)}\nCurrent Bet: ${numberWithCommas(biggestBet)}`}
      visible={visible}
      onClose={onClose}
      showCloseButton={false}
      containerStyle={[
        { borderRadius: 16 },
        loggedInDeviceDeviceType() === DeviceType.pad ? { maxWidth: 390, alignSelf: 'center', width: '100%' } : {},
      ]}
    >
      <View style={styles.modalInnerContainer}>
        <View
          style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }]}
        >
          <View style={[{ flex: 1 }]}>
            <Text style={[styles.modalHeading, { textAlign: 'left', color: '#fff' }]}>
              {`     : ${turnTimer.toString()}`}
            </Text>
          </View>
          <Text tx="gameScreen.betModal.betInputHeading" style={styles.modalHeading} />
          <View style={[{ flex: 1 }]} />
        </View>
        <View style={[styles.modalBetTypeContainer, {}]}>
          <LinearGradient
            colors={['#49CDF2', '#49CDF2', '#9444FB']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={styles.modalBetTypeButtonBorder}
          >
            <LinearGradient
              colors={isNumberEntrySelected ? ['#070D37', '#070D37'] : ['#49CDF2', '#49CDF2', '#9444FB']}
              start={{ x: 0.0, y: 1.0 }}
              end={{ x: 1.0, y: 1.0 }}
              style={styles.modalBetTypeButton}
            >
              <Text
                allowFontScaling={false}
                tx="gameScreen.betModal.suggestedBet"
                onPress={() => {
                  setEnteredBetValue('')
                  setIsNumberEntrySelected(false)
                }}
                // style={[styles.footerTabText, { color: isNumberEntrySelected ? '#fff' : '#6E8DE3' }]}
                style={[styles.footerTabText, {}]}
              />
            </LinearGradient>
          </LinearGradient>
          <LinearGradient
            colors={['#49CDF2', '#49CDF2', '#9444FB']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={styles.modalBetTypeButtonBorder}
          >
            <LinearGradient
              colors={!isNumberEntrySelected ? ['#070D37', '#070D37'] : ['#49CDF2', '#49CDF2', '#9444FB']}
              start={{ x: 0.0, y: 1.0 }}
              end={{ x: 1.0, y: 1.0 }}
              style={styles.modalBetTypeButton}
            >
              <Text
                allowFontScaling={false}
                tx="gameScreen.betModal.numberEntry"
                onPress={() => {
                  setEnteredBetValue('')
                  setIsNumberEntrySelected(true)
                }}
                // style={[styles.footerTabText, { color: isNumberEntrySelected ? '#fff' : '#6E8DE3' }]}
                style={[styles.footerTabText, {}]}
              />
            </LinearGradient>
          </LinearGradient>
        </View>
        <View>
          {isNumberEntrySelected ? (
            numberEntry()
          ) : (
            <View style={{ padding: 20 }}>
              {betValues.map((item: any, index: any) => {
                // console.log('betValue', item)
                return (
                  <TouchableOpacity
                    key={index}
                    style={styles.betRow}
                    onPress={(e) => {
                      e.preventDefault()
                      setEnteredBetValue(`${item.points}`)
                    }}
                  >
                    <LinearGradient
                      colors={['#49CDF2', '#49CDF2', '#9444FB']}
                      start={{ x: 0.0, y: 1.0 }}
                      end={{ x: 1.0, y: 1.0 }}
                      style={styles.betInputFieldContainer}
                    >
                      <View
                        style={[
                          styles.betInputFieldInnerContainer,
                          {
                            backgroundColor: item.points.toString() === enteredBetValue ? 'transparent' : '#070D37',
                          },
                        ]}
                      >
                        <Text
                          allowFontScaling={false}
                          tx="gameScreen.formattedNumber"
                          txOptions={{ value: numberWithCommas(item.points) }}
                          style={styles.betInputField}
                        />
                      </View>
                    </LinearGradient>
                    <View style={styles.betInputFieldLabelContainer}>
                      <Text
                        allowFontScaling={false}
                        tx="common.label"
                        txOptions={{ label: item.label }}
                        style={styles.betInputFieldLabel}
                      />
                    </View>
                  </TouchableOpacity>
                )
              })}
            </View>
          )}
        </View>
        {confirmFooter()}
      </View>
    </CommonModal>
  )
}
