import { Platform, StyleSheet, PlatformIOSStatic } from 'react-native'

import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
    justifyContent: 'center',
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 15,
    padding: 1,
  },
  modalInnerContainer: {
    backgroundColor: '#070D37',
    borderRadius: 15,
  },
  modalTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 24,
    fontFamily: fontFamily.heroNewBoldItalic,
    fontWeight: '700',
    paddingVertical: 10,
  },
  modalHeading: {
    // lineHeight: 21,
    textTransform: 'uppercase',
    fontFamily: fontFamily.heroNewBoldItalic,
    fontSize: 15,
    textAlign: 'center',
    marginTop: 15,
    color: '#FFBB0E',
  },
  modalBetTypeButton: {
    justifyContent: 'center',
    padding: 14,
    borderRadius: 22,
  },
  modalBetTypeButtonBorder: {
    justifyContent: 'center',
    padding: 2,
    borderRadius: 24,
  },
  modalBetTypeContainer: {
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 6,
    paddingBottom: 4,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  footerTabText: {
    fontFamily: fontFamily.heroNewBoldItalic,
    fontSize: 15,
    lineHeight: 18,
    fontWeight: 'normal',
    textAlign: 'center',
    color: 'white',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 6,
    height: isIPad ? 75 : 64,
  },
  footerConfirm: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: 5,
  },
  footerError: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 10,
    flex: 2,
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  betRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
  betInputFieldContainer: {
    flex: 1.4,
    padding: 1,
    borderRadius: 25,
  },
  betInputFieldInnerContainer: {
    backgroundColor: '#070D37',
    borderRadius: 31,
  },
  betInputField: {
    fontFamily: fontFamily.heroNewBoldItalic,
    textAlign: 'center',
    color: '#fff',
    fontSize: 26,
    paddingHorizontal: 15,
    height: 52,
    // borderWidth: 0.5,
    borderColor: '#6E8DE3',
    borderRadius: 50,
    paddingTop: Platform.OS === 'ios' ? 8 : 0,
    textAlignVertical: 'center',
  },
  betInputFieldLabelContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  betInputFieldLabel: {
    fontFamily: fontFamily.heroNewBoldItalic,
    textAlign: 'right',
    color: '#fff',
    fontSize: 13,
  },
  betModalCLoseContainer: {
    position: 'absolute',
    left: 20,
    top: -8,
  },
  closeImage: {
    height: 30,
    width: 30,
  },
  cancelButtonContainer: {
    flex: 1,
    marginLeft: 10,
    paddingHorizontal: 15,
  },
  cancelImage: {
    width: 40,
    height: 40,
  },
  confirmText: {
    fontFamily: fontFamily.heroNewBoldItalic,
    // color: '#FFBB0E',
    textAlign: 'right',
    marginRight: 25,
    textDecorationStyle: 'solid',
    textDecorationColor: '#FFBB0E',
    textTransform: 'uppercase',
    fontSize: isIPad ? moderateScale(20) : moderateScale(26) ,
  },
  dialNumbers: {
    fontFamily: fontFamily.heroNewBoldItalic,
    color: '#fff',
    fontSize: 36,
  },
  deleteButtonImage: {
    width: 56,
    height: 34,
  },
  bottomRightDialSpacer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelImageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialNumberContainer: {
    width: 70,
    height: 70,
    padding: 2,
    borderRadius: 35,
    marginVertical: 10,
    marginHorizontal: 10,
  },
  dialContainer: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberEntryValue: {
    marginVertical: 0,
    color: '#fff',
    fontFamily: fontFamily.heroNewBoldItalic,
    textAlign: 'center',
    // height: 34,
    fontSize: 34,
    borderColor: 'red',
  },
})
