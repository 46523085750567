import 'react-native-get-random-values'

import { debounce } from 'lodash'
import LottieView from 'lottie-react-native'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import {
  Alert,
  BackHandler,
  FlatList,
  Image,
  ImageBackground,
  Linking,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native'
import VersionCheck from 'react-native-version-check'

import { GameModes } from '@poker-powher/poker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { ActionModal } from '../../components'
import { translate } from '../../i18n/translate'
import { useStore } from '../../models'
import { events } from '../../models/session/analytics/analytics-events'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import { Card } from './card'
import { data, Options } from './lobbyData'
import startStyles from './styles'

const logo = require('../../../assets/icons/Logo-Purple.png')
const blankHome = require('../../../assets/icons/Blank-Home_Purple.png')

export const LobbyScreen = observer(() => {
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  const showLottieAnimation = store.session.showWinningHandLottie
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = React.useState(false)
  const [playerRole, setPlayerRole] = React.useState('')

  useFocusEffect(() => {
    checkVersion()
    store.session.teardown()
    store.session.setPlayKind(playerRole)
  })

  const checkVersion = async () => {
    try {
      if (!__DEV__) {
        const updateNeeded = await VersionCheck.needUpdate()
        console.log('Update needed: ', updateNeeded)
        if (updateNeeded.isNeeded) {
          Alert.alert('Update version', 'Please update to the latest version', [
            {
              text: 'Update',
              onPress: async () => {
                BackHandler.exitApp()
                await Linking.openURL(updateNeeded.storeUrl)
              },
            },
          ])
        }
      } else {
        console.log('Debugging mode')
      }
    } catch (error) {
      console.log('Error in checking the versions', error)
    }
  }

  const gotoGame = async () => {
    if (store.session.isLobbyScreenEnabled) {
      navigation.navigate(DrawerRoutes.gameLobby)
    } else {
      navigation.navigate(DrawerRoutes.game)
    }
  }

  useEffect(() => {
    store.session.closeSocketConnection()
    if (store.session.joinCode) {
      navigation.navigate('join')
    }
  }, [store.session.joinCode])

  const onShare = async () => {
    const userId = store.session.user?._id.toString()
    if (Platform.OS !== 'web') {
      store.session.setEvent(events.game.gameCreated, {
        playerName: store.session.user?.name,
        joinCode: store.session.share?.hash,
        userId,
      })
    }
    try {
      setLoading(true)
      const userId = store.session.user?._id
      store.session.setIsTournamentModeOn(false)
      // await store.session.createChannel()
      store.session.clearChannel()
      store.session.clearGame()
      // const gameId = myUserId
      const gameId = nanoid()
      await store.session.createShare(gameId, GameModes.Default.tag)

      // NOTE: We skip await to allow navigation to continue
      store.session.promptShare()

      const shareUserId = store.session.share?.userId ?? 'share'
      const hasChannel = !!store.session.channel?.channelName
      const isHost = !hasChannel && userId === shareUserId
      console.log('onShare GameScreen.useEffect hasChannel', hasChannel)
      console.log('onShare GameScreen.useEffect isHost', isHost)
      // if the host is creating the game and the game has not begun (host didn't re-join or close/reopen the app)
      if (isHost && store.session.game === undefined) {
        console.log('onShare GameScreen.useEffect creating channel and game...', isHost, userId, shareUserId)
        // store.session.clearMessages()
        await Promise.all([store.session.createChannel(gameId), store.session.createGame(gameId)])
          .then(() => {
            gotoGame()
          })
          .catch((error) => console.error('setupGame GameScreen.useEffect isHost', error))
        console.log('onShare GameScreen.useEffect channel and game created')
      }
    } catch (error) {
      console.error('onShare', error)
      store.session.logout()
      navigation.navigate(DrawerRoutes.logout)
    } finally {
      setLoading(false)
    }
  }

  const onSelectingOption = (key: string) => {
    switch (key) {
      case Options.joinGame:
        navigation.navigate(DrawerRoutes.join)
        break
      case Options.createGame:
        store.session.setIsTournamentModeOn(false)
        // onShare()
        openUserRoleModal()
        break
      case Options.communityGame:
        store.session.setIsTournamentModeOn(true)
        navigation.navigate(DrawerRoutes.tournamentPassword)
        break
      case Options.miniGamesLobby:
        store.session.setIsTournamentModeOn(false)
        navigation.navigate(DrawerRoutes.miniGamesLobby)
        break
    }
  }

  const openUserRoleModal = () => {
    const isTeacher = store.session.isTeacher
    if (isTeacher) {
      setVisible(true)
    } else {
      onShare()
    }
  }

  const onSelectRole = (role: string) => {
    store.session.setPlayKind(role)
    setPlayerRole(role)
    setVisible(false)
    requestAnimationFrame(() => {
      setTimeout(() => {
        onShare()
      }, 250)
    })
  }

  return (
    <ImageBackground source={blankHome} style={startStyles.image}>
      <FlatList
        data={data}
        keyExtractor={(item, index) => item.id}
        // Hack to add padding to bottom.
        ListFooterComponent={() => <View style={{ height: 30 }} />}
        ListFooterComponentStyle={{
          height: 30,
        }}
        ListHeaderComponent={() => {
          if (showLottieAnimation) {
            return (
              <LottieView
                source={require('./home_1.json')}
                autoPlay={true}
                loop={false}
                style={startStyles.lottieImage}
                speed={0.8}
              />
            )
          }
          return (
            <TouchableOpacity onLongPress={() => navigation.navigate('admin', { screen: 'games' })}>
              <Image
                source={logo}
                style={{
                  height: Platform.OS === 'ios' ? 300 : 200,
                  // flex: 1,
                  alignSelf: 'center',
                  width: '70%',
                  resizeMode: 'contain',
                  marginBottom: 20,
                  padding: 10,
                  marginTop: 20,
                }}
              />
            </TouchableOpacity>
          )
        }}
        renderItem={({ item }) => (
          <TouchableOpacity style={{ flex: 1 }} onPress={debounce(() => onSelectingOption(item.id), 250)}>
            <Card
              icon={item.icon}
              loading={loading}
              heading1={item.heading1}
              heading2={item.heading2}
              description={item.description}
              showBanner={item.showBanner}
            />
          </TouchableOpacity>
        )}
      />
      <ActionModal
        key={'userRole'}
        onSelect={(role: string) => onSelectRole(role)}
        message={translate('join.confirmMessageBeforeJoinTheTable') ?? ''}
        visible={visible}
        buttonTitle1={translate('common.observe')}
        buttonTitle2={translate('common.play')}
        onModalHide={() => setVisible(false)}
        onClose={() => setVisible(false)}
        type={'userRole'}
      />
    </ImageBackground>
  )
})
