/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * ChannelBase
 * auto generated base class for the model ChannelModel.
 */
export const ChannelModelBase = ModelBase
  .named('Channel')
  .props({
    __typename: types.optional(types.literal("Channel"), "Channel"),
    _id: types.identifier,
    channelName: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    hostId: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ChannelModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get channelName() { return this.__attr(`channelName`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get hostId() { return this.__attr(`hostId`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
}
export function selectFromChannel() {
  return new ChannelModelSelector()
}

export const channelModelPrimitives = selectFromChannel()._id.channelName.createdAt.hostId.updatedAt
