import _, { debounce } from 'lodash'
import React from 'react'
import { FlatList, View } from 'react-native'
import { ModalProps } from 'react-native-modal'
import { fontFamily } from '../../theme/fontFamily'
import { CommonModal } from '../CommonModal/commonModal'
import { Text } from '../text/text'
import styles  from './styles'
export interface LeaderBoardProps extends ModalProps {
  visible: boolean
  message: string
  observers: number[]
  onAction: () => void
  onClose: () => void
  boardData?: any
  myPlayerId: number
  isAccessedFromDrawer: boolean
  isGameOver?: boolean 
}

export const LeaderBoard = (props: LeaderBoardProps) => {
  const { visible, onAction, onClose, message, observers, boardData, myPlayerId, isAccessedFromDrawer, ...rest } = props
  const [prizePool, setPrizePool] = React.useState(0)
  const [isGameRunning, setIsGameRunning] = React.useState(false)
  const [runTime, setRunTime] = React.useState('00:00:00')
  const [entries, setEntries] = React.useState('0/0')
  const [myRanking, setMyRanking] = React.useState(0)
  const [playerData, setPlayerData] = React.useState([])
  const [winnerData, setWinnerData] = React.useState([])

  React.useEffect(() => {
    if (boardData) {
      const data = boardData
      setPrizePool(data.prizePool)
      setRunTime(data.runTime)
      setPlayerData(data.players)
      setWinnerData(data.winners)
      setIsGameRunning(data.isGameRunning)
    }
  }, [boardData])

  React.useEffect(() => {
    if (playerData.length > 0) {
      playerData.forEach((element: any, index) => {
        if (Number(element.playerId) === myPlayerId) {
          const myRank = winnerData.length > 0 ? index + 1 : 1
          // setMyRanking(element.rank)
          setMyRanking(myRank)
          const entriesData = `${myRank}/${playerData.length}`
          setEntries(entriesData)
        }
      })
    }
  }, [playerData])

  const onPress = debounce(() => {
    onAction()
    onClose()
  }, 250)

  const modalContent = () => {
    return (
      <View style={{ backgroundColor: '#0D2063' }}>
        {!isAccessedFromDrawer ? (
          <Text
            style={styles.winningHeading}
            tx={'common.label'}
            txOptions={{
              label:
                winnerData.length > 0
                  ? Number(winnerData[0].playerId) === myPlayerId
                    ? 'You Won'
                    : `${winnerData[0].playerName} Won`
                  : '',
            }}
          />
        ) : null}
        {!isAccessedFromDrawer ? <View style={styles.separator} /> : null}
        <Text
          style={[styles.leaderBoardText, isAccessedFromDrawer ? { paddingTop: 20 } : {}]}
          tx={'leaderboard.leaderboardHeading'}
        />
        <View style={{ flexDirection: 'row', marginTop: 12 }}>
          <View style={styles.myDataContainer}>
            <Text tx="leaderboard.myRank" style={styles.myDataLabels} />
            <Text tx="leaderboard.myRankValue" txOptions={{ rank: myRanking }} style={styles.myDataValues} />
          </View>
          <View style={styles.myDataContainer}>
            <Text tx="leaderboard.entries" style={styles.myDataLabels} />
            <Text tx="leaderboard.entriesValue" txOptions={{ entries: entries }} style={styles.myDataValues} />
          </View>
          {/* <View style={styles.myDataContainer}>
            <Text tx="leaderboard.prizePool" style={styles.myDataLabels} />
            <Text tx="leaderboard.poolPrizeValue" txOptions={{ prizeValue: prizePool }} style={styles.myDataValues} />
          </View> */}
          <View style={styles.myDataContainer}>
            <Text tx="leaderboard.runtime" style={styles.myDataLabels} />
            <Text tx="leaderboard.runtimeValue" txOptions={{ runtime: runTime }} style={styles.myDataValues} />
          </View>
        </View>
        <View style={styles.tableHeader}>
          <View style={styles.rankHeaderContainer}>
            <Text tx="leaderboard.rank" style={styles.rankHeading} />
          </View>
          <View style={styles.stackHeaderContainer}>
            <Text tx="leaderboard.stack" style={styles.stackHeading} />
          </View>
          <View style={styles.nameHeaderContainer}>
            <Text tx="leaderboard.name" style={styles.nameHeading} />
          </View>
          {/* <View style={styles.raHeaderContainer}>
            <Text tx="leaderboard.ra" style={styles.raHeading} />
          </View> */}
        </View>
        <View>
          {/**
           * TODO: Highlight my rank with the real data
           */}
          <FlatList
            data={playerData}
            extraData={[playerData, boardData]}
            keyExtractor={(item) => item.playerId}
            renderItem={({ item, index }) => {
              if (!String(observers).includes(item.playerId)) {
                // console.log('## ====> observer', String(observers[0]))
                // console.log('##===> player Id', item.playerId)
                return (
                  <View
                    style={[
                      { flexDirection: 'row', paddingVertical: 12 },
                      { backgroundColor: Number(item.playerId) === myPlayerId ? '#ffffff10' : 'transparent' },
                    ]}
                  >
                    <View style={{ alignItems: 'center', width: '25%' }}>
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: fontFamily.sfProDisplayBold,
                          color: Number(item.playerId) === myPlayerId ? '#FFBB0E' : 'white',
                        }}
                      >
                        {winnerData.length > 0 ? index + 1 : 1}
                      </Text>
                    </View>
                    <View style={{ width: '25%' }}>
                      <Text style={styles.stackValue}>
                        {item.chips >= 1000 ? `${(item.chips / 1000).toFixed(1)}k` : item.chips}
                      </Text>
                    </View>
                    <View style={{ paddingLeft: 20, width: '50%' }}>
                      <Text {...props} numberOfLines={1} ellipsizeMode={'tail'} style={[styles.nameValue, {textDecorationLine : item?.isLeft ? 'line-through' : 'none'} ]}>
                        {item.playerName}
                        {item?.isLeft ? ' (left)': ''}
                      </Text>
                    </View>
                    {/**
                     * R+A coloumn is for multitable
                     */}
                    {/* <View style={styles.raValueContainer}>
                      <Text style={styles.raValue}>{item.ra}</Text>
                    </View> */}
                  </View>
                )
              } else {
                return null
              }
            }}
            style={{ paddingVertical: 20 }}
          />
        </View>
      </View>
    )
  }

  return (
    <CommonModal
      {...rest}
      visible={visible}
      onClose={onClose}
      showCloseButton={true}
      containerStyle={{ borderRadius: 6 }}
    >
      {modalContent()}
    </CommonModal>
  )
}
