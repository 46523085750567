/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PlayerStatBiggestWinBase
 * auto generated base class for the model PlayerStatBiggestWinModel.
 */
export const PlayerStatBiggestWinModelBase = ModelBase
  .named('PlayerStatBiggestWin')
  .props({
    __typename: types.optional(types.literal("PlayerStatBiggestWin"), "PlayerStatBiggestWin"),
    chipsWon: types.union(types.undefined, types.number),
    createdAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PlayerStatBiggestWinModelSelector extends QueryBuilder {
  get chipsWon() { return this.__attr(`chipsWon`) }
  get createdAt() { return this.__attr(`createdAt`) }
}
export function selectFromPlayerStatBiggestWin() {
  return new PlayerStatBiggestWinModelSelector()
}

export const playerStatBiggestWinModelPrimitives = selectFromPlayerStatBiggestWin().chipsWon.createdAt
