import React from 'react'
import { Image, StyleSheet, Text, View, ViewProps } from 'react-native'
import { PrimaryButton } from '../../../components/PrimaryButton/PrimaryButton'
import { MiniGamesRoutes } from '../../../navigation/minigames-navigator'
import { useNavigation } from '@react-navigation/native'
import { fontFamily } from '../../../theme/fontFamily'

interface GamePreviewCardProps extends ViewProps {
  img: any
  title: string
  params: object
}

export const GamePreviewCard = ({ img, title, params }: GamePreviewCardProps) => {
  const navigation = useNavigation<any>()

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardInner}>
        <Image source={img} style={styles.previewImage}></Image>

        <View style={styles.ctaContainer}>
          <Text style={styles.gameName} numberOfLines={1} ellipsizeMode="tail">
            {title}
          </Text>

          <PrimaryButton
            textProps={{
              style: styles.buttonText,
              tx: 'miniGamesScreen.gamePreviewCard.playButton',
            }}
            buttonStyles={styles.playButton}
            onPress={() => navigation.navigate(MiniGamesRoutes.introGameScreen, params)}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginVertical: 10,
  },
  cardInner: {
    borderRadius: 8,
    backgroundColor: '#232335',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  previewImage: {
    resizeMode: 'cover',
    width: '100%',
    height: 100,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  playButton: { height: 30, width: 150, marginTop: 8 },
  buttonText: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontFamily: fontFamily.sfProDisplayBold,
    fontSize: 16,
    lineHeight: 19,
  },
  gameName: {
    color: '#fff',
    alignSelf: 'flex-start',
    fontFamily: fontFamily.sfProDisplayBold,
    fontSize: 14,
    lineHeight: 17,
  },
  ctaContainer: {
    padding: 10,
  },
})
