/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum GameModes {
  default="default",
tournament="tournament"
}

/**
* GameModes
 *
 * The specific modes of different game
*/
export const GameModesEnumType = types.enumeration("GameModes", [
        "default",
  "tournament",
      ])
