'use strict'
import { StyleSheet } from 'react-native'
import { scale, verticalScale } from '../../styles/sizes'

export const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    height: verticalScale(60),
    flexDirection: 'row',
  },
  itemLeft: {
    width: '25%',
    justifyContent: 'center',
    alignItems: "center",
    paddingHorizontal: 3,
  },
  itemCenter: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  itemRight: {
    width: '25%',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingHorizontal: 10,
  },
})
