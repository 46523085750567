/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ChatKindEnumType } from "./ChatKindEnum"
import { RootStoreType } from "./index"


/**
 * ChatMessageBase
 * auto generated base class for the model ChatMessageModel.
 */
export const ChatMessageModelBase = ModelBase
  .named('ChatMessage')
  .props({
    __typename: types.optional(types.literal("ChatMessage"), "ChatMessage"),
    _id: types.identifier,
    channelName: types.union(types.undefined, types.string),
    chatKind: types.union(types.undefined, ChatKindEnumType),
    color: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    message: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    userName: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ChatMessageModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get channelName() { return this.__attr(`channelName`) }
  get chatKind() { return this.__attr(`chatKind`) }
  get color() { return this.__attr(`color`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get message() { return this.__attr(`message`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get userName() { return this.__attr(`userName`) }
}
export function selectFromChatMessage() {
  return new ChatMessageModelSelector()
}

export const chatMessageModelPrimitives = selectFromChatMessage()._id.channelName.chatKind.color.createdAt.message.updatedAt.userName
