import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ImageBackground, StyleSheet, Text, View } from 'react-native'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import { Menu, Modal } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'

import Clipboard from '@react-native-clipboard/clipboard'

import { useStore } from '../../models'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

export const AdminNetworkScreen = observer(() => {
  const store = useStore()
  const [visible, setVisible] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const containerStyle = { backgroundColor: 'white', padding: 20 }
  const { isInternetReachable, isNetworkConnected: isConnected, networkType } = store.network

  const cellRow = (key: string, value: string | boolean) => {
    return (
      <View style={styles.listRow}>
        <Text allowFontScaling={false} style={styles.keyValueText}>
          {key}
        </Text>
        <Text allowFontScaling={false} style={styles.keyValueText}>
          {value}
        </Text>
      </View>
    )
  }

  const websocketEvents = store.network.websocketEvents.slice().reverse()

  return (
    <ImageBackground source={image} style={{ ...startStyles.image }}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View style={{ flex: 1 }}>
            {cellRow('Network Status', isConnected ? 'Connected' : 'Disconnected')}
            {cellRow('Network Reachable', isInternetReachable ? 'Yes' : 'No')}
            {cellRow('Network Type', networkType ?? '')}
            {cellRow('Websocket.readyState', store.network.wsReadyState)}
            {cellRow('Websocket.connectionEvents:', '')}
            <FlatList
              data={websocketEvents}
              keyExtractor={(item, index) => `network-item-${index}`}
              renderItem={({ item, index, separators }) => (
                <Menu.Item
                  key={`admin-menu-messages-${index}`}
                  // icon="content-paste"
                  onPress={debounce(() => {
                    // const pretty = item.toString()
                    // const pretty = JSON.stringify(item, null, 2)
                    try {
                      Clipboard.setString(item)
                    } catch (error) {
                      console.warn('Simulator does not support clipboard')
                      console.log(Object.keys(Clipboard))
                    }
                    setMessage(item)
                    showModal()
                  }, 250)}
                  title={item ?? ''}
                />
              )}
            />
          </View>
          <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
            <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
              Click outside this area to dismiss.
            </Text>
            <Text allowFontScaling={false}>{'\n'}</Text>
            <ScrollView>
              <Text allowFontScaling={false}>{message}</Text>
            </ScrollView>
          </Modal>
        </SafeAreaView>
      </View>
    </ImageBackground>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    marginVertical: 10,
  },
  listRow: {
    flexDirection: 'row',
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    marginVertical: 5,
    padding: 10,
  },
  keyValueText: {
    fontSize: 15,
  },
})
