import { LinearGradient } from 'expo-linear-gradient'
import LottieView from 'lottie-react-native'
import * as React from 'react'
import { Dimensions, Pressable, TextStyle, TouchableOpacity, View } from 'react-native'
import Modal, { ModalProps } from 'react-native-modal'

import { Pot as PotType } from '@poker-powher/poker'

import { Board } from '../Board/Board'
import { PlayerHoleCards } from '../PlayerHoleCards/PlayerHoleCards'
import { Pot, PotChipsType } from '../Pot/Pot'
import { Text } from '../text/text'
import { styles } from './styles'

export interface WelcomeModalProps extends ModalProps {
  communityCards: string | string[]
  holeCards: string[]
  winningHandCards: string[]
  onClose: () => void
  points: number
  sidePots: PotType[]
  status: string
  type: string
  visible: boolean
  showLottieAnimation?: boolean
  useFourColoredCards?: boolean
  outOfChipsTextStyle?: any
  isFolded: boolean
}

export enum ModalTypes {
  allIn = 'allIn',
  winningHand = 'winningHand',
  call = 'CALL',
  raise = 'RAISE',
  fold = 'FOLD',
  sidepotPlayers = 'sidepotPlayers',
}

export const StatusModal = (props: WelcomeModalProps) => {
  const {
    onClose,
    type,
    status,
    points,
    sidePots,
    communityCards,
    visible,
    showLottieAnimation,
    useFourColoredCards,
    holeCards,
    winningHandCards,
    outOfChipsTextStyle,
    isFolded,
    ...rest
  } = props

  const communityAndWinningCardsDifference: string[] =
    holeCards.length > 0 && winningHandCards.length > 0
      ? [...communityCards].filter((x) => !winningHandCards.includes(x))
      : []

  const holeAndWinningCardsDifference: string[] =
    holeCards.length > 0 && winningHandCards.length > 0
      ? [...holeCards].filter((x) => !winningHandCards.includes(x))
      : []

  const potAndCardDetails = () => {
    return (
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <Pot
            points={points}
            pots={sidePots}
            potChipsType={PotChipsType.yellowChips}
            customPotContainerStyle={{ marginHorizontal: 15 }}
            hideSidepot={true}
          />
          {holeCards.length > 0 ? (
            <PlayerHoleCards
              hand={holeCards}
              isFolded={isFolded}
              customCardContainerStyle={{ marginRight: 0 }}
              useFourColoredCardStyle={useFourColoredCards ? useFourColoredCards : false}
              disabledCards={holeAndWinningCardsDifference}
              customLeftCardStyle={{
                transform: [{ rotate: '0deg' }],
                width: 48,
                height: 72,
                marginHorizontal: 2,
              }}
              customRightCardStyle={{
                transform: [{ rotate: '0deg' }],
                width: 48,
                height: 72,
                marginHorizontal: 2,
              }}
            />
          ) : null}
        </View>
        <View style={{ marginTop: 20, alignItems: 'center' }}>
          {communityCards.length > 0 ? (
            <Board
              customCardStyle={{ marginHorizontal: 6 }}
              communityCards={communityCards}
              useFourColoredCardStyle={useFourColoredCards}
              disabledCards={communityAndWinningCardsDifference}
            />
          ) : null}
        </View>
      </View>
    )
  }

  const skipButton = () => {
    return (
      <LinearGradient
        colors={['#FF3F25', '#DC2BFF']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={{
          width: Dimensions.get('screen').width / 1.3,
          alignItems: 'center',
          padding: 1,
          height: 50,
          borderRadius: 4,
        }}
      >
        <TouchableOpacity
          onPress={onClose}
          style={{
            backgroundColor: '#232323',
            padding: 5,
            width: '100%',
            height: 48,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            allowFontScaling={false}
            tx="common.skip"
            style={{
              textTransform: 'uppercase',
              textAlign: 'center',
              fontStyle: 'italic',
              fontWeight: '900',
              fontSize: 18,
              width: '100%',
            }}
          />
        </TouchableOpacity>
      </LinearGradient>
    )
  }

  const getModalContent = () => {
    switch (type) {
      case ModalTypes.allIn:
        return (
          <Text
            allowFontScaling={false}
            tx="gameScreen.status.allInOrWinning"
            txOptions={{ status: status }}
            style={[styles.allInText, { paddingHorizontal: 22 }, outOfChipsTextStyle]}
          />
        )
      case ModalTypes.sidepotPlayers:
        return (
          <Text
            tx="gameScreen.status.allInOrWinning"
            txOptions={{ status: status }}
            style={[styles.allInText, { paddingHorizontal: 22 }]}
          />
        )
      case ModalTypes.winningHand:
        return (
          <View style={{ flex: 1, alignItems: 'center' }}>
            {showLottieAnimation ? (
              <LottieView
                source={require('./stars-winner.json')}
                autoPlay={true}
                loop={true}
                style={{ width: 500, height: 500, position: 'absolute' }}
              />
            ) : null}
            <Text
              allowFontScaling={false}
              tx="gameScreen.status.allInOrWinning"
              txOptions={{ status: status }}
              style={[styles.allInText, { padding: 22 }]}
            />
            {potAndCardDetails()}
          </View>
        )
      case ModalTypes.call:
        return (
          <Text
            allowFontScaling={false}
            tx="gameScreen.status.called"
            txOptions={{ status: status }}
            style={[styles.allInText, { paddingHorizontal: 22 }]}
          />
        )
      case ModalTypes.raise:
        return (
          <Text
            allowFontScaling={false}
            tx="gameScreen.status.raised"
            txOptions={{ status: status }}
            style={[styles.allInText, { paddingHorizontal: 22 }]}
          />
        )
      case ModalTypes.fold:
        return (
          <Text
            allowFontScaling={false}
            tx="gameScreen.status.folded"
            txOptions={{ status: status }}
            style={[styles.allInText, { paddingHorizontal: 22 }]}
          />
        )
    }
  }

  return (
    <Modal
      {...rest}
      swipeDirection={'up'}
      onSwipeComplete={(e) => {
        onClose()
      }}
      isVisible={visible}
      coverScreen={true}
      shouldRasterizeIOS
      animationIn={'slideInUp'}
      animationInTiming={500}
      animationOut={'slideOutUp'}
      animationOutTiming={500}
      swipeThreshold={0}
      style={{ margin: 0 }}
    >
      <Pressable onPress={onClose} style={styles.modal}>
        <Pressable
          style={{ flex: 1 }}
          onPress={(e) => {
            e.preventDefault()
            onClose()
          }}
        >
          <View style={{ flex: 1.5 }}></View>
          <View style={styles.modelContentContainer}>{getModalContent()}</View>
          <View style={styles.skipButtonContainer}>{skipButton()}</View>
        </Pressable>
      </Pressable>
    </Modal>
  )
}
