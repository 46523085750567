import LottieView from 'lottie-react-native'
import React from 'react'
import { Animated, Dimensions, Easing, Image, ImageSourcePropType, Platform, View } from 'react-native'
import { GameStates, PlayerStates } from '@poker-powher/poker'
import { avatars } from '../Avatars/avatars'
import { moderateScale } from '../../styles/sizes'
import { FoldOpacityOverlay } from '../FoldOpacityOverlay/FoldOpacityOverlay'
import { LiveVideo } from '../LiveVideo/live-video'
import { PlayerCellOverlay } from '../PlayerCellOverlay/PlayerCellOverlay'
import { styles } from './styles'
import { PlayerRoleBadge } from './PlayerRoleBadge'
import { PlayerName } from './PlayerName'

export const Cell = React.memo((props: any) => {
  const svg = props.avatar as ImageSourcePropType
  const isAllIn = (props.playerState && props.playerState.tag) === PlayerStates.AllIn.tag
  const animSize = React.useState(new Animated.Value(0))[0]

  const composition = Animated.timing(animSize, {
    duration: 1000,
    toValue: 1,
    useNativeDriver: true,
    easing: Easing.linear,
  })

  const animateAllinAnim = () => {
    animSize.setValue(0)
    if (composition) {
      composition.start(({ finished }) => {
        if (finished) {
          // if anims keep resetting and reanimating, we can do useState to prevent: allAnimExpanded(false)
        }
      })
    }
    composition.start()
    return composition
  }

  React.useEffect(() => {
    const composition = animateAllinAnim()
    if (isAllIn) {
      composition.start()
    } else {
      // animSize.stopAnimation()
      composition.reset()
    }
    return () => {
      if (composition) {
        composition.reset()
      }
    }
  }, [isAllIn])

  return (
    <>
      {Platform.OS === 'web' ? (
        props.isTableVisible ? (
          <View
            style={{
              width: Dimensions.get('screen').width / 2.5,
              height: Dimensions.get('screen').height / props.totalPlayers,
              maxHeight: Dimensions.get('screen').height / 9,
            }}
          >
            <Image source={svg} style={{ width: '63%', height: '100%', resizeMode: 'cover' }} />
          </View>
        ) : (
          // <View
          //   style={[
          //     {
          //       height: Dimensions.get('screen').height / props.totalPlayers,
          //       maxHeight: Dimensions.get('screen').height / 3,
          //       width: '100%',
          //     },
          //   ]}
          // >
          //   {/* <Image source={props.avatar} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} /> */}
          //   {props.avatar}
          // </View>
          <View style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
            {/* vv All-In Animation */}

            {/* {!props.useFourColoredCards
            ? isAllIn &&
              !props.isShowdown &&
              props.gameState !== GameStates.PreGame.tag &&
              props.gameState !== GameStates.Finished.tag &&
              props.gameState !== GameStates.Reset.tag &&
              props.gameState !== GameStates.ReadyToPlay.tag && (
                <Animated.View
                  style={[
                    styles.lottieContainer,
                    {
                      // NTS : Change this, make a new animated composition/useRef.
                      transform: [
                        {
                          scaleX: animSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 1.5],
                          }),
                        },
                        {
                          scaleY: animSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 1.5],
                          }),
                        },
                      ],
                    },
                  ]}
                >
                  <LottieView
                    source={require('../../../assets/Lottie/sunburst.json')}
                    autoPlay={true}
                    loop={true}
                    resizeMode={Platform.OS === 'ios' ? 'cover' : 'center'}
                    speed={1.5}
                    renderMode={'HARDWARE'}
                    style={{
                      width: '100%',
                      opacity: Platform.OS === 'ios' ? 1 : 0.45,
                    }}
                  />
                </Animated.View>
              )
            } */}
            {isAllIn &&
              !props.isShowdown &&
              props.gameState !== GameStates.PreGame.tag &&
              props.gameState !== GameStates.Finished.tag &&
              props.gameState !== GameStates.Reset.tag &&
              props.gameState !== GameStates.ReadyToPlay.tag && (
                <Animated.View style={styles.lottieContainer}>
                  <LottieView
                    source={require('../../../assets/Lottie/seamless-star-tunnel.json')}
                    autoPlay={true}
                    loop={true}
                    resizeMode={'cover'}
                    speed={0.35}
                    renderMode={'HARDWARE'}
                    style={{
                      width: '100%',
                      minWidth: '100%',
                      opacity: 0.5,
                      overflow: 'visible',
                    }}
                  />
                </Animated.View>
              )}
            {props.playerState.tag === PlayerStates.Observer.tag && (
              <Image
                resizeMode="cover"
                source={require('../../../assets/menu/inclined-lines.png')}
                style={{ height: '100%', position: 'absolute', zIndex: 1 }}
              />
            )}
            <LiveVideo
              avatar={avatars.gray}
              avatarColor={props.player.color}
              channelName={props.channelName}
              hasLocalUserJoinedVideo={props.hasLocalUserJoinedVideo}
              hasLocalUserHiddenVideo={props.hasLocalUserHiddenVideo}
              index={props.index}
              playerId={props.playerId}
              isLocalUser={props.localUserId === props.player.userId}
              isPlayerOnVideoCall={props.isPlayerOnVideoCall}
              isPlayerMutingVideoCall={props.isPlayerMutingVideoCall}
            />
          </View>
        )
      ) : (
        // Logic - pass everything as a prop for rendering remote videos into their corresponding cells
        <View style={{ flex: 1, overflow: 'hidden', position: 'relative' }}>
          {/* vv All-In Animation */}

          {/* {!props.useFourColoredCards
            ? isAllIn &&
              !props.isShowdown &&
              props.gameState !== GameStates.PreGame.tag &&
              props.gameState !== GameStates.Finished.tag &&
              props.gameState !== GameStates.Reset.tag &&
              props.gameState !== GameStates.ReadyToPlay.tag && (
                <Animated.View
                  style={[
                    styles.lottieContainer,
                    {
                      // NTS : Change this, make a new animated composition/useRef.
                      transform: [
                        {
                          scaleX: animSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 1.5],
                          }),
                        },
                        {
                          scaleY: animSize.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 1.5],
                          }),
                        },
                      ],
                    },
                  ]}
                >
                  <LottieView
                    source={require('../../../assets/Lottie/sunburst.json')}
                    autoPlay={true}
                    loop={true}
                    resizeMode={Platform.OS === 'ios' ? 'cover' : 'center'}
                    speed={1.5}
                    renderMode={'HARDWARE'}
                    style={{
                      width: '100%',
                      opacity: Platform.OS === 'ios' ? 1 : 0.45,
                    }}
                  />
                </Animated.View>
              )
            } */}
          {isAllIn &&
            !props.isShowdown &&
            props.gameState !== GameStates.PreGame.tag &&
            props.gameState !== GameStates.Finished.tag &&
            props.gameState !== GameStates.Reset.tag &&
            props.gameState !== GameStates.ReadyToPlay.tag && (
              <Animated.View style={styles.lottieContainer}>
                <LottieView
                  source={require('../../../assets/Lottie/seamless-star-tunnel.json')}
                  autoPlay={true}
                  loop={true}
                  resizeMode={'cover'}
                  speed={0.35}
                  renderMode={'HARDWARE'}
                  style={{
                    width: '100%',
                    minWidth: '100%',
                    opacity: 0.5,
                    overflow: 'visible',
                  }}
                />
              </Animated.View>
            )}
          {props.playerState.tag === PlayerStates.Observer.tag && (
            <Image
              resizeMode="cover"
              source={require('../../../assets/menu/inclined-lines.png')}
              style={{ height: '100%', position: 'absolute', zIndex: 1 }}
            />
          )}
          <LiveVideo
            avatar={
              props.isFolded ||
                props.playerState.tag === PlayerStates.Waiting.tag ||
                props.playerState.tag === PlayerStates.Out.tag ||
                props.playerState.tag === PlayerStates.ForeverOut.tag ||
                props.playerState.tag === PlayerStates.Observer.tag
                ? avatars.gray
                : props.avatar
            }
            channelName={props.channelName}
            hasLocalUserJoinedVideo={props.hasLocalUserJoinedVideo}
            hasLocalUserHiddenVideo={props.hasLocalUserHiddenVideo}
            index={props.index}
            playerId={props.playerId}
            isLocalUser={props.localUserId === props.player.userId}
            isPlayerOnVideoCall={props.isPlayerOnVideoCall}
            isPlayerMutingVideoCall={props.isPlayerMutingVideoCall}
          />
        </View>
      )}
      {props.player &&
        (props.userId ? (
          <PlayerName
            name={props.name}
            index={props.index}
            mappedIndex={props.mappedIndex}
            cards={props.cards}
            cardViewHeight={props.cardViewHeight}
            isShowdown={props.isShowdown}
            useFourColoredCards={props.useFourColoredCards}
            showCardsForAllin={props.showCardsForAllin}
            chipsRemaining={props.player.chips}
            hasPlayerFolded={props.playerState.tag === PlayerStates.Folded.tag}
            isPlayerSittingOut={props.playerState.tag === PlayerStates.Waiting.tag}
            isMe={props.userId === props.player.userId}
            isCardVisible={props.isCardVisible}
            playerState={props.playerState.tag}
            showPlayerName={true}
            isPlayerOnVideoCall={props.isPlayerOnVideoCall}
            currentPlayerIndex={props.currentPlayerIndex}
          />
        ) : null)}
      {props.player ? (
        !props.isTableVisible ? (
          <PlayerRoleBadge
            playerRole={props.playerRole}
            mappedIndex={props.mappedIndex}
            isTableVisible={props.isTableVisible}
            playerTag={props.playerState.tag}
          />
        ) : null
      ) : null}
      {props.player ? (
        (props.playerState.tag === PlayerStates.Folded.tag ||
          props.playerState.tag === PlayerStates.Out.tag ||
          props.playerState.tag === PlayerStates.Waiting.tag ||
          props.playerState.tag === PlayerStates.ForeverOut.tag) &&
          props.playerState.tag !== PlayerStates.Observer.tag &&
          !props.isTableVisible ? (
          <FoldOpacityOverlay
            points={props.chips}
            isTableVisible={props.isTableVisible}
            isLeft={!!props.isLeft}
            mappedIndex={props.mappedIndex}
            isFolded={props.isFolded}
            playerStatus={props.playerState.tag}
          />
        ) : (
          <View>
            {!props.isGamePaused &&
              props.totalPlayers > 1 &&
              props.isGameBegin &&
              props.currentPlayerIndex === props.index &&
              !props.hasUserTappedOnAction &&
              !props.isShowdown ? (
              <Animated.View
                style={[
                  {
                    opacity: props.currentPlayerIndex === props.index ? 1 : 0,
                    width: props.imageHeight.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0%', '100%'],
                    }),
                    position: 'absolute',
                    bottom: props.isPlayerOnVideoCall ? moderateScale(46) : moderateScale(23),
                    left: 0,
                    right: 0,
                    height: 4,
                    borderTopColor: 'rgba(0,0,0,0.66)',
                    borderTopWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor: 'white',
                  },
                ]}
              />
            ) : props.isGamePaused ? (
              <View
                style={[
                  {
                    opacity: props.currentPlayerIndex === props.index ? 1 : 0,
                    width: `${props.startingImageHeight * 100}%`,
                    position: 'absolute',
                    bottom: moderateScale(23),
                    left: 0,
                    right: 0,
                    height: 4,
                    borderTopColor: 'rgba(0,0,0,0.66)',
                    borderTopWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor: 'white',
                  },
                ]}
              />
            ) : null}
            {!props.isTableVisible && props.playerState.tag !== PlayerStates.Observer.tag ? (
              <PlayerCellOverlay
                mappedIndex={props.mappedIndex}
                index={props.index}
                isTableVisible={props.isTableVisible}
                player={props.player}
                status={props.isWaitlisted}
                chips={props.chips}
                lastAction={props.currentPlayerIndex === props.index ? '' : props.lastAction}
                playerState={props.playerState.tag.toUpperCase()}
                localUserId={props.localUserId}
                isMyTurn={props.isMyTurn}
                playerRole={props.playerRole}
                currentPlayerIndex={0}
                lastBetKind={''}
              />
            ) : null}
          </View>
        )
      ) : null}
      {props.player ? (
        props.isTableVisible &&
          props.playerState.tag !== PlayerStates.Observer.tag &&
          (props.isWaitlisted || props.playerState.tag === PlayerStates.Folded.tag) ? (
          <FoldOpacityOverlay
            points={props.chips}
            isTableVisible={props.isTableVisible}
            isLeft={!!props.isLeft}
            mappedIndex={props.mappedIndex}
            isFolded={props.isFolded}
            playerStatus={props.playerState.tag}
          />
        ) : null
      ) : null}
      {props.isWaitlisted ? <View style={styles.waitlistedUser} /> : null}
    </>
  )
})
