import React from 'react'
import { FunFact as FactProps } from '../../screens'
import { Pressable, StyleSheet } from 'react-native'
import { fontFamily } from '../../theme/fontFamily'
import { Text, PaddedLayout, SecondaryButton } from '../../components'

interface FunFactProps {
  fact: FactProps
  onContinue: () => void
}

export const FunFact = ({ fact, onContinue }: FunFactProps) => {
  return (
    <PaddedLayout styles={styles.container}>
      <Text style={styles.title}>{fact.title}</Text>
      <Text style={styles.text}>{fact.text}</Text>
      <SecondaryButton textProps={{ text: 'OK' }} style={{ width: '30%' }} onPress={onContinue} />
    </PaddedLayout>
  )
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: '#1F2535',
    width: '100%',
    height: 'auto',
    left: 0,
    bottom: 0,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 30,
  },
  title: {
    fontFamily: fontFamily.sfProDisplayBlack,
    fontSize: 18,
    lineHeight: 21,
    paddingVertical: 15,
  },
  text: {
    fontFamily: fontFamily.sfProDisplayRegular,
    textAlign: 'center',
    paddingBottom: 10,
  },
})
