import { debounce } from 'lodash'
import React from 'react'
import { Text, View, PlatformIOSStatic, Platform } from 'react-native'
import { PlayerStates } from '@poker-powher/poker'
import { PlayerActions, PlayerHoleCards } from '../../components'
import { translate } from '../../i18n'
import { useStore } from '../../models'
import style from './style'
const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export const CardAndActions = () => {
  const store = useStore()
  const me = store.session.myPlayer
  const actions = store.session.myActions
  const isMyTurn = store.session.isMyTurn
  const rankedHandName = me?.player?.rankedHandName
  const myHoleCards = store.session.myHoleCards
  const holeCards = store.session.isGameReset
    ? ['blank', 'blank']
    : (myHoleCards ?? ['blank', 'blank']).slice().map((card: string) => card.toUpperCase())
  const doAllIn = debounce(async () => {
    await store.session.doAllInForPlayer()
  }, 250)

  const doBet = debounce(async () => {
    store.session.createBetPrompt()
  }, 250)

  const doCall = debounce(async () => {
    await store.session.doCallForPlayer()
  }, 250)

  const doCheck = debounce(async () => {
    await store.session.doCheckForPlayer()
  }, 250)

  const doFold = debounce(async () => {
    await store.session.doFoldForPlayer()
    setTimeout(() => {
      store.session.setHasUserPreFold(false)
    }, 500)
  }, 250)

  return (
    <React.Fragment>
      {!store.session.isMyPlayerSeated && store.session.seatedPlayers.length >= 9 ? (
        <View
          style={{
            minHeight: 150,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text allowFontScaling={false} style={style.fullTableText}>
            {translate('gameScreen.fullTable')}
            {'\n'}
            <Text
              allowFontScaling={false}
              style={{ color: '#0066cc', marginLeft: 15 }}
              onPress={() => store.session.createLeaveGamePrompt()}
            >
              {translate('gameScreen.leaveGame')}
            </Text>
          </Text>
        </View>
      ) : (
        <View
          style={[
            style.holeCardContainer,
            isIPad ? { justifyContent: 'flex-end', marginBottom: 20 } : { justifyContent: 'center', height: 120 },
          ]}
        >
          {/* Player Hole Cards */}
          {!isIPad && myHoleCards.length > 0 && (
            <View style={style.holeCardView}>
              <PlayerHoleCards
                hand={holeCards}
                useFourColoredCardStyle={store.session.isFourColoredDeckEnabled}
                rankedHandName={holeCards.length > 0 ? rankedHandName : ''}
                isFolded={me?.playerState.tag === PlayerStates.Folded.tag}
              />
            </View>
          )}
          {/* Player Actions */}
          {myHoleCards.length > 0 && (
            <PlayerActions
              actions={store.session.hasUserPreFold ? [] : actions}
              // disabled={!isMyTurn}
              disabled={store.session.hasUserTappedOnAction || !isMyTurn || store.session?.isGamePaused}
              doAllIn={doAllIn}
              doCall={doCall}
              doCheck={doCheck}
              doFold={doFold}
              doRaise={doBet}
              isMyTurn={isMyTurn}
              isGamePaused={store.session?.isGamePaused}
              hasUserTappedOnAction={store.session.hasUserTappedOnAction}
              onPreFold={() => store.session.setHasUserPreFold(!store.session.hasUserPreFold)}
              isMyPlayerOutOrFolded={store.session.isMyPlayerFoldedOrWaiting}
              hasUserPreFolded={store.session.hasUserPreFold}
              isShowdownBegin={store.session.isShowdown}
              shouldPrefoldButtonVisible={store.session.shouldPrefoldButtonVisible}
              playerStatus={store.session.myPlayingStatus}
              shouldHighlightCurrentPlayer={store.session.shouldHighlightCurrentPlayer}
            />
          )}
        </View>
      )}
    </React.Fragment>
  )
}
