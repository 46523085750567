/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { RootStoreType } from "./index"


/**
 * InputPlayerStatsBase
 * auto generated base class for the model InputPlayerStatsModel.
 */
export const InputPlayerStatsModelBase = ModelBase
  .named('InputPlayerStats')
  .props({
    __typename: types.optional(types.literal("InputPlayerStats"), "InputPlayerStats"),
    checkpoint: types.union(types.undefined, types.number),
    chips: types.union(types.undefined, types.number),
    isLeft: types.union(types.undefined, types.null, types.boolean),
    playerId: types.union(types.undefined, types.string),
    playerName: types.union(types.undefined, types.string),
    playerOutTime: types.union(types.undefined, types.number),
    stack: types.union(types.undefined, types.number),
    userId: types.union(types.undefined, types.late((): any => UserModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class InputPlayerStatsModelSelector extends QueryBuilder {
  get checkpoint() { return this.__attr(`checkpoint`) }
  get chips() { return this.__attr(`chips`) }
  get isLeft() { return this.__attr(`isLeft`) }
  get playerId() { return this.__attr(`playerId`) }
  get playerName() { return this.__attr(`playerName`) }
  get playerOutTime() { return this.__attr(`playerOutTime`) }
  get stack() { return this.__attr(`stack`) }
  userId(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`userId`, UserModelSelector, builder) }
}
export function selectFromInputPlayerStats() {
  return new InputPlayerStatsModelSelector()
}

export const inputPlayerStatsModelPrimitives = selectFromInputPlayerStats().checkpoint.chips.isLeft.playerId.playerName.playerOutTime.stack
