import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import { ProgressBar } from 'react-native-paper'

interface CustomProgressBarProps {
  progress: number
  max: number
  color?: string
}

export const CustomProgressBar = ({ progress, max, color = '#FFFFFF' }: CustomProgressBarProps) => {
  const progressToPercent = () => {
    return parseFloat((progress / max).toFixed(2))
  }

  return (
    <View style={{ width: '100%' }}>
      <Text style={{ color, paddingVertical: 5 }}>
        {progress} / {max}
      </Text>
      <ProgressBar style={{ borderRadius: 5 }} progress={progressToPercent()} color={color} />
    </View>
  )
}
