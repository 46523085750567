import * as React from 'react'

import { storiesOf } from '@storybook/react-native'

import { Story, StoryScreen, UseCase } from '../../../storybook/views'
import { Button } from './button'

storiesOf('Button', module)
  .addDecorator((fn) => <StoryScreen>{fn()}</StoryScreen>)
  .add('Button Presets', () => (
    <Story>
      {/* <UseCase text="Primary" usage="Apple sign in.">
      <View style={{ padding: 10, backgroundColor: "gray" }}>
      <AppleButton
        buttonStyle={AppleButton.Style.WHITE}
        buttonType={AppleButton.Type.SIGN_IN}
        style={{
        marginVertical: 20,
        width: scale(335),
        height: 50,
        }}
        // onPress={() => navigation.navigate('home')}
        // onPress={debounce(onAppleButtonPress, 250)}
    />
    </View>
      </UseCase> */}
      <UseCase text="Social sign in" usage="Social sign in with Google.">
        <Button name="Sign in with Google" icon="google" onPress={() => console.log('Sign in with Google')} />
      </UseCase>
      <UseCase text="Friend Game" usage="Create table and Invite Friends.">
        <Button onPress={() => console.log('Create table and Invite Friends.')} type="primary" icon="share" />
      </UseCase>
    </Story>
  ))
