import React, { useState, useEffect } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { SecondaryButton } from '../SecondaryButton/SecondaryButton'
import { Option, CARDS } from '../../screens'
import { CardList } from '../../components'

import { cardsToSelect } from '../../screens'

interface OptionsButtonsProps {
  options: Option[]
  selectedAnswer: Option | undefined
  onSelectAnswer: (option: Option) => void
  highlightedCards?: CARDS[]
  selectedCards?: CARDS[]
  title?: string
  selectableCards?: boolean
  onSelectCard?: (card: CARDS) => void
}

export const CardsOptionsButtons = ({
  options,
  selectedAnswer,
  onSelectAnswer,
  highlightedCards,
  selectedCards,
  title,
  selectableCards = false,
  onSelectCard,
}: OptionsButtonsProps) => {
  const multipleOptions = () => {
    return options.length > 1
  }

  const disableButton = () => {
    return !!selectedAnswer || (selectableCards && selectedCards && selectedCards?.length < cardsToSelect)
  }

  return (
    <View>
      <Text style={{ color: 'white', textAlign: 'center' }}>{title}</Text>

      <View style={styles.optionsContainer}>
        {options.map((option: any, index: number) => (
          <View style={[multipleOptions() ? styles.optionContainer : styles.singleOptionContainer]}>
            <View style={multipleOptions() && index % 2 === 0 && styles.withSeparator}>
              {multipleOptions() && index % 2 === 0 && (
                <View style={styles.separator}>
                  <Text style={styles.separatorText}>OR</Text>
                </View>
              )}
              <CardList
                cards={option.cards}
                highlightedCards={highlightedCards}
                selectedCards={selectedCards}
                selectable={selectableCards}
                onSelectCard={onSelectCard}
              />
            </View>
            <SecondaryButton
              outline={!selectedAnswer || selectedAnswer.id !== option.id}
              disabled={disableButton()}
              rounded
              blue
              textProps={{ text: option.text }}
              onPress={() => onSelectAnswer(option)}
              style={multipleOptions() ? styles.button : styles.singleOptionButton}
            />
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  optionContainer: {
    width: '50%',
    alignItems: 'center',
  },
  singleOptionContainer: {
    width: '100%',
    alignItems: 'center',
  },
  button: { marginTop: 10, width: '70%' },
  singleOptionButton: { marginTop: 10, width: '100%' },

  withSeparator: {
    borderRightColor: '#C4C4C4',
    borderRightWidth: 1,
    position: 'relative',
  },
  separator: { position: 'absolute', right: 0, top: '50%', transform: [{ translateY: -10 }, { translateX: 10 }] },
  separatorText: { color: 'white', backgroundColor: '#06102B', paddingVertical: 3 },
})
