'use strict'
import { Dimensions, StyleSheet } from 'react-native'

import { scale, verticalScale } from '../../styles/sizes'
import { color } from '../../theme'

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
    position: 'absolute',
  },
  modalView: {
    flex: 1,
  },
  headingText: {
    fontSize: 20,
  },
  modalTopIcon: { height: 12, width: 12 },
  modalBody: { paddingVertical: 30 },
  modalBodyHeading: {
    fontSize: 34,
    color: color.palette.HighSlateBlue,
    fontWeight: 'bold',
    lineHeight: 41,
    textAlign: 'center',
  },
  modalBodyTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
    color: color.palette.HighSlateBlue,
    textAlign: 'center',
    opacity: 0.7,
  },
  modalFooterButton: {
    backgroundColor: color.palette.orange,
    width: scale(250),
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  modalFooterButtonView: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  modalFooterButtonText: {
    fontSize: 16,
    color: color.palette.white,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 19,
    paddingHorizontal: 13,
  },
  modalFooterButtonIcon: { height: 20, width: 20 },
  wonBg: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wonText: {
    // lineHeight: 44,
    fontFamily: 'SFProDisplay-Black',
    fontSize: 34,
    color: '#FFF',
    letterSpacing: 0,
    // fontWeight: 'bold',
    textAlign: 'center',
    paddingHorizontal: 22,
  },
  allIn: {
    width: '75%',
    height: '75%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  winningText: {
    fontSize: 34,
    color: '#FFF',
    fontFamily: 'SFProDisplay-Black',
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingBottom: 20,
    paddingHorizontal: 22,
  },
  winningView: { flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 0 },
  actionView: { flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 0 },
  actionText: {
    fontSize: 34,
    color: '#FFF',
    letterSpacing: 5,
    fontFamily: 'HeroNew-ExtraBoldItalic',
    fontWeight: 'bold',
    fontStyle: 'italic',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  allInText: {
    fontSize: 34,
    color: '#fff',
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'HeroNew-ExtraBoldItalic',
    textShadowColor: '#00C2FF90',
    textShadowOffset: { width: 8, height: 3 },
    textShadowRadius: 5,
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 14,
    padding: 2,
  },
  modal: {
    flex: 1,
    backgroundColor: '#232335', 
  },
  skipButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  modelContentContainer: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
