import Constants from 'expo-constants'
import { ClientOptions } from 'graphql-ws'
import { get, isEmpty } from 'lodash'
// back out of mobx proxies, which aren't supported in React Native
import { configure } from 'mobx'
import { Instance, onSnapshot, SnapshotOut, types } from 'mobx-state-tree'
import { createHttpClient } from 'mst-gql'
import { SubscriptionClient as LocalSubscriptionClient } from 'subscriptions-transport-ws'

import { STAGE as EnvStage } from '@env'

import { emitter, LogoutEvent } from '../utils/events'
import * as Storage from '../utils/storage'
import { RootStore as APIRootStore } from './api-store'
import {
    ENV_STORAGE_KEY, EnvironmentStages, EnvironmentStore
} from './environment/environment.store'
import { globalConnectionParams } from './GlobalConnectionParams'
import { NetworkStore } from './network/network.store'
import { SubscriptionClient } from './network/SubscriptionClient'
import { PermissionsStore } from './permissions'
import { SessionStore } from './session/session.store'
import { TelemetryStore } from './telemetry'

configure({ useProxies: 'never', enforceActions: 'never' })

export const isDev = __DEV__ && !Constants.isDevice

export const isLatestSubscriptionClient = (x: any): x is SubscriptionClient => {
  return x.hasOwnProperty('readyState')
}

export const RootStoreModel = types.model('RootStore').props({
  api: types.optional(APIRootStore, {}),
  environment: types.optional(EnvironmentStore, {}),
  telemetry: types.optional(TelemetryStore, {}),
  network: types.optional(NetworkStore, {}),
  permissions: types.optional(PermissionsStore, {}),
  session: types.optional(SessionStore, {}),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>

let rootStore: RootStore | null = null

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = 'root'

export const getDefaultStore = async () => {
  if (rootStore === null) {
    // load env from storage
    let EnvStorage = await Storage.load(ENV_STORAGE_KEY)
    EnvStorage = isEmpty(EnvStorage) ? null : EnvStorage
    const enumStage = EnvStage as keyof typeof EnvironmentStages
    const stage: EnvironmentStages = enumStage ? enumStage : EnvStorage ? EnvStorage : EnvironmentStages.dev
    // const stage: EnvironmentStages = EnvironmentStages.prod
    // const stage: EnvironmentStages = EnvironmentStages.dev
    // const stage: EnvironmentStages = EnvironmentStages.local
    // console.log('root.store process.env', process.env)
    console.log('root.store EnvStorage', EnvStorage)
    console.log('root.store enumStage', enumStage)
    console.log('root.store stage', stage)

    const baseUrls: Record<EnvironmentStages, string> = {
      [EnvironmentStages.local]: 'http://localhost:3000',
      [EnvironmentStages.dev]: 'https://api-dev.ppwr.io',
      [EnvironmentStages.prod]: 'https://api.ppwr.io',
      //[EnvironmentStages.dev]: 'https://api-dev.pokerpowherapp.com',
      // [EnvironmentStages.prod]: 'https://8wph3n5zrd.execute-api.us-east-1.amazonaws.com/prod',
      // [EnvironmentStages.prod]: 'https://api.pokerpowherapp.com',
    }

    const baseWebsocketUrls: Record<EnvironmentStages, string> = {
      [EnvironmentStages.local]: 'ws://localhost:3000/graphql',
      [EnvironmentStages.dev]: 'wss://gxhwpy6a88.execute-api.us-east-1.amazonaws.com/dev',
      [EnvironmentStages.prod]: 'wss://0go5iwalbd.execute-api.us-east-1.amazonaws.com/prod',
    }

    const baseURL = baseUrls[stage]
    console.log('root.store baseURL', baseURL)

    const baseWebSocketURL = baseWebsocketUrls[stage]
    console.log('root.store baseWebSocketURL', baseWebSocketURL)

    const subscriptionClientOptions: ClientOptions = {
      // ClientOptions:
      //   https://github.com/apollographql/subscriptions-transport-ws

      // how long the client should wait in ms for a keep-alive message from the server (default 30000 ms),
      // this parameter is ignored if the server does not send keep-alive messages.
      // This will also be used to calculate the max connection time per connect/reconnect
      // timeout: 5000,

      // object that will be available as first argument of onConnect (in server side), if passed a function
      // - it will call it and send the return value, if function returns as promise
      // - it will wait until it resolves and send the resolved value.
      connectionParams: () => {
        console.log('root.store connectionParams', globalConnectionParams)
        const { token } = globalConnectionParams
        const headers = token ? { authorization: `bearer ${token}` } : {}
        console.log('root.store connectionParams headers', headers)
        return { headers }
      },

      on: {
        connected: (event: unknown) => {
          console.log('root.store connected', event)
        },
        closed: (event) => {
          console.log('root.store closed', event)
          // if closed with the `4403: Forbidden` close event
          // the client or the server is communicating that the token
          // is no longer valid and should be therefore refreshed
          // if (event.code === 4403) shouldRefreshToken = true
        },
        error: (event) => {
          console.log('root.store error', event)
        },
      },

      // how long the client should wait in ms, when there are no active subscriptions,
      // before disconnecting from the server. Set to 0 to disable this behavior. (default 0)
      // inactivityTimeout: 0,
      // reconnect: true,
      retryAttempts: 10,
      shouldRetry: () => true,
      lazy: true,
      // connectionCallback: (errors: Error[], result?: any) => {
      //   if (errors) {
      //     console.error('root.store connectionCallback error', errors)
      //     return
      //   }
      //   console.log('root.store connectionCallback result', result)
      // },
      url: baseWebSocketURL,
    }

    const env = {
      baseURL,
      baseWebSocketURL,
      stage,
      gqlHttpClient: createHttpClient(`${baseURL}/graphql`),
      //   const customFetch = (uri, options) => {
      //   const { header } = Hawk.client.header(
      //     "http://example.com:8000/resource/1?b=1&a=2",
      //     "POST",
      //     { credentials: credentials, ext: "some-app-data" }
      //   );
      //   options.headers.Authorization = header;
      //   return fetch(uri, options);
      // };

      // const link = new HttpLink({ fetch: customFetch });
      // gqlHttpClient: createHttpClient(`${baseURL}/graphql`, { fetch: customFetch }),
      // @NOTE: This is an Anthony hack to make GraphQL subscriptions work locally until NestJs 9.0 is released.
      // @TODO: One day NestJs + Apollo 3.0 + Graphql-Ws will come to life and we can consolidate this to the latest graphql-ws
      gqlWsClient:
        stage === EnvironmentStages.local
          ? new LocalSubscriptionClient(baseWebSocketURL, {
              reconnect: true,
            })
          : new SubscriptionClient(subscriptionClientOptions),
      socket: WebSocket,
    }

    // add middleware to inject session
    // env.gqlWsClient.use([
    //   {
    //     applyMiddleware(opts, next) {
    //       console.log('applyMiddleware opts', opts)
    //       // opts.context = {
    //       //   token: __super_secret_token__,
    //       // }
    //       next()
    //     },
    //   },
    // ])
    try {
      // load data from storage
      const data = await Storage.load(ROOT_STATE_STORAGE_KEY)
      // console.log('loaded data from storage', data, RootStoreModel.is(data))
      if (RootStoreModel.is(data)) {
        // @ts-ignore
        rootStore = RootStoreModel.create(data, env)
      } else {
        rootStore = RootStoreModel.create({}, env)
      }
    } catch (error) {
      // if there's any problems loading, then let's at least fallback to an empty state
      // instead of crashing.
      rootStore = RootStoreModel.create({}, env)
      // but please inform us what happened
      console.error('unable to load storage from cache', { error })
    }

    if (rootStore) {
      console.log('setup set stage', rootStore.environment.setStage(stage))
      // track changes & save to storage
      console.log('setup onSnapshot', !!rootStore)
      onSnapshot(rootStore, (snapshot) => {
        // console.log('snap! onSnapshot')
        Storage.save(ROOT_STATE_STORAGE_KEY, snapshot)
      })
    }

    const originalFetch = global.fetch
    // @ts-ignore
    global.fetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
      // console.log('fetch middleware', input)
      // array.push.apply(array, elements)
      return originalFetch(input, init).then(async (response) => {
        const json = await response.json()
        const statusCode = get(json, 'errors[0].extensions.exception.status', response.status)
        // console.log('fetch middleware response.status', statusCode)
        if (statusCode === 403) {
          console.log('fetch middleware 403', statusCode)
          emitter.emit(LogoutEvent)
          rootStore?.session.logout()
        }
        return { ...response, json: () => Promise.resolve(json) }
      })
    }

    // console.log('setup onPatch', !!rootStore)
    // onPatch(rootStore, (snapshot) => {
    // console.log('snap! onPatch')
    // Storage.save(ROOT_STATE_STORAGE_KEY, snapshot)
    // })
    // onPatch(rootStore.session, (snapshot) => {
    // console.log('snap! onPatch')
    // Storage.save(ROOT_STATE_STORAGE_KEY, snapshot)
    // })
  }

  return rootStore
}
