import React from 'react'
import { Text, ViewProps, View } from 'react-native'
import { Question } from '../../screens'

interface GameDescriptionProps {
  question: Question
  style?: ViewProps['style']
}

export const GameDescription = ({ question, style }: GameDescriptionProps) => {
  return (
    <View>
      <Text style={[{ color: 'white', textAlign: 'center', fontWeight: 'bold' }, style]}>{question.question}</Text>
      {question.caption && (
        <Text style={[{ color: 'white', textAlign: 'center', paddingTop: 5 }, style]}>{question.caption}</Text>
      )}
    </View>
  )
}
