/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your PrimaryNavigator) which the user
 * will use once logged in.
 */
import React from 'react'
import { Linking } from 'react-native'

import {
    LinkingOptions, NavigationContainer, NavigationContainerRef
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import { AuthNavigator } from './auth-navigator'
import { MainNavigator } from './main-navigator'
import { Toast } from './toast'

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * We recommend using MobX-State-Tree store(s) to handle state rather than navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */

enum RootRoutes {
  auth = 'auth',
  main = 'main',
}
export type RootParamList = Record<keyof typeof RootRoutes, undefined>

const Stack = createStackNavigator<RootParamList>()

const RootStack = () => {
  const initialRouteName = RootRoutes.auth
  return (
    <>
      <Stack.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          headerMode: 'float',
          headerStyle: { borderBottomWidth: 0, backgroundColor: 'transparent' },
        }}
      >
        <Stack.Screen name={RootRoutes.auth} component={AuthNavigator} />
        <Stack.Screen name={RootRoutes.main} component={MainNavigator} />
      </Stack.Navigator>
      <Toast />
    </>
  )
}

const linking: LinkingOptions = {
  prefixes: [
    'pokerpowher://',
    'https://games-dev.pokerpowherapp.com',
    'https://games.pokerpowherapp.com',
    'https://dev.ppwr.io',
    'https://ppwr.io',
  ],
  config: {
    screens: {
      home: {
        screens: {
          join: {
            path: 'join/:code',
            exact: true,
          },
        },
      },
    },
  },
  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL()

    console.log('getInitialURL', url)
    // if (url != null) {
    //   return url
    // }

    // Check if there is an initial firebase notification.
    // const message = await messaging().getInitialNotification()

    // Get the `url` property from the notification which corresponds to a screen
    // This property needs to be set on the notification payload when sending it
    // return message?.notification.url
    return url
  },
}

export const RootNavigator = React.forwardRef<
  NavigationContainerRef,
  Partial<React.ComponentProps<typeof NavigationContainer>>
>((props, ref) => {
  return (
    <NavigationContainer {...props} linking={linking} ref={ref}>
      <RootStack />
    </NavigationContainer>
  )
})

RootNavigator.displayName = 'RootNavigator'
