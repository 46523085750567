import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

import { useStore } from '../../models'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { Text } from '../text/text'

const initialMinutes = Math.abs(Math.floor(0 / 60))
  .toString()
  .padStart(2, '0')
const initialSecond = Math.floor(0 % 60)
  .toString()
  .padStart(2, '0')

// export interface BlindProps {
//   currentSmallBlind: string
//   currentBigBlind: string
//   nextSmallBlind: string
//   nextBigBlind: string
//   customContainerStyle?: StyleProp<ViewStyle>
//   isGameInProgress: boolean
//   remainingTime: number
//   gameStartTimestamp: number
//   blindLevel: number
//   onStart: () => void
//   resetTimer: () => void
//   appState: string
//   onFirstTime: () => void
//   blindTime: number
// }

const styles = StyleSheet.create({
  blindText: {
    color: 'white',
    fontSize: moderateScale(13),
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    alignItems: 'center',
  },
  separator: {
    width: 3,
    height: 16,
    backgroundColor: '#3F5FC3',
  },
})

export const Blinds = observer(() => {
  const store = useStore()
  const gameStartTimestamp = store.session.gameStartTime
  const blindLevel = store.session.blindLevel
  const appState = store.session.appState
  const resetTimer = () => store.session.resetTimer()
  const remainingTime = store.session.remainingTime
  const onFirstTime = () => store.session.setIsFirstTime(true)
  const onStart = () => store.session.startBlindTimer()
  const blindTime = store.session.blindTime
  const isGameInProgress = store.session.isPlayingGame
  const customContainerStyle = { marginTop: 2, marginBottom: -10 }
  const currentSmallBlind =
    store.session.currentBlind.small >= 1000
      ? `${(store.session.currentBlind.small / 1000).toFixed(1)}k`
      : `${store.session.currentBlind.small}`
  const currentBigBlind =
    store.session.currentBlind.big >= 1000
      ? `${(store.session.currentBlind.big / 1000).toFixed(1)}k`
      : `${store.session.currentBlind.big}`
  const nextSmallBlind =
    store.session.nextBlind.small >= 1000
      ? `${(store.session.nextBlind.small / 1000).toFixed(1)}k`
      : `${store.session.nextBlind.small}`
  const nextBigBlind =
    store.session.nextBlind.big >= 1000
      ? `${(store.session.nextBlind.big / 1000).toFixed(1)}k`
      : `${store.session.nextBlind.big}`

  React.useEffect(() => {
    onFirstTime()
  }, [])

  React.useEffect(() => {
    if (isGameInProgress && blindLevel >= 0 && appState === 'active') {
      onStart()
    } else {
      resetTimer()
    }
    return () => {
      resetTimer()
    }
  }, [isGameInProgress, appState])

  return (
    <View style={[styles.container, customContainerStyle]}>
      <Text tx="blinds.blinds" style={[styles.blindText, { fontFamily: fontFamily.sfProDisplayBold }]} />
      <View style={styles.separator} />

      <Text
        tx="blinds.time"
        txOptions={
          Number(nextSmallBlind) !== 0
            ? {
                minutes:
                  remainingTime < blindTime
                    ? Math.abs(Math.floor(remainingTime / 60))
                        .toString()
                        .padStart(2, '0')
                    : initialMinutes,
                seconds:
                  remainingTime < blindTime
                    ? Math.floor(remainingTime % 60)
                        .toString()
                        .padStart(2, '0')
                    : initialSecond,
              }
            : {
                minutes: '--',
                seconds: '--',
              }
        }
        style={[styles.blindText, { fontFamily: fontFamily.sfProDisplayHeavy }]}
      />
      <View style={styles.separator} />
      <View style={{ flexDirection: 'row' }}>
        <Text tx="blinds.current" style={styles.blindText} />
        <Text
          tx="blinds.currentBlindValues"
          txOptions={{
            smallBlind: Number(currentSmallBlind) === 0 ? '--' : currentSmallBlind,
            bigBlind: Number(currentBigBlind) === 0 ? '--' : currentBigBlind,
          }}
          style={[styles.blindText, { fontFamily: fontFamily.sfProDisplayHeavy }]}
        />
      </View>
      <View style={styles.separator} />
      <View style={{ flexDirection: 'row' }}>
        <Text tx="blinds.next" style={styles.blindText} />
        <Text
          tx="blinds.nextBlindValues"
          txOptions={{
            smallBlind: Number(nextSmallBlind) !== 0 ? nextSmallBlind : '--',
            bigBlind: Number(nextBigBlind) !== 0 ? nextBigBlind : '--',
          }}
          style={[styles.blindText, { fontFamily: fontFamily.sfProDisplayHeavy }]}
        />
      </View>
    </View>
  )
})
