import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Animated, Pressable, View } from 'react-native'
import { GameState, PlayerState } from '@poker-powher/poker'
import { PlayerType } from '../../models/session/session-game'
import { styles } from './styles'
import { PlayerRole } from './PlayerRoleBadge'
import { scale } from '../../styles/sizes'
import { Cell } from './Cell'

export const PlayerCellWithoutTable = (props: {
  currentPlayerIndex: number
  index: number
  totalPlayers: number
  onPress: () => void
  isShowdown: boolean
  shouldHighlightCurrentPlayer: boolean
  isWaitlisted: boolean
  isFolded: boolean
  setCellSize: Function
  playerId: string
  cellSize: number[]
  imageHeight: Animated.Value
  avatar: JSX.Element
  channelName?: string
  hasLocalUserJoinedVideo: boolean
  hasLocalUserHiddenVideo: boolean
  localUserId?: string
  mappedIndex: number
  name: string
  bigBlindIndex: number
  smallBlindIndex: number
  dealerIndex: number
  playerState: PlayerState
  isTableVisible: boolean
  isLeft?: boolean
  player: PlayerType
  gameState: GameState
  agoraUserList: { uid: number; videoMuteState: boolean }[]
  hasLocalUserMutedVideo: boolean
  isPlayerMutingVideoCall: boolean
  showOptionModal: () => void
  cards: string[]
  onTimeoutAction: () => void
  useFourColoredCards: boolean
  showCardsForAllin: boolean
  isGamePaused: boolean
  isGameBegin: boolean
  isMyTurn: boolean
  setUserForTeacher: (userId: string) => void
  isTeacher: boolean
  isCardVisible: boolean
  secondsPassed: number
  role: string
  actionTime: number
  startingImageHeight: number
  actionDurationLeft: number
  hasUserTappedOnAction: boolean
  playerVideoMuteState: boolean
  userId: string
  isVideoScreenVisible: boolean
  lastAction: string
  playerRole: PlayerRole
  chips: number
  isPlayerOnVideoCall: boolean
  cardViewHeight: number
}) => {
  const colors =
    props.currentPlayerIndex === props.index &&
    !props.isShowdown &&
    props.shouldHighlightCurrentPlayer &&
    !props.isWaitlisted &&
    !props.isFolded
      ? ['#0CE0FF', '#DD2AFF']
      : ['#05102d', '#05102d']

  return (
    <View
      key={`player-cell-${props.index}`}
      style={[
        props.totalPlayers === 1 ? { height: '100%', flex: 1 } : null,
        styles.cardMain,
        { overflow: 'hidden' },
        props.totalPlayers === 2
          ? { height: '50%', width: '100%' }
          : {
              height:
                100 / ((props.totalPlayers % 2 === 0 ? props.totalPlayers : props.totalPlayers + 1) / 2) - 0.2 + '%',
              width: '50%',
            },
      ]}
    >
      <Pressable onPress={props.onPress} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <LinearGradient
          colors={colors}
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 1.0, y: 1.0 }}
          style={[
            {
              width: '100%',
              height: '100%',
              padding: props.currentPlayerIndex === props.index && !props.isShowdown ? scale(5) : scale(2),
              borderRadius: 6,
            },
          ]}
          onLayout={(e) => {
            const height = e.nativeEvent.layout.height - 2
            const width = e.nativeEvent.layout.width
            props.setCellSize([width, height])
          }}
        >
          <Cell {...props} />
        </LinearGradient>
      </Pressable>
    </View>
  )
}
