import * as React from 'react'
import { Platform, TextStyle, View, ViewStyle, PlatformIOSStatic } from 'react-native'

import { PlayerStates } from '@poker-powher/poker'

import { translate } from '../../i18n'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'
import { Button } from '../button/button'

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

enum ActionHint {
  BLANK = '',
  FOLD = 'FOLD',
  CHECK = 'CHECK',
  BET = 'BET',
  RAISE = 'RAISE',
  CALL = 'CALL',
  ALLIN = 'ALLIN',
}

export interface PlayerActionsProps {
  actions: ActionHint[]
  doAllIn?: () => void
  doCall?: () => void
  doCheck?: () => void
  doRaise?: () => void
  doFold?: () => void
  disabled: boolean
  isMyTurn: boolean
  isGamePaused: boolean
  hasUserTappedOnAction: boolean
  onPreFold: () => void
  isMyPlayerOutOrFolded: boolean
  hasUserPreFolded: boolean
  isShowdownBegin: boolean
  shouldPrefoldButtonVisible: boolean
  playerStatus: string
  shouldHighlightCurrentPlayer: boolean
}

export const PlayerActions = React.memo((props: PlayerActionsProps) => {
  const {
    actions,
    doAllIn,
    doCall,
    doCheck,
    doRaise,
    doFold,
    disabled,
    isMyTurn,
    isGamePaused,
    hasUserTappedOnAction,
    onPreFold,
    isMyPlayerOutOrFolded,
    hasUserPreFolded,
    isShowdownBegin,
    shouldPrefoldButtonVisible,
    playerStatus,
    shouldHighlightCurrentPlayer,
  } = props

  const storyBtnStyle: ViewStyle = {
    width: 'auto',
    height: moderateScale(44),
    paddingHorizontal: 10,
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 2,
    shadowColor: 'lightgray',
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.7,
    shadowRadius: 2,
    elevation: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 0,
    marginTop: 4,
  }
  const textStyle: TextStyle = {
    color: '#232335',
    fontSize: moderateScale(18),
    fontWeight: '900',
    textAlign: 'center',
    fontFamily: fontFamily.heroNewExtraBold,
  }
  const colorCode = '#FFF'

  const btnStyle: ViewStyle = {
    width: isIPad ? moderateScale(120) : moderateScale(151),
    height: moderateScale(36),
    borderRadius: 2,
    shadowColor: '#DADADA',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    elevation: 1,
    display: 'flex',
    // flex: 1, // button height issues? Delete this line to let height value override it.
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    marginVertical: 0,
    marginTop: 4,
    opacity: disabled ? 0.2 : 1,
  }

  const buttonView: ViewStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: Platform.OS === 'web' ? 'flex-start' : 'flex-end',
    alignItems: 'center',
    height: '100%',
  }

  const extendedStyle: ViewStyle = { ...btnStyle, opacity: 11 }

  const style = Platform.OS === 'web' ? storyBtnStyle : btnStyle
  const onPress = {
    [ActionHint.BLANK]: () => {
      return
    },
    [ActionHint.BET]: doRaise,
    [ActionHint.FOLD]: doFold,
    [ActionHint.RAISE]: doRaise,
    [ActionHint.CALL]: doCall,
    [ActionHint.CHECK]: doCheck,
    [ActionHint.ALLIN]: doAllIn,
  }

  if (
    [
      PlayerStates.AllIn.tag.toString(),
      PlayerStates.Waiting.tag.toString(),
      PlayerStates.Out.tag.toString(),
      PlayerStates.Folded.tag.toString(),
      PlayerStates.Observer.tag.toString(),
      PlayerStates.ForeverOut.tag.toString(),
    ].includes(playerStatus) ||
    (!shouldHighlightCurrentPlayer && actions.length === 0)
  ) {
    return <View />
  }

  if (!isMyTurn && !isGamePaused && !hasUserTappedOnAction && shouldPrefoldButtonVisible) {
    return (
      <Button
        key={`action-fold`}
        colorCode={colorCode}
        textStyle={textStyle}
        btnStyle={isMyPlayerOutOrFolded || isShowdownBegin ? style : extendedStyle}
        name={hasUserPreFolded ? translate(`playerActionHints.UNDOFOLD`) : translate(`playerActionHints.PREFOLD`)}
        onPress={onPreFold}
        type={ActionHint.FOLD.toLowerCase()}
        disabled={isMyTurn || isGamePaused || isMyPlayerOutOrFolded || isShowdownBegin}
      />
    )
  }

  return (
    <View style={isIPad ? { flexDirection: 'row' } : buttonView}>
      {actions &&
        actions.length > 0 &&
        actions
          .slice(0, 3)
          .map((action: ActionHint, index) => (
            <Button
              key={`action-${index}`}
              colorCode={action === ActionHint.BLANK ? 'transparent' : colorCode}
              textStyle={textStyle}
              btnStyle={style}
              name={translate(`playerActionHints.${action}`)}
              onPress={onPress[action]}
              type={action.toLowerCase()}
              disabled={disabled || isMyPlayerOutOrFolded}
            />
          ))}
      {actions && (actions.length === 2 || actions.length === 0) && !actions.includes(ActionHint.FOLD) && (
        <Button
          key={`action-3`}
          colorCode={actions.length === 0 ? 'transparent' : colorCode}
          textStyle={textStyle}
          btnStyle={{ ...btnStyle, opacity: 0.2 }}
          name={translate(actions.length === 0 ? 'playerActionHints.BLANK' : 'playerActionHints.FOLD')}
          type={ActionHint.ALLIN.toLowerCase()}
          disabled={true}
        />
      )}
    </View>
  )
})
