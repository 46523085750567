/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameModesEnumType } from "./GameModesEnum"
import { RootStoreType } from "./index"


/**
 * ShareBase
 * auto generated base class for the model ShareModel.
 */
export const ShareModelBase = ModelBase
  .named('Share')
  .props({
    __typename: types.optional(types.literal("Share"), "Share"),
    _id: types.identifier,
    channelName: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    firstName: types.union(types.undefined, types.string),
    gameMode: types.union(types.undefined, GameModesEnumType),
    hash: types.union(types.undefined, types.string),
    message: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    url: types.union(types.undefined, types.string),
    userId: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ShareModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get channelName() { return this.__attr(`channelName`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get firstName() { return this.__attr(`firstName`) }
  get gameMode() { return this.__attr(`gameMode`) }
  get hash() { return this.__attr(`hash`) }
  get message() { return this.__attr(`message`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get url() { return this.__attr(`url`) }
  get userId() { return this.__attr(`userId`) }
}
export function selectFromShare() {
  return new ShareModelSelector()
}

export const shareModelPrimitives = selectFromShare()._id.channelName.createdAt.firstName.gameMode.hash.message.updatedAt.url.userId
