import 'react-native-get-random-values'
import { LinearGradient } from 'expo-linear-gradient'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Keyboard, Platform, Pressable, SafeAreaView, TextInput, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { GameModes } from '@poker-powher/poker'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Text } from '../../components'
import { useStore } from '../../models'
import { events } from '../../models/session/analytics/analytics-events'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import { styles } from './styles'
import { debounce } from 'lodash'
import { ActionTimeDropdown } from './action-time-dropdown'

const pushFromTopAmount = Platform.OS === 'ios' ? 0 : 80

export const CreateTournamentScreen = observer(() => {
  const navigation = useNavigation()
  const store = useStore()
  const isFocused = useIsFocused()

  const [tournamentName, setTournamentName] = React.useState('')
  const [tournamentBlindsLength, setTournamentBlindsLength] = React.useState('')
  const [tournamentStartingChips, setTournamentStartingChips] = React.useState('')
  const [tournamentActionTime, setTournamenActionTime] = React.useState('')
  const [errorMsg, setErrorMsg] = React.useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTournamentName('')
    setTournamentBlindsLength('')
    setTournamentStartingChips('')
    setTournamenActionTime('')
    setErrorMsg('')
  }, [isFocused])

  const onShare = async () => {
    const userId = store.session.user?._id.toString()
    if (Platform.OS !== 'web') {
      store.session.setEvent(events.game.gameCreated, {
        playerName: store.session.user?.name,
        joinCode: store.session.share?.hash,
        userId,
      })
    }
    try {
      const userId = store.session.user?._id
      // await store.session.createChannel()
      store.session.clearChannel()
      store.session.clearGame()
      // const gameId = myUserId
      const gameId = nanoid()
      await store.session.createShare(gameId, GameModes.Tournament.tag)
      //if not shared or chooses any option other than copy in ios or any action from share modal the next line wont get executed if using await
      //else execute gotoGameBefore prompting share model

      const shareUserId = store.session.share?.userId ?? 'share'
      const hasChannel = !!store.session.channel?.channelName
      const isHost = !hasChannel && userId === shareUserId
      console.log('setupGame GameScreen.useEffect hasChannel', hasChannel)
      console.log('setupGame GameScreen.useEffect isHost', isHost)
      // if the host is creating the game and the game has not begun (host didn't re-join or close/reopen the app)
      if (isHost && store.session.game === undefined) {
        store.session.setIsTournamentModeOn(true) // Need gameKind before creating game.
        console.log('setupGame GameScreen.useEffect creating channel and game...', isHost, userId, shareUserId)
        // store.session.clearMessages()
        await Promise.all([store.session.createChannel(gameId), store.session.createGame(gameId)])
          .then(async () => {
            // gotoGame()
            let actionTime = tournamentActionTime.replace(' sec', '')
            await store.session.createTournament(
              tournamentName,
              tournamentBlindsLength.length > 0 ? Number(tournamentBlindsLength) : undefined,
              actionTime.length > 0 ? Number(actionTime) : undefined,
              tournamentStartingChips.length > 0 ? Number(tournamentStartingChips) : undefined
            )
            // if (store.session.isHost || store.session.isTeacher) {
            //   store.session.promptShare()
            // }
          })
          .catch((error) => console.error('setupGame GameScreen.useEffect isHost', error))
        console.log('setupGame GameScreen.useEffect channel and game created')
      }
    } catch (error) {
      console.error('onShare', error)
      setLoading(false)
      store.session.logout()
    }
  }

  const createActiveTournament = async () => {
    try {
      await onShare()
      setTimeout(() => {
        setLoading(false)
        navigation.navigate(DrawerRoutes.activeGames)
      }, 1000)
    } catch (error) {
      console.log('Error in creating tournament', error)
      setLoading(false)
    }
  }

  return (
    <SafeAreaView style={[styles.container, { paddingTop: pushFromTopAmount }]}>
      <Pressable style={styles.container} onPress={() => Keyboard.dismiss()}>
        <View style={{ marginHorizontal: 41 }}>
          <Text tx="createTournamentScreen.heading" style={styles.heading}></Text>
          <Text tx="createTournamentScreen.name" style={styles.nameHeading} />
          <TextInput
            placeholder="Test"
            placeholderTextColor={'#e1e1e1'}
            value={tournamentName}
            onChangeText={(value) => setTournamentName(value)}
            style={styles.inputField}
          />
          <Text tx="createTournamentScreen.blindsLength" style={styles.nameHeading} />
          <TextInput
            value={tournamentBlindsLength}
            onChangeText={(value) => setTournamentBlindsLength(value)}
            style={styles.inputField}
            keyboardType="number-pad"
            placeholder="1 min - 60 min"
            placeholderTextColor={'#e1e1e1'}
          />
          <Text tx="createTournamentScreen.startingChips" style={styles.nameHeading} />
          <TextInput
            value={tournamentStartingChips}
            onChangeText={(value) => setTournamentStartingChips(value)}
            style={styles.inputField}
            keyboardType="number-pad"
            placeholder="min. 1,000 - max. 50,000"
            placeholderTextColor={'#e1e1e1'}
          />
          <Text tx="createTournamentScreen.actionTime" style={styles.nameHeading} />
          <ActionTimeDropdown setTournamenActionTime={setTournamenActionTime} />
          <TouchableOpacity
            disabled={loading}
            onPress={debounce(() => {
              if (
                tournamentBlindsLength.length > 0 &&
                (Number(tournamentBlindsLength) > 60 || Number(tournamentBlindsLength) < 1)
              ) {
                setErrorMsg('Blind length range is 1 - 60 min only')
              } else if (
                tournamentStartingChips.length > 0 &&
                (Number(tournamentStartingChips) < 1000 || Number(tournamentStartingChips) > 50000)
              ) {
                setErrorMsg('Starting chips range is 1k - 50k only')
              } else if (tournamentName.length > 0) {
                setLoading(true)
                createActiveTournament()
              } else {
                setErrorMsg('Fill the tournament name')
              }
            }, 250)}
            style={{ marginTop: 40, height: 55 }}
          >
            <LinearGradient
              colors={['#49CDF2', '#9444FB']}
              start={[0, 1]}
              end={[1, 0]}
              style={[styles.gradientButton, { flexDirection: 'row', alignItems: 'center' }]}
            >
              <Text tx="createTournamentScreen.create" allowFontScaling={false} style={styles.buttonText} />
              {loading && <ActivityIndicator size={'large'} color="white" style={{ marginLeft: 8 }} />}
            </LinearGradient>
          </TouchableOpacity>
          <Text>{errorMsg}</Text>
        </View>
      </Pressable>
    </SafeAreaView>
  )
})
