import { ImageSourcePropType } from 'react-native'

export enum CARD {
  'AC' = 'AC',
  'AD' = 'AD',
  'AH' = 'AH',
  'AS' = 'AS',
  '2C' = '2C',
  '2D' = '2D',
  '2H' = '2H',
  '2S' = '2S',
  '3C' = '3C',
  '3D' = '3D',
  '3H' = '3H',
  '3S' = '3S',
  '4C' = '4C',
  '4D' = '4D',
  '4H' = '4H',
  '4S' = '4S',
  '5C' = '5C',
  '5D' = '5D',
  '5H' = '5H',
  '5S' = '5S',
  '6C' = '6C',
  '6D' = '6D',
  '6H' = '6H',
  '6S' = '6S',
  '7C' = '7C',
  '7D' = '7D',
  '7H' = '7H',
  '7S' = '7S',
  '8C' = '8C',
  '8D' = '8D',
  '8H' = '8H',
  '8S' = '8S',
  '9C' = '9C',
  '9D' = '9D',
  '9H' = '9H',
  '9S' = '9S',
  TC = 'TC',
  TD = 'TD',
  TH = 'TH',
  TS = 'TS',
  JC = 'JC',
  JD = 'JD',
  JH = 'JH',
  JS = 'JS',
  QC = 'QC',
  QD = 'QD',
  QH = 'QH',
  QS = 'QS',
  KC = 'KC',
  KD = 'KD',
  KH = 'KH',
  KS = 'KS',
  QUESTION = 'QUESTION',
  BACK = 'BACK',
}

const nonBasicCards = [CARD.QUESTION, CARD.BACK]
export const BASIC_CARDS = Object.keys(CARD).filter((cardKey) => !nonBasicCards.includes(cardKey as CARD))

export interface RankingCards {
  name: string
  displayName: string
  rankingCards: RankingCardsRow[]
}
export interface RankingCardsRow {
  name: string
  cards: CARD[]
}

export type ImageType = number

export const isImageType = (value: CARD[] | ImageType): value is ImageType => {
  return typeof value === 'number'
}

export type AnswerType = CARD[] | number

export const answerIsIdType = (value: AnswerType): value is number => {
  return typeof value === 'number'
}

export interface FunFact {
  title: string
  text: string
}

export type Game = SurveyGame | VersusGame

export const isSurveyGame = (value: Game): value is SurveyGame => {
  return 'questions' in value
}

export interface Option {
  id: number
  text: string
  cards?: CARD[]
}

export interface Question {
  question: string
  correctAnswer: AnswerType
  options: Option[]
  elementToDisplay?: CARD[] | ImageType
  caption?: string
  highlight?: CARD[]
  optionsWithCards?: boolean
  optionsTitle?: string
  selectableCards?: boolean
  correctCaption?: string
  incorrectCaption?: string
  funFact?: FunFact
}
export interface SurveyGame {
  name: string
  cards: CARD[]
  img: ImageSourcePropType
  gameDescription: string
  prevButton: string
  nextButton: string
  prevButtonRedirect?: string
  rankingToDisplay?: string
  questions: Question[]
}

export interface VersusGame {
  name: string
  cards: CARD[]
  img: ImageSourcePropType
  gameDescription: string
}

export const arraysEqual = (arr1: CARD[], arr2: CARD[]) => {
  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()
  return sortedArr1.length === sortedArr2.length && sortedArr1.every((value, index) => value === sortedArr2[index])
}

export const answerIsCorrect = (correctAnswer: AnswerType, answer: AnswerType): boolean => {
  if (answerIsIdType(correctAnswer) && answerIsIdType(answer)) {
    return answer === correctAnswer
  } else if (!answerIsIdType(correctAnswer) && !answerIsIdType(answer)) {
    return arraysEqual(correctAnswer, answer)
  }
  return false
}
