import { debounce, get, orderBy, uniqBy } from 'lodash'
import { castToSnapshot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'
// import * as RNFileSystem from 'react-native-fs'

// import RNFetchBlob from 'rn-fetch-blob'
import { BoardEventKind, GameEventKind, PlayerStates, TableEventKind } from '@poker-powher/poker'

import { translate } from '../../../i18n'
import { DismissModalEvent, emitter } from '../../../utils/events'
import { ChatKind, MessageContractModel } from '../../api-store'
import { withRootStore } from '../../extensions'
import { APIEvents } from '../session-messages'

export enum GameEventTypes {
  processed = 'processed',
}

export const GameEvent = types.model('GameEvent').props({
  gamePlayTime: types.optional(types.number, 0),
  message: MessageContractModel,
})

export type GameEventType = Instance<typeof GameEvent>
export const GameEventQueue = types
  .model('GameEventQueue')
  .props({
    processedEventMap: types.optional(types.map(GameEvent), {}),
    processedEventQueue: types.optional(types.array(GameEvent), []),
    eventQueue: types.optional(types.array(GameEvent), []),
    timeout: types.maybe(types.frozen<NodeJS.Timeout>()),
  })
  .extend(withRootStore)
  .volatile((self) => {
    let unsubscribeOnGameDelete: Function = debounce(() => {
      self.rootStore.session.createGameDeletedAlert()
      self.rootStore.session.endCall()
      return
    }, 1000)
    return { unsubscribeOnGameDelete }
  })
  .views((self) => ({
    getFirstGameEvent(): GameEventType | undefined {
      if (self.eventQueue.length) {
        return self.eventQueue[0]
      }
      return undefined
    },
  }))
  .actions((self) => ({
    clearTimeout() {
      if (self.timeout) {
        clearTimeout(self.timeout)
      }
      self.timeout = undefined
    },
    setTimeout(timeout: NodeJS.Timeout | undefined) {
      self.timeout = timeout
    },
    clearGameEvents() {
      self.processedEventQueue.clear()
      self.processedEventMap.clear()
      self.eventQueue.clear()
    },
    getDynamicMin(flatMin: number, playerMultiplier: number): number {
      const delay = Math.min(flatMin, self.rootStore.session.gamePlayers.length * playerMultiplier)
      return delay
    },
  }))
  .actions((self) => ({
    processEvent() {
      console.log('game-events processEvent', self.eventQueue.length)
      const original = self.getFirstGameEvent()
      if (!original) {
        return null
      }
      const event = getSnapshot(original)
      console.log('game-events processEvent with', event)
      const message = event.message
      // process message
      if (message.payload && message.payload.kind) {
        const kind = message.payload.kind
        console.log(`game-events processEvent ${kind}`)
        // self.rootStore.session.setSnack(`processEvent ${kind}`)
        const userId = self.rootStore.session.user?._id.toString()
        console.log('game-events onMessageData kind', kind, message.payload.timestamp)
        if ([APIEvents.GameSnapshotForPlayer].includes(kind)) {
          const isMe = get(message, 'payload.forPlayer.userId', '') === userId
          console.log('game-events onMessageData [api:user:game:snapshot] isMe message', isMe, message)
          // const currentPlayerIndex = get(message, 'payload.table[0].currentPlayer', -1)
          // const isFlop = get(message, 'payload.table[0].isFlop', false)
          // const isRiver = get(message, 'payload.table[0].isRiver', false)
          // const isTurn = get(message, 'payload.table[0].isTurn', false)
          self.rootStore.session.setisShowdown(false)

          if (isMe) {
            self.rootStore.session.setGame(message.payload)
            if (self.rootStore.session.isMyTurn) {
              // reset all player's last actions
              self.rootStore.session.updateLastAction(undefined)
            }
          }
        } else if ([TableEventKind.HandWinners].includes(kind)) {
          console.log(`game-events onMessageData[${TableEventKind.HandWinners}] and the winners are...`, message)
          self.rootStore.session.setShouldHighlightCurrentPlayer(false)
          setTimeout(() => {
            self.rootStore.session.setGameWinners(message.payload)
            if (self.rootStore.session.isTournamentModeOn === true) {
              const players = get(message, 'payload.snapshot.players', [])
              if (players.length > 0) {
                const countPlayersOut = players.filter((item: any) => item.chips === 0)
                // my player went out, show current leaderboard
                const gameWinnner = players.filter((item: any) => item.chips > 0)

                // tournament is over
                const isAllPlayersOut = countPlayersOut.length + 1 === self.rootStore.session.seatedPlayers.length
                if (isAllPlayersOut) {
                  self.rootStore.session.setIsLeaderboardAccessedFromDrawer(false)
                  self.rootStore.session.getTournamentStats(false)
                  self.rootStore.session.setIsGameOver(true)
                  self.rootStore.session.resetBlind()

                  if (gameWinnner.length > 0) {
                    const gameWinningString = JSON.stringify(gameWinnner[0])
                    const parsegameWinner = JSON.parse(gameWinningString)
                    self.rootStore.session.addChatLocally(
                      self.rootStore.session.channel?.channelName || 'channelNameUndefined',
                      parsegameWinner.name || 'userNameUndefined',
                      `${parsegameWinner.name} ${translate('chatDealerMessages.playerWonTournament')}!`,
                      ChatKind.dealer,
                      'white'
                    )
                  }
                }
              }
            }
          }, 100)
          return { ...event, gamePlayTime: self.getDynamicMin(5000, 1250) }
        } else if ([TableEventKind.PlayerAllIn].includes(kind)) {
          console.log(`game-events onMessageData[${TableEventKind.PlayerAllIn}] and the allin is...`, message)
          self.rootStore.session.setGameAllIn(message.payload)
          return { ...event, gamePlayTime: 1000 }
        } else if ([TableEventKind.HandShowdown].includes(kind)) {
          self.rootStore.session.setGame(message.payload.snapshot)
          self.rootStore.session.setisShowdown(true)
          self.rootStore.session.setShouldHighlightCurrentPlayer(false)
          return { ...event, gamePlayTime: self.getDynamicMin(4000, 1200) }
        } else if ([TableEventKind.PlayerOutOfChips].includes(kind)) {
          console.log(`game-events onMessageData[${TableEventKind.PlayerOutOfChips}] and the allin is...`, message)
          const userId = self.rootStore.session.user?._id
          const playerOutUserId = get(message.payload, 'snapshot.forPlayer.userId', '')
          self.rootStore.session.addPlayerRanks(playerOutUserId)
          if (playerOutUserId === userId) {
            // const playerRank = self.rootStore.session.getPlayerRank(playerOutUserId)
            // console.log('###--> message payload==>', self.rootStore.session.isLeaderboardDisplayedToOutPlayer)
            // self.rootStore.session.setMyRank(playerRank)
            requestAnimationFrame(() => {
              if (!self.rootStore.session.isLeaderboardDisplayedToOutPlayer) {
                self.rootStore.session.setIsLeaderboardDisplayedToOutPlayer(true)
                self.rootStore.session.getTournamentStats(true)
              }
              self.rootStore.session.getTournamentPlayerRank()
            })
            console.log('###==> after promise')
            // console.log('self.rootStore.session.myRank', self.rootStore.session.myRank)
            // if (self.rootStore.session.myRank > 0 && !self.rootStore.session.isWinnerInfoDisplayedToOutPlayer) {
            //   console.log('self.rootStore.session.myRank', self.rootStore.session.myRank)
            //   self.rootStore.session.setIsWinnerInfoDisplayedToOutPlayer(true)
            //   self.rootStore.session.createPlayerOutOfChipsModal()
            // }

            if (
              !self.rootStore.session.isWinnerInfoDisplayedToOutPlayer &&
              self.rootStore.session.myRank > 0 &&
              self.rootStore.session.isTournamentModeOn
            ) {
              self.rootStore.session.setIsWinnerInfoDisplayedToOutPlayer(true)
              self.rootStore.session.createPlayerOutOfChipsModal()
            }
          }

          console.log('playerOutUserId: ', playerOutUserId)
          if (playerOutUserId === userId && !self.rootStore.session.isGamePaused) {
            console.log('setPlayerOutOfChips matched')
          }
          self.rootStore.session.joinGame(true)
        } else if ([APIEvents.GameDeleted].includes(kind)) {
          console.log(`game-events ${APIEvents.GameDeleted}`)
          self.unsubscribeOnGameDelete()
          return { ...event, gamePlayTime: 0 }
        } else if ([TableEventKind.PlayerBuyIn].includes(kind)) {
          return { ...event, gamePlayTime: 0 }
        } else if ([TableEventKind.PlayerLeft].includes(kind)) {
          console.log('game-events player left', JSON.stringify(message.payload))
          console.log('game-events player left isPlayingGame?', self.rootStore.session.isPlayingGame)
          const isMe = get(message, 'payload.snapshot.forPlayer.userId', '') === userId
          console.log('game-events player left isMe message', isMe, message)
          const players = get(message, 'payload.snapshot.players', [])
          if (players.length === 1 && self.rootStore.session.isTournamentModeOn) {
            self.rootStore.session.getTournamentStats(false)
          }
          if (!self.rootStore.session.isPlayingGame) {
            self.rootStore.session.setGame(message.payload.snapshot)
          } else {
            // NOTE: game engine does not send forPlayer with player actions, so let's force pull
            //   self.rootStore.session.joinGame(true)
            //
            const mIOut = players.filter((player: any) => player.userId === userId)
            //NOTE: Check if my userId is included in the players array
            if (mIOut.length === 1) {
              // self.rootStore.session.joinGame(true)
              self.rootStore.session.setGame(message.payload.snapshot)
            } else if (mIOut.length === 0) {
              self.rootStore.session.teardown()
              self.rootStore.session.closeSocketConnection()
              self.rootStore.session.setMIOutOfGame(true)
            }
            // self.rootStore.session.setGame(message.payload.snapshot)
          }
          return { ...event, gamePlayTime: 0 }
        } else if ([TableEventKind.SetPlayerBuyInEvent].includes(kind)) {
          // console.log("SetPlayerBuyInEvent ", JSON.stringify(message.payload))
          self.rootStore.session.setGame(message.payload.snapshot)
        } else if ([TableEventKind.DoNoActionForPlayerEvent].includes(kind)) {
          // console.log("DoNoActionForPlayerEvent ", JSON.stringify(message.payload))
          self.rootStore.session.setGame(message.payload.snapshot)
        } else if ([TableEventKind.PlayerJoined, TableEventKind.PlayerWaitlisted].includes(kind)) {
          console.log('game-events player joined', message.payload)
          console.log('game-events player isPlayingGame?', self.rootStore.session.isPlayingGame)
          const currentTimestamp = new Date().getTime()
          let diffTimeStamp = self.rootStore.session.lastActiveCheckpoint - currentTimestamp
          self.rootStore.session.setPlayerJoinedTimeStamp(diffTimeStamp)
          // @TODO: is game started and !player inplay
          // if (!self.rootStore.session.isPlayingGame) {
          //   // if (!self.rootStore.session.isMyTurn) {
          //   self.rootStore.session.setGame(message.payload.snapshot)
          // } else {
          //   // NOTE: game engine does not send forPlayer with player actions, so let's force pull
          //   self.rootStore.session.joinGame(true)
          // }

          // @TODO: do we need this?
          // if someone joins during a player action
          //   Player Time Stops
          if (!self.rootStore.session.isPlayingGame) {
            self.rootStore.session.joinGame(true)
          } else {
            self.rootStore.session.setGame(message.payload.snapshot)
          }

          return {
            ...event,
            gamePlayTime: 0,
          }
        } else if ([TableEventKind.Paused].includes(kind) || [GameEventKind.Pause].includes(kind)) {
          // THIS GETS CALLED TWICE. ^^ Those two events happen nearly back-to-back at times.

          if (!self.rootStore.session.isGamePaused) {
            // @TODO: enable pause modal when table is !autostart
            // self.rootStore.session.createGamePauseTimerModal()
            const currentTimestamp = new Date().getTime()
            const pauseCheckpoint = get(message.payload, 'snapshot.forPlayer.checkpoint', currentTimestamp)
            self.rootStore.session.setPauseGameTimestamp(pauseCheckpoint)
            self.rootStore.session.setGame(message.payload.snapshot)
            if ([GameEventKind.Pause].includes(kind)) {
              self.rootStore.session.addChatLocally(
                self.rootStore.session.channel?.channelName || 'channelNameUndefined',
                self.rootStore.session.myPlayer?.name || 'userNameUndefined',
                `The game has been paused.`,
                ChatKind.dealer,
                'white'
              )
            }
            return { ...event, gamePlayTime: 0 }
          }
          return { ...event, gamePlayTime: 0 }
        } else if ([TableEventKind.Started].includes(kind) || [GameEventKind.Start].includes(kind)) {
          // THIS GETS CALLED TWICE. ^^ Those two events happen nearly back-to-back at times.

          if (!self.rootStore.session.isPlayingGame) {
            // self.rootStore.session.setGame(message.payload.snapshot)
            emitter.emit(DismissModalEvent)
            // NOTE: game engine does not send forPlayer with player actions, so let's force pull
          }

          if ([GameEventKind.Start].includes(kind)) {
            if (self.rootStore.session.isGamePaused) {
              self.rootStore.session.addChatLocally(
                self.rootStore.session.channel?.channelName || 'channelNameUndefined',
                self.rootStore.session.myPlayer?.name || 'userNameUndefined',
                `The game has been resumed.`,
                ChatKind.dealer,
                'white'
              )
            }
          }

          self.rootStore.session.setShouldHighlightCurrentPlayer(true)
          self.rootStore.session.joinGame(true)
          // const blindTimerCheckpoint = message.payload.snapshot.table[0].blindTimerCheckpoint
          const currentTimestamp = new Date().getTime()
          const gameStartTime = message.payload.snapshot.table[0].gameStartTime
          const nextBlindIndex = message.payload.snapshot.table[0].nextBlindIndex
          const blindLength = self.rootStore.session.blindTime
          const blindLengthInMicrosec = blindLength * 1000
          const nextTimestamp = gameStartTime + blindLengthInMicrosec * (nextBlindIndex + 1)
          const remainingTime = Math.ceil(Math.abs(nextTimestamp - currentTimestamp) / 1000)
          self.rootStore.session.setGameStartTime(gameStartTime)

          return { ...event, gamePlayTime: 0 }
        } else if (
          [
            TableEventKind.StreetFinished,
            // BoardEventKind.DealtFlop,
            // BoardEventKind.DealtTurn,
            // BoardEventKind.DealtRiver,
          ].includes(kind)
        ) {
          console.log('SNAPSHOT', get(message, 'payload.snapshot'))
          const isMe = get(message, 'payload.snapshot.forPlayer.userId', '') === userId
          console.log(
            `game-events onMessageData[${TableEventKind.StreetFinished}] and showdown begin...`,
            isMe,
            message
          )
          // NOTE: this is a global event (no one's turn) just delay for tables to show each street
          // if (isMe) {
          // self.rootStore.session.setGame(message.payload.snapshot)
          // }

          self.rootStore.session.setGame(message.payload.snapshot)

          // const lastPlayerAction = get(message, 'payload.snapshot.table[0].lastPlayerAction', undefined)
          // if (self.rootStore.session.game) {
          //   const lastBet = get(message, 'payload.snapshot.table[0].lastBet', undefined)
          //   // NOTE: if last action is folded need to grab current snapshot, otherwise grab previous
          //   const players =
          //     lastPlayerAction === PlayerStates.Folded.tag
          //       ? get(message, 'payload.snapshot.players', [])
          //       : self.rootStore.session.game.players.slice()
          //   // const currentPlayerIndex = self.rootStore.session.game.table[0].currentPlayer
          //   const currentPlayerIndex = get(message, 'payload.snapshot.table[0].currentPlayer', -1)
          //   if (lastBet && currentPlayerIndex < players.length) {
          //     const replaceablePlayer = players[currentPlayerIndex]
          //     if (replaceablePlayer) {
          //       players[currentPlayerIndex] = {
          //         ...players[currentPlayerIndex],
          //         bet: lastBet.amount,
          //         bets: [lastBet],
          //       }
          //     }
          //   }
          //   self.rootStore.session.setGame({
          //     ...self.rootStore.session.game,
          //     forPlayer: {
          //       ...self.rootStore.session.game.forPlayer,
          //       // remove player actions while streets are displayed
          //       actions: [],
          //     },
          //     // wipe player bets
          //     players,
          //     // players: players.map((player: Player) => ({ ...player, bet: 0, bets: [] })),
          //     table: {
          //       [0]: {
          //         ...self.rootStore.session.defaultTable,
          //         // remove player actions while streets are displayed
          //         currentPlayer: -1,
          //         // only update communitycads to preserve current player's state
          //         communityCards: get(message, 'payload.snapshot.table[0].communityCards', []),
          //       },
          //     },
          //   })
          //   // DEPRECATED: we don't use this anymore
          //   // self.rootStore.session.updateLastAction(undefined)
          // }
          return { ...event, gamePlayTime: 500 }
        } else if ([TableEventKind.ShowCardsForTeacherEvent].includes(kind)) {
          self.rootStore.session.setIsCardsVisible(true)
          self.rootStore.session.addChatLocally(
            self.rootStore.session.channel?.channelName || 'channelNameUndefined',
            self.rootStore.session.myPlayer?.name || 'userNameUndefined',
            `The cards have been shown by a teacher.`,
            ChatKind.dealer,
            'white'
          )
        } else if ([TableEventKind.HideCardsForTeacherEvent].includes(kind)) {
          self.rootStore.session.setIsCardsVisible(false)
          self.rootStore.session.addChatLocally(
            self.rootStore.session.channel?.channelName || 'channelNameUndefined',
            self.rootStore.session.myPlayer?.name || 'userNameUndefined',
            `The cards have been hidden by a teacher.`,
            ChatKind.dealer,
            'white'
          )
        } else if ([TableEventKind.HighestBetPlayer].includes(kind)) {
          self.rootStore.session.setGame(message.payload.snapshot)
          self.rootStore.session.setisShowdown(true)
        } else if ([TableEventKind.PlayerCalled].includes(kind)) {
          // const forPlayerName = get(message.payload, 'snapshot.forPlayer.name', 'A Player')
          // if (forPlayerName) {
          //   self.rootStore.session.addChatLocally(
          //     self.rootStore.session.channel?.channelName || 'channelNameUndefined',
          //     forPlayerName,
          //     `${forPlayerName} ${translate('chatDealerMessages.playerCalled')}`,
          //     ChatKind.dealer,
          //     'white'
          //   )
          // }
        } else if ([TableEventKind.PlayerChecked].includes(kind)) {
          // const forPlayerName = get(message.payload, 'snapshot.forPlayer.name', 'A Player')
          // if (forPlayerName) {
          //   self.rootStore.session.addChatLocally(
          //     self.rootStore.session.channel?.channelName || 'channelNameUndefined',
          //     forPlayerName,
          //     `${forPlayerName} ${translate('chatDealerMessages.playerChecked')}`,
          //     ChatKind.dealer,
          //     'white'
          //   )
          // }
        } else if ([TableEventKind.PlayerFolded].includes(kind)) {
          // const forPlayerName = get(message.payload, 'snapshot.forPlayer.name', 'A Player')
          // if (forPlayerName) {
          //   self.rootStore.session.addChatLocally(
          //     self.rootStore.session.channel?.channelName || 'channelNameUndefined',
          //     forPlayerName,
          //     `${forPlayerName} ${translate('chatDealerMessages.playerFolded')}`,
          //     ChatKind.dealer,
          //     'white'
          //   )
          // }
        } else if ([TableEventKind.PlayerObserver].includes(kind)) {
          self.rootStore.session.joinGame(true)
        } else if ([BoardEventKind.DealtFlop, BoardEventKind.DealtTurn, BoardEventKind.DealtRiver].includes(kind)) {
          return { ...event, gamePlayTime: self.getDynamicMin(1000, 333) }
        } else if ([TableEventKind.PlayerRemovedByTeacherEvent].includes(kind)) {
          const leavingPlayer = message.payload.snapshot?.forPlayer
          const leavingPlayerId = leavingPlayer?.id
          const myData = self.rootStore.session.myPlayer
          if (myData && leavingPlayerId === myData?.id) {
            self.rootStore.session.leaveCurrentGame()
            self.rootStore.session.setMIOutOfGame(true)
          }
        } else if ([TableEventKind.CalculateNextBlindEvent].includes(kind)) {
          // const checkpoint = get(message.payload.snapshot, 'table[0].blindTimerCheckpoint', new Date().getTime())
          const blindTimerCheckpoint = message.payload.snapshot.table[0].blindTimerCheckpoint
          const tableCheckpoint = message.payload.snapshot.table[0].checkpoint
          const currentTimestamp = new Date().getTime()
          const gameStartTime = message.payload.snapshot.table[0].gameStartTime
          const nextBlindIndex = message.payload.snapshot.table[0].nextBlindIndex
          const blindLength = self.rootStore.session.blindTime
          const blindLengthInMicrosec = self.rootStore.session.blindTime * 1000
          const nextTimestamp = gameStartTime + blindLengthInMicrosec * (nextBlindIndex + 1)
          const remainingTime = Math.ceil(Math.abs(nextTimestamp - currentTimestamp) / 1000)
          console.log(
            'CalculateNextBlindEvent: ',
            blindTimerCheckpoint,
            currentTimestamp,
            gameStartTime,
            nextBlindIndex,
            blindLength,
            nextTimestamp,
            remainingTime
          )
          self.rootStore.session.setBlindLevel(nextBlindIndex)
          self.rootStore.session.setGameStartTime(gameStartTime)
          self.rootStore.session.setRemainingTime(remainingTime)
          self.rootStore.session.setGame(message.payload.snapshot)
          self.rootStore.session.setCurrentBlindTimestamp(blindTimerCheckpoint)
        } else if ([TableEventKind.Reset].includes(kind)) {
          const resetTimestamp = message.payload.timestamp
          self.rootStore.session.setGameResetTimestamp(resetTimestamp)
          self.rootStore.session.setPauseGameTimestamp(0)
          // self.rootStore.session.setCurrentBlindTimestamp(new Date().getTime())
          self.rootStore.session.setGame(message.payload.snapshot)
        } else if ([TableEventKind.PlayerObserver].includes(kind)) {
          const observerPlayer = get(message.payload, 'snapshot.forPlayer.userId', '')
          if (observerPlayer === userId) {
            self.rootStore.session.fetchMe()
          }
          self.rootStore.session.setGame(message.payload.snapshot)
        } else if ([TableEventKind.HandNew].includes(kind)) {
          self.rootStore.session.setisShowdown(false)
          self.rootStore.session.setPauseGameTimestamp(0)
          // now is a good time to checkin on the GraphQL Subscription and underlying WebSocket Connection
          const didReconnect = self.rootStore.session.createChannelSubscription(true)
          if (!didReconnect) {
            // NOTE: game engine does not send forPlayer with player actions, so let's force pull
            // self.rootStore.session.joinGame(true)
            console.info('new hand, didReconnect?', didReconnect)
          }
          // requestAnimationFrame(() => {
          //   // cleanup memory
          //   RNFetchBlob.fs
          //     .unlink(RNFileSystem.CachesDirectoryPath)
          //     .then(() => {
          //       console.log('cache deleted')
          //     })
          //     .catch((error: any) => {
          //       console.log('Error in cache delete', error)
          //     })
          // })
        } else if ([APIEvents.GamePlayerLeft.includes(kind)]) {
          if (self.rootStore.session.seatedPlayers.length === 1) {
            self.rootStore.session.getTournamentStats(false)
          }
        }

        // const noDelayWhitelist = [
        // BoardEventKind.DealtFlop,
        // BoardEventKind.DealtTurn,
        // BoardEventKind.DealtRiver,
        //   BoardEventKind.DealtHoleCards,
        //   BoardEventKind.BetsToPot,
        //   TableEventKind.HandCurrentPlayer,
        //   TableEventKind.HandDealtCards,
        //   TableEventKind.HandNew,
        //   TableEventKind.PlayerBet,
        //   TableEventKind.PlayerFolded,
        //   TableEventKind.PlayerCalled,
        //   TableEventKind.PlayerChecked,
        //   TableEventKind.PlayerReset,
        //   TableEventKind.PlayerPostedBlind,
        //   TableEventKind.Started,
        // ]
        // if (noDelayWhitelist.includes(kind)) {
        //   return { ...event, gamePlayTime: 0 }
        // }
        return { ...event, gamePlayTime: 0 }
      }

      return event
    },
  }))
  .actions((self) => ({
    checkGameEvents(callback?: (callback?: () => void) => void) {
      console.log('game-events checkGameEvents', self.timeout)
      if (!self.timeout) {
        console.log('game-events check', self.timeout)
        // process next event
        // self.rootStore.session.setisShowdown(false)
        const event = self.processEvent()
        if (event) {
          self.eventQueue.shift()
          const gamePlayTime = event.gamePlayTime
          const kind = event.message.payload.kind.slice()
          console.log('game-events check with', kind, 'hold for', gamePlayTime, 'seconds', event)

          const teardown = () => {
            // teardown last message
            // self.rootStore.session.setisShowdown(false)
            self.rootStore.session.clearGameWinners()
            self.rootStore.session.clearAllIn()

            self.clearTimeout()
          }
          const next = () => {
            teardown()
            callback && callback(callback)
          }
          if (gamePlayTime > 0) {
            // InteractionManager.setDeadline(Math.max(gamePlayTime, 250))
            const timeout: any = setTimeout(() => {
              console.log('game-events check timeout!', kind, 'hold for', gamePlayTime, 'seconds', !!callback)
              emitter.emit(GameEventTypes.processed)
              next()
            }, gamePlayTime)
            self.setTimeout(timeout)
          } else {
            // NOTE: infinite recursion without blowing up the callstack
            // https://dawertz.medium.com/infinite-recursion-with-javascript-5a5a27b7d7fc
            setTimeout(() => next(), 0)
          }
        }
      }
    },
  }))
  .actions((self) => ({
    pushGameEvents(data: GameEventType[]) {
      //
      // @TODO: Remove hack once we pin version to Redis INCR
      // console.warn('@TODO hack game-events mapping version!')
      // const mappedData = data.map((event) => {
      //   const message = event.message
      //   let version = message.payload?.version ?? 1
      //   if (message.payload.kind === TableEventKind.StreetFinished) {
      //     version = 97
      //   } else if (message.payload.kind === TableEventKind.HandShowdown) {
      //     version = 98
      //   } else if (message.payload.kind === TableEventKind.HandWinners) {
      //     version = 99
      //   } else if (message.payload.kind === TableEventKind.HandNew) {
      //     version = 100
      //   }
      //   const mappedEvent = {
      //     ...event,
      //     message: { ...message, payload: { ...message.payload, version } },
      //   }
      //   return mappedEvent
      // })
      // @TODO: end HACK ^^
      //
      // const clone = differenceBy(
      //   uniqBy([...self.eventQueue, ...mappedData], (event) => event.message.payload.id),
      //   [...self.processedEventQueue],
      //   (event: any) => event.message.payload.id
      // )
      // const lastProcessedEvent =
      //   self.processedEventQueue.length > 0 ? self.processedEventQueue[self.processedEventQueue.length - 1] : null
      // NOTE: We do a toast, if we skip events that have not been processed (meaning we received an incoming event with >= version of lastProcessedEvent)
      // let didSkipEvents = false
      // const clone = uniqBy([...self.eventQueue, ...mappedData], (event) => event.message.payload.id)
      const clone = uniqBy([...self.eventQueue, ...data], (event) => event.message.payload.id)
      // .filter((event) => {
      //   const flag = lastProcessedEvent && lastProcessedEvent.message.payload.version >= event.message.payload.version
      //   if (flag) {
      //     didSkipEvents = true
      //   }
      //   return !flag
      // })
      // if (didSkipEvents) {
      //   self.rootStore.session.setSnack('Catching you up...')
      // }
      // self.rootStore.session.toggleSnackbar(didSkipEvents)
      console.log('game-events pushGameEvents clone.len', clone.length)
      const sorted = orderBy(
        clone,
        [(event) => event.message.payload.timestamp, (event) => event.message.payload.version],
        ['asc', 'asc']
      )
      self.eventQueue.replace(castToSnapshot(sorted))
      console.log('game-events pushGameEvents sorted.len', sorted.length)
      const check = debounce(() => {
        self.checkGameEvents(self.checkGameEvents)
      }, 250)
      check()
    },
  }))
  .actions((self) => {
    const afterCreate = async () => {
      self.clearTimeout()
      self.clearGameEvents()
      console.log('game-events afterCreate')
    }

    const beforeDestroy = () => {}

    return { afterCreate, beforeDestroy }
  })
/**
 * The GameEventQueueStore instance.
 */
export type GameEventQueueStore = Instance<typeof GameEventQueue>

/**
 * The data of a GameEventQueueStore.
 */
export type GameEventQueueStoreSnapshot = SnapshotOut<typeof GameEventQueue>
