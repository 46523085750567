import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
    justifyContent: 'center',
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 15,
    padding: 1,
  },
  modalInnerContainer: {
    backgroundColor: '#070D37',
    borderRadius: 15,
    paddingVertical: 25,
  },
  modalTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'HeroNew-BoldItalic',
    fontWeight: '700',
    paddingVertical: 10,
  },
  modalHeading: {
    // lineHeight: 21,
    textTransform: 'uppercase',
    fontFamily: 'HeroNew-BoldItalic',
    fontSize: 15,
    textAlign: 'center',
    marginTop: 15,
    color: '#FFBB0E',
  },
  betRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
  betInputFieldContainer: {
    flex: 1,
    padding: 1,
    borderRadius: 25,
  },
  betInputFieldInnerContainer: {
    backgroundColor: '#070D37',
    borderRadius: 31,
  },
  betInputField: {
    fontFamily: 'HeroNew-BoldItalic',
    textAlign: 'center',
    color: '#fff',
    fontSize: 26,
    paddingHorizontal: 15,
    height: 52,
    // borderWidth: 0.5,
    borderColor: '#6E8DE3',
    borderRadius: 50,
    paddingTop: Platform.OS === 'ios' ? 8 : 0,
    textAlignVertical: 'center',
  },
  betInputFieldLabelContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  betInputFieldLabel: {
    fontFamily: 'HeroNew-BoldItalic',
    textAlign: 'right',
    color: '#fff',
    fontSize: 13,
  },
  modalFooter: {
    flexDirection: 'row',
    marginVertical: 15,
  },
  footerTabText: {
    fontFamily: 'HeroNew-BoldItalic',
    fontSize: 10,
    lineHeight: 14,
    textAlign: 'center',
  },
  betModalCLoseContainer: {
    position: 'absolute',
    left: 20,
    top: -8,
  },
  closeImage: {
    height: 30,
    width: 30,
  },
  cancelButtonContainer: {
    flex: 1,
    marginLeft: 10,
    paddingHorizontal: 15,
  },
  cancelImage: {
    width: 40,
    height: 40,
  },
  confirmText: {
    fontSize: 26,
    fontFamily: 'HeroNew-BoldItalic',
    color: '#FFBB0E',
  },
  dialNumbers: {
    fontFamily: 'HeroNew-BoldItalic',
    color: '#fff',
    fontSize: 36,
  },
  deleteButtonImage: {
    width: 56,
    height: 34,
  },
  tickImageButton: {
    width: 41,
    height: 32,
  },
  tickImageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelImageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialNumberContainer: {
    width: 78,
    height: 78,
    padding: 2,
    borderRadius: 39,
    marginVertical: 10,
    marginHorizontal: 10,
  },
  dialContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberEntryValue: {
    marginVertical: 5,
    color: '#fff',
    fontFamily: 'HeroNew-BoldItalic',
    textAlign: 'center',
    height: 34,
    fontSize: 34,
    borderColor: 'transparent',
  },
})
