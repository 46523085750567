import safeStringify from 'fast-safe-stringify'
import { Client, Event } from 'graphql-ws'
import { getEnv, Instance, SnapshotOut, types } from 'mobx-state-tree'

import NetInfo, { NetInfoState, NetInfoStateType } from '@react-native-community/netinfo'

import { withRootStore } from '../extensions'
import { RootStoreEnv } from '../RootStoreEnv'
import { websocketConnectionEvents } from './SubscriptionClient'

const replacer = (key: string, value: unknown) => {
  // console.log('Key:', JSON.stringify(key), 'Value:', JSON.stringify(value))
  // Remove the circular structure
  if (value === '[Circular]') {
    return
  }
  return value
}

/**
 * A NetworkStore model.
 */

export const NetworkStore = types
  .model('NetworkStore')
  .props({
    isNetworkConnected: types.maybeNull(types.boolean),
    isInternetReachable: types.maybeNull(types.boolean),
    isWebSocketConnected: types.optional(types.boolean, false),
    networkType: types.optional(types.string, NetInfoStateType.unknown),
    websocketEvents: types.optional(types.array(types.string), []),
  })
  .extend(withRootStore)
  .volatile((self) => {
    const netInfoState = {
      type: NetInfoStateType.unknown,
      isConnected: null,
      isInternetReachable: null,
      details: null,
    } as NetInfoState
    const eventSubscriptions: Function[] = []
    return {
      eventSubscriptions,
      netInfoState,
    }
  })
  .views((self) => ({
    get wsReadyState(): string {
      const env = getEnv<RootStoreEnv>(self)
      const status = env.gqlWsClient.status.toString()
      console.log('wsReadyState', status)
      // // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
      const readyStates: Record<string, string> = {
        0: 'connecting',
        1: 'open',
        2: 'closing',
        3: 'closed',
      }
      return readyStates[status] || 'unknown'
    },
  }))
  .actions((self) => ({
    setIsWebSocketConnected(value: boolean) {
      self.isWebSocketConnected = value
    },
    setIsNetworkConnected(value: boolean | null) {
      self.isNetworkConnected = value
    },
    setNetworkType(type: string) {
      self.networkType = type
    },
    setInternetReachable(value: boolean | null) {
      self.isInternetReachable = value
    },
  }))
  .actions((self) => ({
    setNetInfo(netInfoState: NetInfoState) {
      console.log('network-store setNetInfo', netInfoState)
      // 1. check if network is accessible 2. Invoke createChannelSubscription
      // if (!self.isNetworkConnected && netInfoState.isConnected || netInfoState.isConnected === null) {
      //   console.log('Reconnected....', !self.isNetworkConnected, netInfoState.isConnected)
      //   self.rootStore.session.clearMessages()
      //   self.rootStore.session.createChannelSubscription(true)
      // }
      // self.netInfoState = netInfoState
      if (
        self.isInternetReachable !== netInfoState.isInternetReachable ||
        self.isNetworkConnected !== netInfoState.isConnected
      ) {
        console.log('Reconnected....', self.isInternetReachable, netInfoState.isInternetReachable)
        self.rootStore.session.clearMessages()
        // self.rootStore.session.createChannelSubscription(true)
      }
      self.netInfoState = netInfoState
      self.setNetworkType(self.netInfoState.type)
      self.setIsNetworkConnected(netInfoState.isConnected)
      self.setIsNetworkConnected(netInfoState.isInternetReachable)
    },
    pushWebsocketEvent(state: Event, message?: string) {
      // console.info('network-store setWebsocketConnectionState', state)
      if (['connecting', 'closed'].includes(state)) {
        self.setIsWebSocketConnected(false)
      } else if (['connected'].includes(state)) {
        self.setIsWebSocketConnected(true)
      } else if (['error'].includes(state)) {
        self.rootStore.session.clearMessages()
        // self.rootStore.session.createChannelSubscription(true)
      }
      const event = message ? `${state}${message}` : state
      // self.websocketEvents.push(event)
      self.websocketEvents.replace([...self.websocketEvents.slice(-20), event])
    },
  }))
  .actions((self) => {
    const teardownWebsocketListeners = () => {
      self.eventSubscriptions.map((unsubscribe) => unsubscribe())
    }
    const setupWebsocketListeners = (gqlWsClient: Client) => {
      teardownWebsocketListeners()
      self.eventSubscriptions = websocketConnectionEvents.map((event) => {
        // console.log('network-store setupWebsocketListeners', event)
        // if (event === WebsocketConnectionStates.error) {
        //   return gqlWsClient.on(event, (error) => {
        //     console.log('network-store websocket error', error)
        //     self.pushWebsocketEvent(event)
        //     // self.pushWebsocketEvent(event, error.message.toString())
        //   })
        // }
        // // return gqlWsClient.on(event, () => self.pushWebsocketEvent(event), gqlWsClient)
        return gqlWsClient.on(event, (params: unknown) => {
          // console.log('network-store websocket event', event, params)
          let message = ''
          try {
            message = params ? ' ' + safeStringify(params, replacer, 2) : ''
          } catch (error) {
            console.error('network-store error', error)
          }
          self.pushWebsocketEvent(event, message)
        })
      })
    }
    const unsubscribe = NetInfo.addEventListener((state) => self.setNetInfo(state))

    const afterAttach = async () => {
      self.setIsWebSocketConnected(false)
      self.websocketEvents.clear()
      try {
        const netInfoState = await NetInfo.fetch()
        self.setNetInfo(netInfoState)
        console.log('network-store afterAttach', netInfoState)
      } catch (error) {
        console.error('network-store', error)
      }
    }

    const beforeDestroy = () => {
      self.eventSubscriptions.map((unsubscribe) => unsubscribe())
      unsubscribe()
    }

    return { afterAttach, beforeDestroy, setupWebsocketListeners, teardownWebsocketListeners }
  })

/**
 * The NetworkStore instance.
 */
export type NetworkStoreType = Instance<typeof NetworkStore>

/**
 * The data of a NetworkStore.
 */
export type NetworkStoreSnapshot = SnapshotOut<typeof NetworkStore>
