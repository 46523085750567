import React from 'react'

export const ActionTimeDropdown = (props: { setTournamenActionTime: Function }) => {
//   const { setTournamenActionTime } = props

  return (
    <select>
      <option selected>Open this select menu</option>
      <option value="30 sec">30 sec</option>
      <option value="60 sec">60 sec</option>
      <option value="90 sec">90 sec</option>
    </select>
  )
}
