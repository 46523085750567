import { StyleSheet, Dimensions } from 'react-native'
import { moderateScale } from '../../styles/sizes'
import { fontFamily } from '../../theme/fontFamily'

const Height = Dimensions.get('screen').height / 1.4

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(5, 16, 45)',
  },
  screenHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 5,
    marginTop: 20,
  },
  buttonText: {
    textAlign: 'center',
    fontWeight: '800',
    fontSize: moderateScale(16),
    fontFamily: fontFamily.sfProDisplayHeavy,
  },
  tournamentTitle: {
    fontSize: moderateScale(14),
    fontFamily: fontFamily.sfProDisplayHeavy,
    color: 'white',
  },
  seatLeft: {
    fontSize: moderateScale(13),
    fontFamily: fontFamily.sfProDisplayRegular,
    color: 'white',
    paddingTop: 5,
  },
  gameCardContainer: {
    flex: 1,
    borderRadius: 8,
    margin: 2.5,
  },
  gameCardImageContainer: {
    padding: 2,
    height: 135,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tournamentDetailsContainer: {
    backgroundColor: '#232335',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 10,
  },
  gradientButton: {
    flex: 1,
    borderRadius: 8,
    justifyContent: 'center',
    height: 36,
  },
  emptyContainer:{
    justifyContent: 'center', alignItems: 'center', height: Height
  },
  emptyText:{
    fontSize: moderateScale(14),
    fontFamily: fontFamily.sfProDisplayHeavy,
  }
})

export default styles
