import React from 'react'
import { Dimensions, Image, View, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Text } from '../../components'
import styles from './styles'

const window = Dimensions.get('window')
const tournamentGroup = require('../../../assets/icons/tournament-group.png')

interface GameCardProps {
  isTeacher: boolean
  title: string
  seatLeft: number
  channelName: string
  color: string
  isLocked: boolean
  openDeleteModal: (id: string) => void
  isValidDuration: boolean
  gameId: string
  onRegister: () => void
}

const GameCard = (props: GameCardProps) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={!props.isTeacher}
      style={styles.gameCardContainer}
      onLongPress={() => props.openDeleteModal(props?.gameId)}
    >
      <View
        style={[
          styles.gameCardImageContainer,
          {
            backgroundColor: props.color,
          },
        ]}
      >
        <Image source={tournamentGroup} resizeMode="contain" style={{ width: window.width / 4, height: 100 }} />
      </View>
      <View style={styles.tournamentDetailsContainer}>
        <Text tx="common.label" txOptions={{ label: props.title }} style={styles.tournamentTitle} />
        <Text
          tx="activeGameScreen.seatsLeft"
          txOptions={{ seats: props.seatLeft > 0 ? props.seatLeft : 0 }}
          style={styles.seatLeft}
        />
        <TouchableOpacity
          style={{ marginVertical: 10, opacity: props.seatLeft === 0 ? 0.5 : 1 }}
          onPress={() => {
            props.onRegister()
          }}
        >
          {props.seatLeft > 0 && props.isValidDuration && props.isLocked === false ? (
            <LinearGradient colors={['#49CDF2', '#9444FB']} start={[0, 1]} end={[1, 0]} style={styles.gradientButton}>
              <Text tx="activeGameScreen.register" allowFontScaling={false} style={styles.buttonText} />
            </LinearGradient>
          ) : (
            <LinearGradient colors={['#B1A7B2', '#77719A']} start={[0, 1]} end={[1, 0]} style={styles.gradientButton}>
              <Text tx="activeGameScreen.observer" allowFontScaling={false} style={styles.buttonText} />
            </LinearGradient>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  )
}

export default GameCard