import { LinearGradient } from 'expo-linear-gradient'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  Dimensions, FlatList, ImageBackground, Pressable, ScrollView, StyleSheet, Text, View
} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { useIsFocused, useNavigation } from '@react-navigation/native'

import { translate } from '../../i18n'
import { useStore } from '../../models'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import { moderateScale } from '../../styles/sizes'
import { numberWithCommas } from '../../utils/numberWithCommas'

export enum TabOptions {
  lobby = 'lobby',
  rules = 'rules',
}

const PlayerName = ({ data }: any) => {
  return (
    <View style={styles.playerNameContainer}>
      <Text style={styles.playerName}>{data}</Text>
    </View>
  )
}

export const GameLobbyScreen = observer(() => {
  const navigation = useNavigation<DrawerNavigation>()
  const isFocused = useIsFocused()
  const store = useStore()
  const [activeTab, setActiveTab] = useState(TabOptions.lobby)

  useEffect(() => {
    const setup = async () => {
      await store.session.setupGame(navigation)
    }
    if (isFocused) {
      setup()
    }
    return () => {
      // DEPRECATED: We do not want to end video calls or 
      // unsubscribe from game events when navigating away from the game
      // store.session.endCall()
      // store.session.unsubscribeAll()
    }
  }, [isFocused])

  const displayRules = () => {
    return (
      <View style={{ marginLeft: 30 }}>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.maximumSeats')}</Text>
        <Text style={styles.rulesValues}>9</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.buyInAmount')}</Text>
        <Text style={styles.rulesValues}>{numberWithCommas(2500)}</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.startingChips')}</Text>
        <Text style={styles.rulesValues}>{numberWithCommas(2500)}</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.numberOfRebuys')}</Text>
        <Text style={styles.rulesValues}>3</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.rebuyTime')}</Text>
        <Text style={styles.rulesValues}>20:00</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.actionTime')}</Text>
        <Text style={styles.rulesValues}>00:45</Text>
        <Text style={styles.rulesHeading}>{translate('gameWaitingRoom.blingInterval')}</Text>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.rulesValues, { fontStyle: 'italic', paddingTop: 10 }]}>
              {translate('gameWaitingRoom.smallBlind')}
            </Text>
            <Text style={[styles.rulesValues, { paddingTop: 10, paddingLeft: 20 }]}>25</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.rulesValues, { fontStyle: 'italic', paddingTop: 10 }]}>
              {translate('gameWaitingRoom.bigBlind')}
            </Text>
            <Text style={[styles.rulesValues, { paddingTop: 10, paddingLeft: 42 }]}>50</Text>
          </View>
        </View>
      </View>
    )
  }

  const renderItem = ({ item }: any) => <PlayerName data={item.name} />

  const displayLobbyPlayers = () => {
    const players = store.session.players
    return (
      <View style={{ marginLeft: 30 }}>
        {players.map((item, index) => {
          return (
            <View style={styles.inGameListContainer}>
              <Text style={styles.inGameIndex}>{index + 1}</Text>
              <Text style={styles.inGamePlayerName}>{item.name}</Text>
            </View>
          )
        })}
        <View style={styles.observerContainer}>
          <Text style={styles.observerText}>{translate('gameWaitingRoom.observers')}</Text>
          <FlatList
            data={store.session.getObserverList() || []}
            numColumns={2}
            renderItem={renderItem}
            keyExtractor={(item, index) => `player-lobby-item-${index}`}
          />
        </View>
      </View>
    )
  }

  return (
    <ImageBackground source={require('../../assets/game-lobby-tiles.png')} style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
        <View style={{ flex: 0.8 }}>
          <Text style={styles.heading}>{translate('gameWaitingRoom.heading')}</Text>
        </View>
        <View style={styles.tabContainer}>
          <LinearGradient
            colors={activeTab === TabOptions.lobby ? ['#FF3F25', '#DC2BFF'] : ['transparent', 'transparent']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={{ width: '50%', height: 40 }}
          >
            <Pressable onPress={() => setActiveTab(TabOptions.lobby)} style={styles.tabButton}>
              <Text
                style={[
                  styles.tabLabel,
                  {
                    opacity: activeTab === TabOptions.lobby ? 1 : 0.5,
                  },
                ]}
              >
                {translate('gameWaitingRoom.lobbyTab')}
              </Text>
            </Pressable>
          </LinearGradient>
          <LinearGradient
            colors={activeTab === TabOptions.rules ? ['#FF3F25', '#DC2BFF'] : ['transparent', 'transparent']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={{ width: '50%', height: 40 }}
          >
            <Pressable onPress={() => setActiveTab(TabOptions.rules)} style={styles.tabButton}>
              <Text
                style={[
                  styles.tabLabel,
                  {
                    opacity: activeTab === TabOptions.rules ? 1 : 0.5,
                  },
                ]}
              >
                {translate('gameWaitingRoom.rulesTab')}
              </Text>
            </Pressable>
          </LinearGradient>
        </View>
        <View style={{ flex: 14, paddingHorizontal: 30 }}>
          {activeTab === TabOptions.lobby ? displayLobbyPlayers() : displayRules()}
        </View>
        <View style={styles.footerContainer}>
          <LinearGradient
            colors={['#FF3F25', '#DC2BFF']}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={styles.skipButtonGradientContainer}
          >
            <TouchableOpacity onPress={() => navigation.navigate(DrawerRoutes.game)} style={styles.skipButton}>
              <Text style={styles.skipButtonLable}>{translate('gameWaitingRoom.startGame')}</Text>
            </TouchableOpacity>
          </LinearGradient>
        </View>
      </ScrollView>
    </ImageBackground>
  )
})

const styles = StyleSheet.create({
  heading: {
    color: '#fff',
    fontWeight: '900',
    fontStyle: 'italic',
    fontSize: moderateScale(24),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  tabContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 52,
  },
  tabButton: {
    backgroundColor: '#0D1D57',
    height: 38,
    justifyContent: 'center',
  },
  tabLabel: {
    fontSize: moderateScale(14),
    color: '#fff',
    fontWeight: '800',
    textAlign: 'center',
  },
  footerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 22,
  },
  skipButtonGradientContainer: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginBottom: 22,
  },
  skipButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  skipButtonLable: {
    color: '#fff',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: moderateScale(18),
    textTransform: 'uppercase',
  },
  observerContainer: {
    backgroundColor: '#ffffff20',
    paddingBottom: 10,
    marginLeft: -30,
    marginTop: 40,
    minHeight: 100,
    borderRadius: 4,
    width: Dimensions.get('screen').width - 55,
  },
  observerText: {
    fontSize: moderateScale(18),
    paddingVertical: 15,
    paddingHorizontal: 10,
    fontStyle: 'italic',
    color: '#fff',
    fontWeight: '900',
  },
  rulesHeading: {
    color: '#fff',
    opacity: 0.5,
    fontWeight: '800',
    fontSize: moderateScale(14),
    paddingTop: 20,
  },
  rulesValues: {
    color: '#fff',
    fontWeight: '900',
    fontSize: moderateScale(18),
    paddingTop: 10,
    fontStyle: 'italic',
  },
  inGamePlayerName: {
    paddingHorizontal: 20,
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#fff',
    fontSize: moderateScale(18),
    textTransform: 'capitalize',
    fontWeight: '800',
  },
  inGameIndex: {
    fontStyle: 'italic',
    textAlign: 'center',
    color: '#FEE301',
    fontSize: moderateScale(24),
    fontWeight: '800',
  },
  inGameListContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  playerNameContainer: {
    width: '50%',
    justifyContent: 'center',
    padding: 10,
  },
  playerName: {
    color: 'white',
    opacity: 1,
    fontSize: moderateScale(14),
    fontWeight: '800',
  },
})
