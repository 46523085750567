/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * PlayerStatBestHandWonBase
 * auto generated base class for the model PlayerStatBestHandWonModel.
 */
export const PlayerStatBestHandWonModelBase = ModelBase
  .named('PlayerStatBestHandWon')
  .props({
    __typename: types.optional(types.literal("PlayerStatBestHandWon"), "PlayerStatBestHandWon"),
    createdAt: types.union(types.undefined, types.frozen()),
    rankedHandIndex: types.union(types.undefined, types.null, types.number),
    rankedHandName: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PlayerStatBestHandWonModelSelector extends QueryBuilder {
  get createdAt() { return this.__attr(`createdAt`) }
  get rankedHandIndex() { return this.__attr(`rankedHandIndex`) }
  get rankedHandName() { return this.__attr(`rankedHandName`) }
}
export function selectFromPlayerStatBestHandWon() {
  return new PlayerStatBestHandWonModelSelector()
}

export const playerStatBestHandWonModelPrimitives = selectFromPlayerStatBestHandWon().createdAt.rankedHandIndex.rankedHandName
