import React from 'react'
import { FlatList, View, ViewProps, TouchableOpacity, StyleSheet } from 'react-native'
import { Card } from '../card/card'
import globalStyles from '../../styles/globalStyle'
import { CARD } from '../../screens'

interface CardListProps {
  cards: CARD[]
  styles?: ViewProps['style']
  contentContainerStyles?: ViewProps['style']
  highlightedCards?: CARD[]
  selectedCards?: CARD[]
  selectable?: boolean
  onSelectCard?: (card: CARD) => void
}

export const CardList = ({
  cards,
  highlightedCards,
  selectedCards,
  styles,
  contentContainerStyles,
  selectable,
  onSelectCard,
}: CardListProps) => {
  const cardIsDisabled = (card: CARD): boolean => {
    return !!highlightedCards?.length && !highlightedCards?.includes(card)
  }

  const cardIsSelected = (card: CARD): boolean => {
    return !!selectedCards?.length && !!selectable && selectedCards?.includes(card)
  }

  const onPress = (card: CARD) => {
    if (!selectable || !onSelectCard) return
    onSelectCard(card)
  }

  return (
    <View style={[{ width: '100%' }, styles]}>
      <FlatList
        contentContainerStyle={[
          [{ justifyContent: 'center', width: '100%' }, contentContainerStyles],
          contentContainerStyles,
        ]}
        data={cards}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => onPress(item)}>
            <View style={[globalStyles.cardLayout, cardListStyles.cardContainer]}>
              <Card name={item} disabled={cardIsDisabled(item)} selected={cardIsSelected(item)} />
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={(item) => item + Math.random()}
        horizontal
        scrollEnabled={false}
      />
    </View>
  )
}

const cardListStyles = StyleSheet.create({
  cardContainer: {
    marginHorizontal: 8,
    backgroundColor: 'transparent',
  },
})
