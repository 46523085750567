import * as React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'

import { LinearText } from '../linearText/linearText'
import { styles } from './styles'

const apple = require('../../../assets/icons/apple-mac.png')
const google = require('../../../assets/icons/google.png')
const ShareIcon = require('../../../assets/icons/Create-Table.png')

/**
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Button(props: any) {
  const { name, type, icon, onPress, textStyle, btnStyle, colorCode = '#FFF', disabled } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled || false}
      style={[
        type === 'primary' ? styles.buttonDark : styles.buttonWhite,
        { ...btnStyle },
        { backgroundColor: colorCode },
      ]}
    >
      {icon === 'google' ? (
        <View style={[styles.containerRow, { padding: 5 }]}>
          <Image source={google} style={{ marginRight: 10, width: 20, height: 20 }} />
          <Text
            allowFontScaling={false}
            style={[
              type === 'primary' ? styles.buttonDarkText : styles.buttonWhiteText,
              { fontSize: 16, fontFamily: 'HeroNew-Regular' },
            ]}
          >
            {name}
          </Text>
        </View>
      ) : null}
      {icon === 'apple' ? (
        <View style={[styles.containerRow, { padding: 5 }]}>
          <Image
            source={apple}
            style={{ marginRight: 10, width: 25, height: 25, alignSelf: 'center' }}
            resizeMode={'contain'}
          />
          <Text
            allowFontScaling={false}
            style={[
              type === 'primary' ? styles.buttonDarkText : styles.buttonWhiteText,
              { fontSize: 16, fontFamily: 'HeroNew-Regular', paddingTop: 5 },
            ]}
          >
            {name}
          </Text>
        </View>
      ) : null}
      {type === 'primary' ? (
        <View>
          <Image style={styles.shareImageIcon} source={ShareIcon} />
        </View>
      ) : null}
      {type === 'primary' ? (
        <View style={{ flex: 2 }}>
          <LinearText />
          <Text allowFontScaling={false} style={styles.buttonTitleText}>
            Create a table, Invite your friends {'\n'}and play face-to-face
          </Text>
        </View>
      ) : null}
      {type === 'join' ? (
        <View style={styles.containerRow}>
          <Text allowFontScaling={false} style={[textStyle]}>
            {name}
          </Text>
        </View>
      ) : null}
      {['bet', 'call', 'check', 'raise', 'fold', 'allin'].includes(type) ? (
        <View style={styles.containerRow}>
          <Text allowFontScaling={false} style={[textStyle, { color: '#232335' }]}>
            {name}
          </Text>
        </View>
      ) : null}
    </TouchableOpacity>
  )
}
