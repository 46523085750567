import React from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { ModalProps } from 'react-native-modal'
import { PlayerStates } from '@poker-powher/poker'
import { PlayerType } from '../../models/session/session-game'
import { fontFamily } from '../../theme/fontFamily'
import { CommonModal } from '../CommonModal/commonModal'
import { Text } from '../text/text'
import { styles } from './styles'
export interface OptionModalProps extends ModalProps {
  visible: boolean
  onClose: () => void
  joinVideoCall: () => void
  leaveVideoCall: () => void
  hideVideo: () => void
  showVideo: () => void
  muteCall: () => void
  unMuteCall: () => void
  isLocalUserMuted: boolean
  isLocalUserVideoHidden: boolean
  isLocalUserJoined: boolean
  localUserPlayerState: string
  unseatPlayer: (value: string) => void
  isEveryoneMute: boolean
  onMuteEveryone: () => void
  onUnmuteEveryone: () => void
  isTeacher: boolean
  playerUserIdForTeacher: string
  myUserId: string
  onRemovingPlayer: () => void
  players: PlayerType[]
  onSeatBackPlayer: () => void
}

const Option = (props: any) => {
  const { handlePressAction, icon, label } = props
  return (
    <TouchableOpacity
      onPress={handlePressAction}
      style={{ flexDirection: 'row', padding: 10, paddingLeft: 20, alignItems: 'center' }}
    >
      <View style={{ padding: 5 }}>
        <Image source={icon} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
      </View>
      <Text
        allowFontScaling={false}
        tx={label}
        style={{ color: 'white', fontSize: 16, paddingHorizontal: 15, fontFamily: fontFamily.sfProTextRegular }}
      />
    </TouchableOpacity>
  )
}

export const OptionModal = (props: OptionModalProps) => {
  const {
    onClose,
    visible,
    joinVideoCall,
    leaveVideoCall,
    hideVideo,
    showVideo,
    muteCall,
    unMuteCall,
    isLocalUserJoined,
    isLocalUserVideoHidden,
    isLocalUserMuted,
    localUserPlayerState,
    unseatPlayer,
    isEveryoneMute,
    onMuteEveryone,
    onUnmuteEveryone,
    isTeacher,
    playerUserIdForTeacher,
    myUserId,
    onRemovingPlayer,
    players,
    onSeatBackPlayer,
    ...rest
  } = props

  const getPlayerStatus = (selectedUserId: string) => {
    const playerData = players.filter((player: any) => player.userId === selectedUserId)
    return playerData.length > 0 ? playerData[0].playerState.tag : ''
  }

  const modalContent = () => {
    if (isTeacher) {
      return (
        <View style={styles.modalInnerContainer}>
          {
            isLocalUserJoined ? (
              <Option
                label={'gameScreen.optionModal.leaveVideoCall'}
                icon={require('../../../assets/menu/videoOff.png')}
                handlePressAction={leaveVideoCall}
              />
            ) : (
              <Option
                label={'gameScreen.optionModal.joinVideoCall'}
                icon={require('../../../assets/menu/videoOn.png')}
                handlePressAction={joinVideoCall}
              />
            )
          }
          {
            isLocalUserMuted ? (
              <Option
                label={'gameScreen.optionModal.unmute'}
                icon={require('../../../assets/menu/mute.png')}
                handlePressAction={unMuteCall}
              />
            ) : (
              <Option
                label={'gameScreen.optionModal.mute'}
                icon={require('../../../assets/menu/microphone.png')}
                handlePressAction={muteCall}
              />
            )
          }
          {
            isLocalUserVideoHidden ? (
              <Option
                label={'gameScreen.optionModal.showVideo'}
                icon={require('../../../assets/menu/videoOff.png')}
                handlePressAction={showVideo}
              />
            ) : (
              <Option
                label={'gameScreen.optionModal.hideVideo'}
                icon={require('../../../assets/menu/videoOn.png')}
                handlePressAction={hideVideo}
              />
            )
          }
          {getPlayerStatus(playerUserIdForTeacher) === PlayerStates.ForeverOut.tag ? (
            <Option
              label={'teacherOptions.sitBack'}
              icon={require('../../../assets/menu/add-user.png')}
              handlePressAction={onSeatBackPlayer}
            />
          ) : (
            <Option
              label={myUserId === playerUserIdForTeacher ? 'teacherOptions.sitOut' : 'teacherOptions.unseatPlayer'}
              icon={require('../../../assets/menu/BlockUser.png')}
              handlePressAction={unseatPlayer}
            />
          )}
          {myUserId !== playerUserIdForTeacher && (
            <Option
              label={'teacherOptions.removePlayer'}
              icon={require('../../../assets/menu/delete-user.png')}
              handlePressAction={onRemovingPlayer}
            />
          )}
          {/* {isEveryoneMute ? (
            <Option
              label={'teacherOptions.unmuteAll'}
              icon={require('../../../assets/menu/mute.png')}
              handlePressAction={onUnmuteEveryone}
            />
          ) : (
            <Option
              label={'teacherOptions.muteAll'}
              icon={require('../../../assets/menu/microphone.png')}
              handlePressAction={onMuteEveryone}
            />
          )} */}
        </View>
      )
    }
    return (
      <View style={styles.modalInnerContainer}>
        {
          isLocalUserJoined ? (
            <Option
              label={'gameScreen.optionModal.leaveVideoCall'}
              icon={require('../../../assets/menu/videoOff.png')}
              handlePressAction={leaveVideoCall}
            />
          ) : (
            <Option
              label={'gameScreen.optionModal.joinVideoCall'}
              icon={require('../../../assets/menu/videoOn.png')}
              handlePressAction={joinVideoCall}
            />
          )
        }
        {
          isLocalUserMuted ? (
            <Option
              label={'gameScreen.optionModal.unmute'}
              icon={require('../../../assets/menu/mute.png')}
              handlePressAction={unMuteCall}
            />
          ) : (
            <Option
              label={'gameScreen.optionModal.mute'}
              icon={require('../../../assets/menu/microphone.png')}
              handlePressAction={muteCall}
            />
          )
        }
        {
          isLocalUserVideoHidden ? (
            <Option
              label={'gameScreen.optionModal.showVideo'}
              icon={require('../../../assets/menu/videoOff.png')}
              handlePressAction={showVideo}
            />
          ) : (
            <Option
              label={'gameScreen.optionModal.hideVideo'}
              icon={require('../../../assets/menu/videoOn.png')}
              handlePressAction={hideVideo}
            />
          )
        }
        {getPlayerStatus(myUserId) === PlayerStates.ForeverOut.tag && (
          <Option
            label={'teacherOptions.sitBack'}
            icon={require('../../../assets/menu/add-user.png')}
            handlePressAction={onSeatBackPlayer}
          />
        )}
      </View>
    )
  }

  return (
    <CommonModal key={'OptionModal'} {...rest} visible={visible} showCloseButton={true} onClose={onClose}>
      {modalContent()}
    </CommonModal>
  )
}
