import { getEnv, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { STAGE as EnvStage } from '@env'

import * as Storage from '../../utils/storage'
import { withRootStore } from '../extensions'
import { RootStoreEnv } from '../RootStoreEnv'

export const ENV_STORAGE_KEY = 'ENV_STORAGE_KEY'

export enum EnvironmentStages {
  local = 'local',
  dev = 'dev',
  prod = 'prod',
}

/**
 * A EnvironmentStore model.
 */

export const EnvironmentStore = types
  .model('EnvironmentStore')
  .props({
    stage: types.maybeNull(types.enumeration<EnvironmentStages>('EnvironmentStages', Object.values(EnvironmentStages))),
  })
  .extend(withRootStore)
  .views((self) => ({
    getEnv(): RootStoreEnv {
      return getEnv<RootStoreEnv>(self)
    },
    isDev() {
      return self.stage === EnvironmentStages.dev
    },
    isProd() {
      return self.stage === EnvironmentStages.prod
    },
  }))
  .actions((self) => ({
    async setStage(stage: EnvironmentStages) {
      console.log('environment-store setStage', stage)
      self.stage = stage
      await self.rootStore.telemetry.setTelemetryStage(stage)
      await Storage.save(ENV_STORAGE_KEY, stage)
      await self.rootStore.session.setAnalyticsStage(stage)
    },
  }))
  .actions((self) => {
    const afterCreate = async () => {
      const env = getEnv<RootStoreEnv>(self)
      console.log('environment-store afterCreate', EnvStage, env.stage)
      if (!self.stage) {
        self.stage = EnvStage
      }
      console.log('environment-store afterCreate', self.stage)
    }

    const beforeDestroy = () => {}

    return { afterCreate, beforeDestroy }
  })

/**
 * The EnvironmentStore instance.
 */
export type EnvironmentStoreType = Instance<typeof EnvironmentStore>

/**
 * The data of a EnvironmentStore.
 */
export type EnvironmentStoreSnapshot = SnapshotOut<typeof EnvironmentStore>
