/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameKindEnumType } from "./GameKindEnum"
import { PlayerStatBestHandWonModel, PlayerStatBestHandWonModelType } from "./PlayerStatBestHandWonModel"
import { PlayerStatBestHandWonModelSelector } from "./PlayerStatBestHandWonModel.base"
import { PlayerStatBiggestWinModel, PlayerStatBiggestWinModelType } from "./PlayerStatBiggestWinModel"
import { PlayerStatBiggestWinModelSelector } from "./PlayerStatBiggestWinModel.base"
import { StatsKindEnumType } from "./StatsKindEnum"
import { RootStoreType } from "./index"


/**
 * PlayerStatsEntityBase
 * auto generated base class for the model PlayerStatsEntityModel.
 */
export const PlayerStatsEntityModelBase = ModelBase
  .named('PlayerStatsEntity')
  .props({
    __typename: types.optional(types.literal("PlayerStatsEntity"), "PlayerStatsEntity"),
    _id: types.identifier,
    allTimeBestHandWon: types.union(types.undefined, types.null, types.late((): any => PlayerStatBestHandWonModel)),
    allTimeBiggestWin: types.union(types.undefined, types.null, types.late((): any => PlayerStatBiggestWinModel)),
    bestHandWon: types.union(types.undefined, types.null, types.late((): any => PlayerStatBestHandWonModel)),
    biggestWin: types.union(types.undefined, types.null, types.late((): any => PlayerStatBiggestWinModel)),
    createdAt: types.union(types.undefined, types.frozen()),
    gameCreatedAt: types.union(types.undefined, types.null, types.frozen()),
    gameId: types.union(types.undefined, types.frozen()),
    gameKind: types.union(types.undefined, types.null, GameKindEnumType),
    kind: types.union(types.undefined, StatsKindEnumType),
    numberOfHands: types.union(types.undefined, types.number),
    numberOfHandsPlayed: types.union(types.undefined, types.number),
    numberOfHandsWon: types.union(types.undefined, types.number),
    preflopFold: types.union(types.undefined, types.number),
    updatedAt: types.union(types.undefined, types.frozen()),
    userId: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PlayerStatsEntityModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get gameCreatedAt() { return this.__attr(`gameCreatedAt`) }
  get gameId() { return this.__attr(`gameId`) }
  get gameKind() { return this.__attr(`gameKind`) }
  get kind() { return this.__attr(`kind`) }
  get numberOfHands() { return this.__attr(`numberOfHands`) }
  get numberOfHandsPlayed() { return this.__attr(`numberOfHandsPlayed`) }
  get numberOfHandsWon() { return this.__attr(`numberOfHandsWon`) }
  get preflopFold() { return this.__attr(`preflopFold`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get userId() { return this.__attr(`userId`) }
  allTimeBestHandWon(builder?: string | PlayerStatBestHandWonModelSelector | ((selector: PlayerStatBestHandWonModelSelector) => PlayerStatBestHandWonModelSelector)) { return this.__child(`allTimeBestHandWon`, PlayerStatBestHandWonModelSelector, builder) }
  allTimeBiggestWin(builder?: string | PlayerStatBiggestWinModelSelector | ((selector: PlayerStatBiggestWinModelSelector) => PlayerStatBiggestWinModelSelector)) { return this.__child(`allTimeBiggestWin`, PlayerStatBiggestWinModelSelector, builder) }
  bestHandWon(builder?: string | PlayerStatBestHandWonModelSelector | ((selector: PlayerStatBestHandWonModelSelector) => PlayerStatBestHandWonModelSelector)) { return this.__child(`bestHandWon`, PlayerStatBestHandWonModelSelector, builder) }
  biggestWin(builder?: string | PlayerStatBiggestWinModelSelector | ((selector: PlayerStatBiggestWinModelSelector) => PlayerStatBiggestWinModelSelector)) { return this.__child(`biggestWin`, PlayerStatBiggestWinModelSelector, builder) }
}
export function selectFromPlayerStatsEntity() {
  return new PlayerStatsEntityModelSelector()
}

export const playerStatsEntityModelPrimitives = selectFromPlayerStatsEntity()._id.createdAt.gameCreatedAt.gameId.gameKind.kind.numberOfHands.numberOfHandsPlayed.numberOfHandsWon.preflopFold.updatedAt.userId
