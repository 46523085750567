export enum Options {
  joinGame = 'joinGame',
  createGame = 'createGame',
  communityGame = 'communityGame',
  miniGamesLobby = 'miniGamesLobby',
}

const ShareIcon = require('../../../assets/icons/Create-Table-Purple.png')
const JoinIcon = require('../../../assets/icons/Join-Table-Purple.png')
const CommunityGame = require('../../../assets/icons/community-game.png')
const MiniGames = require('../../../assets/icons/icon_minigame.png')

export const data = [
  {
    id: Options.joinGame,
    heading1: 'linearText.joinGame',
    heading2: '',
    description: 'lobbyScreen.inviteByCodeMessage',
    icon: JoinIcon,
    showBanner: false,
  },
  {
    id: Options.createGame,
    heading1: 'linearText.createGame',
    heading2: '',
    description: 'lobbyScreen.createGameButtonMeassage',
    icon: ShareIcon,
    showBanner: false,
  },
  {
    id: Options.communityGame,
    heading1: 'lobbyScreen.community',
    heading2: 'common.games',
    // description: 'lobbyScreen.communityGameMessage',
    description: '',
    icon: CommunityGame,
    showBanner: false,
  },
  // TODO: Comment out before release
  // {
  //   id: Options.miniGamesLobby,
  //   heading1: 'linearText.miniGame',
  //   heading2: '',
  //   description: 'lobbyScreen.miniGameMessage',
  //   icon: MiniGames,
  //   showBanner: false,
  // },
]
