import 'react-native-get-random-values'

import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { Menu } from 'react-native-paper'

import { useNavigation } from '@react-navigation/native'

import { useStore } from '../../models'
import { AdminRoutes } from '../../navigation/admin-navigator'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import styles from '../../styles/globalStyle'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

export const AdminScreen = observer(() => {
  const navigation = useNavigation()
  const store = useStore()
  const versionAndBuild = `v${DeviceInfo.getVersion()} (${DeviceInfo.getBuildNumber()}) ${store.environment.stage}`
  const closeAdminPanel = () => {
    const route = store.session.game?.gameId ? DrawerRoutes.game : DrawerRoutes.lobby
    navigation.navigate(route)
  }

  return (
    <ImageBackground source={image} style={startStyles.image}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <SafeAreaView style={styles.container}>
          <View style={{ flexGrow: 1 }}>
            <Menu.Item
              key="account-force-refresh"
              icon="cached"
              onPress={debounce(() => {
                store.session.forceWSConnect(true)
                closeAdminPanel()
              }, 250)}
              title="Force Refresh"
            />
            <Menu.Item
              key="account-force-clear-modals"
              icon="notification-clear-all"
              onPress={debounce(() => {
                store.session.forceClearModalQueue()
                closeAdminPanel()
              }, 250)}
              title="Clear Modal Queue"
            />
            <View
              style={{
                backgroundColor: 'rgb(150,150,150)',
                width: '100%',
                height: 1,
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            />
            <Menu.Item
              disabled={store.session.channel === undefined}
              key="account-group"
              icon="account-group"
              onPress={debounce(() => {
                navigation.navigate(AdminRoutes.channels)
              }, 250)}
              title="Channels"
            />
            <Menu.Item
              key="earth"
              icon="earth"
              onPress={debounce(() => {
                navigation.navigate(AdminRoutes.environment)
              }, 250)}
              title="Environment"
            />
            <Menu.Item
              key="cards-playing-outline"
              icon="cards-playing-outline"
              onPress={debounce(() => {
                navigation.navigate(AdminRoutes.games)
              }, 250)}
              title="Games"
            />
            <Menu.Item
              key="message-alert-outline"
              icon="message-alert-outline"
              onPress={debounce(() => {
                navigation.navigate(AdminRoutes.messages)
              }, 250)}
              title="Messages"
            />
            <Menu.Item
              icon="access-point"
              onPress={debounce(() => {
                navigation.navigate(AdminRoutes.network)
              }, 250)}
              title="Network"
            />
            <Menu.Item
              key="exit-to-app"
              icon="exit-to-app"
              onPress={debounce(() => {
                // navigation.goBack()
                closeAdminPanel()
              }, 250)}
              title="Leave Admin"
            />
          </View>
          <View style={{ alignItems: 'flex-end', width: '100%' }}>
            <Menu.Item title={versionAndBuild} titleStyle={{ fontSize: 14 }} />
          </View>
        </SafeAreaView>
      </View>
    </ImageBackground>
  )
})
