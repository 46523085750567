import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Card } from '../../components/card/card'
import styles from '../../styles/globalStyle'

export interface BoardProps {
  communityCards: string | string[]
  disabled: boolean
  useFourColoredCardStyle: boolean
  disabledCards?: string[]
  customCardStyle?: StyleProp<ViewStyle>
}

export const Board = React.memo((props: any) => {
  const { communityCards, disabled, useFourColoredCardStyle, disabledCards, customCardStyle } = props
  const isCardDisabled = (cardName: string) => {
    if (disabledCards && disabledCards.length > 0) {
      const index = disabledCards.indexOf(cardName)
      return index === -1 ? false : true
    } else {
      return false
    }
  }

  return (
    <>
      <View style={styles.cardRow}>
        <View
          style={[
            disabled || isCardDisabled(communityCards[0]) ? styles.disabedCardLayout : styles.cardLayout,
            customCardStyle,
          ]}
        >
          <Card name={communityCards[0]} fourColor={useFourColoredCardStyle} />
        </View>
        <View
          style={[
            disabled || isCardDisabled(communityCards[1]) ? styles.disabedCardLayout : styles.cardLayout,
            customCardStyle,
          ]}
        >
          <Card name={communityCards[1]} fourColor={useFourColoredCardStyle} />
        </View>
        <View
          style={[
            disabled || isCardDisabled(communityCards[2]) ? styles.disabedCardLayout : styles.cardLayout,
            customCardStyle,
          ]}
        >
          <Card name={communityCards[2]} fourColor={useFourColoredCardStyle} />
        </View>
        <View
          style={[
            disabled || isCardDisabled(communityCards[3]) ? styles.disabedCardLayout : styles.cardLayout,
            customCardStyle,
          ]}
        >
          <Card name={communityCards[3]} fourColor={useFourColoredCardStyle} />
        </View>
        <View
          style={[
            disabled || isCardDisabled(communityCards[4]) ? styles.disabedCardLayout : styles.cardLayout,
            customCardStyle,
          ]}
        >
          <Card name={communityCards[4]} fourColor={useFourColoredCardStyle} />
        </View>
      </View>
    </>
  )
})
