import * as MailComposer from 'expo-mail-composer'
// import FileSystem from 'expo-file-system'
import * as Print from 'expo-print'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import { MiniGamesNavigator } from './minigames-navigator'
/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React, { useEffect } from 'react'
import {
  Image,
  ImageSourcePropType,
  Linking,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  PlatformIOSStatic,
} from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { ScrollView } from 'react-native-gesture-handler'
import OneSignal from 'react-native-onesignal'
import { Switch } from 'react-native-paper'

import { createDrawerNavigator, useDrawerStatus } from '@react-navigation/drawer'
import { CommonActions, DrawerActions, NavigationProp, useNavigation } from '@react-navigation/native'

import { ActionModal } from '../components/ActionModal/actionModal'
import { BackButton } from '../components/BackButton/BackButton'
import { translate } from '../i18n'
import { useStore } from '../models'
import {
  ActiveGameScreen,
  CreateTournamentScreen,
  GameLobbyScreen,
  Join,
  LobbyScreen,
  NameScreen,
  ProfileScreen,
  TournamentPasswordScreen,
} from '../screens'
import { moderateScale, scale } from '../styles/sizes'
import { fontFamily } from '../theme/fontFamily'
import { emitter, LogoutEvent } from '../utils/events'
import { AdminNavigator, AdminRoutes } from './admin-navigator'
import { GameNavigator } from './game-navigator'
import { RootNavigationProp, RootRoutes } from './root-navigator'

const Drawer = createDrawerNavigator()

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export enum DrawerRoutes {
  admin = 'admin',
  lobby = 'lobby',
  logout = 'logout',
  game = 'game',
  join = 'join',
  share = 'share',
  buy = 'buy',
  gameLobby = 'gameLobby',
  pauseGame = 'pauseGame',
  playGame = 'playGame',
  showCards = 'showCards',
  hideCards = 'hideCards',
  show4ColorDeck = 'show4ColorDeck',
  hide4ColorDeck = 'hide4ColorDeck',
  profile = 'profile',
  notifications = 'notifications',
  name = 'name',
  signup = 'signup',
  terminateGame = 'Terminate Game',
  activeGames = 'activeGames',
  createTournament = 'createTournament',
  tournamentPassword = 'tournamentPassword',
  leaderboard = 'leaderboard',
  support = 'support',
  miniGamesLobby = 'miniGamesLobby',
}
export type DrawerNavigatorParamList = Record<keyof typeof DrawerRoutes, undefined>
export type DrawerNavigation = NavigationProp<DrawerNavigatorParamList>

interface DrawerItemsProps {
  hash?: string
  isAdmin: boolean
  isFourColoredDeckEnabled: boolean
  isTeacher: boolean
}

interface DrawerItems {
  route: DrawerRoutes
  title: string
  icon: ImageSourcePropType
  subtitle?: string
}

const drawerItems = (props: DrawerItemsProps): DrawerItems[] => {
  const { hash, isAdmin, isTeacher, isFourColoredDeckEnabled } = props
  const store = useStore()

  const TopUserControls = [
    {
      route: DrawerRoutes.profile,
      title: 'Profile',
      icon: require('../../assets/menu/user.png'),
    },
    {
      route: DrawerRoutes.notifications,
      title: 'Notifications',
      icon: require('../../assets/menu/bell.png'),
    },
  ]

  const MiniGamesPlays = [
    {
      route: DrawerRoutes.miniGamesLobby,
      title: 'Mini Games',
      icon: require('../../assets/menu/user.png'),
    },
  ]

  const TopGameControls = [
    {
      route: DrawerRoutes.lobby,
      title: 'Leave Game',
      icon: require('../../assets/menu/BackToLobby.png'),
    },
    {
      route: DrawerRoutes.share,
      title: 'Share Link',
      subtitle: hash ? `Game Code: ${hash}` : ' ',
      icon: require('../../assets/menu/ShareLink.png'),
    },
    {
      route: DrawerRoutes.buy,
      title: 'Add More Chips',
      icon: require('../../assets/menu/white-chips.png'),
    },
    {
      route: isFourColoredDeckEnabled ? DrawerRoutes.hide4ColorDeck : DrawerRoutes.show4ColorDeck,
      title: isFourColoredDeckEnabled ? 'Hide 4-Color Deck' : 'Show 4-Color Deck',
      icon: require('../../assets/menu/white-cards.png'),
    },
    {
      route: DrawerRoutes.leaderboard,
      title: 'Leaderboard',
      icon: require('../../assets/menu/leaderboard.png'),
    },
  ]

  const teacherControls = [
    {
      route: DrawerRoutes.showCards,
      title: 'Show Cards',
      icon: require('../../assets/menu/ShowCards.png'),
    },
    {
      route: DrawerRoutes.hideCards,
      title: 'Hide Cards',
      icon: require('../../assets/menu/HideCards.png'),
    },
    {
      route: DrawerRoutes.playGame,
      title: 'Play Game',
      icon: require('../../assets/menu/Play.png'),
    },
    {
      route: DrawerRoutes.pauseGame,
      title: 'Pause Game',
      icon: require('../../assets/menu/Pause.png'),
    },
    {
      route: DrawerRoutes.terminateGame,
      title: 'Terminate Game',
      icon: require('../../assets/menu/fired.png'),
    },
  ]

  const adminControls = [
    {
      route: DrawerRoutes.admin,
      title: 'Settings',
      icon: require('../../assets/menu/Admin.png'),
    },
  ]

  const signUpForPokerPower = [
    {
      route: DrawerRoutes.signup,
      title: `Sign up for Poker ${'\n'}Power Classes`,
      icon: require('../../assets/menu/new-user.png'),
    },
  ]

  const bottomUserControls = [
    {
      route: DrawerRoutes.support,
      title: 'Support',
      icon: require('../../assets/icons/chat-double-white.png'),
    },
    {
      route: DrawerRoutes.logout,
      title: 'Logout',
      icon: require('../../assets/menu/Logout.png'),
    },
  ]

  const beforeGameStart = [
    {
      route: DrawerRoutes.showCards,
      title: 'Show Cards',
      icon: require('../../assets/menu/ShowCards.png'),
    },
    {
      route: DrawerRoutes.pauseGame,
      title: 'Pause Game',
      icon: require('../../assets/menu/Pause.png'),
    },
    {
      route: DrawerRoutes.terminateGame,
      title: 'Terminate Game',
      icon: require('../../assets/menu/fired.png'),
    },
  ]

  let controls = TopUserControls
  if (store.session?.game?.gameId) {
    controls = controls.concat(TopGameControls)
    if (isTeacher || __DEV__) {
      controls = store.session.gameStartTime > 0 ? controls.concat(teacherControls) : controls.concat(beforeGameStart)
    }
    controls = controls.concat(signUpForPokerPower)
  }

  if (store.environment?.isDev() || isAdmin || __DEV__) {
    controls = controls.concat(adminControls)
  }
  controls = controls.concat(bottomUserControls)
  return controls
}

const styles = StyleSheet.create({
  drawerContainer: {
    flex: 1,
  },
  drawerHead: {
    marginTop: 32,
  },
  drawerIcon: {
    resizeMode: 'contain',
    width: 16,
    height: 16,
  },
  drawerText: {
    color: 'white',
    fontSize: scale(16),
    fontWeight: '500',
    paddingLeft: 16,
    marginTop: 2,
    fontFamily: fontFamily.sfProTextRegular,
  },
  drawerSubTitle: {
    color: 'white',
    fontSize: scale(14),
    marginTop: 3,
    paddingLeft: 42,
    fontFamily: fontFamily.sfProTextRegular,
  },
  drawerHeadBottomBorder: {
    backgroundColor: 'lightgray',
    height: 2,
  },
  drawerMenuContainer: {
    height: '100%',
    backgroundColor: 'rgba(7, 13, 55, .7)',
    borderLeftColor: '#9444fb',
    borderLeftWidth: 1,
    // alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    marginLeft: 115,
    overflow: 'hidden',
  },
  imageContainer: {
    height: 25,
    width: 25,
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  appVersionText: {
    fontSize: 12,
    color: 'grey',
    paddingLeft: 42,
    marginBottom: 40,
  },
  headerRightText: {
    fontSize: 16,
    color: '#fff',
    paddingTop: 29,
    // paddingRight: 18,
  },
})

const DrawerContent = observer((props: any) => {
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  const isDrawerOpen = useDrawerStatus() === 'open'

  const isFourColoredDeckEnabled = store.session?.isFourColoredDeckEnabled
  const isTeacher = store.session?.isTeacher
  const isAdmin = store.session?.isAdmin || store.session?.isSuperAdmin
  const isPushDisabled = store.permissions?.isPushDisabled
  const hash = store.session?.share?.hash

  const mailRecipients = ['app.support@pokerpower.com', 'poker-power-support@pineapples.dev']
  const mailBccRecipients = [
    'amitsingh@pineapples.dev',
    'harishtiwari@pineapples.dev',
    'vipin@pineapples.dev',
    'pradeep@linkites.com',
    'jspears@peak6.com',
    'mpatel@peak6.com',
    'anthony@pineapples.dev',
    'aj@pokerpower.com',
    'sebastian.kaplanski@pineapples.dev',
    'ivan.lencina@pineapples.dev',
    'franco.mitoire@pineapples.dev',
  ]

  const gotoSignup = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'auth' }],
      })
    )
  }

  useEffect(() => {
    emitter.addListener(LogoutEvent, gotoSignup)
    return () => {
      emitter.removeListener(LogoutEvent, gotoSignup)
    }
  }, [])

  const toggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer())
  }

  const onShare = async () => {
    try {
      await store.session.createShare()
      await store.session.promptShare()
      toggleDrawer()
    } catch (error) {
      console.error('onShare', error)
    }
  }

  const onLeaveGame = () => {
    console.log('onLeaveGame')
    toggleDrawer()
    store.session.createLeaveGamePrompt()
  }

  const openSupportModal = async () => {
    console.log('Support modal from drawer')

    try {
      const snap = getSnapshot(store)
      const prunedSnap = {
        session: snap.session,
        env: snap.environment,
        network: snap.network,
      }

      // NOTE: Expo's FileSystem does not work on Web. We'll probably want to switch to a form for web.
      // const fileUri = `${FileSystem.documentDirectory || FileSystem.cacheDirectory}BugReport-${new Date()}.txt`
      // await FileSystem.writeAsStringAsync(fileUri, JSON.stringify(prunedSnap), {
      //   encoding: FileSystem.EncodingType.UTF8,
      // })
      const { uri: fileUri } = await Print.printToFileAsync({
        html: JSON.stringify(prunedSnap),
      })
      const emailBody = `Please tell us ${store.session.user ? store.session.user.email : '\b'} what went wrong below:`
      const mailOptions: MailComposer.MailComposerOptions = {
        recipients: mailRecipients,
        bccRecipients: mailBccRecipients,
        subject: 'Poker Power - Bug Report',
        body: emailBody,
        attachments: [fileUri],
      }
      const canSendMail = await MailComposer.isAvailableAsync()
      if (canSendMail) {
        MailComposer.composeAsync(mailOptions).then((data) => {
          // https://docs.expo.dev/versions/latest/sdk/mail-composer/#mailcomposerstatus
          // This callback only works on iOS. vv
          if (data.status == 'sent') {
            console.log('Support email was sent.')
          }
        })
      } else {
        console.error('canSendMail is false/null/undef.', canSendMail)
      }
    } catch (error) {
      console.error('Something went wrong when sending debug info.', error)
    } finally {
      console.log('GameModal.onSendSupport finally')
      toggleDrawer()
    }
  }

  const OpenPokerPowerForSignup = () => {
    const url = 'https://pokerpower.com/free-virtual-lessons/'
    toggleDrawer()
    Linking.openURL(url)
  }

  const isOpen = useDrawerStatus() === 'open'
  const toggleNotification = () => {
    console.log('case [DrawerRoutes.notifications] isPushDisabled', isPushDisabled)
    // User wants to enable Push Notifications
    if (isPushDisabled) {
      OneSignal.promptForPushNotificationsWithUserResponse((permission) => {
        console.log('case [DrawerRoutes.notifications] permission', permission)
        OneSignal.disablePush(!permission)
        store.permissions.setIsPushDisabled(!permission)
        if (permission === false) {
          if (Platform.OS === 'ios') {
            Linking.openURL('app-settings://')
          } else {
            Linking.openSettings()
          }
        }
      })
      // User wants to disable Push Notifications
    } else {
      OneSignal.disablePush(true)
      store.permissions.setIsPushDisabled(true)
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <Pressable onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())} style={styles.drawerContainer}>
        <View style={[styles.drawerMenuContainer, { paddingTop: Platform.OS === 'android' ? 25 : 0 }]}>
          <SafeAreaView style={{ flex: 7 }}>
            <ScrollView style={{ flex: 1 }}>
              <Pressable style={{ flex: 1, marginTop: 0 }}>
                {drawerItems({ hash, isTeacher, isAdmin, isFourColoredDeckEnabled }).map((item, index) => {
                  if (!store.session?.isGamePaused && item.route === DrawerRoutes.playGame) {
                    return null
                  } else if (store.session?.isGamePaused && item.route === DrawerRoutes.pauseGame) {
                    return null
                  }
                  if (store.session?.isCardsVisible && item.route === DrawerRoutes.showCards) {
                    return null
                  } else if (!store.session?.isCardsVisible && item.route === DrawerRoutes.hideCards) {
                    return null
                  }
                  if (
                    (item.route === DrawerRoutes.buy ||
                      item.route === DrawerRoutes.pauseGame ||
                      item.route === DrawerRoutes.playGame ||
                      item.route === DrawerRoutes.showCards ||
                      item.route === DrawerRoutes.hideCards ||
                      item.route === DrawerRoutes.share) &&
                    store.session.isTournamentModeOn === true
                  ) {
                    return null
                  }
                  if (item.route === DrawerRoutes.logout || item.route === DrawerRoutes.support) {
                    return null
                  }
                  if (
                    (store.session?.isTournamentModeOn === false && item.route === DrawerRoutes.leaderboard) ||
                    (store.session?.seatedPlayers.length < 1 &&
                      store.session.isTournamentModeOn === true &&
                      item.route === DrawerRoutes.leaderboard)
                  ) {
                    return null
                  }
                  return (
                    <TouchableOpacity
                      disabled={store.session?.gameStartTime === 0 && item.route === DrawerRoutes.terminateGame}
                      key={`drawer-item-${index}`}
                      style={{
                        paddingVertical: 14,
                        paddingLeft: 34,
                        flexDirection: 'row',
                        alignItems: 'center',
                        ...(item.subtitle ? { flexWrap: 'wrap' } : null),
                      }}
                      onPress={async (e) => {
                        if (isDrawerOpen) {
                          console.log('route to', item.route)
                          e.preventDefault()
                          if (isOpen) {
                            switch (item.route) {
                              case DrawerRoutes.profile:
                                toggleDrawer()
                                navigation.navigate(DrawerRoutes.profile)
                                break
                              case DrawerRoutes.pauseGame:
                                if (store.session.gameStartTime > 0) {
                                  toggleDrawer()
                                  await store.session.pauseTheGame()
                                }
                                break
                              case DrawerRoutes.playGame:
                                toggleDrawer()
                                await store.session.resumeTheGame()
                                break
                              case DrawerRoutes.buy:
                                if (
                                  store.session.isMyPlayerFoldedOrWaiting ||
                                  (store.session.isGameReset && store.session.chipsCountOnGameReset === 0)
                                ) {
                                  toggleDrawer()
                                  store.session.setHasUserDeclinedToBuy(true)
                                  store.session.createBuyPrompt()
                                }
                                break

                              case DrawerRoutes.hide4ColorDeck:
                                store.session.setIsFourColoredDeckEnabled(false)
                                toggleDrawer()
                                break
                              case DrawerRoutes.show4ColorDeck:
                                store.session.setIsFourColoredDeckEnabled(true)
                                toggleDrawer()
                                break

                              case DrawerRoutes.admin:
                                toggleDrawer()
                                navigation.navigate(DrawerRoutes.admin)
                                break

                              case DrawerRoutes.lobby:
                                onLeaveGame()
                                break

                              case DrawerRoutes.signup:
                                OpenPokerPowerForSignup()
                                break

                              case DrawerRoutes.support:
                                openSupportModal()
                                break

                              case DrawerRoutes.logout:
                                try {
                                  toggleDrawer()
                                  gotoSignup()
                                  await store.session.leaveCurrentGame()
                                } catch (error) {
                                  console.error('drawer-navigator leaveCurentGame', error)
                                } finally {
                                  store.session.logout()
                                }
                                break

                              case DrawerRoutes.share:
                                if (Platform.OS !== 'web') {
                                  await onShare()
                                }
                                break

                              case DrawerRoutes.hideCards:
                                store.session.hideCardsForTeacher()
                                toggleDrawer()
                                break
                              case DrawerRoutes.showCards:
                                if (store.session.gameStartTime > 0) {
                                  store.session.showCardsForTeacher()
                                  toggleDrawer()
                                }
                                break

                              case DrawerRoutes.terminateGame:
                                if (store.session.gameStartTime > 0) {
                                  store.session.createTerminateModal()
                                  toggleDrawer()
                                }
                                break

                              case DrawerRoutes.leaderboard:
                                store.session.isTournamentModeOn
                                  ? store.session.setIsLeaderboardAccessedFromDrawer(true)
                                  : null
                                store.session.isTournamentModeOn ? store.session.getTournamentStats(false) : null
                                toggleDrawer()
                                break

                              case DrawerRoutes.notifications:
                                toggleNotification()
                                break
                            }
                          } else {
                            toggleDrawer()
                            console.warn('Tried to press item in drawer while drawer is closed..')
                          }
                        }
                      }}
                    >
                      <View style={styles.imageContainer}>
                        <Image resizeMode="contain" style={{ flex: 1 }} source={item.icon} />
                      </View>
                      {item.title ? (
                        <Text
                          allowFontScaling={false}
                          style={[
                            styles.drawerText,
                            {
                              color:
                                item.route === DrawerRoutes.buy
                                  ? store.session.isMyPlayerFoldedOrWaiting ||
                                    (store.session.isGameReset && store.session.chipsCountOnGameReset === 0)
                                    ? 'white'
                                    : 'grey'
                                  : item.route === DrawerRoutes.showCards ||
                                    item.route === DrawerRoutes.pauseGame ||
                                    item.route === DrawerRoutes.terminateGame
                                  ? store.session.gameStartTime > 0
                                    ? 'white'
                                    : 'grey'
                                  : 'white',
                            },
                          ]}
                        >
                          {item.title}
                        </Text>
                      ) : null}
                      {item.subtitle ? (
                        <Text allowFontScaling={false} style={styles.drawerSubTitle}>
                          {item.subtitle}
                        </Text>
                      ) : null}
                      {item.route === DrawerRoutes.notifications ? (
                        <Switch
                          onValueChange={(value: boolean) => {
                            toggleNotification()
                          }}
                          style={{ marginLeft: 28 }}
                          value={isPushDisabled === false}
                        ></Switch>
                      ) : null}
                    </TouchableOpacity>
                  )
                })}
              </Pressable>
            </ScrollView>
          </SafeAreaView>
          <Pressable style={{ flex: 1, marginBottom: 20 }}>
            {drawerItems({ hash, isTeacher, isAdmin, isFourColoredDeckEnabled }).map((item, index) => {
              if (item.route !== DrawerRoutes.logout && item.route !== DrawerRoutes.support) {
                return null
              }

              return (
                <TouchableOpacity
                  key={`drawer-item-${index}`}
                  style={{
                    paddingVertical: 14,
                    paddingLeft: 34,
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...(item.route === DrawerRoutes.logout
                      ? { position: 'relative' }
                      : item.route === DrawerRoutes.support
                      ? { position: 'relative' }
                      : null),
                    ...(item.subtitle ? { flexWrap: 'wrap' } : null),
                  }}
                  onPress={async (e) => {
                    if (isDrawerOpen) {
                      console.log('route to', item.route)
                      e.preventDefault()
                      if (isOpen) {
                        switch (item.route) {
                          case DrawerRoutes.support:
                            openSupportModal()
                            break

                          case DrawerRoutes.logout:
                            try {
                              toggleDrawer()
                              gotoSignup()
                              await store.session.leaveCurrentGame()
                            } catch (error) {
                              console.error('drawer-navigator leaveCurentGame', error)
                            } finally {
                              store.session.logout()
                            }
                            break
                        }
                      } else {
                        toggleDrawer()
                        console.warn('Tried to press item in drawer while drawer is closed..')
                      }
                    }
                  }}
                >
                  <View style={styles.imageContainer}>
                    <Image resizeMode="contain" style={{ flex: 1 }} source={item.icon} />
                  </View>
                  {item.title ? (
                    <Text allowFontScaling={false} style={[styles.drawerText, {}]}>
                      {item.title}
                    </Text>
                  ) : null}
                  {item.subtitle ? (
                    <Text allowFontScaling={false} style={styles.drawerSubTitle}>
                      {item.subtitle}
                    </Text>
                  ) : null}
                </TouchableOpacity>
              )
            })}
          </Pressable>
          <Text style={styles.appVersionText}>{`v${DeviceInfo.getVersion()} (${DeviceInfo.getBuildNumber()})`}</Text>
        </View>
      </Pressable>
    </View>
  )
})

export const DrawerHamburgerButton = () => {
  const navigation = useNavigation<DrawerNavigation>()

  return (
    <TouchableOpacity
      hitSlop={{ top: 10, bottom: 20, left: 10, right: 20 }}
      onPress={async () => {
        navigation.dispatch(DrawerActions.toggleDrawer())
      }}
      style={{ paddingTop: 0, paddingRight: 10, marginBottom: 5 }}
    >
      <Image
        style={{ height: moderateScale(14.88), width: 24, marginLeft: 7 }}
        source={require('../assets/Group.png')}
      />
    </TouchableOpacity>
  )
}

export const DrawerNavigator = observer((props: any) => {
  const navigation = useNavigation<DrawerNavigation & RootNavigationProp>()
  const store = useStore()

  const [visible, setVisible] = React.useState(false)

  const navToAuth = () => {
    // Note:- Action Modal from gameModal.tsx is not loaded yet.
    store.session.logout()
    navigation.navigate(RootRoutes.auth)
  }

  const navToLobby = () => {
    store.session.clearGameModalQueue()
    navigation.navigate(DrawerRoutes.lobby)
  }

  const navToActiveGames = () => {
    navigation.navigate(DrawerRoutes.activeGames)
  }

  // const initialRouteName = store?.session?.channel ?? undefined !== undefined ? 'game' : 'name'
  const initialRouteName = store?.session?.channel ?? undefined !== undefined ? DrawerRoutes.game : DrawerRoutes.name
  // store.session.channel !== undefined ? 'game' : store.session.joinCode !== null ? 'join' : 'lobby'
  console.log('DrawerNavigator initialRouteName', initialRouteName)

  const headerLeft = () => {
    return null
    // return (
    //   <TouchableOpacity
    //     hitSlop={{ top: 10, bottom: 20, left: 10, right: 20 }}
    //     onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
    //     style={{ paddingTop: 29, paddingLeft: 2 }}
    //   >
    //     <Image
    //       style={{ height: moderateScale(14.88), width: 24, marginLeft: 7 }}
    //       source={require('../assets/Group.png')}
    //     />
    //   </TouchableOpacity>
    // )
  }

  const headerRight = () => {
    return (
      <TouchableOpacity
        hitSlop={{ top: 10, bottom: 20, left: 10, right: 20 }}
        onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
        style={{ paddingTop: 0, paddingRight: 10 }}
      >
        <Image
          style={{ height: moderateScale(14.88), width: 24, marginLeft: 7 }}
          source={require('../assets/Group.png')}
        />
      </TouchableOpacity>
    )
  }

  return (
    <React.Fragment>
      <ActionModal
        key={'logout'}
        onAction={navToAuth}
        onModalHide={() => setVisible(false)}
        onClose={() => setVisible(false)}
        message={translate('common.logoutConfirmation')}
        visible={visible}
      />
      <Drawer.Navigator
        initialRouteName={initialRouteName}
        drawerContent={(drawerProps) => <DrawerContent {...drawerProps} />}
        screenOptions={{
          gestureEnabled: false,
          overlayColor: 'transparent',
          drawerType: 'front',
          drawerPosition: 'right',
          drawerStyle: {
            backgroundColor: 'transparent',
            width: '100%',
          },
          headerShown: true,
          headerStyle: { borderBottomWidth: 0, backgroundColor: 'rgb(49, 22, 94)', height: 86 },
        }}
      >
        <Drawer.Screen
          name={DrawerRoutes.lobby}
          component={LobbyScreen}
          options={{
            headerLeft: () => (
              <View
                style={[
                  {
                    width: isIPad ? '60%' : '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  },
                ]}
              >
                <BackButton navigate={() => setVisible(true)} />
              </View>
            ),
            headerTitle: () => (
              <View
                style={[
                  {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={[{ color: 'white', fontSize: 18, fontWeight: '500', paddingHorizontal: 5 }]}></Text>
              </View>
            ),
            headerRight: () => <DrawerHamburgerButton />,
            headerLeftContainerStyle: {
              flex: 1.5,
            },
            headerTitleContainerStyle: {
              flex: 2,
            },
            headerRightContainerStyle: {
              flex: 1.5,
            },
          }}
        />
        <Drawer.Screen
          name={AdminRoutes.admin}
          component={AdminNavigator}
          options={{ gestureEnabled: false, swipeEnabled: false, headerLeft, headerRight }}
          // options={{ ...drawerOptions, headerTitleAlign: 'center',headerBackgroundContainerStyle: {backgroundColor: 'white'},  gestureEnabled: false, swipeEnabled: false }}
        />
        <Drawer.Screen
          name={DrawerRoutes.join}
          component={Join}
          options={{
            headerLeft: () => (
              <View
                style={[
                  {
                    width: isIPad ? '60%' : '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  },
                ]}
              >
                <BackButton navigate={navToLobby} />
              </View>
            ),
            headerTitle: () => (
              <View
                style={[
                  {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={[{ color: 'white', fontSize: 18, fontWeight: '500', paddingHorizontal: 5 }]}> </Text>
              </View>
            ),
            headerRight: () => <DrawerHamburgerButton />,
            headerLeftContainerStyle: {
              flex: 1.5,
            },
            headerTitleContainerStyle: {
              flex: 2,
            },
            headerRightContainerStyle: {
              flex: 1.5,
            },
          }}
        />
        <Drawer.Screen name={DrawerRoutes.name} component={NameScreen} options={{ headerShown: false }} />

        <Drawer.Screen
          name={DrawerRoutes.profile}
          component={ProfileScreen}
          options={{
            headerShown: false,
            title: '',
            headerStyle: { backgroundColor: '#0D2063', shadowColor: '#0D2063' },
          }}
        />
        {/* <Drawer.Screen name={DrawerRoutes.gameLobby} component={GameLobbyScreen} options={{ headerShown: true, title: '',  headerStyle: {backgroundColor: '#0D2063', shadowColor: '#0D2063'} }} /> */}
        <Drawer.Screen
          name={DrawerRoutes.gameLobby}
          component={GameLobbyScreen}
          options={{
            headerShown: true,
            title: '',
            headerStyle: { backgroundColor: '#0D2063', shadowColor: '#0D2063' },
          }}
        />
        <Drawer.Screen
          name={DrawerRoutes.activeGames}
          component={ActiveGameScreen}
          options={{
            headerLeft: () => (
              <View
                style={[
                  {
                    width: isIPad ? '60%' : '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  },
                ]}
              >
                <BackButton navigate={navToLobby} />
              </View>
            ),
            headerTitle: () => (
              <View
                style={[
                  {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={[{ color: 'white', fontSize: 18, fontWeight: '500', paddingHorizontal: 5 }]}> </Text>
              </View>
            ),
            headerRight: () => <DrawerHamburgerButton />,
            headerStyle: {
              backgroundColor: 'rgb(5, 16,45)',
            },
            headerLeftContainerStyle: {
              flex: 1.5,
            },
            headerTitleContainerStyle: {
              flex: 2,
            },
            headerRightContainerStyle: {
              flex: 1.5,
            },
          }}

          // options={{
          //   headerShown: true,
          //   title: '',
          //   headerStyle: { backgroundColor: 'rgb(5, 16, 45)', shadowColor: '#0D2063' },
          // }}
        />
        <Drawer.Screen
          name={DrawerRoutes.createTournament}
          component={CreateTournamentScreen}
          options={{
            headerLeft: () => (
              <View
                style={[
                  {
                    width: isIPad ? '60%' : '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  },
                ]}
              >
                <BackButton navigate={navToActiveGames} />
              </View>
            ),
            headerTitle: () => (
              <View
                style={[
                  {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={[{ color: 'white', fontSize: 18, fontWeight: '500', paddingHorizontal: 5 }]}> </Text>
              </View>
            ),
            headerRight: () => <DrawerHamburgerButton />,
            headerStyle: {
              backgroundColor: 'rgb(5, 16,45)',
            },
            headerLeftContainerStyle: {
              flex: 1.5,
            },
            headerTitleContainerStyle: {
              flex: 2,
            },
            headerRightContainerStyle: {
              flex: 1.5,
            },
          }}
        />
        <Drawer.Screen
          name={DrawerRoutes.tournamentPassword}
          component={TournamentPasswordScreen}
          options={{
            headerLeft: () => (
              <View
                style={[
                  {
                    width: isIPad ? '60%' : '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                  },
                ]}
              >
                <BackButton navigate={navToLobby} />
              </View>
            ),
            headerTitle: () => (
              <View
                style={[
                  {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
              >
                <Text style={[{ color: 'white', fontSize: 18, fontWeight: '500', paddingHorizontal: 5 }]}> </Text>
              </View>
            ),
            headerRight: () => <DrawerHamburgerButton />,
            headerLeftContainerStyle: {
              flex: 1.5,
            },
            headerTitleContainerStyle: {
              flex: 2,
            },
            headerRightContainerStyle: {
              flex: 1.5,
            },
          }}
        />
        <Drawer.Screen
          name={DrawerRoutes.miniGamesLobby}
          component={MiniGamesNavigator}
          options={{ headerShown: false }}
        />

        <Drawer.Screen name={DrawerRoutes.game} component={GameNavigator} options={{ headerShown: false }} />
      </Drawer.Navigator>
    </React.Fragment>
  )
})
