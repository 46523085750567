import { debounce } from 'lodash'
import * as React from 'react'
import { Image, Platform, StyleProp, ViewProps, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Pot as PotType } from '@poker-powher/poker'

import { numberWithCommas } from '../../utils/numberWithCommas'
import { Text } from '../text/text'
import { styles } from './styles'

interface PotProps extends ViewProps {
  points: number
  pots: PotType[]
  viewPotPlayers?: () => void
  potChipsType?: PotChipsType
  customPotContainerStyle?: StyleProp<ViewStyle>
  hideSidepot?: boolean
}

export enum PotChipsType {
  whiteChips,
  yellowChips,
}

const WhiteChips = require('../../assets/ChipsWhite.png')
const YellowChips = require('../../assets/ChipsYellow.png')

export const Pot = React.memo((props: PotProps) => {
  const { points, pots, viewPotPlayers, potChipsType, customPotContainerStyle, hideSidepot } = props
  const potCount = pots.length
  // const sidePotText = potCount ? (potCount === 1 ? `${potCount} side pot` : `${potCount} side pots`) : ''
  const onPressPotPlayers = debounce(() => {
    if (potCount > 0) {
      viewPotPlayers && viewPotPlayers()
    }
  }, 250)

  return (
    <>
      <TouchableOpacity
        onPress={onPressPotPlayers}
        disabled={potCount > 0 ? false : true}
        style={[
          styles.potContainer,
          {
            backgroundColor: Platform.OS === 'web' ? 'rgb(5, 16,45)' : 'transparent',
          },
          customPotContainerStyle,
        ]}
      >
        <Image style={styles.chips} source={potChipsType === PotChipsType.yellowChips ? YellowChips : WhiteChips} />
        <Text
          allowFontScaling={false}
          tx="gameScreen.formattedNumber"
          txOptions={{ value: numberWithCommas(points) }}
          style={[
            styles.chipsCount,
            {
              textAlign: Platform.OS === 'web' ? 'left' : 'right',
            },
          ]}
        />
        {potCount > 0 && !!!hideSidepot ? (
          <Text
            allowFontScaling={false}
            tx={potCount > 1 ? 'gameScreen.multipleSidePots' : 'gameScreen.singleSidePot'}
            txOptions={potCount > 1 ? { count: potCount } : {}}
            style={styles.sidepots}
          />
        ) : null}
      </TouchableOpacity>
    </>
  )
})
