/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum StatsKind {
  game="game",
player="player"
}

/**
* StatsKind
 *
 * The specific kind of different stats
*/
export const StatsKindEnumType = types.enumeration("StatsKind", [
        "game",
  "player",
      ])
