import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  pokerTableContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pokerTable: {
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#03B0FA',
    minHeight: '25%',
    width: '80%',
    borderRadius: 60,
  },
  rightPanelContainer: {
    flex: 2,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftPanelContainer: {
    flex: 2,
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  pokerCoinImage: {
    width: 45,
    height: 57,
  },
  totalChipsText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 12,
    fontWeight: '900',
    fontFamily: 'SFProDisplay-Bold',
  },
})
