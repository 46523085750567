import { getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { ModalProps } from 'react-native'

import { GameSnapshotModel, GameSnapshotModelType } from '../../api-store'
import { withRootStore } from '../../extensions'

export enum GameModalKind {
  allIn = 'allIn',
  bet = 'bet',
  buy = 'buy',
  leaveGame = 'leaveGame',
  winningHand = 'winningHand',
  options = 'options',
  joinVideo = 'joinVideo',
  pot = 'pot',
  gameDeleted = 'gameDeleted',
  sidepotPlayers = 'sidepotPlayers',
  gameStartTimer = 'gameStartTimer',
  gamePauseTimer = 'gamePauseTimer',
  teacherOptions = 'teacherOptions',
  terminateGame = 'terminateGame',
  playerOutOfChips = 'playerOutOfChips',
  leaderBoard = 'leaderBoard',
  support = 'support',
}

export enum LayerType {
  alert = 'alert',
  toast = 'toast',
  activityIndicator = 'activityIndicator',
  prompts = 'prompts',
  messages = 'messages',
  screen = 'screen',
}

export const GameWinner = types.model('GameWinner').props({
  winningText: types.optional(types.string, ''),
  chipsWon: types.optional(types.number, 0),
  holeCards: types.optional(types.array(types.string), ['blank', 'blank']),
  winningCards: types.optional(types.array(types.string), ['blank', 'blank', 'blank', 'blank', 'blank']),
  mainPot: types.optional(types.number, 0),
  sidePot: types.maybe(types.frozen()),
})

export const GameModal = types.model('GameModal').props({
  game: types.maybe(GameSnapshotModel),
  props: types.frozen<ModalProps>(),
  kind: types.enumeration<GameModalKind>('GameModalKind', Object.values(GameModalKind)),
  layerType: types.enumeration<LayerType>('LayerType', Object.values(LayerType)),
  isBlocking: types.optional(types.boolean, false),
  timeToKeepOpen: types.optional(types.number, 0),
  gameWinner: types.optional(GameWinner, {}),
  message: types.optional(types.string, ''),
})

export type GameModalType = Instance<typeof GameModal>
export type GameWinnerType = Instance<typeof GameWinner>

export const GameModalQueue = types
  .model('GameModalQueue')
  .props({
    modalQueue: types.optional(types.array(GameModal), []),
  })
  .extend(withRootStore)
  .views((self) => ({
    getFirstGameModal(): GameModalType | undefined {
      if (self.modalQueue.length > 0) {
        return self.modalQueue[0]
      }
      return undefined
    },
  }))
  .actions((self) => ({
    pushGameModals(data: GameModalType) {
      return self.modalQueue.push(GameModal.create(data))
    },
    shiftGameModals() {
      return self.modalQueue.shift()
    },
    clearGameModalQueue() {
      self.modalQueue.clear()
    },
    createBetPrompt() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.bet,
          isBlocking: false,
          layerType: LayerType.prompts,
          timeToKeepOpen: 0,
        })
      )
    },
    createBuyPrompt() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.buy,
          isBlocking: false,
          layerType: LayerType.prompts,
          timeToKeepOpen: 0,
        })
      )
    },
    createLeaveGamePrompt() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.leaveGame,
          isBlocking: false,
          layerType: LayerType.prompts,
          timeToKeepOpen: 0,
        })
      )
    },
    createAllInModal() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.allIn,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: 5,
        })
      )
    },
    createWinningHandModal(data: GameSnapshotModelType, gameWinnerData: GameWinnerType) {
      self.modalQueue.push(
        GameModal.create({
          game: data,
          props: {},
          kind: GameModalKind.winningHand,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: 5,
          gameWinner: gameWinnerData,
        })
      )
    },
    createOptionsPrompt() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.options,
          isBlocking: false,
          layerType: LayerType.prompts,
          timeToKeepOpen: 0,
        })
      )
    },
    createJoinVideoPrompt() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.joinVideo,
          isBlocking: false,
          layerType: LayerType.prompts,
          timeToKeepOpen: 0,
        })
      )
    },
    createPotModal() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.pot,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: 5,
        })
      )
    },
    createTerminateModal() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.terminateGame,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: 0,
        })
      )
    },
    createGameDeletedAlert() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.gameDeleted,
          isBlocking: true,
          layerType: LayerType.messages,
          timeToKeepOpen: 0,
        })
      )
      // self.modalQueue.push(
      //   GameModal.create({
      //     props: {},
      //     kind: GameModalKind.gameDeleted,
      //     isBlocking: false,
      //     layerType: LayerType.alert,
      //     timeToKeepOpen: 0,
      //   })
      // )
    },
    createSidepotPlayer(message: string) {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.sidepotPlayers,
          isBlocking: false,
          layerType: LayerType.alert,
          timeToKeepOpen: 5,
          message: message,
        })
      )
    },
    createLeaderBoard(autodismiss: boolean) {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.leaderBoard,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: !self.rootStore.session.isGameOver && autodismiss ? 5 : 0
          // timeToKeepOpen: self.rootStore.session.isGameOver || self.rootStore.session.players.length === 1 === true ? 0 : 5,
        })
      )
    },
    createPlayerOutOfChipsModal() {
      self.modalQueue.push(
        GameModal.create({
          props: {},
          kind: GameModalKind.playerOutOfChips,
          isBlocking: false,
          layerType: LayerType.messages,
          timeToKeepOpen: 5,
        })
      )
    },
    // createGameStartTimerModal(time?: number) {
    //   self.modalQueue.push(
    //     GameModal.create({
    //       props: {},
    //       kind: GameModalKind.gameStartTimer,
    //       isBlocking: false,
    //       layerType: LayerType.alert,
    //       timeToKeepOpen: time ? time : 60,
    //     })
    //   )
    // },
    // createGamePauseTimerModal(time?: number) {
    //   self.modalQueue.push(
    //     GameModal.create({
    //       props: {},
    //       kind: GameModalKind.gamePauseTimer,
    //       isBlocking: false,
    //       layerType: LayerType.alert,
    //       timeToKeepOpen: time ? time : 30,
    //     })
    //   )
    // },
  }))

/**
 * The GameModalQueueStore instance.
 */
export type GameModalQueueStore = Instance<typeof GameModalQueue>

/**
 * The data of a GameModalQueueStore.
 */
export type GameModalQueueStoreSnapshot = SnapshotOut<typeof GameModalQueue>
