import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Snackbar } from 'react-native-paper'

import { useStore } from '../models'

export const Toast = observer(() => {
  const store = useStore()
  const visible = store.session.showSnackbar && store.session.snack !== undefined
  return (
    <Snackbar
      visible={visible}
      onDismiss={debounce(() => store.session.clearSnack())}
      action={{
        label: 'Dismiss',
        // onPress: () => {},
      }}
    >
      {store.session.snack}
    </Snackbar>
  )
})
