import React, { useState } from 'react'
import { Text, View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import { Question, Option, answerIsIdType, CARDS, answerIsCorrect } from '../../screens'

interface QuestionFeedbackProps {
  selectedOption: Option
  question: Question
  selectedCards?: CARDS[]
  onPress: () => void
}

export const QuestionFeedback = ({ selectedOption, question, selectedCards = [], onPress }: QuestionFeedbackProps) => {
  const elementToCompare = answerIsIdType(question.correctAnswer) ? selectedOption.id : selectedCards
  const isCorrect = answerIsCorrect(question.correctAnswer, elementToCompare)

  return (
    <View style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
      {isCorrect && (
        <Image style={{ alignSelf: 'center', marginBottom: 5 }} source={require('../../assets/you-got-it.png')}></Image>
      )}
      {!isCorrect && (
        <Image style={{ alignSelf: 'center', marginBottom: 5 }} source={require('../../assets/oops.png')}></Image>
      )}
      {isCorrect && question.correctCaption && <Text style={styles.text}>{question.correctCaption}</Text>}
      {!isCorrect && question.incorrectCaption && <Text style={styles.text}>{question.incorrectCaption}</Text>}
      {question.funFact && (
        <TouchableOpacity onPress={onPress} style={styles.text}>
          <Text style={styles.link}> Why?</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  text: { color: 'white', textAlign: 'center', maxWidth: '80%', alignSelf: 'center' },
  link: { color: '#49CDF2', textAlign: 'center', alignSelf: 'center' },
})
