import { RouteProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Image, Text, StyleSheet } from 'react-native'
import { CardList } from '../../components/CardList/CardList'
import { PaddedLayout } from '../../components/layouts/PaddedLayout'
import { SecondaryButton } from '../../components/SecondaryButton/SecondaryButton'
import { MiniGamesRoutes, MiniGamesNavigation } from '../../navigation/minigames-navigator'
import { gamesData } from './data'
import { Game, isSurveyGame } from './interfaces/mini-games.interface'
import { fontFamily } from '../../theme/fontFamily'

interface IntroScreenProps {
  route: RouteProp<{ params: { gameName: string } }>
}

export const IntroScreen = ({ route }: IntroScreenProps) => {
  const [gameData, setGameData] = useState<Game>()
  const navigation = useNavigation<MiniGamesNavigation>()
  const { gameName } = route.params

  useEffect(() => {
    const currentGame = gamesData.find((game: Game) => game.name === gameName)

    setGameData(currentGame)
  }, [gameName])

  if (!gameData) return null

  return (
    <PaddedLayout>
      {gameData && <Image style={styles.image} source={gameData.img}></Image>}

      {gameData && <CardList cards={gameData.cards}></CardList>}

      {gameData && <Text style={styles.description}>{gameData.gameDescription}</Text>}

      <SecondaryButton
        textProps={{ tx: 'miniGamesScreen.introScreen.startGameButton', style: styles.buttonText }}
        onPress={() =>
          navigation.navigate(
            isSurveyGame(gameData) ? MiniGamesRoutes.surveyGameScreen : MiniGamesRoutes.versusGameScreen,
            {
              gameName,
            }
          )
        }
      ></SecondaryButton>
    </PaddedLayout>
  )
}

const styles = StyleSheet.create({
  image: {
    marginBottom: 30,
    alignSelf: 'center',
  },
  description: {
    fontFamily: fontFamily.sfProDisplayRegular,
    fontSize: 14,
    lineHeight: 17,
    textAlign: 'center',
    alignSelf: 'center',
    color: '#FFFFFF',
    marginVertical: 25,
    maxWidth: '70%',
  },
  buttonText: {
    fontFamily: fontFamily.sfProDisplayBlackItalic,
    fontSize: 18,
    lineHeight: 21,
  },
})
