/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { LoginFromProviderEnumType } from "./LoginFromProviderEnum"
import { UserRolesEnumType } from "./UserRolesEnum"
import { RootStoreType } from "./index"


/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    _id: types.identifier,
    avatar: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    email: types.union(types.undefined, types.string),
    isGuest: types.union(types.undefined, types.null, types.boolean),
    loginFrom: types.union(types.undefined, LoginFromProviderEnumType),
    name: types.union(types.undefined, types.string),
    phoneNumber: types.union(types.undefined, types.string),
    socialId: types.union(types.undefined, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
    userRoles: types.union(types.undefined, types.array(UserRolesEnumType)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get avatar() { return this.__attr(`avatar`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get email() { return this.__attr(`email`) }
  get isGuest() { return this.__attr(`isGuest`) }
  get loginFrom() { return this.__attr(`loginFrom`) }
  get name() { return this.__attr(`name`) }
  get phoneNumber() { return this.__attr(`phoneNumber`) }
  get socialId() { return this.__attr(`socialId`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get userRoles() { return this.__attr(`userRoles`) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser()._id.avatar.createdAt.email.isGuest.loginFrom.name.phoneNumber.socialId.updatedAt.userRoles
