'use strict'
import { StyleSheet } from 'react-native'
import { moderateScale, scale } from '../../styles/sizes'
import { color } from '../../theme'
import { fontFamily } from '../../theme/fontFamily'

export const styles = StyleSheet.create({
  cardMain: {
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    height: 95,
    margin: 3,
    // opacity: 0.9,
    // backgroundColor:  'red',//color.palette.white,
    // borderRadius: 10,
  },
  cardBackgroundImage: {
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    // backgroundColor: color.palette.black,
    // opacity: 0.8,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  cardRank: {
    position: 'absolute',
    top: 9,
    right: 10,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 16,
    color: color.palette.white,
  },
  cardInnerBottom: {
    flexDirection: 'row',
    width: scale(160),
    height: 41,
    backgroundColor: color.palette.white,
  },
  cardUserImage: {
    top: 8,
    left: 5,
    width: 25,
    height: 25,
    resizeMode: 'cover',
  },
  cardUserInfo: {
    width: scale(90),
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: color.palette.white,
    marginHorizontal: 5,
    paddingHorizontal: 5,
  },
  cardUserName: {
    width: scale(64),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 12,
    letterSpacing: -0.24,
    color: color.palette.darkBlack,
  },
  cardUserTime: {
    position: 'absolute',
    top: 142,
    left: 104,
    width: scale(49),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'right',
    color: color.palette.white,
  },
  cardUserScore: {
    position: 'absolute',
    bottom: 0,
    width: scale(49),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'right',
    color: 'green', //color.palette.darkBlack,
  },
  cardUserTimeAgo: {
    width: scale(38),
    fontSize: 8,
    lineHeight: 10,
    letterSpacing: -0.24,
    color: 'rgba(60, 60, 67, 0.6)',
  },
  cardUserDuration: {
    width: scale(34),
    fontSize: 8,
    fontWeight: '500',
    lineHeight: 10,
    alignSelf: 'center',
    color: color.palette.darkBlack,
  },
  potContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: 8,
    minWidth: 90,
  },
  chips: {
    width: moderateScale(72),
    height: moderateScale(17),
    resizeMode: 'contain',
  },
  chipsCount: {
    fontSize: moderateScale(24),
    fontWeight: '900',
    color: '#FFFFFF',
    lineHeight: moderateScale(29),
    fontFamily: fontFamily.sfProDisplayHeavy
  },
  sidepots: {
    fontSize: moderateScale(13),
    fontWeight: '500',
    textDecorationLine: 'underline',
    color: '#FFBB0E',
    lineHeight: moderateScale(16),
    fontFamily: fontFamily.sfProDisplayBlack
  },
})
