import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { Platform } from 'react-native'

// import Share, { ShareOptions } from 'react-native-share'
import { MappingError } from '../../utils/errors/errors.mapping'
import { ShareModel, ShareModelType } from '../api-store'
import { withRootStore } from '../extensions'
import { shareIcon } from './session-share-icon'

/**
 * A SessionShareStore model.
 */

export const SessionShareStoreModel = types
  .model('SessionShareStoreModel')
  .props({
    share: types.maybe(ShareModel),
  })
  .extend(withRootStore)
//   .actions((self) => ({
//     clearShare() {
//       self.share = undefined
//     },
//     setShare(data: ShareModelType) {
//       self.share = data
//     },
//   }))
//   .actions((self) => ({
//     async fetchShare(hash: string) {
//       console.log('fetchShare', hash)
//       try {
//         const response = await self.rootStore.api.queryGetShareByHash({ hash })
//         if (ShareModel.is(response.getShareByHash)) {
//           self.rootStore.session.setShare(response.getShareByHash)
//         } else {
//           MappingError.check(ShareModel.name, ShareModel, response.getShareByHash)
//         }
//       } catch (error) {
//         console.error('queryGetShareByHash', error)
//         throw error
//       }
//     },
//     async createShare(channelNameForShare?: string) {
//       const channelName = channelNameForShare || self.rootStore.session.channel?.channelName
//       const userId = self.rootStore.session.user?._id
//       console.log('createShare channelName', channelName)
//       console.log('createShare userId', userId)
//       if (channelName && userId) {
//         const firstName = self.rootStore.session.firstName
//         try {
//           const response = await self.rootStore.api.mutateCreateShare({
//             channelName,
//             firstName,
//             hash: '',
//             message: '',
//             url: '',
//             userId: userId,
//           })
//           if (ShareModel.is(response.createShare)) {
//             self.rootStore.session.setShare(response.createShare)
//           } else {
//             MappingError.check(ShareModel.name, ShareModel, response.createShare)
//           }
//         } catch (error) {
//           console.error('mutateCreateShare', error)
//           throw error
//         }
//       }
//     },
//     async promptShare() {
//       const url = self.share?.url
//       const title = self.share?.message
//       const message = self.share?.message
//       const icon = shareIcon

//       const options = Platform.select({
//         ios: {
//           activityItemSources: [
//             // {
//             //   // For sharing url with custom title.
//             //   placeholderItem: { type: 'url', content: url },
//             //   item: {
//             //     default: { type: 'url', content: url },
//             //   },
//             //   subject: {
//             //     default: title,
//             //   },
//             //   linkMetadata: { originalUrl: url, url, title },
//             // },
//             // {
//             //   // For sharing text.
//             //   placeholderItem: { type: 'text', content: message },
//             //   item: {
//             //     default: { type: 'text', content: message },
//             //     message: null, // Specify no text to share via Messages app.
//             //   },
//             //   linkMetadata: {
//             //     // For showing app icon on share preview.
//             //     title: message,
//             //   },
//             // },
//             {
//               // For using custom icon instead of default text icon at share preview when sharing with message.
//               placeholderItem: {
//                 type: 'url',
//                 content: icon,
//               },
//               item: {
//                 default: {
//                   type: 'text',
//                   content: `${message} ${url}`,
//                 },
//               },
//               linkMetadata: {
//                 title: message,
//                 icon: icon,
//               },
//             },
//           ],
//         },
//         default: {
//           title,
//           subject: title,
//           message: `${message} ${url}`,
//         },
//       }) as ShareOptions

//       console.log('session-share prompt', options)

//       await Share.open(options)
//     },
//   }))
// .actions((self) => ({
//   setShare(user: Partial<ShareModelType>) {
//     const keys = Object.keys(user) as Array<keyof ShareModelType>
//     keys.forEach((key) => {
//       const value = user[key]
//       console.log('casedKey', { key, value })
//       if (value && Object.prototype.hasOwnProperty.call(self, key)) {
//         self[key] = value
//       }
//     })
//   },
// }))

/**
 * The SessionShareStore instance.
 */
export type SessionShareStore = Instance<typeof SessionShareStoreModel>

/**
 * The data of a SessionShareStore.
 */
export type SessionShareStoreSnapshot = SnapshotOut<typeof SessionShareStoreModel>
