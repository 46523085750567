import React, { useState, useEffect } from 'react'
import { Text, View } from 'react-native'
import { SecondaryButton } from '../SecondaryButton/SecondaryButton'
import { Option } from '../../screens'

interface OptionsButtonsProps {
  options: Option[]
  selectedAnswer: Option | undefined
  onSelectAnswer: (option: Option) => void
}

export const OptionsButtons = ({ options, selectedAnswer, onSelectAnswer }: OptionsButtonsProps) => {
  return (
    <View>
      <Text style={{ color: 'white', textAlign: 'center' }}>Tap to select answer</Text>

      {options.map((option: any, index: number) => (
        <SecondaryButton
          outline={!selectedAnswer || selectedAnswer.text !== option.text}
          disabled={!!selectedAnswer}
          rounded
          blue
          textProps={{ text: option.text }}
          onPress={() => onSelectAnswer(option)}
          style={{ marginTop: 10 }}
        />
      ))}
    </View>
  )
}
