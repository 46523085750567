import { StyleSheet, Dimensions } from "react-native"
import { moderateScale } from "../../styles/sizes"
import { fontFamily } from "../../theme/fontFamily"

const window = Dimensions.get('window')
const IMAGE_HEIGHT = window.height / 3

export const startStyles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    image: {
      flex: 1,
      resizeMode: 'cover',
      justifyContent: 'center',
    },
    text: {
      color: 'white',
      fontSize: 42,
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: '#000000a0',
    },
    keyboardContainerStyle: {
      flex: 1,
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
    },
    errorMessage: {
      textAlign: 'center',
      color: 'red',
      fontSize: 13,
      fontFamily: fontFamily.sfProTextRegular,
    },
    NameInput: {
      width: '80%',
      height: 52,
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: 6,
      borderWidth: 1,
      borderColor: '#E4E4E4',
      color: '#353535',
    },
    joinText: {
      textAlign: 'center',
      fontWeight: '800',
      fontSize: 20,
      fontFamily: fontFamily.sfProDisplayHeavy,
    },
    logo: {
      height: IMAGE_HEIGHT,
      resizeMode: 'contain',
      marginBottom: 20,
      padding: 10,
      marginTop: 20,
    },
    logoContainer: {
      flex: 0.8,
      justifyContent: 'center',
      paddingTop: 10,
    },
    inputContainer: {
      flex: 1.2,
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
    },
    backText: {
      color: '#49CDF2',
      fontWeight: '900',
      textTransform: 'uppercase',
      fontFamily: fontFamily.sfProDisplayHeavy,
    },
    enterCodeMessage: {
      fontSize: moderateScale(22),
      color: '#49CDF2',
      fontWeight: '900',
      textAlign: 'center',
      paddingHorizontal: 35,
      fontFamily: fontFamily.sfProDisplayHeavy,
    },
    backButton: {
      alignSelf: 'flex-start',
      marginLeft: 27,
      marginTop: 4,
      color: '#49CDF2',
    },
  })
  