import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { translate } from '../../i18n'
import { ActionModal } from '../ActionModal/actionModal'
import { ModalTypes, StatusModal } from '../modal/modal'
import { OptionModal } from '../OptionModal/optionModal'
import { PotModal } from '../PotModal/potModal'

export interface GameModalProps {
  type: string
  status: string
  isModalVisible?: boolean
}

export const GameModal = observer((props: GameModalProps) => {
  const { type, status, isModalVisible } = props
  const [playerStatus, setPlayerStatus] = React.useState(false)
  const [description, setDescription] = React.useState('')

  useEffect(() => {
    setDescription(status)
    setPlayerStatus(!!isModalVisible)
  }, [status, isModalVisible])

  const onClose = () => {
    setPlayerStatus(false)
  }

  // return <>{playerStatus && <StatusModal type={type} status={description} onClose={onClose} />}</>

  const renderModalType = () => {
    switch (type.toLowerCase()) {
      case 'fold':
        return (
          <StatusModal
            communityCards={[]}
            winningHandCards={[]}
            holeCards={['8s', 'Ah']}
            onClose={onClose}
            points={4400}
            sidePots={[]}
            status={status}
            type={ModalTypes.fold}
            visible={playerStatus}
            showLottieAnimation={false}
            useFourColoredCards={false}
          />
        )
      case 'raise':
        return (
          <StatusModal
            communityCards={[]}
            winningHandCards={[]}
            holeCards={['8s', 'Ah']}
            onClose={onClose}
            points={4400}
            sidePots={[]}
            status={status}
            type={ModalTypes.raise}
            visible={playerStatus}
            showLottieAnimation={false}
            useFourColoredCards={false}
          />
        )
      case 'call':
        return (
          <StatusModal
            communityCards={[]}
            winningHandCards={[]}
            holeCards={['8s', 'Ah']}
            onClose={onClose}
            points={4400}
            sidePots={[]}
            status={status}
            type={ModalTypes.call}
            visible={playerStatus}
            showLottieAnimation={false}
            useFourColoredCards={false}
          />
        )
      case 'win':
        return (
          <StatusModal
            communityCards={['6h', '8c', '8h', '5s', 'As']}
            winningHandCards={['8s', '8c', '8h', 'Ah', 'As']}
            holeCards={['8s', 'Ah']}
            onClose={onClose}
            points={4400}
            sidePots={[]}
            status={status ? status : 'You Won with full House'}
            type={ModalTypes.winningHand}
            visible={playerStatus}
            showLottieAnimation={false}
            useFourColoredCards={false}
          />
        )
      case 'buy':
        return (
          <ActionModal
            key={'BuyPromptModal'}
            onAction={() => {}}
            actionOnDeny={() => {}}
            onClose={onClose}
            message={'Buy in for'}
            stack={10000}
            visible={playerStatus}
            shouldHideSecondButton={false}
          />
        )
      case 'leave':
        return (
          <ActionModal
            key={'LeaveGameModal'}
            onAction={() => {}}
            onClose={onClose}
            message={translate('gameScreen.confirmMessageBeforeLeavingGame') ?? ''}
            visible={playerStatus}
          />
        )
      case 'options':
        return (
          <OptionModal
            key={'OptionModal'}
            visible={playerStatus}
            joinVideoCall={() => {}}
            leaveVideoCall={() => {}}
            muteCall={() => {}}
            unMuteCall={() => {}}
            hideVideo={() => {}}
            showVideo={() => {}}
            isLocalUserMuted={false}
            isLocalUserVideoHidden={false}
            isLocalUserJoined={false}
            localUserPlayerState={'inplay'}
            onClose={onClose}
            onMuteEveryone={onClose}
            onUnmuteEveryone={onClose}
            isEveryoneMute={false}
            unseatPlayer={onClose}
            isTeacher={true}
            playerUserIdForTeacher={'userId'}
            myUserId={'userId'}
            onRemovingPlayer={() => {}}
            players={[]}
            onSeatBackPlayer={() => {}}
          />
        )
      case 'joinvideo':
        return (
          <ActionModal
            key={'JoinVideoModal'}
            onAction={() => {}}
            onClose={onClose}
            message={translate('gameScreen.joinVideoCall') ?? ''}
            visible={playerStatus}
          />
        )
      case 'pot':
        return <PotModal key={'PotModal'} potList={[]} visible={playerStatus} onClose={onClose} />
      case 'gamedeleted':
        const message = translate('gameScreen.gameDeletedMessage')

        return (
          <ActionModal
            key={'gameDeleted'}
            onAction={onClose}
            onClose={onClose}
            message={message}
            visible={playerStatus}
            buttonTitle1={translate('common.ok')}
            shouldHideSecondButton={true}
          />
        )
      case 'terminategame':
        const msg = translate('gameScreen.terminateGameMessage')
        return (
          <ActionModal
            key={'TerminateGame'}
            onAction={() => {
              onClose
            }}
            onClose={onClose}
            message={msg}
            visible={playerStatus}
          />
        )
      case 'playeroutofchips':
        return (
          <StatusModal
            communityCards={[]}
            winningHandCards={[]}
            holeCards={[]}
            onClose={onClose}
            points={5000}
            sidePots={[]}
            status={'You rank 3rd'}
            type={ModalTypes.allIn}
            visible={playerStatus}
            outOfChipsTextStyle={{
              fontSize: 30,
            }}
          />
        )
    }
  }

  return <>{renderModalType()}</>
})
