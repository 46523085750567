import { observer } from 'mobx-react-lite'
import React from 'react'

import { NavigationProp } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import { SignUp } from '../screens'

export enum AuthRoutes {
  signup = 'signup',
}
export type AuthParamList = Record<keyof typeof AuthRoutes, undefined>
export type AuthNavigation = NavigationProp<AuthParamList>

const Stack = createStackNavigator<AuthParamList>()

export const AuthNavigator = observer(() => {
  const initialRouteName = AuthRoutes.signup
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        headerStyle: { borderBottomWidth: 0, backgroundColor: 'rgb(5, 16,45)', height: 86, },
      }}
    >
      <Stack.Screen name={AuthRoutes.signup} component={SignUp} />
    </Stack.Navigator>
  )
})
