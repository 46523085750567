import { LinearGradient } from 'expo-linear-gradient'
import React, { useState } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { ChatKind } from '../../models/api-store'
import { styles } from './styles'


const chatImg = require('../../../assets/icons/chat-double-purple.png')

interface IChatPreviewProps {
  isChatting: boolean
  setIsChatting: (chatStatus : boolean) => void
  latestChat: any
  handleChatNav: () => void
}

export const ChatPreview = (props: IChatPreviewProps) => {


  const handlePressAction = () => {
    props.setIsChatting(true)
    props.handleChatNav()
  }

  const { message, userName, chatKind } = props.latestChat;

  return (
    <TouchableOpacity 
      style={styles.moduleContainer}
      onPress={handlePressAction}
    >
      <LinearGradient
        colors={['#49CDF2', '#49CDF2', '#9444FB']}
        start={{ x: 0.0, y: 1.0 }}
        end={{ x: 1.0, y: 1.0 }}
        style={{ padding: 2 }}
      >
        <View style={styles.chatPreviewContainer}>

          <View style={styles.chatPreviewField}>
            {
              message !== "" && message && userName !== "" && userName && 
              <Text style={styles.baseTextStyle} numberOfLines={1} ellipsizeMode='tail'>
                  <Text 
                    style={[styles.baseTextStyle, { fontWeight: '500' }]}
                  >
                    
                    { chatKind === ChatKind.dealer
                    ?
                    // `Dealer: `
                    ``
                    :
                      userName.length > 14 
                      ? 
                      `${userName.slice(0, 14)}..:  ` 
                      : 
                      `${userName}: `
                    }
                  </Text>
                {`${message}`}
              </Text>
            }
          </View>

          <View
            style={styles.chatPreviewIcon}
          >
            <View style={{ padding: 5 }}>
              <Image source={chatImg} style={{ width: 20, height: 20, resizeMode: 'contain' }} />
            </View>
          </View>

        </View>
      </LinearGradient>
    </TouchableOpacity>
  )
}
