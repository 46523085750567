import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React from 'react'
import { DevSettings, ImageBackground, StyleSheet, View } from 'react-native'
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler'
import { List, Modal, RadioButton } from 'react-native-paper'
import RNRestart from 'react-native-restart'

import Clipboard from '@react-native-clipboard/clipboard'
import { useNavigation } from '@react-navigation/native'

import { Text } from '../../components'
import { translate } from '../../i18n'
import { EnvironmentStages, useStore } from '../../models'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'

const startStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 42,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000a0',
  },
})

const image = require('../../../assets/icons/Home_Purple.png')

export const AdminEnvironmentScreen = observer(() => {
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  const { baseURL, baseWebSocketURL } = store.environment.getEnv()
  const stage = store.environment.stage

  const [message, setMessage] = React.useState('')
  const [visible, setVisible] = React.useState(false)
  const [checked, setCheckedState] = React.useState<EnvironmentStages>(stage)

  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)
  const containerStyle = { backgroundColor: 'white', padding: 20 }

  const setChecked = async (value: EnvironmentStages) => {
    try {
      console.log('setChecked', value)
      setCheckedState(value)
      if (value === checked) {
        return
      }
      await store.environment.setStage(value)
      store.session.logout()
      navigation.navigate(DrawerRoutes.signup)
      // Immediately reload the React Native Bundle
      RNRestart.Restart()
      DevSettings && DevSettings.reload()
    } catch (error) {
      console.warn('restart', error)
    }
  }

  return (
    <ImageBackground source={image} style={{ ...startStyles.image }}>
      <View style={{ backgroundColor: 'rgba(255,255,255,.9)', flex: 1 }}>
        <View style={{ flex: 1 }}>
          <List.Item
            style={{ margin: 5 }}
            title={translate('adminEnvironmentScreen.envTitle', { env: stage })}
            description="Tap radio button below to switch environment"
            // description={translate('adminEnvironmentScreen.envSubtitle', {
            //   fromEnv: isSwitchOn ? 'prod' : 'dev',
            //   toEnv: isSwitchOn ? 'dev' : 'prod',
            // })}
          />
          <View style={{ paddingHorizontal: 40, height: 120 }}>
            <TouchableOpacity
              onPress={() => setChecked(EnvironmentStages.local)}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Text allowFontScaling={false} style={{ flex: 0.25, color: 'black' }}>
                {EnvironmentStages.local}
              </Text>
              <RadioButton
                value={EnvironmentStages.local}
                status={checked === EnvironmentStages.local ? 'checked' : 'unchecked'}
                onPress={() => setChecked(EnvironmentStages.local)}
                uncheckedColor="grey"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setChecked(EnvironmentStages.dev)}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Text allowFontScaling={false} style={{ flex: 0.25, color: 'black' }}>
                {EnvironmentStages.dev}
              </Text>
              <RadioButton
                value={EnvironmentStages.dev}
                status={checked === EnvironmentStages.dev ? 'checked' : 'unchecked'}
                uncheckedColor="grey"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setChecked(EnvironmentStages.prod)}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <Text allowFontScaling={false} style={{ flex: 0.25, color: 'black' }}>
                {EnvironmentStages.prod}
              </Text>
              <RadioButton
                value={EnvironmentStages.prod}
                status={checked === EnvironmentStages.prod ? 'checked' : 'unchecked'}
                onPress={() => setChecked(EnvironmentStages.prod)}
                uncheckedColor="grey"
              />
            </TouchableOpacity>
          </View>
          <List.Item
            onPress={() => {
              Clipboard.setString(baseURL)
            }}
            style={{ margin: 5 }}
            title="Base URL"
            description={baseURL}
          />
          <List.Item
            onPress={() => {
              Clipboard.setString(baseWebSocketURL)
            }}
            style={{ margin: 5 }}
            title="Base WebSocket URL"
            description={baseWebSocketURL}
          />
          <List.Item
            // icon="content-paste"
            onPress={debounce(() => {
              const snapshot = getSnapshot(store)
              // NOTE: removing the GraphGL query cache
              // if (snapshot['api']) {
              //   delete snapshot['api']
              // }
              const pretty = JSON.stringify(snapshot, null, 2)
              setMessage(pretty)
              Clipboard.setString(pretty)
              showModal()
            }, 250)}
            title={translate('adminEnvironmentScreen.appStateSnapshot')}
            description="Tap to copy snapshot to clipboard"
          />
        </View>
        <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
          <Text
            allowFontScaling={false}
            tx="adminMessageScreen.howToDismissMessage"
            style={{ color: 'black', textAlign: 'center' }}
          />
          <Text allowFontScaling={false}>{'\n'}</Text>
          <ScrollView>
            <Text allowFontScaling={false} style={{ color: 'black' }}>
              {message}
            </Text>
          </ScrollView>
        </Modal>
      </View>
    </ImageBackground>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    marginVertical: 10,
  },
  listRow: {
    flexDirection: 'row',
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    marginVertical: 5,
    padding: 10,
  },
  keyValueText: {
    fontSize: 15,
  },
})
