import React, { useEffect, useState } from 'react'
import { storiesOf } from '@storybook/react-native'
import { Story, StoryScreen, UseCase } from '../../../storybook/views'
import { GameModal } from './GameModal'
import { GameActionModal } from './GameActionModal'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'

storiesOf('Game', module)
  .addDecorator((fn) => <StoryScreen>{fn()}</StoryScreen>)
  .add('Game Modal', () => {
    const Parent = ({ children, ...props }: any) => {
      const [state, setState] = useState({ type: '', description: '', isModalVisible: false })

      useEffect(() => {
        if (state.isModalVisible) {
          setTimeout(() => {
            setState({ ...state, isModalVisible: false })
          }, 2000)
        }
      }, [state])
      return <View style={{ flex: 1 }}>{children(state, setState)}</View>
    }
    return (
      <Parent>
        {(state: any, setState: any) => (
          <Story>
            <UseCase text="Game Modal" usage="Action">
              <TextInput
                allowFontScaling={false}
                style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
                onChangeText={(text) => setState({ ...state, type: text })}
                placeholder="Enter type win/buy/leave/options/joinvideo/pot/gamedeleted/terminategame/playeroutofchips"
              />
              <TextInput
                allowFontScaling={false}
                style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
                onChangeText={(text) => setState({ ...state, description: text })}
                placeholder="Enter description..."
              />
              <TouchableOpacity
                style={{
                  width: 100,
                  paddingVertical: 10,
                  borderRadius: 5,
                  borderColor: 'lightgray',
                  borderWidth: 1,
                  shadowColor: '#000',
                  elevation: 5,
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.7,
                }}
                onPress={() => {
                  setState({ ...state, isModalVisible: true })
                }}
              >
                <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
                  Action
                </Text>
              </TouchableOpacity>
              <GameModal type={state.type} status={state.description} isModalVisible={state.isModalVisible} />
            </UseCase>
          </Story>
        )}
      </Parent>
    )
  })
  .add('Game Action Modal', () => {
    const Parent = ({ children, ...props }: any) => {
      const [state, setState] = useState({ name: '', action: '', isModalVisible: false })
      useEffect(() => {
        if (state.isModalVisible) {
          setTimeout(() => {
            setState({ ...state, isModalVisible: false })
          }, 2000)
        }
      }, [state])
      return <View style={{ flex: 1 }}>{children(state, setState)}</View>
    }
    return (
      <Parent>
        {(state: any, setState: any) => (
          <Story>
            <UseCase text="Game Action Modal" usage="Action">
              <TextInput
                allowFontScaling={false}
                style={{ padding: 5, height: 30, borderColor: 'lightgray', borderWidth: 1, marginBottom: 20 }}
                onChangeText={(text) => setState({ ...state, name: text })}
                placeholder="Enter player name"
              />
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                {['CALL', 'RAISE', 'FOLD'].map((item) => {
                  return (
                    <TouchableOpacity
                      style={{
                        width: 100,
                        paddingVertical: 10,
                        borderRadius: 5,
                        borderColor: 'lightgray',
                        borderWidth: 1,
                        shadowColor: '#000',
                        elevation: 5,
                        shadowOffset: { width: 0, height: 0 },
                        shadowOpacity: 0.7,
                      }}
                      onPress={() => {
                        setState({ ...state, action: item, isModalVisible: true })
                      }}
                    >
                      <Text allowFontScaling={false} style={{ textAlign: 'center' }}>
                        {item}
                      </Text>
                    </TouchableOpacity>
                  )
                })}
              </View>
              <GameModal
                key={state.name}
                type={state.action}
                status={state.name}
                isModalVisible={state.isModalVisible}
              />
            </UseCase>
          </Story>
        )}
      </Parent>
    )
  })
