import React from 'react'
import { StyleSheet, View, ViewProps } from 'react-native'

interface PaddedLayoutProps {
  children: React.ReactNode
  styles?: ViewProps['style']
}

export const PaddedLayout = ({ children, styles }: PaddedLayoutProps) => {
  return <View style={[defaultStyles.layout, styles]}>{children}</View>
}

const defaultStyles = StyleSheet.create({
  layout: {
    backgroundColor: '#06102B',
    display: 'flex',
    height: '100%',
    padding: 20,
  },
})
