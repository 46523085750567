import 'react-native-get-random-values'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { miniGames } from './data/minigames-data'
import { GamePreviewCard } from './components/game-preview-card'
import { PaddedLayout } from '../../components/layouts/PaddedLayout'
import { ScreenTitle } from '../../components/ScreenTitle/ScreenTitle'

export const MiniGamesLobbyScreen = observer(() => {
  return (
    <PaddedLayout>
      <ScreenTitle tx={'miniGamesScreen.title'} />

      <FlatList
        style={miniGameLobbyStyles.cardPreviewContainer}
        data={miniGames}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
        renderItem={({ item }) => (
          <View style={{ width: '48%' }}>
            <GamePreviewCard img={item.img} title={item.title} params={item.params} />
          </View>
        )}
        keyExtractor={(item) => item.title}
        numColumns={2}
      />
    </PaddedLayout>
  )
})

const miniGameLobbyStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#08102a', // TODO: Use palette if possible.
  },
  cardPreviewContainer: {
    flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
  },
})
