/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * GameSnapshotBase
 * auto generated base class for the model GameSnapshotModel.
 */
export const GameSnapshotModelBase = ModelBase
  .named('GameSnapshot')
  .props({
    __typename: types.optional(types.literal("GameSnapshot"), "GameSnapshot"),
    currentPlayerAction: types.union(types.undefined, types.null, types.frozen()),
    forPlayer: types.union(types.undefined, types.null, types.frozen()),
    gameId: types.union(types.undefined, types.string),
    gameName: types.union(types.undefined, types.string),
    gameState: types.union(types.undefined, types.frozen()),
    players: types.union(types.undefined, types.null, types.frozen()),
    table: types.union(types.undefined, types.frozen()),
    withBet: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GameSnapshotModelSelector extends QueryBuilder {
  get currentPlayerAction() { return this.__attr(`currentPlayerAction`) }
  get forPlayer() { return this.__attr(`forPlayer`) }
  get gameId() { return this.__attr(`gameId`) }
  get gameName() { return this.__attr(`gameName`) }
  get gameState() { return this.__attr(`gameState`) }
  get players() { return this.__attr(`players`) }
  get table() { return this.__attr(`table`) }
  get withBet() { return this.__attr(`withBet`) }
}
export function selectFromGameSnapshot() {
  return new GameSnapshotModelSelector()
}

export const gameSnapshotModelPrimitives = selectFromGameSnapshot().currentPlayerAction.forPlayer.gameId.gameName.gameState.players.table.withBet
