import { ViewProps, ViewStyle } from 'react-native'

export enum CardTilt {
  left = 'left',
  right = 'right',
}

export interface CardProps extends ViewProps {
  name: string
  tilt?: CardTilt
  disabled?: boolean
  selected?: boolean
  fourColor?: boolean
}
