import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { ImageBackground, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native'
import { Card } from '../../components/card/card'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import colors from '../../styles/colors'
import { fontFamily } from '../../theme/fontFamily'
import { CardValues, gamesData } from './data'
import { BASIC_CARDS, CARD, Game, VersusGame } from './interfaces/mini-games.interface'
import { CardList } from '../../components'
import { delay } from '../../utils/delay'
import { translate } from '../../i18n'
import { MiniGamesRoutes } from '../../navigation/minigames-navigator'

interface VersusGameScreenProps {
  route: RouteProp<{ params: { gameName: string } }>
}

interface InfoCard {
  text: string
  value: number
  visible?: boolean
}

export const VersusGameScreen = ({ route }: VersusGameScreenProps) => {
  const { gameName } = route.params
  const navigation = useNavigation<any>()
  const [currentGame, setCurrentGame] = useState<VersusGame>()
  const [startGameCounter, setStartGameCounter] = useState(3)
  const [tableCards, setTableCards] = useState<InfoCard[]>([])
  const [playerCards, setPlayerCards] = useState<InfoCard[]>([])
  const [opponentCards, setOpponentCards] = useState<InfoCard[]>([])
  const [warPlayerCards, setWarPlayerCards] = useState<CARD[]>([])
  const [warOpponentCards, setWarOpponentCards] = useState<CARD[]>([])
  const [resultText, setResultText] = useState('')
  const [startTime, setStartTime] = useState(0)
  const [timer, setTimer] = useState<NodeJS.Timer>()
  const [totalWars, setTotalWars] = useState(0)
  const [playerWins, setPlayerWins] = useState(0)
  const [totalRounds, setTotalRounds] = useState(0)
  const [warsWonCount, setWarsWonCount] = useState(0)

  useFocusEffect(
    useCallback(() => {
      if (gameName) {
        const currentGame = gamesData.find((game: Game) => game.name === gameName)
        if (!currentGame) {
          navigation.navigate(DrawerRoutes.miniGamesLobby)
          return
        }

        initializeData(currentGame as VersusGame)
      }

      return () => {
        timer && clearInterval(timer)
        setTimer(undefined)
        setStartGameCounter(3)
      }
    }, [gameName])
  )

  const fisherYatesShuffle = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)) // random index from 0 to i

      // swap elements array[i] and array[j]
      // we use "destructuring assignment" syntax to achieve that
      // you'll find more details about that syntax in later chapters
      // same can be written as:
      // let t = array[i]; array[i] = array[j]; array[j] = t
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  const initializeData = (game: VersusGame) => {
    setCurrentGame(game)
    setStartGameCounter(3)
    setTableCards([])
    setWarPlayerCards([])
    setWarOpponentCards([])
    setStartTime(Date.now())
    setResultText('')
    setTotalRounds(0)
    setTotalWars(0)
    setPlayerWins(0)
    setWarsWonCount(0)

    const shuffledCards = fisherYatesShuffle(BASIC_CARDS)
    const firstHalf = shuffledCards.slice(0, 26)
    const secondHalf = shuffledCards.slice(26)

    setPlayerCards(
      firstHalf.map((card) => {
        const key = card.charAt(0)
        return { text: card, value: CardValues[key as keyof typeof CardValues], visible: true }
      })
    )
    setOpponentCards(
      secondHalf.map((card) => {
        const key = card.charAt(0)
        return { text: card, value: CardValues[key as keyof typeof CardValues], visible: true }
      }) || []
    )

    const timer = setInterval(() => {
      startGameCounter > 0 ? setStartGameCounter((prev) => prev - 1) : clearInterval(timer)
    }, 1000)
    setTimer(timer)
  }

  const getRunTime = () => {
    const endTime = new Date().getTime()
    const runTime = Math.round((endTime - startTime) / 1000)
    const seconds = runTime % 60
    const minutes = Math.floor(runTime / 60)
    const hours = Math.floor(minutes / 60)
    const strHours = hours < 10 ? `0${hours}` : hours
    const strMinutes = minutes < 10 ? `0${minutes}` : minutes
    const strSeconds = seconds < 10 ? `0${seconds}` : seconds
    return `${strHours}:${strMinutes}:${strSeconds}`
  }

  const gameIsOver = () => {
    return playerCards.length === 0 || opponentCards.length === 0
  }

  const playPlayersCards = async () => {
    const newPlayerCards = [...playerCards]
    const newOpponentCards = [...opponentCards]

    const playerCard = newPlayerCards.pop() as InfoCard
    const opponentCard = newOpponentCards.pop() as InfoCard

    setPlayerCards(newPlayerCards)
    setOpponentCards(newOpponentCards)

    opponentCard.visible = false

    const tableCards = [playerCard, opponentCard]
    setTableCards(tableCards)

    // INFO: Show opponent card after 500ms
    await delay(500)
    opponentCard.visible = true

    await checkWinner(playerCard.value, opponentCard.value, tableCards)

    if (gameIsOver()) {
      return gameOver()
    }
  }

  const gameOver = () => {
    navigation.navigate(MiniGamesRoutes.miniGameFinalScreen, {
      gameName,
      runTime: getRunTime(),
      total: totalRounds,
      stats: {
        rounds: totalRounds,
        roundsWon: playerWins,
        wars: totalWars,
        warsWon: warsWonCount,
      },
    })
  }

  const playWar = async (tableCards: InfoCard[]) => {
    const newPlayerCards = fisherYatesShuffle(playerCards)
    const newOpponentCards = fisherYatesShuffle(opponentCards)

    const warPlayerCard = newPlayerCards.pop() as InfoCard
    const warOpponentCard = newOpponentCards.pop() as InfoCard

    const newWarPlayerCards = [CARD.BACK, CARD.BACK, CARD.BACK, warPlayerCard.text]
    const newWarOpponentCards = [CARD.BACK, CARD.BACK, CARD.BACK, CARD.BACK]

    setWarPlayerCards(newWarPlayerCards as CARD[])
    setWarOpponentCards(newWarOpponentCards as CARD[])

    await delay(500)
    newWarOpponentCards[newWarOpponentCards.length - 1] = warOpponentCard.text as CARD

    if (warPlayerCard.value > warOpponentCard.value) {
      const updatedOpponentCards = newOpponentCards.filter((card) => card.text !== warOpponentCard.text)

      setPlayerCards((prev) => [warOpponentCard, ...prev])
      setOpponentCards(() => updatedOpponentCards)
      setWarsWonCount((prev) => prev + 1)
    } else {
      const updatedPlayerCards = newPlayerCards.filter((card) => card.text !== warPlayerCard.text)

      setPlayerCards(() => updatedPlayerCards)
      setOpponentCards((prev) => [warPlayerCard, ...prev])
    }

    checkWinner(warPlayerCard.value, warOpponentCard.value, tableCards)
  }

  const checkWinner = async (playerCard: number, opponentCard: number, tableCards: InfoCard[]) => {
    if (playerCard > opponentCard) {
      setResultText(translate('miniGamesScreen.versusScreen.warGame.playerWins'))
      setPlayerCards((prev) => [tableCards[0], tableCards[1], ...prev])
      setPlayerWins((prev) => prev + 1)
    } else if (playerCard < opponentCard) {
      setResultText(translate('miniGamesScreen.versusScreen.warGame.opponentWins'))
      setOpponentCards((prev) => [tableCards[0], tableCards[1], ...prev])
    } else {
      setResultText(translate('miniGamesScreen.versusScreen.warGame.warStart'))

      await delay(500)
      setResultText('')
      playWar(tableCards)

      setTotalWars((prev) => prev + 1)
      setTotalRounds((prev) => prev + 1)

      return
    }

    await delay(1000)
    setResultText('')
    setTableCards(() => [])
    setWarOpponentCards(() => [])
    setWarPlayerCards(() => [])
    setTotalRounds((prev) => prev + 1)
  }

  const canPlay = () => {
    const isWarInProgress = warPlayerCards.length > 0 || warOpponentCards.length > 0
    const areTableCardsEmpty = tableCards.length === 0

    if (!isWarInProgress) {
      return areTableCardsEmpty
    }

    return false
  }

  return (
    <ImageBackground style={styles.background} source={require('../../assets/war_bg.png')}>
      <View style={styles.container}>
        <View style={styles.backCardContainer}>
          <Text style={styles.cardsCountText}>
            {translate('miniGamesScreen.versusScreen.warGame.opponentCards')} ({opponentCards.length})
          </Text>
          <Card name="BACK"></Card>
        </View>
        <View>
          {!!warOpponentCards.length && <CardList cards={warOpponentCards} />}
          {!!tableCards.length && (
            <CardList
              cards={tableCards.filter((card) => card.visible).map((card: InfoCard) => card.text as CARD)}
              styles={styles.tableCardsContainer}
            ></CardList>
          )}
          {!!warPlayerCards.length && <CardList cards={warPlayerCards} />}
        </View>
        <TouchableWithoutFeedback onPress={() => playPlayersCards()} disabled={!canPlay()}>
          <View style={styles.backCardContainer}>
            <Text style={styles.resultText}>{resultText || ' '}</Text>

            <Card name="BACK"></Card>
            <Text style={styles.cardsCountText}>
              {translate('miniGamesScreen.versusScreen.warGame.playerCards')} ({playerCards.length})
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>

      {startGameCounter >= 0 && (
        <View style={styles.overlay}>
          <Text style={styles.overlayText}>{translate('miniGamesScreen.versusScreen.warGame.timer')}</Text>
          <Text style={styles.overlayCounter}>{startGameCounter}</Text>
        </View>
      )}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80%',
  },
  backCardContainer: {
    height: 80,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  cardsCountText: {
    fontFamily: fontFamily.sfProDisplayBoldItalic,
    color: colors.text,
    marginVertical: 15,
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(7, 13, 55, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayText: {
    fontFamily: fontFamily.sfProDisplayBoldItalic,
    color: colors.text,
    fontSize: 24,
    lineHeight: 29,
  },
  overlayCounter: {
    fontFamily: fontFamily.sfProDisplayBold,
    color: colors.text,
    fontSize: 36,
    lineHeight: 43,
  },
  resultText: {
    fontFamily: fontFamily.sfProDisplayHeavyItalic,
    color: colors.text,
    fontSize: 28,
    lineHeight: 43,
  },
  tableCardsContainer: {
    marginVertical: 10,
  },
})
