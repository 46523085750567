import { useKeepAwake } from 'expo-keep-awake'
import { assign, debounce, fill, orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useEffect, useState } from 'react'
import { Alert, SafeAreaView, Text, Vibration, View, PlatformIOSStatic, Platform } from 'react-native'
import { Modal, Portal, Provider } from 'react-native-paper'
import { PlayerStates } from '@poker-powher/poker'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Blinds, Board, Grid, GridPreset, PlayerHoleCards, Pot, TimerOverlay, TimerTypes } from '../../components'
import { ChatPreview } from '../../components/ChatPreview/ChatPreview'
import { useStore } from '../../models'
import { events } from '../../models/session/analytics/analytics-events'
import { PlayerType } from '../../models/session/session-game'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import { GameRoutes } from '../../navigation/game-navigator'
import styles from '../../styles/globalStyle'
import style from './style'
import { GameModal } from './GameModal'
import { CardAndActions } from './CardAndActions'
import { translate } from '../../i18n/translate'

const isIPad = Platform.OS === 'ios' ? (Platform as PlatformIOSStatic).isPad : false

export const GameScreen = observer(() => {
  useKeepAwake()
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()
  const isFocused = useIsFocused()
  const channelName = store.session.channel?.channelName
  const [timerValue, setTimerValue] = React.useState(0)
  const [visible, setVisible] = useState(false)

  const hideOverlayModal = () => setVisible(false)
  const turnTimer = store.session.getSecondsPassed

  const gotoLobby = debounce(async () => {
    // NOTE: don't block UI
    await store.session.leaveCurrentGame()
    store.session.clearGameModalQueue()
    navigation.navigate(DrawerRoutes.lobby)
  }, 250)

  React.useEffect(() => {
    if (store.session.mIOutOfGame) {
      store.session.setMIOutOfGame(false)
      store.session.clearGameModalQueue()
      navigation.navigate(DrawerRoutes.lobby)
    }
  }, [store.session.mIOutOfGame])

  React.useEffect(() => {
    if (store.session.isPlayingGame) {
      const socketState = translate(`socketState.${store.network.wsReadyState}`)
      if (!socketState.includes('missing') && socketState.trim() !== '') {
        store.session.setSnack(socketState)
        store.session.toggleSnackbar()
        setTimeout(() => {
          store.session.clearSnack()
          store.session.toggleSnackbar()
        }, 5000)
        store.session.createChannelSubscription(true)
      }
    }
  }, [store.network.isWebSocketConnected])

  const setup = debounce(async () => {
    try {
      if (store.session.isBootstrapped) {
        await store.session.setupGame(navigation)
      }
    } catch (err) {
      const error = err as any
      console.error('game-screen', error)
      // if (error instanceof ClientError) {
      console.error('game-screen error.response.status', error.response.status)
      if (error.response?.status === 404) {
        const message = error?.message ?? 'Unknown Error'
        Alert.alert('Oops', message.substr(0, message.indexOf(':')).slice(0, 200), [
          {
            text: 'Back to Home',
            onPress: gotoLobby,
          },
        ])
      } else {
        store.session.logout()
        navigation.navigate(DrawerRoutes.signup)
      }
      // }
    }
  }, 250)

  useEffect(() => {
    //Sort the seated players according to timestamp and get the difference between the last joined player and second player
    const sortedPlayers = orderBy(store.session.seatedPlayers, 'player.checkpoint')
    const firstHighestTimestamp = sortedPlayers[sortedPlayers.length - 1]?.player?.checkpoint // recently joined player

    if (
      store.session.seatedPlayers.length >= 4 &&
      store.session.isPlayingGame === false &&
      store.session.isTournamentModeOn === true
    ) {
      if (sortedPlayers) {
        const secondPlayerTimestamp = sortedPlayers[3]?.player?.checkpoint // second player
        if (firstHighestTimestamp && secondPlayerTimestamp) {
          const diff = Math.ceil((firstHighestTimestamp - secondPlayerTimestamp) / 1000)
          isNaN(diff) ? setTimerValue(0) : setTimerValue(60 - diff)
        } else {
          setTimerValue(60)
        }
      }
    } else if (
      store.session.seatedPlayers.length >= 2 &&
      store.session.isPlayingGame === false &&
      store.session.isTournamentModeOn === false
    ) {
      if (sortedPlayers) {
        const secondPlayerTimestamp = sortedPlayers[1]?.player?.checkpoint // second player
        if (firstHighestTimestamp && secondPlayerTimestamp) {
          const diff = Math.ceil((firstHighestTimestamp - secondPlayerTimestamp) / 1000)
          isNaN(diff) ? setTimerValue(0) : setTimerValue(180 - diff + 2)
        } else {
          setTimerValue(180)
        }
      }
    }
    console.log('game-screen useEffect store.session.isPlayingGame', store.session.isPlayingGame)
    if (!(store.session?.isGamePaused || store.session?.isGameReset)) {
      const timeout = setTimeout(() => {
        setVisible(
          store.session.seatedPlayers.length > (store.session.isTournamentModeOn ? 3 : 1) &&
            store.session.isPlayingGame === false
        )
      }, 500)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [store.session.seatedPlayers])

  useEffect(() => {
    if (isFocused) {
      setup()
    }
    return () => {
      // DEPRECATED: We do not want to end video calls or unsubscribe from game events when navigating away from the game
    }
  }, [isFocused])

  const useVibration = async () => {
    try {
      if (store.session.isMyTurn && !store.session.isShowdown) {
        await Vibration.vibrate(1000) // Device Will Vibrate for 1 seconds.
      }
    } catch (error) {
      console.error('Vibration', error)
    }
  }

  useEffect(() => {
    useVibration()
  }, [store.session.isMyTurn])

  const me = store.session.myPlayer
  const myPlayerIndex = me?.index
  const players = store.session.players
  const myPlayer = me?.player ?? { chips: 0 }
  const mainPot = store.session.defaultTable?.mainPot ?? 0
  const cards = store.session.defaultTable && !store.session.isGameOver ? store.session.defaultTable.communityCards : []
  const communityCards = assign(fill(new Array(5), 'blank'), cards)

  const userId = store.session.user?._id.toString()
  const channelChats = getSnapshot(store.session.channelChats)
  const isMyTurn = store.session.isMyTurn
  console.log('GameScreen isMyTurn', isMyTurn)
  console.log('GameScreen board.communityCards', communityCards)
  console.log('GameScreen store.session.game', JSON.stringify(store.session.game))
  console.log('GameScreen store.session.gamePlayers', JSON.stringify(store.session.gamePlayers))

  const myHoleCards = store.session.myHoleCards
  const holeCards = store.session.isGameReset
    ? ['blank', 'blank']
    : (myHoleCards ?? ['blank', 'blank']).slice().map((card: string) => card.toUpperCase())

  const sidePots = store.session.defaultTable?.sidePots ?? []
  console.log('GameScreen sidePots', sidePots)
  // @ts-ignore
  const gridPreset = GridPreset[store.session.gridPreset]
  const rankedHandName = me?.player?.rankedHandName

  if (store.session.isGameOver) {
    store.session.resetBlind()
  }
  React.useEffect(() => {
    if (isFocused && myPlayerIndex && myPlayerIndex === -1 && store.session.seatedPlayers.length < 9) {
      store.session.joinGame(true)
    }
  }, [isFocused])

  const doFold = debounce(async () => {
    await store.session.doFoldForPlayer()
    setTimerValue(0)
    setTimeout(() => {
      store.session.setHasUserPreFold(false)
    }, 500)
  }, 250)

  const doStart = debounce(async () => {
    try {
      requestAnimationFrame(async () => {
        hideOverlayModal()
        const totalPlayers = store.session.seatedPlayers
        const playersWithChips = totalPlayers.filter((player) => player.chips > 0)
        console.log('Player array with chips: ', playersWithChips)
        if (playersWithChips.length > 1) {
          store.session.setHasUserDeclinedToBuy(false)
          await store.session.startGame()
          store.session.setIsAnyPlayerGoesOutOfChips(false)
        } else {
          store.session.setHasUserDeclinedToBuy(true)
          // await store.session.pauseTheGame()
        }
      })
    } catch (error) {
      console.error('GameScreen.doStart', error)
    } finally {
      setTimerValue(0)
    }
  }, 250)

  const doResume = debounce(async () => {
    try {
      hideOverlayModal()
      await store.session.resumeTheGame()
    } catch (error) {
      console.error('GameScreen.doResume', error)
    }
  }, 250)

  const gameStartForEvent = () => {
    Platform.OS !== 'web' &&
      store.session.setEvent(events.game.start, {
        playerName: store.session.user?.name,
        joinCode: store.session.share?.hash,
        channelName,
        userId,
      })
  }

  React.useEffect(() => {
    if (isMyTurn && store.session.hasUserPreFold) {
      doFold()
    }
  }, [isMyTurn, store.session.hasUserPreFold])

  /**
   * isWebSocketConnected = false but isConnected = true
   */

  React.useEffect(() => {
    if (!store.network.isWebSocketConnected && store.network.netInfoState.isConnected) {
      store.session.clearMessages()
      store.session.clearGameEvents()
      store.session.createChannelSubscription(true)
    }
    if (!store.network.isWebSocketConnected || !store.network.netInfoState.isConnected) {
      console.log('React.UseEffect Game-Screen Websocket requires Reconnection')
    }
  }, [store.network.isWebSocketConnected, store.network.netInfoState.isConnected])

  const doPauseGame = async () => {
    try {
      await store.session.pauseTheGame()
    } catch (error) {
      console.error('GameScreen.doPauseGame', error)
    }
  }

  React.useEffect(() => {
    if (store.session.isGameReset) {
      const playerOutOfChips = store.session.seatedPlayers.filter((data: PlayerType) => data.chips === 0)
      if (playerOutOfChips.length > 0 && store.session.isTournamentModeOn === false) {
        //Note: Show buy-in popup for player(s) goes out of chips. Show pause timer for player having chips
        if (myPlayer && myPlayer.chips === 0 && myPlayer.buyIn) {
          //NOTE: Settimeout will prevent double winning modal
          setTimeout(() => {
            store.session.createBuyPrompt()
          }, 500)
        }
        const timeLeft = turnTimer()
        setTimerValue(timeLeft)
        doPauseGame()
        setVisible(true)
      } else if (myPlayer) {
        setTimeout(() => {
          requestAnimationFrame(() => doStart())
        }, 5000)
      }
    }
  }, [store.session.isGameReset])

  React.useEffect(() => {
    if (store.session.isGameDeleted) {
      store.session.setIsGameDeleted(false)
      store.session.clearGameModalQueue()
      navigation.navigate(DrawerRoutes.lobby)
    }
  }, [store.session.isGameDeleted])

  const handleChatNav = () => {
    navigation.navigate(GameRoutes.chatOverlay)
  }

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: 'rgb(5, 16, 45)', paddingLeft: 1 }]}>
      <Provider>
        <Portal>
          <View style={style.gameScreenContainer}>
            {store.session.isPlayingGame && store.session.isTournamentModeOn ? (
              <Blinds />
            ) : store.session.isTournamentModeOn && !store.session.isGameOver ? (
              <Text style={{ color: '#fff', alignSelf: 'center', fontSize: 16, marginTop: 15, marginBottom: -5 }}>
                Minimum 4 players needed to start game
              </Text>
            ) : null}
            {/* Player Video Grid */}
            <Grid
              // someArray.slice() doesn't always return flat value. Use getSnapshot() instead..
              agoraUserList={getSnapshot(store.session.agoraUsers)}
              channelName={channelName}
              hasLocalUserJoinedVideo={store.session.isLocalUserJoined}
              hasLocalUserMutedVideo={store.session.isLocalUserMuted}
              hasLocalUserHiddenVideo={store.session.isLocalUserVideoHidden}
              gameState={store.session.game?.gameState.tag}
              isShowdown={store.session.isShowdown}
              localUserId={userId}
              mainPot={mainPot}
              players={store.session.seatedPlayingPlayers}
              preset={gridPreset}
              onTimeoutAction={() => {
                setTimeout(() => store.session.noActionPerformed(), 0)
              }}
              showOptionModal={() => {
                if (store.session.isOptionModalEnabled) {
                  store.session.createOptionsPrompt()
                }
              }}
              useFourColoredCards={store.session.isFourColoredDeckEnabled}
              showCardsForAllin={store.session.showCardsForAllin}
              isGamePaused={store.session?.isGamePaused}
              isGameBegin={
                !store.session.isShowdown &&
                store.session.seatedPlayers.length > 1 &&
                store.session.isPlayingGame &&
                !store.session.isGameReset
              }
              isMyTurn={isMyTurn}
              isTeacher={store.session.isTeacher}
              isCardVisible={store.session.isCardsVisible}
              setUserForTeacher={(playerUserIdForTeacher: string) => {
                store.session.setPlayerUserIdForTeacher(playerUserIdForTeacher)
              }}
              secondsPassed={turnTimer()}
              shouldHighlightCurrentPlayer={store.session.shouldHighlightCurrentPlayer}
              actionTime={store.session.actionTime}
              startingImageHeight={store.session.getTimePassedPercentage()}
              actionDurationLeft={store.session.getPlayerActionDuration()}
              hasUserTappedOnAction={store.session.hasUserTappedOnAction}
            />
            <View
              style={[
                styles.cardPointsView,
                gridPreset === GridPreset.table && {
                  justifyContent: 'center',
                },
              ]}
            >
              {isIPad && myHoleCards.length > 0 && (
                <View style={style.holeCardContainerIpad}>
                  <PlayerHoleCards
                    hand={holeCards}
                    useFourColoredCardStyle={store.session.isFourColoredDeckEnabled}
                    // customCardContainerStyle={{
                    //   opacity: me?.playerState.tag === PlayerStates.Folded.tag ? 0.5 : 1,
                    // }}
                    rankedHandName={holeCards.length > 0 ? rankedHandName : ''}
                    isFolded={me?.playerState.tag === PlayerStates.Folded.tag}
                  />
                </View>
              )}

              {/* Player Black Overlay */}
              {gridPreset !== GridPreset.table ? (
                <Pot points={mainPot} pots={sidePots} viewPotPlayers={store.session.createPotModal} />
              ) : null}
              {/* Player Cards Withdraw */}
              <Board
                communityCards={communityCards}
                preset={gridPreset}
                useFourColoredCardStyle={store.session.isFourColoredDeckEnabled}
              />
            </View>

            <View style={styles.cardActionContainer}>
              {/* show Table Full when spectators are watching game players */}
              <CardAndActions />
            </View>
            <ChatPreview
              isChatting={store.session.isChatting}
              setIsChatting={store.session.setIsChatting}
              latestChat={channelChats.length > 0 && channelChats[channelChats.length - 1]}
              handleChatNav={handleChatNav}
            />
            {/* <WifiReconnectIcon
              isWebSocketConnected={store.network.isWebSocketConnected}
              isNetworkConnected={store.network.isNetworkConnected}
            /> */}
          </View>

          <Modal
            visible={visible}
            onDismiss={() => {
              //modalProps.isDimissable?
              // hideOverlayModal()
            }}
            style={style.modalStyle}
          >
            <TimerOverlay
              key={'StartTimeOverlay'}
              onPress={() => {
                setVisible(false)
                if (store.session?.isGamePaused) {
                  doResume()
                } else {
                  doStart()
                }
              }}
              onClose={() => {
                setVisible(false)
                if (store.session?.isGamePaused) {
                  doResume()
                } else {
                  doStart()
                }
              }}
              time={timerValue}
              type={store.session.isGameReset ? TimerTypes.gamePause : TimerTypes.gameStart}
              isTeacher={store.session.isTeacher}
              isHost={store.session.isHost}
              visible={visible}
              gameStartForEvent={gameStartForEvent}
            />
          </Modal>
        </Portal>
      </Provider>
      {/* <ChatOverlay /> */}
      <GameModal
        turnTimer={turnTimer}
        timerValue={timerValue}
        onClose={() => {
          return
        }}
      />
    </SafeAreaView>
  )
})
