import React, { useState, useCallback } from 'react'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { View, Image } from 'react-native'
import { ProgressLayout } from '../../components/layouts'
import { CardList } from '../../components/CardList/CardList'
import { QuestionFeedback, OptionsButtons, GameDescription, FunFact } from '../../components'
import { MiniGamesRoutes, MiniGamesNavigation } from '../../navigation/minigames-navigator'
import { useFocusEffect } from '@react-navigation/native'

// data
import { gamesData, cardsToSelect } from './data'

// interfaces
import {
  Question,
  Option,
  SurveyGame,
  isImageType,
  CARD,
  answerIsIdType,
  answerIsCorrect,
  AnswerType,
  Game,
} from '../../screens'
import { CardsOptionsButtons } from '../../components/Minigames/CardsOptionsButtons'

interface IntroScreenProps {
  route: RouteProp<{ params: { gameName: string } }>
}

export const SurveyGameScreen = ({ route }: IntroScreenProps) => {
  const { gameName } = route.params
  const navigation = useNavigation<MiniGamesNavigation>()
  const [currentGame, setCurrentGame] = useState<SurveyGame>()
  const [progress, setProgress] = useState(1)
  const [currentQuestion, setCurrentQuestion] = useState<Question>()
  const [selectedAnswer, setSelectedAnswer] = useState<Option>()
  const [selectedCards, setSelectedCards] = useState<CARD[]>([])
  const [answeredCorrectlyCount, setAnsweredCorrectlyCount] = useState(0)
  const [startTime, setStartTime] = useState(0)
  const [showFunFact, setShowFunFact] = useState(false)

  const incrementProgress = () => {
    if (progress === currentGame?.questions.length) {
      return navigation.navigate(MiniGamesRoutes.miniGameFinalScreen, {
        completionPercentage: Math.round((answeredCorrectlyCount * 100) / currentGame?.questions.length),
        runTime: getRunTime(),
        total: currentGame?.questions.length,
        stats: {
          correct: answeredCorrectlyCount,
          wrong: currentGame?.questions.length - answeredCorrectlyCount,
        },
        gameName,
      })
    }

    setProgress(progress + 1)
    setCurrentQuestion(currentGame?.questions[progress])
    setSelectedAnswer(undefined)
  }

  const getRunTime = () => {
    const endTime = new Date().getTime()
    const runTime = Math.round((endTime - startTime) / 1000)
    const seconds = runTime % 60
    const minutes = Math.floor(runTime / 60)
    const hours = Math.floor(minutes / 60)

    const strHours = hours < 10 ? `0${hours}` : hours
    const strMinutes = minutes < 10 ? `0${minutes}` : minutes
    const strSeconds = seconds < 10 ? `0${seconds}` : seconds

    return `${strHours}:${strMinutes}:${strSeconds}`
  }

  const onPressLeftButton = () => {
    if (currentGame?.prevButtonRedirect) {
      navigation.navigate(MiniGamesRoutes[currentGame.prevButtonRedirect as keyof typeof MiniGamesRoutes], {
        ranking: currentGame?.rankingToDisplay,
      })
    }
  }

  const onSelectCard = (card: CARD) => {
    if (selectedCards.includes(card)) {
      setSelectedCards(selectedCards.filter((c) => c !== card))
    } else if (selectedCards.length < cardsToSelect) {
      setSelectedCards([...selectedCards, card])
    }
  }

  // TODO: move selectedAnswer > selectedOption and create selectedAnswer with type AnswerType
  // TODO: createisCorrect state and propagate to QuestionFeedback component
  const onSelectAnswer = (answer: Option) => {
    if (!currentQuestion) return
    setSelectedAnswer(answer)
    const elementToCompare: AnswerType = answerIsIdType(currentQuestion?.correctAnswer) ? answer.id : selectedCards
    const isCorrect = answerIsCorrect(currentQuestion?.correctAnswer, elementToCompare)

    if (isCorrect) {
      setAnsweredCorrectlyCount(answeredCorrectlyCount + 1)
    }
  }

  const initializeData = (game: SurveyGame) => {
    setCurrentGame(game)
    setCurrentQuestion(game.questions[0])
    setSelectedAnswer(undefined)
    setSelectedCards([])
    setProgress(1)
    setAnsweredCorrectlyCount(0)
    setStartTime(Date.now())
  }

  useFocusEffect(
    useCallback(() => {
      if (gameName) {
        const currentGame = gamesData.find((game: Game) => game.name === gameName)
        if (!currentGame) {
          navigation.navigate(MiniGamesRoutes.miniGamesLobby)
          return
        }
        initializeData(currentGame as SurveyGame)
      }
    }, [gameName])
  )

  const highlightedCards = (): CARD[] => {
    return selectedAnswer && currentQuestion?.highlight ? currentQuestion.highlight : []
  }

  if (!currentGame || !currentQuestion) return null

  return (
    <View>
      <ProgressLayout
        leftButtonText={currentGame.prevButton}
        rightButtonText={currentGame.nextButton}
        progress={progress}
        maxProgress={currentGame.questions.length}
        onPressRightButton={incrementProgress}
        onPressLeftButton={onPressLeftButton}
        showButtons={!!selectedAnswer}
      >
        {currentQuestion.question && (
          <View style={{ minHeight: '10%', justifyContent: 'center' }}>
            <GameDescription question={currentQuestion} />
          </View>
        )}
        {currentQuestion.elementToDisplay && (
          <View style={{ justifyContent: 'center', minHeight: '20%' }}>
            {!isImageType(currentQuestion.elementToDisplay) && (
              <CardList
                cards={currentQuestion.elementToDisplay}
                highlightedCards={highlightedCards()}
                selectedCards={selectedCards}
                selectable={currentQuestion.selectableCards}
                onSelectCard={onSelectCard}
              />
            )}
            {isImageType(currentQuestion.elementToDisplay) && (
              <Image style={{ alignSelf: 'center' }} source={currentQuestion.elementToDisplay}></Image>
            )}
          </View>
        )}
        {currentQuestion.options && (
          <View>
            {!currentQuestion.optionsWithCards && (
              <OptionsButtons
                options={currentQuestion.options}
                selectedAnswer={selectedAnswer}
                onSelectAnswer={(answer: Option) => onSelectAnswer(answer)}
              />
            )}
            {currentQuestion.optionsWithCards && (
              <CardsOptionsButtons
                options={currentQuestion.options}
                selectedAnswer={selectedAnswer}
                onSelectAnswer={(answer: Option) => onSelectAnswer(answer)}
                title={currentQuestion.optionsTitle}
                selectableCards={currentQuestion.selectableCards}
                highlightedCards={highlightedCards()}
                selectedCards={selectedCards}
                onSelectCard={onSelectCard}
              />
            )}
          </View>
        )}
        {selectedAnswer && (
          <QuestionFeedback
            selectedOption={selectedAnswer}
            question={currentQuestion}
            selectedCards={selectedCards}
            onPress={() => setShowFunFact(true)}
          />
        )}
      </ProgressLayout>
      {currentQuestion.funFact && showFunFact && (
        <FunFact fact={currentQuestion.funFact} onContinue={() => setShowFunFact(false)} />
      )}
    </View>
  )
}
