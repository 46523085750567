import { StyleSheet } from 'react-native'
import { moderateScale } from '../../styles/sizes'

export const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#00000070',
    justifyContent: 'center',
  },
  modalContainer: {
    marginHorizontal: 35,
    marginBottom: 20,
    backgroundColor: '#070D37',
    borderWidth: 0.5,
    borderRadius: 14,
    padding: 2,
  },
  modalInnerContainer: {
    backgroundColor: '#070D37',
    borderRadius: 14,
  },
  modalCloseContainer: {
    position: 'absolute',
    left: 20,
    top: -8,
  },
  closeImage: {
    height: 30,
    width: 30,
  },
  playerList: {
    color: 'white',
    fontSize: moderateScale(16),
    paddingLeft: 15,
    fontWeight: '500',
    // lineHeight: 18,
  },
  chipsText: {
    color: 'white',
    paddingLeft: 5,
    fontSize: moderateScale(16),
    fontWeight: '500',
    // lineHeight: 18,
  },
  playerListContainer: {
    flex: 3, paddingRight: 15 
  },
  chipsStackContainer: {
    flex: 1, padding: 5, flexDirection: 'row', alignItems: 'center', paddingVertical: 20 
  },
  chipsIcon: {
    paddingBottom: 3, alignSelf: 'flex-end'
  },
  chipsValue: {
    padding: 0, alignSelf: 'flex-end'
  }
})
