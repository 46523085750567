/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameEntityModel, GameEntityModelType } from "./GameEntityModel"
import { GameEntityModelSelector } from "./GameEntityModel.base"
import { InputPlayerStatsModel, InputPlayerStatsModelType } from "./InputPlayerStatsModel"
import { InputPlayerStatsModelSelector } from "./InputPlayerStatsModel.base"
import { TournamentModel, TournamentModelType } from "./TournamentModel"
import { TournamentModelSelector } from "./TournamentModel.base"
import { RootStoreType } from "./index"


/**
 * TournamentStatsBase
 * auto generated base class for the model TournamentStatsModel.
 */
export const TournamentStatsModelBase = ModelBase
  .named('TournamentStats')
  .props({
    __typename: types.optional(types.literal("TournamentStats"), "TournamentStats"),
    _id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    gameId: types.union(types.undefined, types.late((): any => GameEntityModel)),
    isGameRunning: types.union(types.undefined, types.boolean),
    kind: types.union(types.undefined, types.string),
    players: types.union(types.undefined, types.array(types.late((): any => InputPlayerStatsModel))),
    prizePool: types.union(types.undefined, types.number),
    runTime: types.union(types.undefined, types.string),
    tournamentId: types.union(types.undefined, types.late((): any => TournamentModel)),
    updatedAt: types.union(types.undefined, types.frozen()),
    winners: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class TournamentStatsModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get isGameRunning() { return this.__attr(`isGameRunning`) }
  get kind() { return this.__attr(`kind`) }
  get prizePool() { return this.__attr(`prizePool`) }
  get runTime() { return this.__attr(`runTime`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get winners() { return this.__attr(`winners`) }
  gameId(builder?: string | GameEntityModelSelector | ((selector: GameEntityModelSelector) => GameEntityModelSelector)) { return this.__child(`gameId`, GameEntityModelSelector, builder) }
  players(builder?: string | InputPlayerStatsModelSelector | ((selector: InputPlayerStatsModelSelector) => InputPlayerStatsModelSelector)) { return this.__child(`players`, InputPlayerStatsModelSelector, builder) }
  tournamentId(builder?: string | TournamentModelSelector | ((selector: TournamentModelSelector) => TournamentModelSelector)) { return this.__child(`tournamentId`, TournamentModelSelector, builder) }
}
export function selectFromTournamentStats() {
  return new TournamentStatsModelSelector()
}

export const tournamentStatsModelPrimitives = selectFromTournamentStats()._id.createdAt.isGameRunning.kind.prizePool.runTime.updatedAt.winners
