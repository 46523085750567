import React from 'react'
import { Image, Platform, StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { PlayerStates } from '@poker-powher/poker'
import { Text } from '../text/text'
import { styles } from './styles'

export enum PlayerRole {
  player = '',
  dealer = 'D',
  bigBlind = 'BB',
  smallBlind = 'SB',
}

export const PlayerRoleBadge = (props: {
  playerTag: string
  playerRole: PlayerRole
  mappedIndex: number
  isTableVisible: boolean
  textStyle?: TextStyle
  containerStyle?: StyleProp<ViewStyle>
}) => {
  const { playerTag, playerRole, mappedIndex, isTableVisible, textStyle, containerStyle } = props
  const customStyle = textStyle ? textStyle : {}
  const DealerBadgeText = () => {
    const dealerStyle = Platform.OS === 'web' ? [styles.dealerBadgeText, { color: 'red' }] : styles.dealerBadgeText
    return (
      <Text
        allowFontScaling={false}
        tx="gameScreen.playerRoleOrName"
        txOptions={{ value: playerRole }}
        style={dealerStyle}
      />
    )
  }
  const defaultBadgeText = (
    <Text
      allowFontScaling={false}
      tx="gameScreen.playerRoleOrName"
      txOptions={{ value: playerRole }}
      style={[
        styles.cardRank,
        props.mappedIndex % 2 === 0
          ? { left: Platform.OS === 'web' ? 10 : 6, textAlign: 'left' }
          : { right: Platform.OS === 'web' ? 10 : 6, textAlign: 'right' },
        Platform.OS === 'web' ? { position: 'absolute' } : {},
      ]}
    />
  )
  if (playerTag === PlayerStates.Out.tag) {
    return null
  }
  if (isTableVisible) {
    switch (props.playerRole) {
      case PlayerRole.dealer:
        return (
          <View style={[{ flexDirection: 'row', alignItems: 'center' }, containerStyle]}>
            <View style={styles.dotCircle} />
            <Text
              allowFontScaling={false}
              tx="gameScreen.playerRoleOrName"
              txOptions={{ value: playerRole }}
              style={[{ paddingLeft: 1, color: 'white' }, customStyle]}
            />
          </View>
        )
      default:
        return (
          <Text
            allowFontScaling={false}
            tx="gameScreen.playerRoleOrName"
            txOptions={{ value: playerRole }}
            style={[{ paddingLeft: 1, color: 'white' }, customStyle]}
          />
        )
    }
  } else {
    switch (props.playerRole) {
      case PlayerRole.dealer:
        if (Platform.OS === 'web') {
          return (
            <View
              style={[
                mappedIndex % 2 === 0
                  ? { left: 6, justifyContent: 'flex-start' }
                  : { right: 6, justifyContent: 'flex-end' },
                styles.dealerBadgeContainer,
                styles.dotCircle,
                { margin: 3, top: 6 },
              ]}
            >
              <DealerBadgeText />
            </View>
          )
        } else {
          return (
            <View
              style={[
                mappedIndex % 2 === 0
                  ? { left: 6, justifyContent: 'flex-start' }
                  : { right: 6, justifyContent: 'flex-end' },
                styles.dealerBadgeContainer,
              ]}
            >
              <View style={styles.dotCircle}>{<DealerBadgeText />}</View>
            </View>
          )
        }
      default:
        return playerTag === PlayerStates.Observer.tag ? (
          <Image
            source={require('../../../assets/menu/open-eye.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20, position: 'absolute', right: 5, top: 5 }}
          />
        ) : (
          defaultBadgeText
        )
    }
  }
}
