import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  chatBubbleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginVertical: 1,
    paddingBottom: 12,
    paddingHorizontal: 6,
    minHeight: 40,
    // overflow: 'hidden',
  },
  chatBubbleNameContainer: {
    width: '100%',
  },
  chatBubbleName: {
    color: 'white',
    fontSize: 17,
    paddingHorizontal: 10,
    paddingVertical: 2,
    opacity: 1,
  },
  chatBubbleContentDealer: {
    backgroundColor: 'rgb(5, 16,45)',
    maxWidth: '100%',
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  chatBubbleContent: {
    backgroundColor: 'transparent',
    maxWidth: '100%',
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  chatBubbleDealerFrame: {
    borderRadius: 20,
    padding: 1,
    overflow: 'hidden',
  },
  chatBubbleObserverFrame: {
    borderRadius: 22,
    overflow: 'hidden',
    borderColor: 'rgba(200, 200, 200, 0.9)',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  chatBubbleTextContainer: {

  },
  chatBubbleText: {
    maxWidth: '100%',
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 2,
  },
})
