import * as Font from 'expo-font'

export const initFonts = async () => {
  // Refer to ./assets/fonts/custom-fonts.md for instructions.
  // ...
  // Welcome back! Just uncomment this and replace/append with your font file names!
  // ⬇
  // await Font.loadAsync({
  //   Montserrat: require("./Montserrat-Regular.ttf"),
  //   "Montserrat-Regular": require("./Montserrat-Regular.ttf"),
  // })
  await Font.loadAsync({
    'HeroNew-Regular': require('./Hero-New-Regular.otf'),
    'HeroNew-RegularItalic': require('./Hero-New-Regular-Italic.otf'),
    'HeroNew-Bold': require('./Hero-New-Bold.otf'),
    'HeroNew-ExtraBold': require('./Hero-New-ExtraBold.otf'),
    'HeroNew-BoldItalic': require('./Hero-New-Bold-Italic.otf'),
    'HeroNew-ExtraBoldItalic': require('./Hero-New-ExtraBold-Italic.otf'),
    'SFProDisplay-Black': require('./SF-Pro-Display-Black.otf'),
    'SFProDisplay-Bold': require('./SF-Pro-Display-Bold.otf'),
    'SFProDisplay-BlackItalic': require('./SF-Pro-Display-BlackItalic.otf'),
    'SFProDisplay-BoldItalic': require('./SF-Pro-Display-BoldItalic.otf'),
    'SFProDisplay-Heavy': require('./SF-Pro-Display-Heavy.otf'),
    'SFProDisplay-HeavyItalic': require('./SF-Pro-Display-HeavyItalic.otf'),
    'SFProDisplay-Light': require('./SF-Pro-Display-Light.otf'),
    'SFProDisplay-LightItalic': require('./SF-Pro-Display-LightItalic.otf'),
    'SFProDisplay-Medium': require('./SF-Pro-Display-Medium.otf'),
    'SFProDisplay-MediumItalic': require('./SF-Pro-Display-MediumItalic.otf'),
    'SFProDisplay-Regular': require('./SF-Pro-Display-Regular.otf'),
    'SFProDisplay-RegularItalic': require('./SF-Pro-Display-RegularItalic.otf'),
    'SFProDisplay-Semibold': require('./SF-Pro-Display-Semibold.otf'),
    'SFProDisplay-SemiboldItalic': require('./SF-Pro-Display-SemiboldItalic.otf'),
    'SFProDisplay-Thin': require('./SF-Pro-Display-Thin.otf'),
    'SFProDisplay-ThinItalic': require('./SF-Pro-Display-ThinItalic.otf'),
    'SFProDisplay-Ultralight': require('./SF-Pro-Display-Ultralight.otf'),
    'SFProDisplay-UltralightItalic': require('./SF-Pro-Display-UltralightItalic.otf'),
    'SFProText-Bold': require('./SF-Pro-Text-Bold.otf'),
    'SFProText-BoldItalic': require('./SF-Pro-Text-BoldItalic.otf'),
    'SFProText-Heavy': require('./SF-Pro-Text-Heavy.otf'),
    'SFProText-HeavyItalic': require('./SF-Pro-Text-HeavyItalic.otf'),
    'SFProText-Light': require('./SF-Pro-Text-Light.otf'),
    'SFProText-LightItalic': require('./SF-Pro-Text-LightItalic.otf'),
    'SFProText-Medium': require('./SF-Pro-Text-Medium.otf'),
    'SFProText-MediumItalic': require('./SF-Pro-Text-MediumItalic.otf'),
    'SFProText-Regular': require('./SF-Pro-Text-Regular.otf'),
    'SFProText-RegularItalic': require('./SF-Pro-Text-RegularItalic.otf'),
    'SFProText-Semibold': require('./SF-Pro-Text-Semibold.otf'),
    'SFProText-SemiboldItalic': require('./SF-Pro-Text-SemiboldItalic.otf'),
  })
}
