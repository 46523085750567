/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { GameKindEnumType } from "./GameKindEnum"
import { RootStoreType } from "./index"


/**
 * GameEntityBase
 * auto generated base class for the model GameEntityModel.
 */
export const GameEntityModelBase = ModelBase
  .named('GameEntity')
  .props({
    __typename: types.optional(types.literal("GameEntity"), "GameEntity"),
    _id: types.identifier,
    channelName: types.union(types.undefined, types.string),
    createdAt: types.union(types.undefined, types.frozen()),
    forPlayers: types.union(types.undefined, types.frozen()),
    hostId: types.union(types.undefined, types.frozen()),
    kind: types.union(types.undefined, types.null, GameKindEnumType),
    snapshot: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    users: types.union(types.undefined, types.array(types.string)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class GameEntityModelSelector extends QueryBuilder {
  get _id() { return this.__attr(`_id`) }
  get channelName() { return this.__attr(`channelName`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get forPlayers() { return this.__attr(`forPlayers`) }
  get hostId() { return this.__attr(`hostId`) }
  get kind() { return this.__attr(`kind`) }
  get snapshot() { return this.__attr(`snapshot`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  get users() { return this.__attr(`users`) }
}
export function selectFromGameEntity() {
  return new GameEntityModelSelector()
}

export const gameEntityModelPrimitives = selectFromGameEntity()._id.channelName.createdAt.forPlayers.hostId.kind.snapshot.updatedAt.users
