import { applySnapshot, getEnv, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'
import OneSignal from 'react-native-onesignal'

import { emitter, LogoutEvent } from '../../utils/events'
import { JwtModel, JwtModelType } from '../api-store'
import { EnvironmentStages } from '../environment'
import { withRootStore } from '../extensions'
import { globalConnectionParams } from '../GlobalConnectionParams'
import { RootStoreEnv } from '../RootStoreEnv'
import { events } from './analytics/analytics-events'

/**
 * A SessionTokenStore model.
 */

export const SessionTokenStoreModel = types
  .model('SessionTokenStoreModel')
  .props({
    isBootstrapped: types.optional(types.boolean, false),
    token: types.maybe(types.string),
    refreshToken: types.maybe(types.string),
  })
  .extend(withRootStore)
  .actions((self) => ({
    setRefreshToken(token?: string) {
      console.log('setRefreshToken', token)
      if (token) {
        // Won't re-render because value isn't changing
        self.isBootstrapped = true
      }
      self.refreshToken = token
    },
    setToken(token?: string) {
      console.log('setToken', token)
      self.token = token
      globalConnectionParams.token = token
      const env = getEnv<RootStoreEnv>(self)
      // https://github.com/apollographql/subscriptions-transport-ws/issues/171
      if (token) {
        const headers = { authorization: `bearer ${token}` }
        console.log('unsubscribeAll setHeaders', headers)
        env.gqlHttpClient.setHeaders(headers)
        self.rootStore.network.setupWebsocketListeners(
          // @ts-ignore
          env.stage === EnvironmentStages.local ? env.gqlWsClient : env.gqlWsClient.client
        )
        self.isBootstrapped = true
      } else {
        env.gqlHttpClient.setHeaders({})
      }
    },
  }))
  .actions((self) => ({
    setCode(data: JwtModelType) {
      if (JwtModel.is(data)) {
        const jwt = JwtModel.create(data)
        // console.log('is jwt', jwt, !!self.rootStore)
        self.setRefreshToken(jwt.refreshToken)
        self.setToken(jwt.accessToken)
      }
    },
  }))
  .actions((self) => {
    // const root = self.rootStore
    const logout = () => {
      console.log('logout')
      self.setToken(undefined)
      applySnapshot(self, {})
      OneSignal.removeExternalUserId()
      self.rootStore.session.setEvent(events.logout.userLogout)
    }

    const login = async (email: string, password: string) => {
      try {
        console.log('login', { email, password })
        // ... yield can be used in async/await style
        // const response: AuthenticationResponse = await root.services.api.session.post('/auth/login', {
        //   email,
        //   password,
        // })
        // const { accessToken } = response.data
        // console.log('logged in', { email, password, accessToken })
        // if (accessToken) {
        //   self.setToken(accessToken)
        // }
      } catch (error) {
        // ... including try/catch error handling
        console.error('unable to login', { error })
        logout()
      }
      console.log('JWT', { token: self.token })
      // The action will return a promise that resolves to the returned value
      // (or rejects with anything thrown from the action)
      return self.token
    }

    return { login, logout }
  })
  .actions((self) => {
    const afterCreate = () => {
      self.isBootstrapped = false
      console.log('token', self.token)
      self.setToken(self.token)
      emitter.addListener(LogoutEvent, self.logout)
      self.isBootstrapped = true
    }
    const beforeDestroy = () => {
      emitter.removeListener(LogoutEvent, self.logout)
      return
    }
    return { afterCreate, beforeDestroy }
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.token !== undefined
    },
  }))

/**
 * The SessionTokenStore instance.
 */
export type SessionTokenStore = Instance<typeof SessionTokenStoreModel>

/**
 * The data of a SessionTokenStore.
 */
export type SessionTokenStoreSnapshot = SnapshotOut<typeof SessionTokenStoreModel>
