import { constant, times } from 'lodash'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

// import RtcEngine, { ChannelProfile, ClientRole, VideoStreamType } from 'react-native-agora'
import { withRootStore } from '../extensions'

enum RTCRole {
  publisher = 1,
  subscriber = 1,
}

const countSeats = 9
/**
 * defaultIndex = 0 will restrict user if (uid - 1) = 0 and uid range is [1,9]. uid = 0 will never add to agoraUsers
 */
const defaultIndex = -1
const defaultAgoraUsers = times(countSeats, constant(defaultIndex))

/**
 * Agora Store Model
 */

export const SessionAgoraStoreModel = types
  .model('SessionAgoraStoreModel')
  .props({
    agoraToken: types.optional(types.string, ''),
    agoraUsers: types.optional(types.array(types.number), defaultAgoraUsers),
    isLocalUserJoined: types.optional(types.boolean, false),
    isLocalUserMuted: types.optional(types.boolean, false),
    showOptionModal: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
//   .volatile((self) => {
//     const rtcEngine: RtcEngine = new RtcEngine()
//     return {
//       rtcEngine,
//     }
//   })
//   .actions((self) => ({
//     clearAgoraToken() {
//       self.agoraToken = ''
//     },
//     setAgoraToken(token: string) {
//       self.agoraToken = token
//     },
//     setRTCEngine(engine: RtcEngine) {
//       self.rtcEngine = engine
//     },
//     setLocalUserJoined(value: boolean) {
//       self.isLocalUserJoined = value
//     },
//     setLocalUserMutedStatus(value: boolean) {
//       self.isLocalUserMuted = value
//     },
//     setShowOptionModal(value: boolean) {
//       self.showOptionModal = value
//     },
//     spliceAgoraUsers(index: number, deleteCount: number, uid?: number) {
//       if (uid) {
//         // self.agoraUsers.splice(index, deleteCount, uid)
//         self.agoraUsers.push(uid)
//       } else {
//         self.agoraUsers.splice(index, deleteCount, defaultIndex)
//       }
//     },
//   }))
//   .actions((self) => ({
//     async getAgoraToken(channelName: string, uid: number, role: RTCRole = RTCRole.publisher): Promise<string> {
//       console.log('session-agora getAgoraToken', channelName, uid, role)
//       try {
//         const response = await self.rootStore.api.queryGetAgoraToken({ channelName, uid, role })
//         console.log('Agora token', response)
//         response && self.setAgoraToken(response.getAgoraToken)
//         return response.getAgoraToken
//       } catch (error) {
//         console.error('Error in getting agora token', error)
//         throw error
//       }
//     },
//   }))
//   .actions((self) => ({
//     async startCall(uid: number) {
//       const channelName = self.rootStore.session.channel?.channelName
//       const userId = self.rootStore.session.user?._id
//       if (channelName && userId) {
//         console.log('session-agora startCall', self.agoraToken, channelName, userId, uid)
//         const token = await self.getAgoraToken(channelName, uid)
//         await self.rtcEngine.joinChannel(token, channelName, userId, uid)
//       }
//     },
//     async endCall() {
//       await self.rtcEngine.leaveChannel()
//       self.spliceAgoraUsers(0, self.agoraUsers.length)
//       self.setLocalUserJoined(false)
//     },
//     async pauseVideoStream() {
//       await self.rtcEngine.muteLocalVideoStream(true)
//     },
//     async resumeVideoStream() {
//       await self.rtcEngine.muteLocalVideoStream(false)
//     },
//     async muteVideoCall() {
//       await self.rtcEngine.muteLocalAudioStream(true)
//       self.setLocalUserMutedStatus(true)
//     },
//     async unMuteVideoCall() {
//       await self.rtcEngine.muteLocalAudioStream(false)
//       self.setLocalUserMutedStatus(false)
//     },
//     async setupVideo() {
//       // const engine = self.rtcEngine
//       const appId = '06997babc99845ef943d90ee04cb1a99'
//       const engine = await RtcEngine.create(appId)
//       self.setRTCEngine(engine)

//       // Enable the video module.
//       await engine.enableVideo()
//       await engine.enableAudio()
//       // await engine.disableAudio()
//       await engine.setChannelProfile(ChannelProfile.LiveBroadcasting)
//       await engine.setClientRole(ClientRole.Broadcaster)
//       await engine.setCameraAutoFocusFaceModeEnabled(true)
//       await engine.setCameraZoomFactor(1)
//       await engine.setRemoteDefaultVideoStreamType(VideoStreamType.High)
//       // setDefaultMuteAllRemoteAudioStreams(muted: boolean)
//       // await engine?.current?.startPreview()
//       // engine?.current?.enableWebSdkInteroperability(true)
//     },
//   }))
//   .actions((self) => {
//     const destroy = async () => {
//       if (self.rtcEngine) {
//         self.rtcEngine.removeAllListeners()
//         await self.rtcEngine.destroy()
//       }
//     }

//     const afterAttach = async () => {
//       self.setShowOptionModal(false)
//       self.setLocalUserJoined(false)
//       self.agoraUsers.replace(defaultAgoraUsers)
//       console.log('session-agora afterAttach')
//       const appId = '06997babc99845ef943d90ee04cb1a99'
//       const engine = await RtcEngine.create(appId)
//       self.setRTCEngine(engine)
//       // Listen for the UserJoined callback.
//       // This callback occurs when the remote user successfully joins the channel.
//       engine.addListener('UserJoined', (uid, elapsed) => {
//         console.log('UserJoined', uid, elapsed)
//         // const playerIndex = self.rootStore.session.getPlayerIndex(uid)
//         const index = self.agoraUsers.indexOf(uid - 1)
//         // Check whether uid exist or not
//         if (index === -1 && uid > 0) {
//           self.spliceAgoraUsers(uid, 1, uid)
//         }
//       })

//       // Listen for the UserOffline callback.
//       // This callback occurs when the remote user leaves the channel or drops offline.
//       engine.addListener('UserOffline', (uid, reason) => {
//         console.log('UserOffline', uid, reason)
//         //Get the index of uid to be deleted/removed
//         const index = self.agoraUsers.indexOf(uid)
//         if (index > -1) {
//           self.spliceAgoraUsers(index, 1)
//         }
//       })

//       // Listen for the JoinChannelSuccess callback.
//       // This callback occurs when the local user successfully joins the channel.
//       engine.addListener('JoinChannelSuccess', (channel, uid, elapsed) => {
//         console.log('JoinChannelSuccess', channel, uid, elapsed)
//         self.setLocalUserJoined(true)
//       })
//     }

//     const beforeDestroy = async () => {
//       console.log('session-agora beforeDestroy')
//       await destroy()
//     }

//     return { afterAttach, beforeDestroy }
//   })

/**
 * The SessionAgoraStore instance.
 */
export type SessionAgoraStore = Instance<typeof SessionAgoraStoreModel>

/**
 * The data of a SessionAgoraStore.
 */
export type SessionAgoraStoreSnapshot = SnapshotOut<typeof SessionAgoraStoreModel>
