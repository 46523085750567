'use strict'
import { StyleSheet } from 'react-native'

import { moderateScale, scale, verticalScale } from '../../styles/sizes'
import { color } from '../../theme'
import { fontFamily } from '../../theme/fontFamily'

export const styles = StyleSheet.create({
  cardMain: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 0,
    marginVertical: 0,
    borderRadius: 2,
  },
  cardBackgroundImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 2,
  },
  cardRank: {
    position: 'absolute',
    top: 3,
    fontSize: 14,
    fontWeight: 'bold',
    color: color.palette.white,
  },
  cardInnerBottom: {
    flexDirection: 'row',
    width: scale(160),
    height: 41,
    backgroundColor: color.palette.white,
  },
  cardUserImage: {
    top: 8,
    left: 5,
    width: 25,
    height: 25,
    resizeMode: 'cover',
  },
  cardUserInfo: {
    width: scale(90),
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: color.palette.white,
    marginHorizontal: 5,
    paddingHorizontal: 5,
  },
  cardUserName: {
    width: scale(64),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 12,
    letterSpacing: -0.24,
    color: color.palette.darkBlack,
  },
  cardUserTime: {
    position: 'absolute',
    top: 142,
    left: 104,
    width: scale(49),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'right',
    color: color.palette.white,
  },
  cardUserScore: {
    position: 'absolute',
    bottom: 0,
    width: scale(49),
    fontSize: 10,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'right',
    color: 'green', //color.palette.darkBlack,
  },
  cardUserTimeAgo: {
    width: scale(38),
    fontSize: 8,
    lineHeight: 10,
    letterSpacing: -0.24,
    color: 'rgba(60, 60, 67, 0.6)',
  },
  cardUserDuration: {
    width: scale(34),
    fontSize: 8,
    fontWeight: '500',
    lineHeight: 10,
    alignSelf: 'center',
    color: color.palette.darkBlack,
  },
  waitlistedUser: {
    // backgroundColor: 'rgba(0,0,0,.6)',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '104%',
    height: '102%',
  },
  playerCellSidebarContainer: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  coinAndRoleOnTableLeftPanel: {
    textAlign: 'left',
    color: '#fff',
    fontSize: 13,
    fontFamily: 'SFProDisplay-Bold',
  },
  coinAndRoleOnTableRightPanel: {
    textAlign: 'right',
    color: '#fff',
    fontSize: 13,
    fontFamily: 'SFProDisplay-Bold',
  },
  betValueLeftPanel: {
    textAlign: 'left',
    color: '#FFBB0E',
    fontSize: 16,
    fontFamily: 'SFProDisplay-Bold',
  },
  callLeftPanelText: {
    textAlign: 'left',
    color: '#fff',
    fontSize: 15,
    fontFamily: 'SFProDisplay-Bold',
  },
  betValueRightPanel: {
    textAlign: 'right',
    color: '#FFBB0E',
    fontSize: 16,
    fontFamily: 'SFProDisplay-Bold',
  },
  callRightPanelText: {
    textAlign: 'right',
    color: '#fff',
    fontSize: 15,
    fontFamily: fontFamily.sfProDisplayBold,
  },
  dealerBadgeText: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '700',
    color: 'red',
    paddingLeft: 1,
    fontFamily: fontFamily.sfProDisplayBold,
  },
  dealerBadgeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: 3,
  },
  dotCircle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 3,
  },
  playerNameText: {
    color: 'white',
    fontSize: moderateScale(20),
    fontWeight: '700',
    paddingLeft: -scale(5),
    marginTop: -verticalScale(8),
    textAlign: 'center',
    width: '100%',
  },
  playerNameContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  playerCardInnerContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 13,
  },
  playerNameInnerContainer: {
    flex: 0.6,
    width: '100%',
    // justifyContent: 'flex-start',
    alignItems: 'center',
  },
  lottieContainer: {
    position: 'absolute',
    // top: -16,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  playerNameInBottom: {
    zIndex: 999,
    // height: moderateScale(24),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: 'rgba(0, 0, 0, .6)',
    alignSelf: 'center',
    textAlign: 'center',
  },
})
