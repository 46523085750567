import * as React from 'react'

import Avatar1 from '../../../assets/Avatar/Avatar1.svg'
import Avatar2 from '../../../assets/Avatar/Avatar2.svg'
import Avatar3 from '../../../assets/Avatar/Avatar3.svg'
import Avatar4 from '../../../assets/Avatar/Avatar4.svg'
import Avatar5 from '../../../assets/Avatar/Avatar5.svg'
import Avatar6 from '../../../assets/Avatar/Avatar6.svg'
import Avatar7 from '../../../assets/Avatar/Avatar7.svg'
import Avatar8 from '../../../assets/Avatar/Avatar8.svg'
import Avatar9 from '../../../assets/Avatar/Avatar9.svg'
import Avatar10 from '../../../assets/Avatar/Avatar10.svg'

export const Avatars = {
  purple: <Avatar1 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  pink: <Avatar2 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  green: <Avatar3 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  blue: <Avatar4 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  orange: <Avatar5 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  red: <Avatar6 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  crimson: <Avatar7 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  teal: <Avatar8 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  lightblue: <Avatar9 width="100%" height="100%" preserveAspectRatio="XY slice" />,
  gray: <Avatar10 width="100%" height="100%" preserveAspectRatio="XY slice" />,
}

// avatars[9] should ALWAYS be gray/fold/out avatar.
// avatars[0 - 8] are ordered colors.
// changing order of this list will results in mix-matched chat and avatar colors.

export const avatars = {
  purple: Avatars.purple,
  pink: Avatars.pink,
  green: Avatars.green,
  blue: Avatars.blue,
  orange: Avatars.orange,
  red: Avatars.red,
  teal: Avatars.teal,
  lightblue: Avatars.lightblue,
  crimson: Avatars.crimson,
  gray: Avatars.gray,
}
