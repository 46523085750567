import * as React from 'react'
import { View } from 'react-native'

import { Card } from '../../components/card/card'
import styles from '../../styles/globalStyle'
export interface CardLayoutProps {
  name: string
}

export const CardLayout = (props: CardLayoutProps) => {
  const { name } = props

  return (
    <>
      <View style={styles.cardLayout}>
        <Card name={name} />
      </View>
    </>
  )
}
