'use strict'
import { StyleSheet } from 'react-native'
import { scale, moderateScale } from '../../styles/sizes'
import { color } from '../../theme'

export const styles = StyleSheet.create({
  buttonDark: {
    alignItems: 'center',    
    justifyContent: 'center',
    flexDirection: 'row',
    height: moderateScale(89),
    width: moderateScale(320),
    borderRadius: 10,
    borderColor: '#EAEAEA',
    // borderWidth: 1,
    backgroundColor: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.01)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    paddingVertical: 20,
    paddingHorizontal: 23,    
    shadowRadius: 4,
    elevation: 5,
  },
  buttonWhite: {
    backgroundColor: color.palette.white,
    width: scale(335),
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginVertical: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  buttonWhiteText: {
    fontSize: 16,
    color: color.palette.HighSlateBlue,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 19.36,
  },
  buttonDarkText: {
    fontSize: 16,
    color: color.palette.white,
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 19.36,
  },
  containerRow: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  shareImageIcon: { width: 58, height: 58, resizeMode: 'contain', marginRight: 23 },
  buttonTitleText: { fontSize: moderateScale(12), color: '#646464' }
})
