import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { FlatList, Image, ScrollView, TouchableOpacity, View } from 'react-native'
import { Divider, List } from 'react-native-paper'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Text } from '../../components'
import { useStore } from '../../models'
import { DrawerRoutes } from '../../navigation/drawer-navigator'
import { translate } from '../../i18n/translate'
import { styles } from './styles'

enum ProfileTabs {
  myStats = 'myStats',
  currentGame = 'currentGame',
  history = 'history',
  profile = 'profile',
}
const current_Game = translate('profileScreen.currentGame')
const community_Game = translate('profileScreen.communityGame')
const headsUp_Game = translate('profileScreen.quickPlayGame')

export const ProfileScreen = observer(() => {
  const navigation = useNavigation()
  const store = useStore()
  const me = store.session.myPlayer
  const channelName = store.session.channel ? store.session.channel.channelName : ''
  const user = store.session.user
  const gameId = store.session.share?.hash
  const isFocused = useIsFocused()
  const [activeTab, setActiveTab] = React.useState(ProfileTabs.currentGame)
  const [playerGame, setPlayerGames] = React.useState<any[]>([])

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getPlayerGames()
    })
    return unsubscribe
  }, [navigation])

  const getPlayerGames = async () => {
    try {
      const games = await store.session.getPlayerGames()
      setPlayerGames(games || [])
    } catch (error) {
      console.log('Get Player Games Error: ', error)
    }
  }

  useEffect(() => {
    if (playerGame.length > 0 && isFocused) {
      getPlayerStatsForGame(playerGame[0].gameId)
    }
  }, [playerGame.length, isFocused])

  const getPlayerStatsForGame = async (gameId: string) => {
    try {
      await store.session.getPlayerStats(gameId)
      setActiveTab(ProfileTabs.currentGame)
    } catch (error) {
      console.log('getPlayerStatsForGame API call error: ', error)
    }
  }

  const setGameStats = () => {
    setActiveTab(ProfileTabs.currentGame)
    if (playerGame && playerGame.length > 0) {
      getPlayerStatsForGame(playerGame[0].gameId)
    }
  }

  const setGlobalStats = async () => {
    try {
      setActiveTab(ProfileTabs.myStats)
      await store.session.getPlayerGlobalStats()
    } catch (error) {
      console.log('getPlayerGlobalStatsForGame API call error: ', error)
    }
  }

  const goBack = () => {
    if (store.session.channel === undefined) {
      navigation.navigate(DrawerRoutes.lobby)
    } else {
      navigation.navigate(DrawerRoutes.game)
    }
  }

  const totalNumberOfHands = store.session.myPlayerStats?.numberOfHands ?? 0
  const numberOfHandsPlayed = store.session.myPlayerStats?.numberOfHandsPlayed ?? 0
  const numberOfHandsWon = store.session.myPlayerStats?.numberOfHandsWon ?? 0
  const bestHandEverWon = store.session.myPlayerStats?.bestHandWon ?? ''
  const biggestWin = store.session.myPlayerStats?.biggestWin ?? ''
  const preflopFold = store.session.myPlayerStats?.preflopFold ?? '0'
  const allTimeBestHandWon = store.session.myPlayerStats?.allTimeBestHandWon ?? ''
  const allTimeBiggestWin = store.session.myPlayerStats?.allTimeBiggestWin ?? ''
  const currentGameId = store.session.gameId ?? ''

  return (
    <View style={{ backgroundColor: '#EEF1F5', flex: 1 }}>
      <View style={{ backgroundColor: '#fff', borderBottomLeftRadius: 30, borderBottomRightRadius: 30 }}>
        <View style={styles.navBar}>
          <TouchableOpacity onPress={goBack} style={{ padding: 5 }}>
            <Image source={require('../../../assets/menu/left-arrow.png')} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
          <Text style={{ fontWeight: 'bold', fontSize: 16 }}>Profile</Text>
          <View style={{ opacity: 0 }}>
            <Image source={require('../../../assets/menu/Admin.png')} style={{ width: 20, height: 20 }} />
          </View>
        </View>
        <View style={styles.tabBarView}>
          <TouchableOpacity onPress={setGameStats} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text
              tx="profileScreen.currentGame"
              style={{
                fontSize: 14,
                fontWeight: activeTab === ProfileTabs.currentGame ? '700' : '300',
                color: activeTab === ProfileTabs.currentGame ? '#659AF5' : '#414969',
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={setGlobalStats} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text
              tx="profileScreen.myStats"
              style={{
                fontSize: 14,
                fontWeight: activeTab === ProfileTabs.myStats ? '700' : '300',
                color: activeTab === ProfileTabs.myStats ? '#659AF5' : '#414969',
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveTab(ProfileTabs.history)}
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Text
              tx="profileScreen.history"
              style={{
                fontSize: 14,
                fontWeight: activeTab === ProfileTabs.history ? '700' : '300',
                color: activeTab === ProfileTabs.history ? '#659AF5' : '#414969',
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setActiveTab(ProfileTabs.profile)}
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Text
              tx="profileScreen.profile"
              style={{
                fontSize: 14,
                fontWeight: activeTab === ProfileTabs.profile ? '700' : '300',
                color: activeTab === ProfileTabs.profile ? '#659AF5' : '#414969',
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ marginBottom: 30, backgroundColor: '#fff' }}>
        {activeTab === ProfileTabs.myStats ? (
          <ScrollView style={{ marginBottom: 150, backgroundColor: '#fff' }}>
            <Text tx="profileScreen.totalNumberOfHands" style={[styles.label, { marginTop: 10 }]} />
            <Text tx="common.label" txOptions={{ label: totalNumberOfHands }} style={styles.values} />
            <Text tx="profileScreen.numberOfHandsPlayed" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: numberOfHandsPlayed }} style={styles.values} />
            <Text tx="profileScreen.numberOfHandsWon" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: numberOfHandsWon }} style={styles.values} />
            <Text tx="profileScreen.bestHandEverWon" style={styles.label} />
            <Text
              tx="common.label"
              txOptions={{
                label: allTimeBestHandWon
                  ? `${allTimeBestHandWon.rankedHandName} on ${new Date(allTimeBestHandWon.createdAt)
                      .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
                      .replace(',', '')}`
                  : '',
              }}
              style={styles.values}
            />
            <Text tx="profileScreen.biggestWin" style={styles.label} />
            <Text
              tx="common.label"
              txOptions={{
                label: allTimeBiggestWin
                  ? `${allTimeBiggestWin.chipsWon} on ${new Date(allTimeBiggestWin.createdAt)
                      .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
                      .replace(',', '')}`
                  : '',
              }}
              style={styles.values}
            />
            <Text tx="profileScreen.preflopFold" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: Number(preflopFold).toFixed(2) }} style={styles.values} />
          </ScrollView>
        ) : null}
        {activeTab === ProfileTabs.currentGame ? (
          <ScrollView style={{ marginBottom: 150, backgroundColor: '#fff' }}>
            <Text tx="profileScreen.totalNumberOfHands" style={[styles.label, { marginTop: 10 }]} />
            <Text tx="common.label" txOptions={{ label: totalNumberOfHands }} style={styles.values} />
            <Text tx="profileScreen.numberOfHandsPlayed" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: numberOfHandsPlayed }} style={styles.values} />
            <Text tx="profileScreen.numberOfHandsWon" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: numberOfHandsWon }} style={styles.values} />
            <Text tx="profileScreen.bestHandEverWon" style={styles.label} />
            <Text
              tx="common.label"
              txOptions={{
                label: bestHandEverWon
                  ? `${bestHandEverWon.rankedHandName} on ${new Date(bestHandEverWon.createdAt)
                      .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
                      .replace(',', '')}`
                  : '',
              }}
              style={styles.values}
            />
            <Text tx="profileScreen.biggestWin" style={styles.label} />
            <Text
              tx="common.label"
              txOptions={{
                label: biggestWin
                  ? `${biggestWin.chipsWon} on ${new Date(biggestWin.createdAt)
                      .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
                      .replace(',', '')}`
                  : '',
              }}
              style={styles.values}
            />
            <Text tx="profileScreen.preflopFold" style={styles.label} />
            <Text tx="common.label" txOptions={{ label: Number(preflopFold).toFixed(2) }} style={styles.values} />
          </ScrollView>
        ) : null}
        {activeTab === ProfileTabs.history ? (
          <React.Fragment>
            {playerGame.length > 0 ? (
              <FlatList
                data={playerGame}
                keyExtractor={(item, index) => `profile-stat-${index}`}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity onPress={() => getPlayerStatsForGame(item.gameId)}>
                      <List.Item
                        title={
                          currentGameId === item.gameId
                            ? current_Game
                            : `Game Created on ${new Date(item.gameCreatedAt)
                                .toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
                                .replace(',', '')}`
                        }
                        description={item.gameKind === 'tournament' ? community_Game : headsUp_Game}
                        right={(props) => (
                          <List.Icon
                            {...props}
                            icon="apple-keyboard-control"
                            style={{ transform: [{ rotate: '90deg' }] }}
                          />
                        )}
                      />
                    </TouchableOpacity>
                  )
                }}
                ItemSeparatorComponent={() => {
                  return <Divider style={{ marginHorizontal: 20 }} />
                }}
              />
            ) : null}
          </React.Fragment>
        ) : null}
        {activeTab === ProfileTabs.profile ? (
          <ScrollView style={{ marginBottom: 150 }}>
            <Text tx="profileScreen.userId" style={[styles.overviewLabel, { marginTop: 10 }]} />
            <Text tx="common.label" txOptions={{ label: me ? me.userId : '' }} style={styles.overviewValues} />
            <Divider style={{ marginHorizontal: 20 }} />
            <Text tx="profileScreen.email" style={styles.overviewLabel} />
            <Text tx="common.label" txOptions={{ label: user ? user.email : '' }} style={styles.overviewValues} />
            <Divider style={{ marginHorizontal: 20 }} />
            <Text tx="profileScreen.channelName" style={styles.overviewLabel} />
            <Text
              tx="common.label"
              txOptions={{ label: channelName ? channelName : '' }}
              style={styles.overviewValues}
            />
            <Divider style={{ marginHorizontal: 20 }} />
            <Text tx="profileScreen.gameId" style={styles.overviewLabel} />
            <Text tx="common.label" txOptions={{ label: gameId ? gameId : '' }} style={styles.overviewValues} />
            <Divider style={{ marginHorizontal: 20 }} />
            <Text tx="profileScreen.status" style={styles.overviewLabel} />
            <Text
              tx="common.label"
              txOptions={{ label: me ? me.playerState.description : '' }}
              style={styles.overviewValues}
            />
          </ScrollView>
        ) : null}
      </View>
    </View>
  )
})
