import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { View, Text } from 'react-native'
import { ChatKind } from '../../models/api-store'
import { styles } from './styles'

interface IChatBubbleProps {
  message: string,
  chatKind: ChatKind,
  userName: string,
  color?: string,
}


export const ChatBubble = (props : IChatBubbleProps) => {

  if (props.chatKind === ChatKind.dealer) {
    return (
      <View style={styles.chatBubbleContainer}>
        <View style={styles.chatBubbleNameContainer}>
          {/* <Text style={styles.chatBubbleName}>{`Dealer: `}</Text> */}
          {/* <Text style={styles.chatBubbleName}>{``}</Text> */}
        </View>
        <LinearGradient
          colors={['#49CDF2', '#49CDF2', '#9444FB']}
          start={{ x: 0.0, y: 1.0 }}
          end={{ x: 1.0, y: 1.0 }}
          style={styles.chatBubbleDealerFrame}   
        >
          <View style={styles.chatBubbleContentDealer}>
            <View style={styles.chatBubbleTextContainer}>
              <Text style={styles.chatBubbleText}>{props.message}</Text>
            </View>
          </View>
        </LinearGradient>
      </View>
    )
  } else if (props.chatKind === ChatKind.observer) {
    return (
      <View style={styles.chatBubbleContainer}>
        <View style={styles.chatBubbleNameContainer}>
          <Text style={styles.chatBubbleName}>{`${props.userName} (Observer):`}</Text>
        </View>
        <LinearGradient
          colors={['rgba(130, 130, 130, 0.25)', 'rgba(130, 130, 130, 0.66)']}
          start={{ x: 1.0, y: 0.0 }}
          end={{ x: 1.0, y: 1.0 }}
          style={styles.chatBubbleObserverFrame}   
        >
          <View style={styles.chatBubbleContent}>
            <View style={styles.chatBubbleTextContainer}>
              <Text style={styles.chatBubbleText}>{props.message}</Text>
            </View>
          </View>
        </LinearGradient>
      </View>
    )
  } else {
    return (
      <View style={styles.chatBubbleContainer}>
        <View style={styles.chatBubbleNameContainer}>
          <Text style={styles.chatBubbleName}>{`${props.userName}: `}</Text>
        </View>
          <View style={[styles.chatBubbleContent, { backgroundColor: props.color }]}>
            <View style={styles.chatBubbleTextContainer}>
              <Text style={styles.chatBubbleText}>{props.message}</Text>
            </View>
          </View>
      </View>
    ) 
  }
}
