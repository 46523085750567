import 'react-native-get-random-values'
import { LinearGradient } from 'expo-linear-gradient'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
    ActivityIndicator, Animated, Dimensions, Easing, ImageBackground, Keyboard,
    KeyboardAvoidingView, Platform, Pressable, SafeAreaView, StatusBar, View, ViewProps
} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Searchbar } from 'react-native-paper'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { Text } from '../../components'
import { useStore } from '../../models'
import { DrawerNavigation, DrawerRoutes } from '../../navigation/drawer-navigator'
import styles from '../../styles/globalStyle'
import { startStyles } from './styles'
import { moderateScale } from '../../styles/sizes'

const window = Dimensions.get('window')
const IMAGE_HEIGHT = window.height / 3
const IMAGE_HEIGHT_SMALL = window.height / 4.5


const image = require('../../../assets/icons/Blank-Home_Purple.png')
const logo = require('../../../assets/icons/Logo-Purple.png')

interface NameInputProps extends ViewProps {
  doSubmit: () => void
  errorMessage: boolean
  imageHeight: Animated.Value
  loading: boolean
  userName: string
  onChangeText: (text: string) => void
}

const NameInput = (props: NameInputProps) => {
  const isFocused = useIsFocused()
  const { errorMessage, doSubmit, imageHeight, loading, userName, onChangeText } = props

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={5}
      style={[startStyles.keyboardContainerStyle]}
    >
      <StatusBar barStyle="dark-content" />
      <View style={startStyles.logoContainer}>
        <Animated.Image source={logo} style={[startStyles.logo, { height: imageHeight }]} />
      </View>
      <View style={startStyles.inputContainer}>
        <View style={{ marginBottom: 5, height: 70, justifyContent: 'flex-end' }}>
          <Text allowFontScaling={false} tx="nameScreen.nameMsg" style={startStyles.enterCodeMessage} />
          <ActivityIndicator size="small" color="#646464" style={{ opacity: loading ? 1 : 0 }} />
          {/* {errorMessage && <Text allowFontScaling={false} tx="join.gameCodeError" style={startStyles.errorMessage} />} */}
        </View>
        <Searchbar
          allowFontScaling={false}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoFocus={isFocused}
          clearIcon="close-circle"
          icon={() => <View />}
          inputStyle={{ fontSize: moderateScale(22), fontWeight: '400', color: '#353535' }}
          maxLength={50}
          onChangeText={onChangeText}
          onSubmitEditing={doSubmit}
          returnKeyType="go"
          style={startStyles.NameInput}
          textAlign="center"
          value={userName}
        />
        <Pressable onPress={doSubmit} style={{ height: 50, width: '80%', marginTop: 8 }}>
          <LinearGradient
            colors={['#49CDF2', '#9444FB']}
            start={[0, 1]}
            end={[1, 0]}
            style={{ flex: 1, borderRadius: 6, justifyContent: 'center' }}
          >
            <Text
              allowFontScaling={false}
              tx="common.next"
              style={[
                startStyles.joinText,
                {
                  color: '#ffffff',
                },
              ]}
            />
          </LinearGradient>
        </Pressable>
      </View>
    </KeyboardAvoidingView>
  )
}

export const NameScreen = observer(() => {
  const navigation = useNavigation<DrawerNavigation>()
  const store = useStore()

  const imageHeight = useState(new Animated.Value(IMAGE_HEIGHT))[0]
  const [userName, setUserName] = useState(store.session.user ? store.session.user.name : '')
  const [errorMessage, setErrorMessage] = useState(false)
  const [loading, setLoading] = useState(false)

  const reset = () => {
    console.log('join reset')
    Keyboard.dismiss()
    setUserName('')
    setErrorMessage(false)
    setLoading(false)
  }

  const doSubmit = debounce(async () => {
    console.log('doSubmit')
    try {
      if (userName) {
        await store.session.updateName(userName)
        await store.session.fetchMe()
        await store.session.setUserIdentify(store.session.user?._id, {
          name: userName,
          email: store.session.user?.email,
        })
        store.session.clearGameModalQueue()
        navigation.navigate(DrawerRoutes.lobby)
      }
    } catch (error) {
      console.log('Username API Error: ', error)
    }
  }, 250)

  const keyboardWillShow = (event: any) => {
    Animated.timing(imageHeight, {
      duration: event.duration,
      toValue: IMAGE_HEIGHT_SMALL,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start()
  }

  const keyboardWillHide = (event: any) => {
    Animated.timing(imageHeight, {
      duration: event.duration,
      toValue: IMAGE_HEIGHT,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start()
  }

  const keyboardDidShow = (event: any) => {
    Animated.timing(imageHeight, {
      duration: event.duration,
      toValue: IMAGE_HEIGHT_SMALL,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start()
  }

  const keyboardDidHide = (event: any) => {
    Animated.timing(imageHeight, {
      duration: event.duration,
      toValue: IMAGE_HEIGHT,
      useNativeDriver: false,
      easing: Easing.linear,
    }).start()
  }

  useEffect(() => {
    if (Platform.OS == 'ios') {
      Keyboard.addListener('keyboardWillShow', keyboardWillShow)
      Keyboard.addListener('keyboardWillHide', keyboardWillHide)
    } else {
      Keyboard.addListener('keyboardDidShow', keyboardDidShow)
      Keyboard.addListener('keyboardDidHide', keyboardDidHide)
    }
    return () => {
      if (Platform.OS == 'ios') {
        Keyboard.removeListener('keyboardWillShow', keyboardWillShow)
        Keyboard.removeListener('keyboardWillHide', keyboardWillHide)
      } else {
        Keyboard.removeListener('keyboardDidShow', keyboardDidShow)
        Keyboard.removeListener('keyboardDidHide', keyboardDidHide)
      }
    }
  }, [])

  useEffect(() => {
    if (store.session.user) {
      const playerName = store.session.user.name
      setUserName(playerName)
    }
  }, [store.session.user])

  return (
    <ImageBackground source={image} style={startStyles.image}>
      <SafeAreaView style={styles.container}>
        <View style={[styles.container, { alignItems: 'center', paddingBottom: 18 }]}>
          <View style={startStyles.backButton}>
            <TouchableOpacity
              onPress={() => {
                reset()
                // navigation.goBack()
              }}
              hitSlop={{ top: 10, bottom: 20, left: 10, right: 20 }}
            >
              {/* <Text allowFontScaling={false} tx="common.back" style={startStyles.backText} /> */}
            </TouchableOpacity>
          </View>
          <NameInput
            doSubmit={doSubmit}
            errorMessage={errorMessage}
            imageHeight={imageHeight}
            loading={loading}
            userName={userName || ''}
            onChangeText={(value: string) => {
              if (errorMessage) {
                setErrorMessage(false)
              }

              setUserName(value)
            }}
          />
        </View>
      </SafeAreaView>
    </ImageBackground>
  )
})
